import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, ListGroup, Dropdown } from "react-bootstrap";

class SearchNSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      suggestions: []
    };
    this.timer = 0;
    this.getSuggestions = async (e, searchBy, searchTypesDict) => {
      const self = this;
      // functions
      const routeRegEx = (searchBy, value) => {
        if (
          searchBy === "SIC_4" ||
          searchBy === "sic" ||
          searchBy === "ST" ||
          searchBy === "MAIL_STATE" ||
          searchBy === "Model"
        ) {
          return new RegExp(`.*${value}.*`, "i");
        } else {
          return new RegExp(`^${value}.*`, "i");
        }
      };
      const routeFetch = async (e, searchText) => {
        e.preventDefault();
        let apiString = `${process.env.REACT_APP_api_url}/api/v2/data`;
        // Look up which columm is the States column
        let stateCol = Object.entries(searchTypesDict).find(col => {
          return col[1]["name"] === "States";
        })[0];
        if (searchBy === "AREA_CODE") {
          return searchTypesDict.AREA_CODE.items;
        } else if (searchBy === "COUNTY" || searchBy === "county") {
          let url = `${apiString}/fipsdata_cities?fields=COUNTY,ST,COUNT,FIPS_ST&filter=COUNTY%20LIKE%20"${searchText}%"&order=COUNT%20DESC&group=COUNT,COUNTY,ST`;
          let body = {
            fields: "COUNTY,ST,FIPS_ST,SUM(COUNT)",
            filter: `COUNTY LIKE '${searchText}%'`,
            order: "SUM(COUNT) DESC",
            group: "COUNTY,ST,FIPS_ST"
          };
          let data = await fetchSuggestions(e, searchText, url, body);
          let countyItems = [];
          let fieldsArray = body.fields.split(",");
          for (let r of data) {
            let county = self.capitalize(r[fieldsArray.indexOf("COUNTY")]);
            let state = r[fieldsArray.indexOf("ST")].toUpperCase();
            //console.log("county: ", county);
            if (county === "Miami-dade") {
              county = "Miami-Dade";
            } // temporary hack so we don't have to reindex
            countyItems.push({
              value: `${county}`,
              agg: { [stateCol]: state },
              name: `${county}, ${state}`
            });
          }
          return countyItems;
        } else if (
          searchBy === "CITY" ||
          searchBy === "MAIL_CITY" ||
          searchBy === "vinCity" ||
          searchBy === "city"
        ) {
          let url = `${apiString}/fipsdata_cities?filter=CITY%20LIKE%20"${searchText}%"&order=COUNT%20DESC`;
          let body = {
            fields: "CITY,FIPS_COMPOSITE,ST,COUNT",
            filter: `CITY LIKE '${searchText}%'`,
            order: "COUNT DESC"
          };
          let data = await fetchSuggestions(e, searchText, url, body);
          let cityItems = [];
          let fieldsArray = body.fields.split(",");
          for (let r of data) {
            let state = r[fieldsArray.indexOf("ST")].toUpperCase();
            let city = r[fieldsArray.indexOf("CITY")];
            //let fips_comp = r[fieldsArray.indexOf("FIPS_COMPOSITE")];

            if (searchBy === "vinCity") {
              cityItems.push({
                value: city,
                agg: { State: state },
                name: `${city}, ${state}`
              });
            } else if (searchBy === "city") {
              cityItems.push({
                value: city,
                agg: { state },
                name: `${city}, ${state}`
              });
            } else {
              cityItems.push({
                value: city,
                agg: { [stateCol]: state },
                name: `${city}, ${state}`
              });
            }
          }
          return cityItems;
        } else if (searchBy === "ST" || searchBy === "MAIL_STATE") {
          return searchTypesDict[searchBy]["items"];
        } else if (searchBy === "SIC_4") {
          let url = `${apiString}/business_siclist?filter=(SIC_4%20LIKE%20"${searchText}%")%20OR%20(SIC_NAME1%20LIKE%20"%${searchText}%")`;
          let body = {
            fields: "SIC_4,SIC_NAME1",
            filters: `(SIC_4 LIKE '${searchText}%') OR (SIC_NAME1 LIKE '${searchText}%')`,
            group: "SIC_4,SIC_NAME1"
          };
          let data = await fetchSuggestions(e, searchText, url, body);

          let sicItems = [];
          let fieldsArray = body.fields.split(",");
          for (let r of data) {
            let sic4 = r[fieldsArray.indexOf("SIC_4")];
            let sicName = self.capitalize(r[fieldsArray.indexOf("SIC_NAME1")]);
            sicItems.push({
              value: `${sic4}`,
              name: `${sic4} | ${sicName}`,
              meta: "SIC 4 - All records"
            });
          }

          let surl = `${apiString}/business_sicsearch?fields=SIC_4,SIC_CODE1,SIC_NAME1,Record_Count&filter=(SIC_4%20LIKE%20"${searchText}%")%20OR%20(SIC_NAME1%20LIKE%20"%${searchText}%")&order=RECORD_COUNT DESC`;
          let body2 = {
            fields: "SIC_4,SIC_CODE1,SIC_NAME1,RECORD_COUNT",
            filter: `(SIC_4 LIKE '${searchText}%') OR (SIC_NAME1 LIKE '${searchText}%')`,
            order: "RECORD_COUNT DESC"
          };

          let data2 = await fetchSuggestions(e, searchText, surl, body2);
          let sicItems2 = [];
          let fieldsArray2 = body2.fields.split(",");
          for (let r of data2) {
            let sic1 = r[fieldsArray2.indexOf("SIC_CODE1")];
            let sicName = r[fieldsArray2.indexOf("SIC_NAME1")];
            let count = r[
              fieldsArray2.indexOf("RECORD_COUNT")
            ].toLocaleString();

            sicItems2.push({
              value: sic1,
              name: `${sic1} | ${sicName}`,
              meta: `SIC 6 - ${count} records`,
              query: [`(SIC_CODE1 = "${sic1}")`],
              count: count
            });
          }

          sicItems = sicItems.concat(
            sicItems2.sort((a, b) => {
              return b.count - a.count;
            })
          );
          return sicItems;
        } else if (
          searchBy === "ZIP" ||
          searchBy === "MAIL_ZIP" ||
          searchBy === "vinZip" ||
          searchBy === "zip" ||
          searchBy === "MAIL_ZIP5"
        ) {
          let url = `${apiString}/fipsdata_zips?filter=ZIP%20LIKE%20"${searchText}%"&order=COUNT%20DESC`;
          let body = {
            fields: "ZIP,ST,COUNT",
            filter: `ZIP LIKE '${searchText}%'`,
            order: "COUNT DESC"
          };
          let data = await fetchSuggestions(e, searchText, url, body);
          let zipItems = [];
          let fieldsArray = body.fields.split(",");
          for (let r of data) {
            let zip = r[fieldsArray.indexOf("ZIP")];
            let state = r[fieldsArray.indexOf("ST")];
            let count = r[fieldsArray.indexOf("COUNT")];
            zipItems.push({
              value: zip,
              name: `${zip} - ${state}`,
              meta: `${count} records`
              //query: [`(${searchBy}="${zip}")`]
            });
          }
          return zipItems;
        } else if (searchBy === "Model") {
          let url = `https://listshack.apps.dreamfactory.com/api/v2/geo/vin2015/_table/makeModel?fields=MAKE,MODEL&filters=MODEL%20like%20"${searchText}%"&orderby=MODEL`;
          let data = await fetchSuggestions(e, searchText, url);
          let makes = [];
          for (let r of data) {
            let make = r.MAKE;
            let model = r.MODEL;
            makes.push({
              value: model,
              name: `${make}, ${model}`,
              query: [`(MAKE="${make}") and (MODEL="${model}")`]
            });
          }
          return makes;
        } else if (searchBy === "title") {
          let items = searchTypesDict[searchBy]["items"];
          let plusOne = {
            value: searchText,
            query: [`(title like ${searchText}%)`],
            name: searchText,
            meta: `All items starting with the text ${searchText}`
          };
          items.push(plusOne);
          return items;
        } else {
          return searchTypesDict[searchBy]["items"];
        }
      };

      const fetchSuggestions = async (e, searchText, url, postBody) => {
        //console.log("url: ", url);
        const init = {
          method: "POST",
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_api_token}`,
            "Content-Type": "application/json"
          },
          "Transfer-Encoding": "chunked",
          cache: "default",
          accept: "application/json",
          body: JSON.stringify(postBody)
        };

        try {
          //console.log("url: ", url);
          let response = await fetch(url, init);
          // only proceed once promise is resolved
          let data = await response.json();
          return await data.resource;
        } catch (error) {
          await console.error(error);
          // Tell the user that something went wrong.
          await self.props.handleAlerts(
            e,
            `Uh oh, something went wrong fetching suggestions. ${error.message}. Try changing your search.  If the problem persists send an email to ${process.env.REACT_APP_contact_email}`,
            "warning"
          );
        }
      };

      const value = e.target.value;
      let suggestions = [];
      let items = await routeFetch(e, value);
      let regex = await routeRegEx(searchBy, value);
      suggestions = await items.sort().filter(v => {
        return regex.test(v.name);
      });
      self.setState({ suggestions });
    };
  }

  capitalize(string) {
    return string.replace(/(?:^|\s)\S/g, function(a) {
      return a.toUpperCase();
    });
  }

  onSearchChanged(e) {
    e.persist();
    let searchBy = this.props.searchBy;
    if (e.target.value.length > 1) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(
        this.getSuggestions,
        300,
        e,
        searchBy,
        this.props.searchTypesDict
      );
    }
    return;
  }

  selectSuggestion(e, suggestion) {
    e.preventDefault();
    this.props.addQueryParam(this.props.dictKey, this.props.searchBy, [
      suggestion
    ]);
    this.setState({
      suggestions: [],
      searchText: ""
    });
  }

  render() {
    const { searchBy, searchTypesDict, selectSearchBy } = this.props;
    const { suggestions, searchText } = this.state;
    const searchTypes = Object.keys(searchTypesDict);
    let showSuggestions = searchText.length > 0 && suggestions.length > 0;
    return (
      <React.Fragment>
        <h5 style={{ marginTop: "30px", marginBottom: "20px" }}>
          Search by&nbsp;
          {this.props.dictKey === "geography" && (
            <Dropdown style={{ display: "inline-block" }}>
              <Dropdown.Toggle
                variant="link"
                style={{
                  fontSize: "1.3rem",
                  textDecoration: "underline",
                  display: "inline-block"
                }}
                id="dropdown-basic"
              >
                {searchTypesDict[searchBy]["name"].toLowerCase()}:
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {searchTypes.map((type, index) => (
                  <Dropdown.Item
                    onClick={e => selectSearchBy(e, type)}
                    as="button"
                    key={index}
                  >
                    {searchTypesDict[type]["name"]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {this.props.dictKey !== "geography" && (
            <span>{searchTypesDict[searchBy]["name"].toLowerCase()}:</span>
          )}
        </h5>
        <div>
          <Form.Group as={Row} controlId={`searchByForm_${searchBy}`}>
            <Form.Label column sm="2">
              {searchTypesDict[searchBy]["name"]}
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder={"Type " + searchTypesDict[searchBy]["name"]}
                onChange={e => {
                  this.onSearchChanged(e);
                  this.setState({ searchText: e.target.value });
                }}
                value={this.state.searchText}
                autoComplete="off"
              />
              <Form.Text className="text-muted text-left">
                Start typing to see possible options. Click your selection.
              </Form.Text>
              {showSuggestions && (
                <ListGroup
                  style={{
                    width: "94.5%",
                    zIndex: "1000",
                    position: "absolute"
                  }}
                >
                  {suggestions.map((suggestion, i) => (
                    <ListGroup.Item
                      key={suggestion.value + i}
                      variant="light"
                      onClick={e => this.selectSuggestion(e, suggestion)}
                      action
                    >
                      {suggestion.name}{" "}
                      <span
                        style={{
                          fontSize: ".8em",
                          fontStyle: "italic",
                          float: "right",
                          marginRight: "10px"
                        }}
                      >
                        {suggestion.meta ? suggestion.meta : null}
                      </span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Col>
          </Form.Group>
        </div>
      </React.Fragment>
    );
  }
}

SearchNSuggest.propTypes = {
  user: PropTypes.object,
  searchType: PropTypes.string,
  searchBy: PropTypes.string,
  addQueryParam: PropTypes.func,
  searchTypesDict: PropTypes.object,
  dictKey: PropTypes.string
};

export default SearchNSuggest;
