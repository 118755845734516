import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_firebase_apiKey,
  authDomain: process.env.REACT_APP_firebase_authDomain,
  databaseURL: process.env.REACT_APP_firebase_databaseURL,
  projectId: process.env.REACT_APP_firebase_projectId,
  storageBucket: process.env.REACT_APP_firebase_storageBucket,
  messagingSenderId: process.env.REACT_APP_firebase_messagingSenderId,
  appId: process.env.REACT_APP_firebase_appId,
  measurementId: process.env.REACT_APP_firebase_measurementId
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const storage = firebase.storage();
const functions = firebaseApp.functions();

const archiveDoc = (params, callback) => {
  let { docRef, unarchive } = params;
  if (typeof docRef === "undefined") {
    return console.error("Please include a document reference.");
  }
  try {
    docRef.update({
      archived: unarchive ? false : true,
      archivedAt: new Date()
    });
    console.log("Document archived.");
    if (callback) {
      callback();
    }
    return;
  } catch (err) {
    // Document probably doesn't exist.
    console.log("Error archiving document: ", err);
    throw new err();
  }
};

const batchWrite = async (params, callback) => {
  let { data } = params;
  // data shaped like:
  // [
  //    {
  //      ref: db reference
  //      op: "delete", "set", "update"
  //      data: {archived: true}
  //    }
  // ]
  try {
    let batch = db.batch();
    let batchWrites = [];
    let results = [];
    for (let [i, write] of data.entries()) {
      //console.log(i, write);
      if (write.op === "update") {
        batch.update(write.ref, write.data);
      }
      if (write.op === "delete") {
        batch.delete(write.ref);
      }
      if (write.op === "set") {
        batch.set(write.ref, write.data);
      }
      batchWrites.push(write);
      // if we've reached the max number in the batch, write them
      if (i > 0 && i % 500 === 0) {
        console.log("Writing batch to firestore.");
        await batch.commit().then(result => {
          console.log("Wrote batch to firestore through document: ", i);
          console.log("Firestore batch writes completed: ", results.length);
          results = [...batchWrites];
        });
        // empty the batch writes array in case this is exactly 500 being committed
        // to avoid doing an empty write when we leave the for loop
        batchWrites = [];
        batch = db.batch();
      }
    }

    if (batchWrites.length > 0) {
      console.log("Writing batch to firestore.");
      await batch.commit().then(() => {
        results = [...results, ...batchWrites];
      });
    }
    console.log("Batch write completed.");
    if (callback) {
      return callback(null, results);
    }
    return results;
  } catch (error) {
    console.log("Error writing batch: ", error);
    if (callback) {
      return callback(error);
    }
    return error;
  }
};

const getUserDocs = async uid => {
  //console.log(uid);
  let userDoc;
  let userDocPromise = new Promise(async (resolve, reject) => {
    try {
      let usersSnap = await db
        .collection("users")
        .where("uid", "==", uid)
        .onSnapshot(uidSnap => {
          if (!uidSnap.empty) {
            //there is a user with this uid
            uidSnap.forEach(async doc => {
              userDoc = doc.data();
              if (uid === userDoc.id) {
                console.log(
                  "This user is not an authorized user of another account."
                );
                resolve([userDoc, doc.id]);
              } else {
                console.log(
                  "This user is an authorized user of someone else's account"
                );
                let accountSnap = await db
                  .collection("users")
                  .where("uid", "==", userDoc.id)
                  .get();
                if (!accountSnap.empty) {
                  accountSnap.forEach(aDoc => {
                    userDoc = aDoc.data();
                    userDoc._docId = doc.id;
                    userDoc._docRef = doc.ref;
                    resolve([userDoc, aDoc.id]);
                  });
                }
              }
              // Detach the listener
              usersSnap();
            });
          } else {
            console.log(
              "Do nothing, the user document hasn't been created yet!"
            );
          }
        });
    } catch (err) {
      console.log("Error getting user docs: ", err);
    }
  });
  return userDocPromise;
};

const getCustomerDocs = async email => {
  let customerDoc, customerDocId;
  let customerDocs = await db
    .collection("customers")
    .where("email", "==", email)
    .get();

  customerDocs.forEach(doc => {
    customerDoc = doc.data();
    customerDocId = doc.id;
    customerDoc._docId = doc.id;
    customerDoc._docRef = doc.ref;
  });
  return [customerDoc, customerDocId];
};

export {
  firebase,
  db,
  firebaseApp,
  storage,
  functions,
  archiveDoc,
  batchWrite,
  getUserDocs,
  getCustomerDocs
};
