export default {
  geography: {
    AREA_CODE: {
      name: 'Area codes',
      displayHeader: true,
      inputType: "geography",
      items: [
        {value: "201", name: "201", meta:" - NJ"},
        {value: "202", name: "202", meta:" - DC"},
        {value: "203", name: "203", meta:" - CT"},
        {value: "204", name: "204", meta:" - MANITOBA"},
        {value: "205", name: "205", meta:" - AL"},
        {value: "206", name: "206", meta:" - WA"},
        {value: "207", name: "207", meta:" - ME"},
        {value: "208", name: "208", meta:" - ID"},
        {value: "209", name: "209", meta:" - CA"},
        {value: "210", name: "210", meta:" - TX"},
        {value: "212", name: "212", meta:" - NY"},
        {value: "213", name: "213", meta:" - CA"},
        {value: "214", name: "214", meta:" - TX"},
        {value: "215", name: "215", meta:" - PA"},
        {value: "216", name: "216", meta:" - OH"},
        {value: "217", name: "217", meta:" - IL"},
        {value: "218", name: "218", meta:" - MN"},
        {value: "219", name: "219", meta:" - IN"},
        {value: "220", name: "220", meta:" - OH"},
        {value: "223", name: "223", meta:" - PA"},
        {value: "224", name: "224", meta:" - IL"},
        {value: "225", name: "225", meta:" - LA"},
        {value: "226", name: "226", meta:" - ONTARIO"},
        {value: "228", name: "228", meta:" - MS"},
        {value: "229", name: "229", meta:" - GA"},
        {value: "231", name: "231", meta:" - MI"},
        {value: "234", name: "234", meta:" - OH"},
        {value: "236", name: "236", meta:" - BRITISH COLUMBIA"},
        {value: "239", name: "239", meta:" - FL"},
        {value: "240", name: "240", meta:" - MD"},
        {value: "242", name: "242", meta:" - BAHAMAS"},
        {value: "246", name: "246", meta:" - BARBADOS"},
        {value: "248", name: "248", meta:" - MI"},
        {value: "249", name: "249", meta:" - ONTARIO"},
        {value: "250", name: "250", meta:" - BRITISH COLUMBIA"},
        {value: "251", name: "251", meta:" - AL"},
        {value: "252", name: "252", meta:" - NC"},
        {value: "253", name: "253", meta:" - WA"},
        {value: "254", name: "254", meta:" - TX"},
        {value: "256", name: "256", meta:" - AL"},
        {value: "260", name: "260", meta:" - IN"},
        {value: "262", name: "262", meta:" - WI"},
        {value: "264", name: "264", meta:" - ANGUILLA"},
        {value: "267", name: "267", meta:" - PA"},
        {value: "268", name: "268", meta:" - ANTIGUA/BARBUDA"},
        {value: "269", name: "269", meta:" - MI"},
        {value: "270", name: "270", meta:" - KY"},
        {value: "272", name: "272", meta:" - PA"},
        {value: "276", name: "276", meta:" - VA"},
        {value: "279", name: "279", meta:" - CA"},
        {value: "281", name: "281", meta:" - TX"},
        {value: "284", name: "284", meta:" - BRITISH VIRGIN ISLANDS"},
        {value: "289", name: "289", meta:" - ONTARIO"},
        {value: "301", name: "301", meta:" - MD"},
        {value: "302", name: "302", meta:" - DE"},
        {value: "303", name: "303", meta:" - CO"},
        {value: "304", name: "304", meta:" - WV"},
        {value: "305", name: "305", meta:" - FL"},
        {value: "306", name: "306", meta:" - SASKATCHEWAN"},
        {value: "307", name: "307", meta:" - WY"},
        {value: "308", name: "308", meta:" - NE"},
        {value: "309", name: "309", meta:" - IL"},
        {value: "310", name: "310", meta:" - CA"},
        {value: "312", name: "312", meta:" - IL"},
        {value: "313", name: "313", meta:" - MI"},
        {value: "314", name: "314", meta:" - MO"},
        {value: "315", name: "315", meta:" - NY"},
        {value: "316", name: "316", meta:" - KS"},
        {value: "317", name: "317", meta:" - IN"},
        {value: "318", name: "318", meta:" - LA"},
        {value: "319", name: "319", meta:" - IA"},
        {value: "320", name: "320", meta:" - MN"},
        {value: "321", name: "321", meta:" - FL"},
        {value: "323", name: "323", meta:" - CA"},
        {value: "325", name: "325", meta:" - TX"},
        {value: "330", name: "330", meta:" - OH"},
        {value: "331", name: "331", meta:" - IL"},
        {value: "332", name: "332", meta:" - NY"},
        {value: "334", name: "334", meta:" - AL"},
        {value: "336", name: "336", meta:" - NC"},
        {value: "337", name: "337", meta:" - LA"},
        {value: "339", name: "339", meta:" - MA"},
        {value: "340", name: "340", meta:" - USVI"},
        {value: "341", name: "341", meta:" - CA"},
        {value: "343", name: "343", meta:" - ONTARIO"},
        {value: "345", name: "345", meta:" - CAYMAN ISLANDS"},
        {value: "346", name: "346", meta:" - TX"},
        {value: "347", name: "347", meta:" - NY"},
        {value: "351", name: "351", meta:" - MA"},
        {value: "352", name: "352", meta:" - FL"},
        {value: "360", name: "360", meta:" - WA"},
        {value: "361", name: "361", meta:" - TX"},
        {value: "364", name: "364", meta:" - KY"},
        {value: "365", name: "365", meta:" - ONTARIO"},
        {value: "367", name: "367", meta:" - QUEBEC"},
        {value: "380", name: "380", meta:" - OH"},
        {value: "385", name: "385", meta:" - UT"},
        {value: "386", name: "386", meta:" - FL"},
        {value: "401", name: "401", meta:" - RI"},
        {value: "402", name: "402", meta:" - NE"},
        {value: "403", name: "403", meta:" - ALBERTA"},
        {value: "404", name: "404", meta:" - GA"},
        {value: "405", name: "405", meta:" - OK"},
        {value: "406", name: "406", meta:" - MT"},
        {value: "407", name: "407", meta:" - FL"},
        {value: "408", name: "408", meta:" - CA"},
        {value: "409", name: "409", meta:" - TX"},
        {value: "410", name: "410", meta:" - MD"},
        {value: "412", name: "412", meta:" - PA"},
        {value: "413", name: "413", meta:" - MA"},
        {value: "414", name: "414", meta:" - WI"},
        {value: "415", name: "415", meta:" - CA"},
        {value: "416", name: "416", meta:" - ONTARIO"},
        {value: "417", name: "417", meta:" - MO"},
        {value: "418", name: "418", meta:" - QUEBEC"},
        {value: "419", name: "419", meta:" - OH"},
        {value: "423", name: "423", meta:" - TN"},
        {value: "424", name: "424", meta:" - CA"},
        {value: "425", name: "425", meta:" - WA"},
        {value: "430", name: "430", meta:" - TX"},
        {value: "431", name: "431", meta:" - MANITOBA"},
        {value: "432", name: "432", meta:" - TX"},
        {value: "434", name: "434", meta:" - VA"},
        {value: "435", name: "435", meta:" - UT"},
        {value: "437", name: "437", meta:" - ONTARIO"},
        {value: "438", name: "438", meta:" - QUEBEC"},
        {value: "440", name: "440", meta:" - OH"},
        {value: "441", name: "441", meta:" - BERMUDA"},
        {value: "442", name: "442", meta:" - CA"},
        {value: "443", name: "443", meta:" - MD"},
        {value: "445", name: "445", meta:" - PA"},
        {value: "450", name: "450", meta:" - QUEBEC"},
        {value: "458", name: "458", meta:" - OR"},
        {value: "463", name: "463", meta:" - IN"},
        {value: "469", name: "469", meta:" - TX"},
        {value: "470", name: "470", meta:" - GA"},
        {value: "473", name: "473", meta:" - GRENADA"},
        {value: "475", name: "475", meta:" - CT"},
        {value: "478", name: "478", meta:" - GA"},
        {value: "479", name: "479", meta:" - AR"},
        {value: "480", name: "480", meta:" - AZ"},
        {value: "484", name: "484", meta:" - PA"},
        {value: "501", name: "501", meta:" - AR"},
        {value: "502", name: "502", meta:" - KY"},
        {value: "503", name: "503", meta:" - OR"},
        {value: "504", name: "504", meta:" - LA"},
        {value: "505", name: "505", meta:" - NM"},
        {value: "506", name: "506", meta:" - NEW BRUNSWICK"},
        {value: "507", name: "507", meta:" - MN"},
        {value: "508", name: "508", meta:" - MA"},
        {value: "509", name: "509", meta:" - WA"},
        {value: "510", name: "510", meta:" - CA"},
        {value: "512", name: "512", meta:" - TX"},
        {value: "513", name: "513", meta:" - OH"},
        {value: "514", name: "514", meta:" - QUEBEC"},
        {value: "515", name: "515", meta:" - IA"},
        {value: "516", name: "516", meta:" - NY"},
        {value: "517", name: "517", meta:" - MI"},
        {value: "518", name: "518", meta:" - NY"},
        {value: "519", name: "519", meta:" - ONTARIO"},
        {value: "520", name: "520", meta:" - AZ"},
        {value: "530", name: "530", meta:" - CA"},
        {value: "531", name: "531", meta:" - NE"},
        {value: "534", name: "534", meta:" - WI"},
        {value: "539", name: "539", meta:" - OK"},
        {value: "540", name: "540", meta:" - VA"},
        {value: "541", name: "541", meta:" - OR"},
        {value: "548", name: "548", meta:" - ONTARIO"},
        {value: "551", name: "551", meta:" - NJ"},
        {value: "559", name: "559", meta:" - CA"},
        {value: "561", name: "561", meta:" - FL"},
        {value: "562", name: "562", meta:" - CA"},
        {value: "563", name: "563", meta:" - IA"},
        {value: "564", name: "564", meta:" - WA"},
        {value: "567", name: "567", meta:" - OH"},
        {value: "570", name: "570", meta:" - PA"},
        {value: "571", name: "571", meta:" - VA"},
        {value: "573", name: "573", meta:" - MO"},
        {value: "574", name: "574", meta:" - IN"},
        {value: "575", name: "575", meta:" - NM"},
        {value: "579", name: "579", meta:" - QUEBEC"},
        {value: "580", name: "580", meta:" - OK"},
        {value: "581", name: "581", meta:" - QUEBEC"},
        {value: "585", name: "585", meta:" - NY"},
        {value: "586", name: "586", meta:" - MI"},
        {value: "587", name: "587", meta:" - ALBERTA"},
        {value: "601", name: "601", meta:" - MS"},
        {value: "602", name: "602", meta:" - AZ"},
        {value: "603", name: "603", meta:" - NH"},
        {value: "604", name: "604", meta:" - BRITISH COLUMBIA"},
        {value: "605", name: "605", meta:" - SD"},
        {value: "606", name: "606", meta:" - KY"},
        {value: "607", name: "607", meta:" - NY"},
        {value: "608", name: "608", meta:" - WI"},
        {value: "609", name: "609", meta:" - NJ"},
        {value: "610", name: "610", meta:" - PA"},
        {value: "612", name: "612", meta:" - MN"},
        {value: "613", name: "613", meta:" - ONTARIO"},
        {value: "614", name: "614", meta:" - OH"},
        {value: "615", name: "615", meta:" - TN"},
        {value: "616", name: "616", meta:" - MI"},
        {value: "617", name: "617", meta:" - MA"},
        {value: "618", name: "618", meta:" - IL"},
        {value: "619", name: "619", meta:" - CA"},
        {value: "620", name: "620", meta:" - KS"},
        {value: "623", name: "623", meta:" - AZ"},
        {value: "626", name: "626", meta:" - CA"},
        {value: "628", name: "628", meta:" - CA"},
        {value: "629", name: "629", meta:" - TN"},
        {value: "630", name: "630", meta:" - IL"},
        {value: "631", name: "631", meta:" - NY"},
        {value: "636", name: "636", meta:" - MO"},
        {value: "639", name: "639", meta:" - SASKATCHEWAN"},
        {value: "640", name: "640", meta:" - NJ"},
        {value: "641", name: "641", meta:" - IA"},
        {value: "646", name: "646", meta:" - NY"},
        {value: "647", name: "647", meta:" - ONTARIO"},
        {value: "649", name: "649", meta:" - TURKS & CAICOS ISLANDS"},
        {value: "650", name: "650", meta:" - CA"},
        {value: "651", name: "651", meta:" - MN"},
        {value: "657", name: "657", meta:" - CA"},
        {value: "658", name: "658", meta:" - JAMAICA"},
        {value: "660", name: "660", meta:" - MO"},
        {value: "661", name: "661", meta:" - CA"},
        {value: "662", name: "662", meta:" - MS"},
        {value: "664", name: "664", meta:" - MONTSERRAT"},
        {value: "667", name: "667", meta:" - MD"},
        {value: "669", name: "669", meta:" - CA"},
        {value: "670", name: "670", meta:" - CNMI"},
        {value: "671", name: "671", meta:" - GU"},
        {value: "672", name: "672", meta:" - BRITISH COLUMBIA"},
        {value: "678", name: "678", meta:" - GA"},
        {value: "680", name: "680", meta:" - NY"},
        {value: "681", name: "681", meta:" - WV"},
        {value: "682", name: "682", meta:" - TX"},
        {value: "684", name: "684", meta:" - AS"},
        {value: "689", name: "689", meta:" - FL"},
        {value: "701", name: "701", meta:" - ND"},
        {value: "702", name: "702", meta:" - NV"},
        {value: "703", name: "703", meta:" - VA"},
        {value: "704", name: "704", meta:" - NC"},
        {value: "705", name: "705", meta:" - ONTARIO"},
        {value: "706", name: "706", meta:" - GA"},
        {value: "707", name: "707", meta:" - CA"},
        {value: "708", name: "708", meta:" - IL"},
        {value: "709", name: "709", meta:" - NEWFOUNDLAND AND LABRADOR"},
        {value: "712", name: "712", meta:" - IA"},
        {value: "713", name: "713", meta:" - TX"},
        {value: "714", name: "714", meta:" - CA"},
        {value: "715", name: "715", meta:" - WI"},
        {value: "716", name: "716", meta:" - NY"},
        {value: "717", name: "717", meta:" - PA"},
        {value: "718", name: "718", meta:" - NY"},
        {value: "719", name: "719", meta:" - CO"},
        {value: "720", name: "720", meta:" - CO"},
        {value: "721", name: "721", meta:" - SINT MAARTEN"},
        {value: "724", name: "724", meta:" - PA"},
        {value: "725", name: "725", meta:" - NV"},
        {value: "726", name: "726", meta:" - TX"},
        {value: "727", name: "727", meta:" - FL"},
        {value: "731", name: "731", meta:" - TN"},
        {value: "732", name: "732", meta:" - NJ"},
        {value: "734", name: "734", meta:" - MI"},
        {value: "737", name: "737", meta:" - TX"},
        {value: "740", name: "740", meta:" - OH"},
        {value: "743", name: "743", meta:" - NC"},
        {value: "747", name: "747", meta:" - CA"},
        {value: "754", name: "754", meta:" - FL"},
        {value: "757", name: "757", meta:" - VA"},
        {value: "758", name: "758", meta:" - ST. LUCIA"},
        {value: "760", name: "760", meta:" - CA"},
        {value: "762", name: "762", meta:" - GA"},
        {value: "763", name: "763", meta:" - MN"},
        {value: "765", name: "765", meta:" - IN"},
        {value: "767", name: "767", meta:" - DOMINICA"},
        {value: "769", name: "769", meta:" - MS"},
        {value: "770", name: "770", meta:" - GA"},
        {value: "772", name: "772", meta:" - FL"},
        {value: "773", name: "773", meta:" - IL"},
        {value: "774", name: "774", meta:" - MA"},
        {value: "775", name: "775", meta:" - NV"},
        {value: "778", name: "778", meta:" - BRITISH COLUMBIA"},
        {value: "779", name: "779", meta:" - IL"},
        {value: "780", name: "780", meta:" - ALBERTA"},
        {value: "781", name: "781", meta:" - MA"},
        {value: "782", name: "782", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: "784", name: "784", meta:" - ST. VINCENT & GRENADINES"},
        {value: "785", name: "785", meta:" - KS"},
        {value: "786", name: "786", meta:" - FL"},
        {value: "787", name: "787", meta:" - PUERTO RICO"},
        {value: "801", name: "801", meta:" - UT"},
        {value: "802", name: "802", meta:" - VT"},
        {value: "803", name: "803", meta:" - SC"},
        {value: "804", name: "804", meta:" - VA"},
        {value: "805", name: "805", meta:" - CA"},
        {value: "806", name: "806", meta:" - TX"},
        {value: "807", name: "807", meta:" - ONTARIO"},
        {value: "808", name: "808", meta:" - HI"},
        {value: "809", name: "809", meta:" - DOMINICAN REPUBLIC"},
        {value: "810", name: "810", meta:" - MI"},
        {value: "812", name: "812", meta:" - IN"},
        {value: "813", name: "813", meta:" - FL"},
        {value: "814", name: "814", meta:" - PA"},
        {value: "815", name: "815", meta:" - IL"},
        {value: "816", name: "816", meta:" - MO"},
        {value: "817", name: "817", meta:" - TX"},
        {value: "818", name: "818", meta:" - CA"},
        {value: "819", name: "819", meta:" - QUEBEC"},
        {value: "820", name: "820", meta:" - CA"},
        {value: "825", name: "825", meta:" - ALBERTA"},
        {value: "828", name: "828", meta:" - NC"},
        {value: "829", name: "829", meta:" - DOMINICAN REPUBLIC"},
        {value: "830", name: "830", meta:" - TX"},
        {value: "831", name: "831", meta:" - CA"},
        {value: "832", name: "832", meta:" - TX"},
        {value: "838", name: "838", meta:" - NY"},
        {value: "843", name: "843", meta:" - SC"},
        {value: "845", name: "845", meta:" - NY"},
        {value: "847", name: "847", meta:" - IL"},
        {value: "848", name: "848", meta:" - NJ"},
        {value: "849", name: "849", meta:" - DOMINICAN REPUBLIC"},
        {value: "850", name: "850", meta:" - FL"},
        {value: "854", name: "854", meta:" - SC"},
        {value: "856", name: "856", meta:" - NJ"},
        {value: "857", name: "857", meta:" - MA"},
        {value: "858", name: "858", meta:" - CA"},
        {value: "859", name: "859", meta:" - KY"},
        {value: "860", name: "860", meta:" - CT"},
        {value: "862", name: "862", meta:" - NJ"},
        {value: "863", name: "863", meta:" - FL"},
        {value: "864", name: "864", meta:" - SC"},
        {value: "865", name: "865", meta:" - TN"},
        {value: "867", name: "867", meta:" - YUKON-NW TERR. - NUNAVUT"},
        {value: "868", name: "868", meta:" - TRINIDAD & TOBAGO"},
        {value: "869", name: "869", meta:" - ST. KITTS & NEVIS"},
        {value: "870", name: "870", meta:" - AR"},
        {value: "872", name: "872", meta:" - IL"},
        {value: "873", name: "873", meta:" - QUEBEC"},
        {value: "876", name: "876", meta:" - JAMAICA"},
        {value: "878", name: "878", meta:" - PA"},
        {value: "901", name: "901", meta:" - TN"},
        {value: "902", name: "902", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: "903", name: "903", meta:" - TX"},
        {value: "904", name: "904", meta:" - FL"},
        {value: "905", name: "905", meta:" - ONTARIO"},
        {value: "906", name: "906", meta:" - MI"},
        {value: "907", name: "907", meta:" - AK"},
        {value: "908", name: "908", meta:" - NJ"},
        {value: "909", name: "909", meta:" - CA"},
        {value: "910", name: "910", meta:" - NC"},
        {value: "912", name: "912", meta:" - GA"},
        {value: "913", name: "913", meta:" - KS"},
        {value: "914", name: "914", meta:" - NY"},
        {value: "915", name: "915", meta:" - TX"},
        {value: "916", name: "916", meta:" - CA"},
        {value: "917", name: "917", meta:" - NY"},
        {value: "918", name: "918", meta:" - OK"},
        {value: "919", name: "919", meta:" - NC"},
        {value: "920", name: "920", meta:" - WI"},
        {value: "925", name: "925", meta:" - CA"},
        {value: "928", name: "928", meta:" - AZ"},
        {value: "929", name: "929", meta:" - NY"},
        {value: "930", name: "930", meta:" - IN"},
        {value: "931", name: "931", meta:" - TN"},
        {value: "934", name: "934", meta:" - NY"},
        {value: "936", name: "936", meta:" - TX"},
        {value: "937", name: "937", meta:" - OH"},
        {value: "938", name: "938", meta:" - AL"},
        {value: "939", name: "939", meta:" - PUERTO RICO"},
        {value: "940", name: "940", meta:" - TX"},
        {value: "941", name: "941", meta:" - FL"},
        {value: "947", name: "947", meta:" - MI"},
        {value: "949", name: "949", meta:" - CA"},
        {value: "951", name: "951", meta:" - CA"},
        {value: "952", name: "952", meta:" - MN"},
        {value: "954", name: "954", meta:" - FL"},
        {value: "956", name: "956", meta:" - TX"},
        {value: "959", name: "959", meta:" - CT"},
        {value: "970", name: "970", meta:" - CO"},
        {value: "971", name: "971", meta:" - OR"},
        {value: "972", name: "972", meta:" - TX"},
        {value: "973", name: "973", meta:" - NJ"},
        {value: "978", name: "978", meta:" - MA"},
        {value: "979", name: "979", meta:" - TX"},
        {value: "980", name: "980", meta:" - NC"},
        {value: "984", name: "984", meta:" - NC"},
        {value: "985", name: "985", meta:" - LA"},
        {value: "986", name: "986", meta:" - ID"},
        {value: "989", name: "989", meta:" - MI"},
      ],
      helpText: "Select up to 5 area codes per search"
    },
    AREA_CODE_Integer: {
      name: 'Area codes',
      displayHeader: true,
      inputType: "geography",
      items: [
        {value: 201, name: "201", meta:" - NJ"},
        {value: 202, name: "202", meta:" - DC"},
        {value: 203, name: "203", meta:" - CT"},
        {value: 204, name: "204", meta:" - MANITOBA"},
        {value: 205, name: "205", meta:" - AL"},
        {value: 206, name: "206", meta:" - WA"},
        {value: 207, name: "207", meta:" - ME"},
        {value: 208, name: "208", meta:" - ID"},
        {value: 209, name: "209", meta:" - CA"},
        {value: 210, name: "210", meta:" - TX"},
        {value: 212, name: "212", meta:" - NY"},
        {value: 213, name: "213", meta:" - CA"},
        {value: 214, name: "214", meta:" - TX"},
        {value: 215, name: "215", meta:" - PA"},
        {value: 216, name: "216", meta:" - OH"},
        {value: 217, name: "217", meta:" - IL"},
        {value: 218, name: "218", meta:" - MN"},
        {value: 219, name: "219", meta:" - IN"},
        {value: 220, name: "220", meta:" - OH"},
        {value: 223, name: "223", meta:" - PA"},
        {value: 224, name: "224", meta:" - IL"},
        {value: 225, name: "225", meta:" - LA"},
        {value: 226, name: "226", meta:" - ONTARIO"},
        {value: 228, name: "228", meta:" - MS"},
        {value: 229, name: "229", meta:" - GA"},
        {value: 231, name: "231", meta:" - MI"},
        {value: 234, name: "234", meta:" - OH"},
        {value: 236, name: "236", meta:" - BRITISH COLUMBIA"},
        {value: 239, name: "239", meta:" - FL"},
        {value: 240, name: "240", meta:" - MD"},
        {value: 242, name: "242", meta:" - BAHAMAS"},
        {value: 246, name: "246", meta:" - BARBADOS"},
        {value: 248, name: "248", meta:" - MI"},
        {value: 249, name: "249", meta:" - ONTARIO"},
        {value: 250, name: "250", meta:" - BRITISH COLUMBIA"},
        {value: 251, name: "251", meta:" - AL"},
        {value: 252, name: "252", meta:" - NC"},
        {value: 253, name: "253", meta:" - WA"},
        {value: 254, name: "254", meta:" - TX"},
        {value: 256, name: "256", meta:" - AL"},
        {value: 260, name: "260", meta:" - IN"},
        {value: 262, name: "262", meta:" - WI"},
        {value: 264, name: "264", meta:" - ANGUILLA"},
        {value: 267, name: "267", meta:" - PA"},
        {value: 268, name: "268", meta:" - ANTIGUA/BARBUDA"},
        {value: 269, name: "269", meta:" - MI"},
        {value: 270, name: "270", meta:" - KY"},
        {value: 272, name: "272", meta:" - PA"},
        {value: 276, name: "276", meta:" - VA"},
        {value: 279, name: "279", meta:" - CA"},
        {value: 281, name: "281", meta:" - TX"},
        {value: 284, name: "284", meta:" - BRITISH VIRGIN ISLANDS"},
        {value: 289, name: "289", meta:" - ONTARIO"},
        {value: 301, name: "301", meta:" - MD"},
        {value: 302, name: "302", meta:" - DE"},
        {value: 303, name: "303", meta:" - CO"},
        {value: 304, name: "304", meta:" - WV"},
        {value: 305, name: "305", meta:" - FL"},
        {value: 306, name: "306", meta:" - SASKATCHEWAN"},
        {value: 307, name: "307", meta:" - WY"},
        {value: 308, name: "308", meta:" - NE"},
        {value: 309, name: "309", meta:" - IL"},
        {value: 310, name: "310", meta:" - CA"},
        {value: 312, name: "312", meta:" - IL"},
        {value: 313, name: "313", meta:" - MI"},
        {value: 314, name: "314", meta:" - MO"},
        {value: 315, name: "315", meta:" - NY"},
        {value: 316, name: "316", meta:" - KS"},
        {value: 317, name: "317", meta:" - IN"},
        {value: 318, name: "318", meta:" - LA"},
        {value: 319, name: "319", meta:" - IA"},
        {value: 320, name: "320", meta:" - MN"},
        {value: 321, name: "321", meta:" - FL"},
        {value: 323, name: "323", meta:" - CA"},
        {value: 325, name: "325", meta:" - TX"},
        {value: 330, name: "330", meta:" - OH"},
        {value: 331, name: "331", meta:" - IL"},
        {value: 332, name: "332", meta:" - NY"},
        {value: 334, name: "334", meta:" - AL"},
        {value: 336, name: "336", meta:" - NC"},
        {value: 337, name: "337", meta:" - LA"},
        {value: 339, name: "339", meta:" - MA"},
        {value: 340, name: "340", meta:" - USVI"},
        {value: 341, name: "341", meta:" - CA"},
        {value: 343, name: "343", meta:" - ONTARIO"},
        {value: 345, name: "345", meta:" - CAYMAN ISLANDS"},
        {value: 346, name: "346", meta:" - TX"},
        {value: 347, name: "347", meta:" - NY"},
        {value: 351, name: "351", meta:" - MA"},
        {value: 352, name: "352", meta:" - FL"},
        {value: 360, name: "360", meta:" - WA"},
        {value: 361, name: "361", meta:" - TX"},
        {value: 364, name: "364", meta:" - KY"},
        {value: 365, name: "365", meta:" - ONTARIO"},
        {value: 367, name: "367", meta:" - QUEBEC"},
        {value: 380, name: "380", meta:" - OH"},
        {value: 385, name: "385", meta:" - UT"},
        {value: 386, name: "386", meta:" - FL"},
        {value: 401, name: "401", meta:" - RI"},
        {value: 402, name: "402", meta:" - NE"},
        {value: 403, name: "403", meta:" - ALBERTA"},
        {value: 404, name: "404", meta:" - GA"},
        {value: 405, name: "405", meta:" - OK"},
        {value: 406, name: "406", meta:" - MT"},
        {value: 407, name: "407", meta:" - FL"},
        {value: 408, name: "408", meta:" - CA"},
        {value: 409, name: "409", meta:" - TX"},
        {value: 410, name: "410", meta:" - MD"},
        {value: 412, name: "412", meta:" - PA"},
        {value: 413, name: "413", meta:" - MA"},
        {value: 414, name: "414", meta:" - WI"},
        {value: 415, name: "415", meta:" - CA"},
        {value: 416, name: "416", meta:" - ONTARIO"},
        {value: 417, name: "417", meta:" - MO"},
        {value: 418, name: "418", meta:" - QUEBEC"},
        {value: 419, name: "419", meta:" - OH"},
        {value: 423, name: "423", meta:" - TN"},
        {value: 424, name: "424", meta:" - CA"},
        {value: 425, name: "425", meta:" - WA"},
        {value: 430, name: "430", meta:" - TX"},
        {value: 431, name: "431", meta:" - MANITOBA"},
        {value: 432, name: "432", meta:" - TX"},
        {value: 434, name: "434", meta:" - VA"},
        {value: 435, name: "435", meta:" - UT"},
        {value: 437, name: "437", meta:" - ONTARIO"},
        {value: 438, name: "438", meta:" - QUEBEC"},
        {value: 440, name: "440", meta:" - OH"},
        {value: 441, name: "441", meta:" - BERMUDA"},
        {value: 442, name: "442", meta:" - CA"},
        {value: 443, name: "443", meta:" - MD"},
        {value: 445, name: "445", meta:" - PA"},
        {value: 450, name: "450", meta:" - QUEBEC"},
        {value: 458, name: "458", meta:" - OR"},
        {value: 463, name: "463", meta:" - IN"},
        {value: 469, name: "469", meta:" - TX"},
        {value: 470, name: "470", meta:" - GA"},
        {value: 473, name: "473", meta:" - GRENADA"},
        {value: 475, name: "475", meta:" - CT"},
        {value: 478, name: "478", meta:" - GA"},
        {value: 479, name: "479", meta:" - AR"},
        {value: 480, name: "480", meta:" - AZ"},
        {value: 484, name: "484", meta:" - PA"},
        {value: 501, name: "501", meta:" - AR"},
        {value: 502, name: "502", meta:" - KY"},
        {value: 503, name: "503", meta:" - OR"},
        {value: 504, name: "504", meta:" - LA"},
        {value: 505, name: "505", meta:" - NM"},
        {value: 506, name: "506", meta:" - NEW BRUNSWICK"},
        {value: 507, name: "507", meta:" - MN"},
        {value: 508, name: "508", meta:" - MA"},
        {value: 509, name: "509", meta:" - WA"},
        {value: 510, name: "510", meta:" - CA"},
        {value: 512, name: "512", meta:" - TX"},
        {value: 513, name: "513", meta:" - OH"},
        {value: 514, name: "514", meta:" - QUEBEC"},
        {value: 515, name: "515", meta:" - IA"},
        {value: 516, name: "516", meta:" - NY"},
        {value: 517, name: "517", meta:" - MI"},
        {value: 518, name: "518", meta:" - NY"},
        {value: 519, name: "519", meta:" - ONTARIO"},
        {value: 520, name: "520", meta:" - AZ"},
        {value: 530, name: "530", meta:" - CA"},
        {value: 531, name: "531", meta:" - NE"},
        {value: 534, name: "534", meta:" - WI"},
        {value: 539, name: "539", meta:" - OK"},
        {value: 540, name: "540", meta:" - VA"},
        {value: 541, name: "541", meta:" - OR"},
        {value: 548, name: "548", meta:" - ONTARIO"},
        {value: 551, name: "551", meta:" - NJ"},
        {value: 559, name: "559", meta:" - CA"},
        {value: 561, name: "561", meta:" - FL"},
        {value: 562, name: "562", meta:" - CA"},
        {value: 563, name: "563", meta:" - IA"},
        {value: 564, name: "564", meta:" - WA"},
        {value: 567, name: "567", meta:" - OH"},
        {value: 570, name: "570", meta:" - PA"},
        {value: 571, name: "571", meta:" - VA"},
        {value: 573, name: "573", meta:" - MO"},
        {value: 574, name: "574", meta:" - IN"},
        {value: 575, name: "575", meta:" - NM"},
        {value: 579, name: "579", meta:" - QUEBEC"},
        {value: 580, name: "580", meta:" - OK"},
        {value: 581, name: "581", meta:" - QUEBEC"},
        {value: 585, name: "585", meta:" - NY"},
        {value: 586, name: "586", meta:" - MI"},
        {value: 587, name: "587", meta:" - ALBERTA"},
        {value: 601, name: "601", meta:" - MS"},
        {value: 602, name: "602", meta:" - AZ"},
        {value: 603, name: "603", meta:" - NH"},
        {value: 604, name: "604", meta:" - BRITISH COLUMBIA"},
        {value: 605, name: "605", meta:" - SD"},
        {value: 606, name: "606", meta:" - KY"},
        {value: 607, name: "607", meta:" - NY"},
        {value: 608, name: "608", meta:" - WI"},
        {value: 609, name: "609", meta:" - NJ"},
        {value: 610, name: "610", meta:" - PA"},
        {value: 612, name: "612", meta:" - MN"},
        {value: 613, name: "613", meta:" - ONTARIO"},
        {value: 614, name: "614", meta:" - OH"},
        {value: 615, name: "615", meta:" - TN"},
        {value: 616, name: "616", meta:" - MI"},
        {value: 617, name: "617", meta:" - MA"},
        {value: 618, name: "618", meta:" - IL"},
        {value: 619, name: "619", meta:" - CA"},
        {value: 620, name: "620", meta:" - KS"},
        {value: 623, name: "623", meta:" - AZ"},
        {value: 626, name: "626", meta:" - CA"},
        {value: 628, name: "628", meta:" - CA"},
        {value: 629, name: "629", meta:" - TN"},
        {value: 630, name: "630", meta:" - IL"},
        {value: 631, name: "631", meta:" - NY"},
        {value: 636, name: "636", meta:" - MO"},
        {value: 639, name: "639", meta:" - SASKATCHEWAN"},
        {value: 640, name: "640", meta:" - NJ"},
        {value: 641, name: "641", meta:" - IA"},
        {value: 646, name: "646", meta:" - NY"},
        {value: 647, name: "647", meta:" - ONTARIO"},
        {value: 649, name: "649", meta:" - TURKS & CAICOS ISLANDS"},
        {value: 650, name: "650", meta:" - CA"},
        {value: 651, name: "651", meta:" - MN"},
        {value: 657, name: "657", meta:" - CA"},
        {value: 658, name: "658", meta:" - JAMAICA"},
        {value: 660, name: "660", meta:" - MO"},
        {value: 661, name: "661", meta:" - CA"},
        {value: 662, name: "662", meta:" - MS"},
        {value: 664, name: "664", meta:" - MONTSERRAT"},
        {value: 667, name: "667", meta:" - MD"},
        {value: 669, name: "669", meta:" - CA"},
        {value: 670, name: "670", meta:" - CNMI"},
        {value: 671, name: "671", meta:" - GU"},
        {value: 672, name: "672", meta:" - BRITISH COLUMBIA"},
        {value: 678, name: "678", meta:" - GA"},
        {value: 680, name: "680", meta:" - NY"},
        {value: 681, name: "681", meta:" - WV"},
        {value: 682, name: "682", meta:" - TX"},
        {value: 684, name: "684", meta:" - AS"},
        {value: 689, name: "689", meta:" - FL"},
        {value: 701, name: "701", meta:" - ND"},
        {value: 702, name: "702", meta:" - NV"},
        {value: 703, name: "703", meta:" - VA"},
        {value: 704, name: "704", meta:" - NC"},
        {value: 705, name: "705", meta:" - ONTARIO"},
        {value: 706, name: "706", meta:" - GA"},
        {value: 707, name: "707", meta:" - CA"},
        {value: 708, name: "708", meta:" - IL"},
        {value: 709, name: "709", meta:" - NEWFOUNDLAND AND LABRADOR"},
        {value: 712, name: "712", meta:" - IA"},
        {value: 713, name: "713", meta:" - TX"},
        {value: 714, name: "714", meta:" - CA"},
        {value: 715, name: "715", meta:" - WI"},
        {value: 716, name: "716", meta:" - NY"},
        {value: 717, name: "717", meta:" - PA"},
        {value: 718, name: "718", meta:" - NY"},
        {value: 719, name: "719", meta:" - CO"},
        {value: 720, name: "720", meta:" - CO"},
        {value: 721, name: "721", meta:" - SINT MAARTEN"},
        {value: 724, name: "724", meta:" - PA"},
        {value: 725, name: "725", meta:" - NV"},
        {value: 726, name: "726", meta:" - TX"},
        {value: 727, name: "727", meta:" - FL"},
        {value: 731, name: "731", meta:" - TN"},
        {value: 732, name: "732", meta:" - NJ"},
        {value: 734, name: "734", meta:" - MI"},
        {value: 737, name: "737", meta:" - TX"},
        {value: 740, name: "740", meta:" - OH"},
        {value: 743, name: "743", meta:" - NC"},
        {value: 747, name: "747", meta:" - CA"},
        {value: 754, name: "754", meta:" - FL"},
        {value: 757, name: "757", meta:" - VA"},
        {value: 758, name: "758", meta:" - ST. LUCIA"},
        {value: 760, name: "760", meta:" - CA"},
        {value: 762, name: "762", meta:" - GA"},
        {value: 763, name: "763", meta:" - MN"},
        {value: 765, name: "765", meta:" - IN"},
        {value: 767, name: "767", meta:" - DOMINICA"},
        {value: 769, name: "769", meta:" - MS"},
        {value: 770, name: "770", meta:" - GA"},
        {value: 772, name: "772", meta:" - FL"},
        {value: 773, name: "773", meta:" - IL"},
        {value: 774, name: "774", meta:" - MA"},
        {value: 775, name: "775", meta:" - NV"},
        {value: 778, name: "778", meta:" - BRITISH COLUMBIA"},
        {value: 779, name: "779", meta:" - IL"},
        {value: 780, name: "780", meta:" - ALBERTA"},
        {value: 781, name: "781", meta:" - MA"},
        {value: 782, name: "782", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: 784, name: "784", meta:" - ST. VINCENT & GRENADINES"},
        {value: 785, name: "785", meta:" - KS"},
        {value: 786, name: "786", meta:" - FL"},
        {value: 787, name: "787", meta:" - PUERTO RICO"},
        {value: 801, name: "801", meta:" - UT"},
        {value: 802, name: "802", meta:" - VT"},
        {value: 803, name: "803", meta:" - SC"},
        {value: 804, name: "804", meta:" - VA"},
        {value: 805, name: "805", meta:" - CA"},
        {value: 806, name: "806", meta:" - TX"},
        {value: 807, name: "807", meta:" - ONTARIO"},
        {value: 808, name: "808", meta:" - HI"},
        {value: 809, name: "809", meta:" - DOMINICAN REPUBLIC"},
        {value: 810, name: "810", meta:" - MI"},
        {value: 812, name: "812", meta:" - IN"},
        {value: 813, name: "813", meta:" - FL"},
        {value: 814, name: "814", meta:" - PA"},
        {value: 815, name: "815", meta:" - IL"},
        {value: 816, name: "816", meta:" - MO"},
        {value: 817, name: "817", meta:" - TX"},
        {value: 818, name: "818", meta:" - CA"},
        {value: 819, name: "819", meta:" - QUEBEC"},
        {value: 820, name: "820", meta:" - CA"},
        {value: 825, name: "825", meta:" - ALBERTA"},
        {value: 828, name: "828", meta:" - NC"},
        {value: 829, name: "829", meta:" - DOMINICAN REPUBLIC"},
        {value: 830, name: "830", meta:" - TX"},
        {value: 831, name: "831", meta:" - CA"},
        {value: 832, name: "832", meta:" - TX"},
        {value: 838, name: "838", meta:" - NY"},
        {value: 843, name: "843", meta:" - SC"},
        {value: 845, name: "845", meta:" - NY"},
        {value: 847, name: "847", meta:" - IL"},
        {value: 848, name: "848", meta:" - NJ"},
        {value: 849, name: "849", meta:" - DOMINICAN REPUBLIC"},
        {value: 850, name: "850", meta:" - FL"},
        {value: 854, name: "854", meta:" - SC"},
        {value: 856, name: "856", meta:" - NJ"},
        {value: 857, name: "857", meta:" - MA"},
        {value: 858, name: "858", meta:" - CA"},
        {value: 859, name: "859", meta:" - KY"},
        {value: 860, name: "860", meta:" - CT"},
        {value: 862, name: "862", meta:" - NJ"},
        {value: 863, name: "863", meta:" - FL"},
        {value: 864, name: "864", meta:" - SC"},
        {value: 865, name: "865", meta:" - TN"},
        {value: 867, name: "867", meta:" - YUKON-NW TERR. - NUNAVUT"},
        {value: 868, name: "868", meta:" - TRINIDAD & TOBAGO"},
        {value: 869, name: "869", meta:" - ST. KITTS & NEVIS"},
        {value: 870, name: "870", meta:" - AR"},
        {value: 872, name: "872", meta:" - IL"},
        {value: 873, name: "873", meta:" - QUEBEC"},
        {value: 876, name: "876", meta:" - JAMAICA"},
        {value: 878, name: "878", meta:" - PA"},
        {value: 901, name: "901", meta:" - TN"},
        {value: 902, name: "902", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: 903, name: "903", meta:" - TX"},
        {value: 904, name: "904", meta:" - FL"},
        {value: 905, name: "905", meta:" - ONTARIO"},
        {value: 906, name: "906", meta:" - MI"},
        {value: 907, name: "907", meta:" - AK"},
        {value: 908, name: "908", meta:" - NJ"},
        {value: 909, name: "909", meta:" - CA"},
        {value: 910, name: "910", meta:" - NC"},
        {value: 912, name: "912", meta:" - GA"},
        {value: 913, name: "913", meta:" - KS"},
        {value: 914, name: "914", meta:" - NY"},
        {value: 915, name: "915", meta:" - TX"},
        {value: 916, name: "916", meta:" - CA"},
        {value: 917, name: "917", meta:" - NY"},
        {value: 918, name: "918", meta:" - OK"},
        {value: 919, name: "919", meta:" - NC"},
        {value: 920, name: "920", meta:" - WI"},
        {value: 925, name: "925", meta:" - CA"},
        {value: 928, name: "928", meta:" - AZ"},
        {value: 929, name: "929", meta:" - NY"},
        {value: 930, name: "930", meta:" - IN"},
        {value: 931, name: "931", meta:" - TN"},
        {value: 934, name: "934", meta:" - NY"},
        {value: 936, name: "936", meta:" - TX"},
        {value: 937, name: "937", meta:" - OH"},
        {value: 938, name: "938", meta:" - AL"},
        {value: 939, name: "939", meta:" - PUERTO RICO"},
        {value: 940, name: "940", meta:" - TX"},
        {value: 941, name: "941", meta:" - FL"},
        {value: 947, name: "947", meta:" - MI"},
        {value: 949, name: "949", meta:" - CA"},
        {value: 951, name: "951", meta:" - CA"},
        {value: 952, name: "952", meta:" - MN"},
        {value: 954, name: "954", meta:" - FL"},
        {value: 956, name: "956", meta:" - TX"},
        {value: 959, name: "959", meta:" - CT"},
        {value: 970, name: "970", meta:" - CO"},
        {value: 971, name: "971", meta:" - OR"},
        {value: 972, name: "972", meta:" - TX"},
        {value: 973, name: "973", meta:" - NJ"},
        {value: 978, name: "978", meta:" - MA"},
        {value: 979, name: "979", meta:" - TX"},
        {value: 980, name: "980", meta:" - NC"},
        {value: 984, name: "984", meta:" - NC"},
        {value: 985, name: "985", meta:" - LA"},
        {value: 986, name: "986", meta:" - ID"},
        {value: 989, name: "989", meta:" - MI"},
      ],
      helpText: "Select up to 5 area codes per search"
    },
    COUNTY: {
      name: 'Counties',
      displayHeader: true,
      inputType: "geography",
      items: [],
      helpText: "Select up to 5 counties per search",
    }, 
    CITY: {
      name: 'Cities',
      displayHeader: true,
      inputType: "geography",
      items: []
    }, 
    ZIP: {
      name: 'Zip codes',
      displayHeader: true,
      inputType: "geography",
      items: [],
    },
    ST: {
      name: 'States',
      displayHeader: true,
      inputType: "geography",
      items: [
        {value: "AK", name: "Alaska"},
        {value: "AL", name: "Alabama"},
        {value: "AR", name: "Arkansas"},
        {value: "AZ", name: "Arizona"},
        {value: "CA", name: "California"},
        {value: "CO", name: "Colorado"},
        {value: "CT", name: "Connecticut"},
        {value: "DC", name: "District"},
        {value: "DE", name: "Delaware"},
        {value: "FL", name: "Florida"},
        {value: "GA", name: "Georgia"},
        {value: "HI", name: "Hawaii"},
        {value: "IA", name: "Iowa"},
        {value: "ID", name: "Idaho"},
        {value: "IL", name: "Illinois"},
        {value: "IN", name: "Indiana"},
        {value: "KS", name: "Kansas"},
        {value: "KY", name: "Kentucky"},
        {value: "LA", name: "Louisiana"},
        {value: "MA", name: "Massachusetts"},
        {value: "MD", name: "Maryland"},
        {value: "ME", name: "Maine"},
        {value: "MI", name: "Michigan"},
        {value: "MN", name: "Minnesota"},
        {value: "MO", name: "Missouri"},
        {value: "MS", name: "Mississippi"},
        {value: "MT", name: "Montana"},
        {value: "NC", name: "North Carolina"},
        {value: "ND", name: "North Dakota"},
        {value: "NE", name: "Nebraska"},
        {value: "NH", name: "New Hampshire"},
        {value: "NJ", name: "New Jersey"},
        {value: "NM", name: "New Mexico"},
        {value: "NV", name: "Nevada"},
        {value: "NY", name: "New York"},
        {value: "OH", name: "Ohio"},
        {value: "OK", name: "Oklahoma"},
        {value: "OR", name: "Oregon"},
        {value: "PA", name: "Pennsylvania"},
        {value: "RI", name: "Rhode Island"},
        {value: "SC", name: "South Carolina"},
        {value: "SD", name: "South Dakota"},
        {value: "TN", name: "Tennessee"},
        {value: "TX", name: "Texas"},
        {value: "UT", name: "Utah"},
        {value: "VA", name: "Virginia"},
        {value: "VT", name: "Vermont"},
        {value: "WA", name: "Washington"},
        {value: "WI", name: "Wisconsin"},
        {value: "WV", name: "West Virginia"},
        {value: "WY", name: "Wyoming"},
      ],
    },
    nationwide: {
      name: 'Nationwide',
      displayHeader: false,
      inputType: "geography",
      items: [],
    }
  }
}