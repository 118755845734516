import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Tab,
  Nav,
  Accordion,
  Card,
  Table,
  Form,
  Alert,
  ListGroup,
  ListGroupItem,
  Modal
} from "react-bootstrap";
import { db, functions } from "./firebase.jsx";
import Geographies from "./geographies.jsx";
import Preview from "./previewAgain.jsx";
import LeadsCount from "./leadscount.jsx";
import SelectedParams from "./selectedParams.jsx";
import {
  SavedSearch,
  Searches,
  saveSearch,
  setSavedSearch
} from "./savedSearch.jsx";
import Spinner from "react-bootstrap/Spinner";
import DownloadProgress from "./downloadProgress.jsx";
import SearchNSuggest from "./searchNSuggest.jsx";
import { object_equals } from "./interfaceListShackPro.jsx";
import { sendEmail } from "./email.jsx";
import queryString from "query-string";
import JSONPretty from "react-json-pretty";

class Search extends React.Component {
  constructor(props) {
    super(props);
    const dataDict = this.props.dataDict;
    //console.log("Database configuration for this search: ", dataDict);
    this.apiUrl = `${process.env.REACT_APP_api_url}${dataDict.apiUrl}`;
    this.state = {
      leadsCount: 0,
      leads: [], /// used for the preview page
      geoSearchBy: null,
      queryParams: [],
      geographyParams: {},
      searchParams: dataDict.defaultSearchParams,
      queryFields: {}, // The fields that the user wants to download
      selectedTab: "0", // The tab corresponding to the UI for the search. Note, string gets converted to INT later, but must be a strong here.
      currentSearch: "", // most recent attempted search
      lastSearch: "", // last successful search sent to database
      isFetching: false,
      isDuplicate: false,
      suppressSearch: this.props.userplan.searchSuppressions,
      duplicateDownloads: null,
      isDownloading: false, // show spinner while waiting for download link for your new shiny file
      downloadCount: "",
      searchName: "",
      displayCol: 14,
      download: null,
      isBigDownload: false, // So browser connections don't time out before the search results are received.
      showSaveModal: false,
      savedSearch: undefined,
      tests: null,
      runningTests: false
    };
    // Rather than setting all the state variables manually for the whole
    // data dictionary, let's set them here with a for loop
    for (let key of Object.keys(dataDict.cols)) {
      if (dataDict.cols[key].inputType === "checkbox") {
        for (let item of dataDict.cols[key].items) {
          this.state[key + item.index] = "";
        }
      }
      if (dataDict.cols[key].inputType === "range") {
        this.state[key + "min"] = "";
        this.state[key + "max"] = "";
      }
      if (dataDict.cols[key].inputType === "select") {
        this.state[key + "select"] = "";
        this.state[key + "select"] = "";
      }
    }
    // These variables control the query fields for downloading different columns
    this.defaultQueryFields = {};
    let dlFields = {};
    for (let field of dataDict.dlCols) {
      let name = `dl_${field.value}`;
      if (field.default) {
        dlFields[field.value] = true;
        this.state[name] = true;
      } else {
        // set the default queryField value for resetting a query
        dlFields[field.value] = false;
        // set state queryField boolean for controlling the UI and determining which fields to download from state
        this.state[name] = false;
      }
    }
    this.state["queryFields"] = { ...dlFields };
    this.defaultQueryFields = { ...dlFields };

    // Allows for a dynamic navigation based on the tab the user is currently viewing in the UI
    this.tabs = {
      0: {
        name: "Select geography",
        eventKey: "geography"
      },
      1: {
        name: "Pick filters",
        eventKey: "filters"
      },
      2: {
        name: "Preview leads",
        eventKey: "preview"
      },
      3: {
        name: "Checkout",
        eventKey: "checkout"
      }
    };
    this.defaultSearchParams = dataDict.defaultSearchParams;
    this.emptySearchParams = {}; // Simplifies resetting queries
    for (let param of Object.keys(dataDict.cols)) {
      this.emptySearchParams[param] = [];
    } // Just empty arrays
    this.emptyGeographyParams = {}; // Simplifies resetting queries
    // Let's set the Geography parameters
    for (let geoKey of Object.keys(dataDict.geography)) {
      this.state["geographyParams"][geoKey] = [];
      this.emptyGeographyParams[geoKey] = [];
    } // Just empty arrays
    this.queryString = queryString.parse(window.location.search);
    this.fetchCount = this.fetchCount.bind(this);
    this.resetQuery = this.resetQuery.bind(this);
    this.selectGeoSearchBy = this.selectGeoSearchBy.bind(this);
    this.addSearchParam = this.addSearchParam.bind(this);
    this.removeSearchParam = this.removeSearchParam.bind(this);
    this.resetGeoParams = this.resetGeoParams.bind(this);
    this.resetSearchParams = this.resetSearchParams.bind(this);
    this.setDefaultParams = this.setDefaultParams.bind(this);
    this.displayOnMap = this.displayOnMap.bind(this);
    this.createUrl = this.createUrl.bind(this);
    this.setQueryFields = this.setQueryFields.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.rangeSelect = this.rangeSelect.bind(this);
    this.createRangeQuery = this.createRangeQuery.bind(this);
    //this.saveSearch = this.saveSearch.bind(this);
    this.handleDlCols = this.handleDlCols.bind(this);
  }

  handleInputChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    //console.log("value: ", value, "name: ", name);
    this.setState({
      [name]: value
    });
  }

  handleDownload(obj) {
    // Call back for the progress bar component that is tracking the download
    this.setState(obj);
  }

  setQueryFields(e, col_value, bool) {
    console.log("setQueryFields", col_value, bool);
    let cState = this.state.queryFields;
    cState[col_value] = this.state.queryFields[col_value] === bool;
    this.setState({
      queryFields: cState
    });
  }

  createUrl(
    searchParams = this.state.searchParams,
    isCount = true,
    isDownload = false
  ) {
    //console.log(
    //  "createUrl: ",
    //  searchParams,
    //  this.state.geographyParams,
    //  isCount,
    //  isDownload
    //);
    const { dataDict } = this.props;
    let { queryFields } = this.state;
    let fields = [];
    for (let colKey in queryFields) {
      if (queryFields[colKey]) {
        fields.push(colKey);
      }
    }
    // sort the searchParams so we can get consistent use of our indexes
    // always put the specified geographies first, they have indexes
    const keyOrder = dataDict.keyOrder;
    let searchTypeObject =
      searchParams !== null
        ? { ...searchParams, ...this.state.geographyParams }
        : { ...this.state.searchParams, ...this.state.geographyParams }; // The order of adding the objects matters

    let sortedKeys = Object.keys(searchTypeObject).sort((a, b) => {
      let alpha = typeof keyOrder[a] === "undefined" ? 10000 : keyOrder[a];
      let beta = typeof keyOrder[b] === "undefined" ? 10000 : keyOrder[b];
      return alpha - beta;
    });

    const validFilter = (key, params) => {
      if (
        !dataDict.selectFilterValidation.includes(key) ||
        (params.length < dataDict["cols"][key]["items"].length &&
          dataDict["cols"][key]["items"].length > 0)
      ) {
        return true;
      }
      return false;
    };

    const returnQueryStatement = (key, param) => {
      if (Array.isArray(param.query)) {
        let queryStatements = [];
        param.query.map(pv => {
          console.log("pv: ", pv);
          return queryStatements.push(pv);
          //console.log("searchFilters: ", searchFilters);
        });
        return queryStatements.join(" or ");
      } else {
        return `(${param.query})`;
      }
    };

    const writeQueryStatement = (key, param) => {
      //console.log(`Adding ${param.query}`);
      if (Array.isArray(param.query)) {
        return param.query.map(pv => {
          //console.log("pv: ", pv)
          return searchFilters.push(pv);
          //console.log("searchFilters: ", searchFilters);
        });
      } else {
        searchFilters.push(`(${param.query})`);
        //return console.log("searchFilters: ", searchFilters);
      }
    };

    const writeSingleStatement = (key, param) => {
      //console.log("writeSingleStatement: ", key, param);
      if (param.query) {
        return writeQueryStatement(key, param);
      } else if (param.value === "") {
        return;
      } else {
        return searchFilters.push(`(${key}='${param.value}')`);
      }
    };

    const writeAggStatement = (key, params) => {
      //console.log("writeAggStatement: ", key, params);
      let values = [];
      let statement = [];
      params.map(param => {
        if (param.query) {
          return statement.push(returnQueryStatement(key, param));
        } else if (typeof param.value === "number") {
          return values.push(param.value);
        } else {
          return values.push(`'${param.value}'`);
        }
      });
      // if they are custom queries statements, return those
      if (statement.length) {
        return `(${statement.join(" or ")})`;
      }
      // Don't write statements that include all possible values
      if (values.length > 0) {
        return `(${key} in (${values}))`;
      }
      return;
    };

    const writeAggFilter = (key, params) => {
      //console.log("writeAggFilter: ", key, params);
      params.map(param => {
        let aggColumn = Object.values(param.agg)[0];
        if (!aggFilters[key]) {
          aggFilters[key] = {};
        }
        if (!aggFilters[key][aggColumn]) {
          aggFilters[key][aggColumn] = [];
        }
        return aggFilters[key][aggColumn].push(param);
      });
    };

    let searchFilters = []; // these will be joined by an AND statement
    let aggFilters = {};
    sortedKeys.map(key => {
      let params = searchTypeObject[key];
      if (!params.length) {
        return null;
      }
      if (params[0].agg) {
        return writeAggFilter(key, params);
      }
      if (params[0].value === "") {
        return null;
      }
      if (params.length === 1) {
        return writeSingleStatement(key, params[0]);
      }
      if (params.length > 1 && validFilter(key, params)) {
        return searchFilters.push(writeAggStatement(key, params));
      }
      return null;
    });
    //console.log("aggFilters: ", aggFilters);

    // Write aggregate statements
    let aggStatements = [];
    Object.keys(aggFilters).map(key => {
      let nestedAggStatements = [];
      //console.log("aggFilters key: ", key);
      let nestedObject = aggFilters[key];
      //console.log("nestedObject: ", nestedObject);
      Object.keys(nestedObject).map(nestedKey => {
        //console.log("nestedKey: ", nestedKey);
        let aggParams = nestedObject[nestedKey];
        //console.log("aggParams: ", aggParams);
        let ap = aggParams[0].agg; // {ST: "NV"}
        let apk = Object.keys(ap)[0]; // "ST"
        let apv = Object.values(ap)[0]; // "NV"
        let ags = `(${apk}='${apv}')`; //(ST="NV")
        if (aggParams.length === 1) {
          return nestedAggStatements.push(
            `(${key}='${aggParams[0].value}') and ${ags}`
          );
        }
        if (aggParams.length > 1) {
          let as = writeAggStatement(key, aggParams);
          //console.log("as: ", as);
          let nas = `(${as} and ${ags})`;
          return nestedAggStatements.push(nas);
        }
        return null;
      });
      if (nestedAggStatements.length) {
        let nestedStatement = nestedAggStatements.join(" or ");
        aggStatements.push(`(${nestedStatement})`);
      }
      return null;
    });
    if (aggStatements.length) {
      searchFilters.push(`(${aggStatements.join(" or ")})`);
    }

    //console.log("searchFilters: ", searchFilters);
    //let fieldsString = isCount ? "" : "fields=" + fields.join(","); // if it's a count only, don't send the fields over... as that should perform faster.
    let fieldString = isCount ? "count(*)" : fields.join(",");
    //let filtersString = `${isCount ? "" : "&" }filter=` + s.join(" or ");
    let filterString = searchFilters.join(" and ");

    // In order to help keep our servers from needless searches by users, we'll only allow them to search if it's a new search.  We'll know if it's new by saving the search query URL and then comparing that to the last search that they did.  If it's the same, disable searching again.  If it's different, let them search. */}
    let queryUrl = {
      fields: fieldString,
      filter: filterString.replace(/"/g, "'"),
      count_only: isCount ? "true" : "false",
      limit: isDownload ? "" : 100,
      url: this.apiUrl,
      index: this.props.dataDict.index ? this.props.dataDict.index : null,
      dictId: this.props.dataDict.dictId ? this.props.dataDict.dictId : null
    };

    // Keep saved searches from disappearing while we are fetching preview leads
    let updateSearch;
    if (!isCount) {
      updateSearch = { ...queryUrl };
      updateSearch.count_only = "true";
      updateSearch.fields = "count(*)";
    }

    this.setState({
      currentSearch: isCount ? queryUrl : updateSearch
    });
    //console.log("queryUrl: ", queryUrl);
    return queryUrl;
  }

  async fetchCount(e, isCount = true, searchParams = null, isTest = false) {
    e.preventDefault();

    // tell the user that we're fetching
    if (!isTest) {
      await this.setState({
        isFetching: true
      });
    }

    let searchUrl = this.createUrl(searchParams, isCount);

    let postBody = JSON.stringify(searchUrl);
    // await response of fetch call
    const init = {
      method: "POST",
      headers: {
        authorization: `Bearer ${this.props.apiKey}`,
        "Content-Type": "application/json"
      },
      "Transfer-Encoding": "chunked",
      cache: "default",
      accept: "application/json",
      body: postBody
    };

    //let indexUrl = this.apiUrl;
    if (isCount) {
      console.log(`Getting count for: ${postBody}`);
    }
    try {
      let response = await fetch(this.apiUrl, init);
      // only proceed once promise is resolved
      let data = await response.json();
      if (data.error) {
        if (!isTest) {
          this.setState({
            leadsCount: 0,
            isFetching: false,
            lastSearch: searchUrl,
            leads: []
          });
          this.props.handleAlerts(
            e,
            <React.Fragment>
              Uh oh, there was a problem with your search. Try changing your
              search. If the problem persists send a screenshot to{" "}
              <Alert.Link href={process.env.REACT_APP_contact_email}>
                {process.env.REACT_APP_contact_email}
              </Alert.Link>
            </React.Fragment>,
            "warning"
          );
        }
        return data;
      }
      if (isCount) {
        await console.log("Records available: ", data);
        if (!isTest) {
          await this.setState({
            leadsCount: data, //display the count to the user
            isFetching: false, // Stop showing the loadng spinner
            lastSearch: searchUrl, // Save the query URL to prevent needless searches and for our cloud function if the user checks out.
            leads: []
          });
        }
      } else {
        let countBody = this.createUrl(null, true);
        console.log("countUrl: ", countBody);
        init.body = JSON.stringify(countBody);
        let countResponse = await fetch(this.apiUrl, init);
        let countData = await countResponse.json();
        console.log("countData: ", countData);
        if (!isTest) {
          await this.setState({
            leadsCount: countData, // still want to display the count
            leads: data.resource, // send preview records to the preview component
            isFetching: false, // Stop showing the loading spinner
            lastSearch: searchUrl // Save the URL for the cloud function if the user decides to check out
          });
        }
      }
      return data;
    } catch (error) {
      await console.error(error);
      if (!isTest) {
        await this.setState({
          isFetching: false
        });
        // Tell the user that something went wrong.
        await this.props.handleAlerts(
          e,
          <React.Fragment>
            Uh oh, something went wrong. ${error.message}. Try changing your
            search. If the problem persists send a screenshot to{" "}
            <Alert.Link href={process.env.REACT_APP_contact_email}>
              {process.env.REACT_APP_contact_email}
            </Alert.Link>
          </React.Fragment>,
          "warning"
        );
      }
      return error;
    }
  }

  async resetQuery(e) {
    //console.log("Reseting the query");
    let searchType = this.state.searchType || "cols";
    let stateObject = await this.setDefaultParams(e, searchType, {
      geoSearchBy: null, //clear the geographies
      download: null, //clear the download
      searchName: "", //clear the searchname
      downloadCount: "", //clear the download count
      leadsCount: 0, //clear the count box
      isDownloading: false, // clear checkout tab
      isBigDownload: false, // clear display of progress bar
      currentSearch: "", // clear current search so you can refresh counts
      queryFields: this.defaultQueryFields
    });
    //console.log("stateObject: ", stateObject);
    await this.setState(stateObject);
  }

  async selectGeoSearchBy(e, type) {
    e.preventDefault();
    this.setState({ geoSearchBy: type });
    // Allow users to skip the geo search and go after the whole database with a nationwide search
    if (type === "nationwide") {
      this.addSearchParam(
        "geography",
        "nationwide",
        { value: "", name: "Nationwide" },
        true
      );
    }
  }

  resetGeoParams(e) {
    e.preventDefault();
    this.setState({
      geographyParams: { ...this.emptyGeographyParams },
      currentSearch: ""
    });
  }

  resetSearchParams(e) {
    e.preventDefault();
    // To Do: reset all the state variables that control the UI
    this.setState({
      searchParams: this.defaultSearchParams,
      currentSearch: ""
    });
  }

  async setDefaultParams(e, searchType = "cols", add_object = {}) {
    const dataDict = this.props.dataDict;
    const emptyGeographyParams = { ...this.emptyGeographyParams }; // Simplifies resetting queries
    //console.log("emptyGeographyParams: ", emptyGeographyParams);
    let defaultParams = dataDict.defaultSearchParams;
    //console.log("defaultParams: ", defaultParams);
    let database = dataDict.dictId;
    let userDoc;
    // Check if the user has saved default search parameters
    await db
      .collection("searches")
      .doc(this.props.user.uid)
      .collection("params")
      .doc(database)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          userDoc = doc.data();
          console.log("Default search data: ", userDoc);
        } else {
          // doc.data() will be undefined in this case
          console.log("No default search document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
    // A function to check whether the search params are the same
    function arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      a = { ...a.sort() };
      b = { ...b.sort() };

      for (var i = 0; i < Object.values(a).length; i++) {
        //console.log(i);
        //console.log(a[i], "!==", b[i]);
        //console.log(a[i] !== b[i]);
        if (a[i] !== b[i]) return false;
      }
      return true;
    }

    // if the user has saved default use it
    if (userDoc && userDoc.queryUrl.index === dataDict.index) {
      console.log("The user has saved default search params.");
      // Check whether the saved search params match the data dictionary
      let dataDictDefault = Object.keys(dataDict.defaultSearchParams);
      let userSavedDefault = Object.keys(userDoc.searchParams);
      //console.log("dataDictDefault: ", dataDictDefault, "userSavedDefault: ", userSavedDefault);

      let dataDictSame = arraysEqual(dataDictDefault, userSavedDefault);
      //console.log(dataDictSame);
      if (dataDictSame) {
        console.log(
          "The data dictionaries match, so we're setting default search params from the database.",
          userDoc.searchParams
        );
        defaultParams = userDoc.searchParams;
        for (let geoKey of Object.keys(emptyGeographyParams)) {
          defaultParams[geoKey] = [];
        }
        //console.log(defaultParams);
      } else {
        console.log(
          "The data dictionaries do not match, using the data dictionaries default search params."
        );
        //console.log("defaultParams: ", defaultParams);
        this.props.handleAlerts(
          "",
          "We've updated the filters that are available to search by. You may need to reset your default search parameters.",
          "info"
        );
      }
    }

    // Create the proper state variables to keep the filters in sync
    // with the default search parameters
    let stateObject = {};
    for (let key of Object.keys(defaultParams)) {
      //console.log("key: ", key);
      let inputType = dataDict["cols"][key].inputType;
      if (defaultParams[key].length > 0) {
        // Look up what type of input it is
        if (inputType === "checkbox") {
          // create an object with a shape like;
          // PHONE_OCCURRENCE1: true
          for (let item of dataDict["cols"][key].items) {
            if (
              defaultParams[key].some(param => {
                // Check if the item is a saved Param
                return (
                  JSON.stringify(Object.values(item).sort()) ===
                  JSON.stringify(Object.values(param).sort())
                );
              })
            ) {
              // if so set value to true, so it stays checked in filters
              stateObject[key + item.index] = true;
            } else {
              // if not set value to "" so it is not checked in filters
              stateObject[key + item.index] = "";
            }
          }
          //for (let param of defaultParams[key]) {
          //  stateObject[key + param.index] = true
          //}
        }
        if (inputType === "select") {
          // create an object with a shape like:
          // phonePrefselect: "A"
          stateObject[key + "select"] = defaultParams[key][0].value;
        }
        if (inputType === "range" || inputType === "stringRange") {
          // create an object with a shape like:
          // AGEmin: "{"value":19,"order":19,"name":19}"
          // AGEmax: "{"value":99,"order":99,"name":99}"
          //console.log("dataDict: ", dataDict);
          //console.log("key: ", key);
          //console.log("dataDict[cols][key]: ", dataDict["cols"][key]);
          let minValue = defaultParams[key][0].value[0];
          let maxValue = defaultParams[key][0].value[1];
          let dmni = dataDict["cols"][key].items.find(
            item => item.value === minValue
          );
          let dmxi = dataDict["cols"][key].items.find(
            item => item.value === maxValue
          );
          stateObject[key + "min"] = JSON.stringify(dmni);
          stateObject[key + "max"] = JSON.stringify(dmxi);
        }
      } else {
        // If the array is empty, reset the state variable to an empty string
        if (inputType === "checkbox") {
          // create an object with a shape like;
          // PHONE_OCCURRENCE1: ""
          for (let item of dataDict["cols"][key].items) {
            stateObject[key + item.index] = "";
          }
        }
        if (inputType === "select") {
          // create an object with a shape like:
          // phonePrefselect: ""
          stateObject[key + "select"] = "";
        }
        if (inputType === "range" || inputType === "stringRange") {
          // create an object with a shape like:
          // AGEmin: ""
          // AGEmax: ""
          stateObject[key + "min"] = "";
          stateObject[key + "max"] = "";
        }
      }
    }
    let geographyParams;
    if (userDoc && userDoc.geographyParams) {
      console.log("The user has saved geography params.");

      //console.log("dataDict.geography:", Object.keys(dataDict.geography), userDoc.geographyParams);

      let dataDictSame = arraysEqual(
        Object.keys(dataDict.geography),
        Object.keys(userDoc.geographyParams)
      );
      //console.log(dataDictSame);

      if (dataDictSame) {
        console.log(
          "The geography data dictionaries match, so we're setting default geography params from the database.",
          userDoc.geographyParams
        );
        geographyParams = userDoc.geographyParams;
      } else {
        console.log(
          "The data dictionaries do not match, using the data dictionaries default search params."
        );
        geographyParams = emptyGeographyParams;
        this.props.handleAlerts(
          "",
          "We've updated the filters that are available to search by. You may need to reset your default search parameters.",
          "info"
        );
      }
    } else {
      geographyParams = emptyGeographyParams;
    }
    //console.log("userDoc.queryUrl: ", userDoc.queryUrl);
    stateObject.geographyParams = geographyParams;
    stateObject.searchParams = defaultParams;
    stateObject.savedSearch = userDoc;
    stateObject.currentSearch = userDoc
      ? userDoc.queryUrl
        ? userDoc.queryUrl
        : ""
      : "";

    for (let addKey of Object.keys(add_object)) {
      stateObject[addKey] = add_object[addKey];
    }
    // Reset the dl_col state variables
    for (let dl_var of dataDict.dlCols) {
      let dl_name = `dl_${dl_var.value}`;
      if (dl_var.default) {
        stateObject[dl_name] = true;
      } else {
        stateObject[dl_name] = false;
      }
    }
    //console.log("stateObject: ", stateObject);
    return await stateObject;
  }

  addSearchParam(dict, key, valuesArray, isSelect = false) {
    //console.log(
    //  'dict: ', dict,
    //  'key: ', key,
    //  'valuesArray: ', valuesArray,
    //  //'state params: ', eval(`this.state.${dict}Params`),
    //  'isSelect: ', isSelect
    //);

    let stateRef =
      dict === "geography"
        ? this.state.geographyParams
        : this.state.searchParams;
    //console.log("stateRef: ", stateRef);
    let paramType = dict === "geography" ? "geographyParams" : "searchParams";
    //console.log("paramType: ", paramType);
    let nQuery = stateRef;
    //console.log("nQuery: ", nQuery);
    let cParam = !isSelect ? nQuery[key] : [];
    //console.log("cParam: ", cParam);
    // Add all the new values to the current values in the array
    let nParam = cParam.concat(valuesArray);
    //console.log("nParam: ", nParam);
    // Set the new values equal to the same index place in old values
    nQuery[key] = nParam;
    //console.log("nQuery: ", nQuery);
    // Save the new values to state
    this.setState({
      [paramType]: nQuery
    });
    this.createUrl();
  }

  removeSearchParam(dict, key, valuesArray) {
    //console.log(
    //  'dict', dict,
    //  'key: ', key,
    //  'valuesArray: ', valuesArray,
    //  'state params: ', eval(`this.state.${dict}Params`)
    //);

    let stateRef =
      dict === "geography"
        ? this.state.geographyParams
        : this.state.searchParams;

    let paramType = dict === "geography" ? "geographyParams" : "searchParams";

    let nQuery = stateRef;

    let cParam = nQuery[key];

    // Filter out all the of values that are included in the array sent over
    let nParam = cParam.filter(array => {
      //This is an entire array ... not just an an element
      return JSON.stringify(array) !== JSON.stringify(valuesArray); // This needs to compare two arrays, not just two elements in the array.
    });
    // Set the new values equal to the same index place in old values
    nQuery[key] = nParam;
    const dataDict = this.props.dataDict;
    let inputType = dataDict["cols"][key].inputType;
    let stateKey, stateKey2, stateValue;
    if (inputType === "checkbox") {
      stateKey = key + valuesArray.index;
      stateValue = false;
    } else if (inputType === "select") {
      stateKey = key + "select";
      stateValue = "";
    } else if (inputType === "range" || inputType === "stringRange") {
      stateKey = key + "min";
      stateKey2 = key + "max";
      stateValue = "";
    }

    // Save the new values to state
    this.setState({
      [paramType]: nQuery,
      [stateKey]: stateValue,
      [stateKey2]: stateValue
    });

    this.createUrl();
  }

  // To Do: allow folks to click a link and open up a map of the geography on Google. Makes it easier to see where you are searching.
  displayOnMap(e, key, param) {
    e.preventDefault();
    console.log(key, param);
  }

  handleFilterChange(e) {
    e.preventDefault();
    console.log("A filter changed on", e.target.name, e.target.dataset.param);
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const param = e.target.dataset.param;
    const index = e.target.dataset.index;
    const name = e.target.name;
    let queryParams = this.state.queryParams;
    console.log("queryParams: ", queryParams);
    console.log("param: ", param);
    console.log("index: ", index);
    queryParams[name] = value;
    this.setState({ name: value });
    console.log(this.state);
  }

  createRangeQuery(key, min, max, string = false, dataDict) {
    //console.log("key: ", key, min, max);
    if (min !== "" && max !== "") {
      // Calculate min and max to dummy proof the range select
      let parsedMin = JSON.parse(min);
      let parsedMax = JSON.parse(max);
      let calcMin = parsedMin.order < parsedMax.order ? parsedMin : parsedMax;
      let calcMax = parsedMin.order < parsedMax.order ? parsedMax : parsedMin;
      let item;
      if (!string) {
        item = {
          query: `(${key} >= ${calcMin.value}) and (${key} <= ${calcMax.value})`,
          value: [calcMin.value, calcMax.value],
          name: `between ${calcMin.name} and ${calcMax.name}`
        };
      }
      if (string) {
        let stringArray = [];
        for (let item of dataDict.cols[key].items) {
          if (item.order >= calcMin.order && item.order <= calcMax.order) {
            stringArray.push(`'${item.value}'`);
          }
        }
        item = {
          query: [`(${key} IN (${stringArray.join(",")}) )`],
          value: [calcMin.value, calcMax.value],
          name: `between ${calcMin.name} and ${calcMax.name}`
        };
      }
      return item;
    }
  }

  async rangeSelect(e, key, min, max) {
    e.preventDefault();
    await this.setState({
      [key + "min"]: min,
      [key + "max"]: max
    });
    if (min !== "" && max !== "") {
      let item = this.createRangeQuery(key, min, max);
      await this.addSearchParam(this.state.searchType, key, [item], true);
      return item;
    }
  }

  async stringRangeSelect(e, key, min, max) {
    e.preventDefault();
    //console.log("stringRangeSelect: ", key, min, max);
    await this.setState({
      [key + "min"]: min,
      [key + "max"]: max
    });
    if (min !== "" && max !== "") {
      let item = this.createRangeQuery(
        key,
        min,
        max,
        true,
        this.props.dataDict
      );
      //console.log("stringArray: ", stringArray);
      await this.addSearchParam(this.state.searchType, key, [item], true);
    }
  }

  createFilterInputs(arrayDictKeys) {
    const dataDict = this.props.dataDict;
    //console.log("dataDict: ", dataDict);
    let inputsArray = [];
    for (let dictKey of arrayDictKeys) {
      let dictObj = dataDict["cols"][dictKey];
      if (dictObj.inputType === "checkbox") {
        inputsArray.push(
          <Form.Group key={`${dictKey}FormGroup`}>
            {dictObj.displayHeader && <Form.Label>{dictObj.name}</Form.Label>}
            {dictObj.items.map(opt => (
              <div key={`${dictKey}-${opt.value}`} className="mb-1">
                <Form.Check
                  type="checkbox"
                  name={`${dictKey}${opt.index}`}
                  label={opt.name}
                  key={`${dictKey}${opt.index}`}
                  data-param={JSON.stringify(opt)}
                  data-index={opt.index}
                  onChange={() => {
                    if (this.state[dictKey + opt.index]) {
                      this.removeSearchParam("cols", dictKey, opt);
                      this.setState({
                        [dictKey + opt.index]: false
                      });
                    } else {
                      this.addSearchParam("cols", dictKey, opt);
                      this.setState({
                        [dictKey + opt.index]: true
                      });
                    }
                  }}
                  checked={this.state[dictKey + opt.index]}
                />
              </div>
            ))}
          </Form.Group>
        );
      } else if (dictObj.inputType === "select") {
        inputsArray.push(
          <Form.Group key={`${dictKey}FormGroup`}>
            <Form.Label>{dictObj.name}</Form.Label>
            <Form.Control
              as="select"
              value={this.state[dictKey + "select"]}
              onChange={e => {
                let nValue = JSON.parse(
                  e.target.options[e.target.selectedIndex].dataset.param
                );
                this.addSearchParam("col", dictKey, nValue, true);
                this.setState({
                  [dictKey + "select"]: e.target.options[e.target.selectedIndex]
                    .value
                });
              }}
            >
              {dictObj.items.map(opt => (
                <option
                  value={opt.value}
                  data-param={JSON.stringify(opt)}
                  key={`${dictKey}-${opt.value}`}
                >
                  {opt.name}
                </option>
              ))}
            </Form.Control>
            {dictObj.formText && <Form.Text>{dictObj.formText}</Form.Text>}
          </Form.Group>
        );
      } else if (dictObj.inputType === "range") {
        let itemsMin = dictObj.items;

        let itemsMax =
          dictObj.sortItems === "reverse"
            ? itemsMin.slice().reverse()
            : dictObj.items;

        inputsArray.push(
          <React.Fragment key={`${dictKey}FormGroup`}>
            <Form.Label>{dictObj.name}</Form.Label>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                From
              </Form.Label>

              <Col sm={4}>
                <Form.Control
                  as="select"
                  value={this.state[dictKey + "min"]}
                  onChange={e =>
                    this.rangeSelect(
                      e,
                      dictKey,
                      e.target.value,
                      this.state[dictKey + "max"]
                    )
                  }
                >
                  <option value="">Select min</option>
                  {itemsMin.map(opt => (
                    <option
                      value={JSON.stringify(opt)}
                      key={`${dictKey}-selectFrom-${opt.value}`}
                    >
                      {opt.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>

              <Form.Label className="" column sm={2}>
                To
              </Form.Label>

              <Col sm={4}>
                <Form.Control
                  as="select"
                  value={this.state[dictKey + "max"]}
                  onChange={e =>
                    this.rangeSelect(
                      e,
                      dictKey,
                      this.state[dictKey + "min"],
                      e.target.value
                    )
                  }
                >
                  <option value="">Select max</option>
                  {itemsMax.map(opt => (
                    <option
                      value={JSON.stringify(opt)}
                      key={`${dictKey}-selectTo-${opt.value}`}
                    >
                      {opt.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </React.Fragment>
        );
      } else if (dictObj.inputType === "stringRange") {
        let itemsMin = dictObj.items;

        let itemsMax =
          dictObj.sortItems === "reverse"
            ? itemsMin.slice().reverse()
            : dictObj.items;

        inputsArray.push(
          <React.Fragment key={`${dictKey}FormGroup`}>
            <Form.Label>{dictObj.name}</Form.Label>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                From
              </Form.Label>

              <Col sm={4}>
                <Form.Control
                  as="select"
                  value={this.state[dictKey + "min"]}
                  onChange={e =>
                    this.stringRangeSelect(
                      e,
                      dictKey,
                      e.target.value,
                      this.state[dictKey + "max"]
                    )
                  }
                >
                  <option value="">Select min</option>
                  {itemsMin.map(opt => (
                    <option
                      value={JSON.stringify(opt)}
                      key={`${dictKey}-selectFrom-${opt.value}`}
                    >
                      {opt.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>

              <Form.Label className="" column sm={2}>
                To
              </Form.Label>

              <Col sm={4}>
                <Form.Control
                  as="select"
                  value={this.state[dictKey + "max"]}
                  onChange={e =>
                    this.stringRangeSelect(
                      e,
                      dictKey,
                      this.state[dictKey + "min"],
                      e.target.value
                    )
                  }
                >
                  <option value="">Select max</option>
                  {itemsMax.map(opt => (
                    <option
                      value={JSON.stringify(opt)}
                      key={`${dictKey}-selectTo-${opt.value}`}
                    >
                      {opt.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </React.Fragment>
        );
      } else if (dictObj.inputType === "search") {
        inputsArray.push(
          <React.Fragment key={`sns${dictKey}`}>
            <SearchNSuggest
              searchBy={dictKey}
              addQueryParam={this.addSearchParam}
              searchTypesDict={dataDict["cols"]}
              dictKey="cols"
              handleAlerts={this.props.handleAlerts}
            />
            <hr style={{ marginBottom: "50px" }} />
          </React.Fragment>
        );
      }
    }

    return inputsArray;
  }

  formatAdvancedFilters(array_filters) {
    // just loop through the filters and create a new object shaped like:
    // {
    //     "Mortgage information": ["Col1", "Col2", "Col3"],
    //     "Financial information": ["Col4", "Col5"]
    // }

    //console.log("array_filters: ", array_filters);
    const dataDict = this.props.dataDict;
    let formattedCols = {};
    for (let col of array_filters) {
      let colCategory = dataDict["cols"][col]["filterCategory"];
      //console.log("colCategory: ", colCategory);
      if (colCategory !== undefined) {
        if (formattedCols[colCategory] === undefined) {
          formattedCols[colCategory] = [col];
        } else {
          formattedCols[colCategory].push(col);
        }
      }
      //console.log("formattedCols: ", formattedCols);
    }
    return formattedCols;
  }

  async downloadList(e) {
    e.preventDefault();
    let {
      searchName,
      downloadCount,
      suppressSearch,
      isDuplicate,
      duplicateDownloads
    } = this.state;
    let { dataDict, userDoc } = this.props;
    let aid = userDoc.uid;

    //console.log("searchName: ", searchName, "Count: ", count);
    if (searchName === "") {
      this.props.handleAlerts(
        e,
        "Please name your search to continue.",
        "danger"
      );
      return;
    }
    if (downloadCount === "") {
      this.props.handleAlerts(
        e,
        "Please include the number of leads you would like to download.",
        "danger"
      );
      return;
    }
    if (downloadCount > this.props.userDoc.downloadLimit) {
      this.props.handleAlerts(
        e,
        <React.Fragment>
          Uh oh, that's more leads than you current download limit. Reduce the
          number of leads or{" "}
          <Link to="/settings/billing" className="alert-link">
            upgrade your account
          </Link>
          .
        </React.Fragment>,
        "danger"
      );
      return;
    }

    if (
      downloadCount * dataDict.leadsMultiplier >
      this.props.userDoc.leadsRemaining
    ) {
      this.props.handleAlerts(
        e,
        <React.Fragment>
          Uh oh, that's more leads than you have available this month. Reduce
          the number of leads to less than{" "}
          {this.props.userDoc.leadsRemaining.toLocaleString()} or{" "}
          <Link to="/settings/billing" className="alert-link">
            upgrade your account
          </Link>
          .
        </React.Fragment>,
        "danger"
      );
      return;
    }

    if (
      suppressSearch &&
      isDuplicate &&
      downloadCount > duplicateDownloads.nonDupeCount
    ) {
      this.props.handleAlerts(
        e,
        <React.Fragment>
          Uh oh, that's more leads than we have available. Reduce the number of
          leads to less than {duplicateDownloads.nonDupeCount.toLocaleString()},
          uncheck "Suppress duplicates", or change your search.
        </React.Fragment>,
        "danger"
      );
      return;
    }

    let query = await this.createUrl(null, false, true);
    await console.log("Downloading list for: ", query);
    let leadsMultiplier =
      typeof dataDict.leadsMultiplier === "undefined"
        ? 1
        : dataDict.leadsMultiplier;
    if (this.state.isDuplicate && this.state.suppressSearch) {
      console.log(
        "Suppressing the this search by sending this with an offset: ",
        this.state.duplicateDownloads.maxOffset
      );
      query.offset = this.state.duplicateDownloads.maxOffset;
    }
    console.log("leadsMultiplier: ", leadsMultiplier, "query: ", query);
    const downloadParams = await {
      searchName: searchName,
      timestamp: new Date(),
      downloadCount: downloadCount,
      leadsRemaining: this.props.userDoc.leadsRemaining,
      leadsMultiplier: leadsMultiplier,
      queryUrl: query,
      geographyParams: this.state.geographyParams,
      searchParams: this.state.searchParams,
      email: this.props.user.email,
      uid: this.props.user.uid,
      aid: aid
    };

    const downloadCsv = functions.httpsCallable("downloadCsv");

    // create a document to keep track of the download information
    let newDoc = await db
      .collection("downloads")
      .doc(aid)
      .collection("files")
      .add(downloadParams);

    let doc_id = await newDoc.id;

    await this.setState({ doc_id: doc_id });

    let csvParams = await {
      doc_id: doc_id,
      downloadCount: downloadCount,
      leadsRemaining: this.props.userDoc.leadsRemaining,
      queryUrl: query,
      searchName: searchName,
      leadsMultiplier: leadsMultiplier,
      aid: aid
    };

    try {
      this.setState({
        isBigDownload: downloadCount <= 10000 ? false : true,
        isDownloading: true
      });

      let csvFileUrl;
      if (downloadCount <= 1000000) {
        // Setting this really big so that it always runs temp hack
        let callFunction = await downloadCsv(csvParams);
        csvFileUrl = await callFunction.data;
        console.log("Download csv from: ", csvFileUrl);

        return await this.setState({
          download: csvFileUrl,
          isDownloading: false
        });
      } else {
        return downloadCsv(csvParams); // This is supposed to ensure that the front end doesn't wait for the file to finish, but it's still waiting and throwing errors.
      }
    } catch (error) {
      console.log("error.type: ", error.constructor.name);
      console.error(
        "Something went wrong with the cloud function call.",
        error
      );
      if (downloadCount <= 10000) {
        await this.setState({
          isDownloading: false
        });
        return await this.props.handleAlerts(
          e,
          `Uh oh, something went wrong with the download:  ${error.message}. Try changing your search, downloading fewer leads, or sending an email to ${process.env.REACT_APP_contact_email}.`,
          "warning"
        );
      }

      //else {
      //  if (error.constructor.name === "deadline-exceeded") {
      //    return
      //  } else {
      //    await this.setState({
      //      isDownloading: false,
      //    });
      //    return await this.props.handleAlerts(e,`Uh oh, something went wrong with the download:  ${error.message}. Try changing your search, downloading fewer leads, or sending an email to ${process.env.REACT_APP_contact_email}.`, "warning");
      //  }
      //}
    }
  }

  handleDlCols(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    //console.log("value: ", value, "name: ", name, "cState[name]", value);
    let cState = { ...this.state.queryFields };
    cState[name] = value;
    let dl_name = `dl_${name}`;
    this.setState({
      [dl_name]: value,
      queryFields: cState
    });
  }

  // Check whether there are any searchParameters
  checkParamCount() {
    const geographyParams = { ...this.state.geographyParams };
    const searchParams = { ...this.state.searchParams };
    const allParams = Object.assign(searchParams, geographyParams);
    let paramCount = 0;
    for (let col of Object.values(allParams)) {
      paramCount += col.length;
    }
    return paramCount > 0 ? true : false;
  }

  async selectCheckoutTab() {
    this.setState({ selectedTab: "3" });
    let currentFilter = this.state.currentSearch.filter;
    let duplicateSum = 0;
    let maxOffset = 0;
    let fetchedDownloads = [];
    let offsets = [];
    let nonDupeCount;
    let downloadsRef = db
      .collection("downloads")
      .doc(this.props.userDoc.uid)
      .collection("files")
      .where("queryUrl.filter", "==", currentFilter);
    let sameDownloads = await downloadsRef.get();
    sameDownloads.forEach(doc => {
      let docData = doc.data();
      docData.id = doc.id;
      // Only show files where the database is the same
      if (docData.queryUrl.url !== this.apiUrl) {
        return null;
      }
      // Only show files where the download completed and it hasn't been reported
      if (docData.csvFile && !docData.reported) {
        fetchedDownloads.push(docData);
      }
      // check if they are actually duplicates
      if (!offsets.includes(docData.offset)) {
        offsets.push(docData.offset);
        duplicateSum += parseInt(docData.downloadCount);
      }
      // prepare for fetching a non-duplicate count
      if (maxOffset < docData.offset) {
        maxOffset = docData.offset;
      }
    });
    //console.log("fetchedDownloads: ", fetchedDownloads, "duplicateSum: ", duplicateSum, "offsets: ", offsets, "currentFilter: ", currentFilter, "this.apiUrl: ", this.apiUrl);
    if (maxOffset > 0) {
      let newSearch = { ...this.state.currentSearch };
      newSearch.offset = maxOffset;
      newSearch.count_only = "true";
      //console.log("newSearch: ", newSearch);
      let postBody = JSON.stringify(newSearch);
      // await response of fetch call
      const init = {
        method: "POST",
        headers: {
          authorization: `Bearer ${this.props.apiKey}`,
          "Content-Type": "application/json"
        },
        "Transfer-Encoding": "chunked",
        cache: "default",
        accept: "application/json",
        body: postBody
      };

      let indexUrl = this.apiUrl;
      try {
        let response = await fetch(indexUrl, init);
        nonDupeCount = await response.json();
      } catch (err) {
        console.log("Something went wrong fetching nonDupeCount: ", err);
      }
    }

    let sortedDownloads = fetchedDownloads.sort((a, b) => {
      let aV = a.timestamp.seconds;
      let bV = b.timestamp.seconds;
      return aV - bV;
    });

    let duplicateDownloads = {
      duplicateSum,
      maxOffset,
      nonDupeCount,
      items: sortedDownloads
    };
    console.log("duplicateDownloads: ", duplicateDownloads);
    this.setState({
      isDuplicate: sortedDownloads.length > 0 ? true : false,
      duplicateDownloads
    });
  }

  render() {
    //console.log("searchAgain this.props: ", this.props, "this.state: " this.state);
    const {
      dataDict,
      userDoc,
      userplan,
      handleModalShow,
      handleAlerts,
      apiKey,
      superAdmin
    } = this.props;
    const {
      leadsCount,
      geographyParams,
      searchParams,
      currentSearch,
      lastSearch,
      isDownloading,
      isBigDownload,
      doc_id,
      selectedTab,
      suppressSearch,
      isDuplicate,
      duplicateDownloads,
      savedSearch,
      savedSearchId,
      tests
    } = this.state;
    const { themeColor, dlCols, cols, leadsMultiplier } = dataDict;
    //console.log("this.state: ", this.state);
    //console.log("savedSearch: ", savedSearch);
    //console.log("this.emptyGeographyParams: ", this.emptyGeographyParams);
    //console.log("this.defaultQueryFields: ", this.defaultQueryFields);
    //console.log("this.props: ", this.props);
    let g = geographyParams;
    //console.log(g);
    let gLength = 0;
    for (let array of Object.values(g)) {
      gLength += array.length;
    }
    let hasGeo = gLength !== 0;
    //console.log("gLength: ", gLength, "hasGeo: ", hasGeo);
    let canFetch =
      JSON.stringify(currentSearch) !== JSON.stringify(lastSearch) &&
      currentSearch !== "";
    //console.log("savedSearch: ", savedSearch, "currentSearch: ", currentSearch);
    //let canSave = typeof savedSearch !== "undefined" ? ( JSON.stringify(currentSearch) !== JSON.stringify( savedSearch.queryUrl ) ) : true;
    let canSave =
      typeof savedSearch !== "undefined"
        ? !object_equals(currentSearch, savedSearch.queryUrl)
        : true;
    //console.log("canSave: ", canSave);
    //if (canSave && savedSearch) { console.log(JSON.stringify(currentSearch), JSON.stringify(savedSearch.queryUrl)) }
    let basicFilters = [];
    let advancedFilters = [];
    for (let key in cols) {
      if (cols[key]["filterType"] === "basic") {
        basicFilters.push(key);
      } else {
        advancedFilters.push(key);
      }
    }

    const hasAdvancedFilters =
      advancedFilters.length < Object.keys(cols).length;
    const basicFilterCategories = this.formatAdvancedFilters(basicFilters);
    const advancedFilterCategories = this.formatAdvancedFilters(
      advancedFilters
    );
    let downloadHelperText =
      userDoc.leadsRemaining * leadsMultiplier < userDoc.downloadLimit
        ? `You have ${userDoc.leadsRemaining.toLocaleString()} leads remaining this month.`
        : `Your can download up to ${userDoc.downloadLimit.toLocaleString()} leads at a time.`;
    if (leadsMultiplier === 0) {
      downloadHelperText = `These leads won't count toward your monthly limit!  You can download up to ${userDoc.downloadLimit.toLocaleString()} leads at a time.`;
    }
    if (suppressSearch && isDuplicate) {
      downloadHelperText =
        duplicateDownloads.nonDupeCount <
          userDoc.leadsRemaining * leadsMultiplier &&
        duplicateDownloads.nonDupeCount < userDoc.downloadLimit
          ? `You can download up to ${duplicateDownloads.nonDupeCount} unique leads. Un-check Suppress duplicates if you would like to download more leads.`
          : downloadHelperText;
    }
    let prevTab =
      parseInt(selectedTab) - 1 >= 0 ? parseInt(selectedTab) - 1 : 0;
    let nexTab = parseInt(selectedTab) + 1 <= 3 ? parseInt(selectedTab) + 1 : 3;
    let curTab = parseInt(selectedTab);
    //console.log(
    //  "curTab: ", curTab,
    //  "prevTab: ", prevTab,
    //  "nexTab: ", nexTab
    //);

    return (
      <Container>
        <Row
          style={{
            marginTop: "30px",
            minHeight: "75vh"
          }}
          className="text-center"
        >
          <Col xs={12}>
            <Row>
              <Col xs={12} md={8}>
                <Row>
                  <Col
                    xs={12}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div className="text-left">
                      <h3 className="text-left">{dataDict.title}</h3>
                      {dataDict.subText && (
                        <React.Fragment>{dataDict.subText}</React.Fragment>
                      )}
                    </div>

                    <LeadsCount
                      count={this.state.leadsCount}
                      refreshCount={this.fetchCount}
                      isFetching={this.state.isFetching}
                      canFetch={canFetch}
                      themeColor={themeColor}
                    />
                  </Col>
                  <Col xs={12}>
                    <Tab.Container defaultActiveKey="geography">
                      <Card
                        style={{
                          marginTop: "20px",
                          minHeight: "500px"
                        }}
                      >
                        <Card.Header>
                          <Nav variant="tabs">
                            <Nav.Item>
                              <Nav.Link
                                eventKey="geography"
                                onClick={() =>
                                  this.setState({ selectedTab: "0" })
                                }
                              >
                                Geography
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="filters"
                                onClick={e => {
                                  this.setState({ selectedTab: "1" });
                                  this.fetchCount(e, false);
                                }}
                                disabled={!hasGeo}
                              >
                                Filters
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="preview"
                                onClick={e => {
                                  this.setState({ selectedTab: "2" });
                                  this.fetchCount(e, false);
                                }}
                                disabled={!hasGeo}
                              >
                                Preview
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="checkout"
                                onClick={() => {
                                  this.selectCheckoutTab();
                                }}
                                disabled={!hasGeo}
                              >
                                Checkout
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Button
                                onClick={() => {
                                  window.location.reload();
                                }}
                                variant="outline-secondary"
                              >
                                Start over
                              </Button>
                            </Nav.Item>
                          </Nav>
                        </Card.Header>
                        <Card.Body>
                          <Tab.Content>
                            <Tab.Pane eventKey="geography">
                              <Geographies
                                searchType={this.state.searchType}
                                geoSearchBy={this.state.geoSearchBy}
                                selectSearchBy={this.selectGeoSearchBy}
                                addQueryParam={this.addSearchParam}
                                searchTypesDict={dataDict["geography"]}
                                dictKey="geography"
                                resetQuery={this.resetQuery}
                                themeColor={themeColor}
                                handleAlert={this.props.handleAlerts}
                              />
                            </Tab.Pane>
                          </Tab.Content>

                          <Tab.Content>
                            <Tab.Pane eventKey="filters">
                              <div className="text-left mb-5">
                                <h4>Select filters to target your leads.</h4>
                                <small>
                                  For the best results, only use filters
                                  important to your search.
                                </small>
                                {this.state.geoSearchBy === "nationwide" && (
                                  <small>
                                    &nbsp;For nationwide searches, select at
                                    least two basic demographic filters to speed
                                    up results and prevent errors.
                                  </small>
                                )}
                              </div>

                              <Form className="text-left" id="saForm">
                                {!hasAdvancedFilters &&
                                  this.createFilterInputs(
                                    Object.keys(dataDict.cols)
                                  )}
                                {hasAdvancedFilters && (
                                  <Accordion defaultActiveKey="0">
                                    <Card className="">
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey="0"
                                        className={`bg-${themeColor} text-white`}
                                      >
                                        Basic filters
                                      </Accordion.Toggle>

                                      <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                          {Object.keys(
                                            basicFilterCategories
                                          ).map((c, i) => {
                                            return (
                                              <React.Fragment key={`c_${c}`}>
                                                <h5>{c}</h5>
                                                {this.createFilterInputs(
                                                  basicFilterCategories[c]
                                                )}
                                                {i <
                                                  Object.keys(
                                                    basicFilterCategories
                                                  ).length -
                                                    1 && <hr />}
                                              </React.Fragment>
                                            );
                                          })}
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey="1"
                                        className={`bg-${themeColor} text-white`}
                                      >
                                        Advanced filters
                                      </Accordion.Toggle>

                                      <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                          {Object.keys(
                                            advancedFilterCategories
                                          ).map((c, i) => {
                                            return (
                                              <React.Fragment key={`c_${c}`}>
                                                <h5>{c}</h5>
                                                {this.createFilterInputs(
                                                  advancedFilterCategories[c]
                                                )}
                                                {i <
                                                  Object.keys(
                                                    advancedFilterCategories
                                                  ).length -
                                                    1 && <hr />}
                                              </React.Fragment>
                                            );
                                          })}
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  </Accordion>
                                )}
                              </Form>
                            </Tab.Pane>
                          </Tab.Content>

                          <Tab.Content>
                            <Tab.Pane eventKey="preview">
                              <Preview
                                isFetching={this.state.isFetching}
                                leads={this.state.leads}
                                previewCols={dataDict.previewCols}
                                dlCols={dataDict.dlCols}
                              />
                            </Tab.Pane>
                          </Tab.Content>

                          <Tab.Content>
                            <Tab.Pane eventKey="checkout">
                              {!isDownloading && this.state.download === null && (
                                <Row>
                                  <Col xs={12} className="text-left">
                                    <h3>Checkout.</h3>
                                    <p>Save your search and download leads.</p>
                                  </Col>
                                  <Col xs={12} className="text-left">
                                    <Form>
                                      <Form.Group>
                                        <Form.Label>Search name*</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Name your search"
                                          name="searchName"
                                          onChange={this.handleInputChange}
                                          value={this.state.searchName}
                                        />
                                      </Form.Group>

                                      <Form.Group>
                                        <Form.Label>
                                          How many leads would you like?*
                                        </Form.Label>
                                        <Form.Control
                                          type="number"
                                          placeholder="Enter number of leads"
                                          name="downloadCount"
                                          onChange={this.handleInputChange}
                                          value={this.state.downLoadCount}
                                        />
                                        <Form.Text className="text-muted">
                                          {downloadHelperText}
                                        </Form.Text>
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  {this.state.isDuplicate && (
                                    <Col xs={12} className="text-left mb-4">
                                      <Form className="mb-2">
                                        <Accordion className="mb-2 mt-2">
                                          <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey="0"
                                            className="text-danger text-italic"
                                            style={{
                                              fontSize: "1.1em",
                                              fontStyle: "italic"
                                            }}
                                          >
                                            You've already downloaded some of
                                            these leads &nbsp;
                                            <i
                                              className="fa fa-angle-down fa-1x"
                                              aria-hidden="true"
                                            ></i>
                                          </Accordion.Toggle>

                                          <Accordion.Collapse eventKey="0">
                                            <Card>
                                              <Card.Body>
                                                <Table
                                                  size="sm"
                                                  hover
                                                  responsive
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th>Search name</th>
                                                      <th>Date</th>
                                                      <th>Download</th>
                                                      <th className="text-right">
                                                        Download count
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {this.state.duplicateDownloads.items.map(
                                                      (download, i) => {
                                                        if (download.csvFile) {
                                                          return (
                                                            <tr key={`tr_${i}`}>
                                                              <td>
                                                                {
                                                                  download.searchName
                                                                }
                                                              </td>
                                                              <td>
                                                                {new Date(
                                                                  download
                                                                    .timestamp
                                                                    .seconds *
                                                                    1000
                                                                ).toLocaleString()}
                                                              </td>
                                                              <td>
                                                                <a
                                                                  className="alert-link"
                                                                  href={
                                                                    download.csvFile
                                                                  }
                                                                  download={`${download.searchName
                                                                    .replace(
                                                                      /\s/g,
                                                                      "_"
                                                                    )
                                                                    .replace(
                                                                      /\//g,
                                                                      "-"
                                                                    )}.csv`}
                                                                >
                                                                  csv
                                                                </a>
                                                              </td>
                                                              <td className="text-right">
                                                                {
                                                                  download.downloadCount
                                                                }
                                                              </td>
                                                            </tr>
                                                          );
                                                        } else return null;
                                                      }
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </Card.Body>
                                              <ListGroup variant="flush">
                                                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                                  Leads already downloaded from
                                                  this search:
                                                  <div
                                                    style={{
                                                      fontSize: "1.2em"
                                                    }}
                                                  >
                                                    {this.state.duplicateDownloads.duplicateSum.toLocaleString()}
                                                  </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                                  Uniqe leads remaining:
                                                  <div
                                                    style={{
                                                      fontSize: "1.2em"
                                                    }}
                                                  >
                                                    {this.state.duplicateDownloads.nonDupeCount.toLocaleString()}
                                                  </div>
                                                </ListGroup.Item>
                                              </ListGroup>
                                            </Card>
                                          </Accordion.Collapse>
                                        </Accordion>
                                        <div className="d-flex flex-row justify-content-start no-gutters">
                                          <div>
                                            <Form.Check
                                              checked={
                                                this.state.suppressSearch
                                              }
                                              onChange={e => {
                                                this.handleInputChange(e);
                                              }}
                                              disabled={
                                                !userplan.searchSuppressions
                                              }
                                              name="suppressSearch"
                                              type="checkbox"
                                              label="Suppress duplicates"
                                            />
                                          </div>
                                          {!userplan.searchSuppressions && (
                                            <div>
                                              <button
                                                type="button"
                                                onClick={handleModalShow}
                                                className="badge badge-pill badge-info ml-1"
                                              >
                                                Upgrade
                                              </button>
                                            </div>
                                          )}
                                        </div>

                                        <Form.Row className="text-left">
                                          <Form.Text className="text-muted text-left">
                                            Search suppressions help prevent you
                                            from downloading the same leads more
                                            than once.
                                          </Form.Text>
                                          {!userplan.searchSuppressions && (
                                            <small className="text-muted text-left">
                                              Your account doesn't have access
                                              to this feature. To avoid
                                              duplicates, change your search or
                                              upgrade your account.
                                            </small>
                                          )}
                                        </Form.Row>
                                      </Form>
                                    </Col>
                                  )}
                                  <Col xs={12} className="text-left">
                                    <Form>
                                      {/* Dry this out */}

                                      <Form.Group as={Row}>
                                        <Form.Label column xs="12">
                                          Select columns*
                                        </Form.Label>
                                        {dlCols
                                          .slice(0, this.state.displayCol)
                                          .map(opt => (
                                            <Col
                                              key={`col-${opt.value}`}
                                              className="mb-1"
                                              xs="6"
                                            >
                                              <Form.Check
                                                type="checkbox"
                                                name={opt.value}
                                                label={opt.name}
                                                key={"col-" + opt.value}
                                                onChange={this.handleDlCols}
                                                checked={
                                                  this.state[`dl_${opt.value}`]
                                                }
                                              />
                                            </Col>
                                          ))}
                                        {this.state.displayCol <
                                          dataDict["dlCols"].length && (
                                          <Button
                                            variant="link"
                                            size="sm"
                                            className="text-left d-block col-12"
                                            onClick={() =>
                                              this.setState({
                                                displayCol:
                                                  dataDict["dlCols"].length
                                              })
                                            }
                                            disabled={
                                              !this.props.userDoc.moreCols
                                            }
                                          >
                                            More columns
                                            {this.props.userDoc.moreCols ===
                                              false && (
                                              <Link
                                                to="/settings"
                                                className="badge badge-pill badge-info ml-1"
                                              >
                                                Upgrade
                                              </Link>
                                            )}
                                          </Button>
                                        )}
                                      </Form.Group>
                                      <small className="text-muted">
                                        Check the columns to included in your
                                        CSV download. Downloads will be
                                        available as long as your account
                                        remains active.
                                      </small>
                                    </Form>
                                  </Col>
                                </Row>
                              )}
                              {isDownloading && isBigDownload && doc_id && (
                                <div
                                  style={{
                                    maxWidth: "500px",
                                    margin: "0 auto",
                                    marginTop: "30px",
                                    marginBottom: "30px"
                                  }}
                                >
                                  <DownloadProgress
                                    searchName={this.state.searchName}
                                    doc_id={this.state.doc_id}
                                    handleDownload={this.handleDownload}
                                    download={this.state.download}
                                    user={this.props.user}
                                    handleAlerts={this.props.handleAlerts}
                                  />
                                </div>
                              )}
                              {isDownloading && (
                                <Row>
                                  <Col xs={12} className="text-center">
                                    {!this.state.isBigDownload && (
                                      <React.Fragment>
                                        <Spinner
                                          variant="primary"
                                          animation="grow"
                                          style={{ margin: "30px" }}
                                        />
                                        <h4>
                                          We're preparing your download. Hold
                                          tight!
                                        </h4>
                                      </React.Fragment>
                                    )}
                                    {this.state.isBigDownload && (
                                      <React.Fragment>
                                        <h4>
                                          Wow, that's a lot of leads! Give us a
                                          few minutes to package them together
                                          and we'll let you know when the file
                                          is ready for download.
                                        </h4>
                                        <Button
                                          variant="outline-primary"
                                          onClick={this.resetQuery}
                                        >
                                          Start a new search
                                        </Button>
                                      </React.Fragment>
                                    )}
                                  </Col>
                                </Row>
                              )}
                              {!isDownloading && this.state.download && (
                                <Row>
                                  <Col xs={12} className="text-center">
                                    <h3>Your download is ready!</h3>
                                    <Table bordered hover className="text-left">
                                      <tbody>
                                        <tr>
                                          <td>
                                            <b>Search name:</b>{" "}
                                          </td>
                                          <td>{this.state.searchName}</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>Leads downloaded:</b>{" "}
                                          </td>
                                          <td>{this.state.downloadCount}</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>Download date:</b>{" "}
                                          </td>
                                          <td>{new Date().toString()}</td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <Button
                                      variant="success"
                                      size="lg"
                                      style={{ marginTop: "30px" }}
                                      href={this.state.download}
                                      download={`${this.state.searchName
                                        .replace(/\s/g, "_")
                                        .replace(/\//g, "-")}.csv`}
                                    >
                                      Download CSV
                                    </Button>
                                    <Button
                                      variant="outline-success"
                                      className="ml-3"
                                      style={{ marginTop: "30px" }}
                                      onClick={() => {
                                        let emailRecipients = [userDoc.email];
                                        if (
                                          typeof userDoc.companyProfile !==
                                          "undefined"
                                        ) {
                                          if (
                                            userDoc.email !==
                                            userDoc.companyProfile.email
                                          ) {
                                            emailRecipients.push(
                                              userDoc.companyProfile.email
                                            );
                                          }
                                        }
                                        let msg = {
                                          to: emailRecipients,
                                          from: "help@listshack.support",
                                          subject: `Download your file from ${process.env.REACT_APP_site_name}`,
                                          text: `Download the file for the search <strong>${this.state.searchName} from ${process.env.REACT_APP_site_name}</strong> by clicking the button below by or copying and pasting the link into your browser. ${this.state.download}.`,
                                          html: `<p>Download the file for the search ${
                                            this.state.searchName
                                          } from ${
                                            process.env.REACT_APP_site_name
                                          } by clicking the button below or copying and pasting the link into your browser:</p><div style="text-align: center; display: block"><a style="padding: 8px 16px; margin: 30px; font-size: 20px; background-color: #17a2b8; color: #ffffff;border-radius:.3rem;text-decoration: none" href="${
                                            this.state.download
                                          }">${
                                            this.state.searchName
                                          }</a></div><p>${
                                            this.state.download
                                          }</p><p><small>This list contains ${this.state.downloadCount.toLocaleString()} leads and you have ${userDoc.leadsRemaining.toLocaleString()} left during this billing cycle. If you have any issues with this file, such as a blank file, log into your account and report the file from the <a href="https://www.listshack.support/downloads">Downloads page</a>.</small></p>`
                                        };

                                        sendEmail({
                                          msg,
                                          handleAlerts,
                                          apiKey
                                        });
                                        handleAlerts(
                                          "",
                                          `A link to download your file was sent to the following email addresses: ${emailRecipients.join(
                                            " and "
                                          )}`,
                                          "success",
                                          "Email sent"
                                        );
                                      }}
                                    >
                                      Email me this file
                                    </Button>
                                    <br />
                                    <small className="text-muted">
                                      Find this download later and report
                                      problems with the file from the{" "}
                                      <Link to="/downloads">
                                        downloads page
                                      </Link>
                                      .
                                    </small>
                                  </Col>
                                </Row>
                              )}
                            </Tab.Pane>
                          </Tab.Content>
                          {!isDownloading && this.state.download === null && (
                            <Nav
                              variant="tabs"
                              className="d-flex justify-content-between mt-3"
                            >
                              <Nav.Item>
                                {curTab !== prevTab && (
                                  <Nav.Link
                                    eventKey={this.tabs[prevTab]["eventKey"]}
                                    className="btn btn-link btn-lg"
                                    onClick={() =>
                                      this.setState({
                                        selectedTab: `${prevTab}`
                                      })
                                    }
                                  >
                                    &lt;&nbsp;{this.tabs[prevTab]["name"]}
                                  </Nav.Link>
                                )}
                              </Nav.Item>
                              <Nav.Item>
                                {curTab === 3 && (
                                  <Button
                                    variant="success"
                                    size="lg"
                                    onClick={e => this.downloadList(e)}
                                    disabled={this.state.isDownloading}
                                  >
                                    {this.state.isDownloading
                                      ? "Downloading"
                                      : "Download list"}
                                  </Button>
                                )}
                                {nexTab === 1 && hasGeo && (
                                  <Nav.Link
                                    eventKey={this.tabs[nexTab]["eventKey"]}
                                    className="btn btn-link btn-lg"
                                    onClick={e => {
                                      this.setState({
                                        selectedTab: `${nexTab}`
                                      });
                                      this.fetchCount(e, false);
                                    }}
                                  >
                                    {this.tabs[nexTab]["name"]}&nbsp;&gt;
                                  </Nav.Link>
                                )}
                                {/*(curTab !== nexTab) && (nexTab !== 2 || nexTab !== 3) && hasGeo &&
                                <Nav.Link
                                  eventKey={this.tabs[nexTab]["eventKey"]}
                                  className="btn btn-link btn-lg"
                                  onClick={(e) => {
                                    this.setState({selectedTab: `${nexTab}`});
                                  }}
                                >
                                  {this.tabs[nexTab]['name']}&nbsp;&gt;
                                </Nav.Link>
                              */}
                                {nexTab === 2 && hasGeo && (
                                  <Nav.Link
                                    eventKey={this.tabs[nexTab]["eventKey"]}
                                    className="btn btn-link btn-lg"
                                    onClick={e => {
                                      this.setState({
                                        selectedTab: `${nexTab}`
                                      });
                                      this.fetchCount(e, false);
                                    }}
                                  >
                                    {this.tabs[nexTab]["name"]}&nbsp;&gt;
                                  </Nav.Link>
                                )}
                                {nexTab === 3 && nexTab !== curTab && hasGeo && (
                                  <Nav.Link
                                    eventKey={this.tabs[nexTab]["eventKey"]}
                                    className="btn btn-link btn-lg"
                                    onClick={() => {
                                      this.selectCheckoutTab();
                                    }}
                                  >
                                    {this.tabs[nexTab]["name"]}&nbsp;&gt;
                                  </Nav.Link>
                                )}
                              </Nav.Item>
                            </Nav>
                          )}
                        </Card.Body>
                      </Card>
                    </Tab.Container>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={4}>
                <div className="sticky-top">
                  <Card className="shadow" style={{ minHeight: "250px" }}>
                    <Card.Header
                      className={`bg-${themeColor} text-white d-flex justify-content-between align-items-center`}
                      style={{ minHeight: "89px" }}
                    >
                      {!hasGeo && (
                        <Button
                          variant="link"
                          onClick={() =>
                            this.setState({ showSavedSearches: true })
                          }
                          className="text-white"
                        >
                          Search selected &nbsp;
                          <i
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      )}
                      {hasGeo && (
                        <React.Fragment>
                          <div>
                            <Button
                              variant="link"
                              onClick={() =>
                                this.setState({ showSavedSearches: true })
                              }
                              className="text-white"
                            >
                              {!canSave && hasGeo && (
                                <React.Fragment>
                                  {savedSearch.searchName
                                    ? savedSearch.searchName
                                    : `DEFAULT`}
                                </React.Fragment>
                              )}
                              {canSave && "Search selected"}
                              &nbsp;
                              <i
                                className="fa fa-caret-down"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </div>
                          <Button
                            className="text-center text-white"
                            variant="link"
                            onClick={async e => {
                              await this.fetchCount(e);
                              this.setState({
                                showSaveModal: true,
                                savedSearch: !canSave ? savedSearch : {},
                                savedSearchId: null
                              });
                            }}
                            style={{
                              marginRight: "-15px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center"
                            }}
                          >
                            {!canSave && hasGeo && (
                              <React.Fragment>
                                <i
                                  className="fa fa-bookmark fa-2x"
                                  aria-hidden="true"
                                ></i>
                                <small>saved</small>
                              </React.Fragment>
                            )}
                            {canSave && hasGeo && (
                              <React.Fragment>
                                <i
                                  className="fa fa-bookmark-o fa-2x"
                                  aria-hidden="true"
                                ></i>
                                <small>save</small>
                              </React.Fragment>
                            )}
                          </Button>
                        </React.Fragment>
                      )}
                    </Card.Header>
                    <Card.Body>
                      {!hasGeo && (
                        <div>
                          Select a geography to start searching for leads!
                        </div>
                      )}
                      {hasGeo && (
                        <div className="text-left">
                          <SelectedParams
                            selectedParams={this.state.geographyParams}
                            dict={dataDict["geography"]}
                            dictKey="geography"
                            reset={this.resetGeoParams}
                            displayOnMap={this.displayOnMap}
                            removeParam={this.removeSearchParam}
                            paramHeader="Geography"
                          />
                          <SelectedParams
                            selectedParams={this.state.searchParams || {}}
                            dict={dataDict["cols"]}
                            dictKey="cols"
                            removeParam={this.removeSearchParam}
                            paramHeader={`${dataDict.type} filters`}
                          />
                        </div>
                      )}
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem>
                        <LeadsCount
                          count={this.state.leadsCount}
                          refreshCount={this.fetchCount}
                          isFetching={this.state.isFetching}
                          canFetch={canFetch}
                          themeColor={themeColor}
                          inline={true}
                        />
                      </ListGroupItem>
                    </ListGroup>
                  </Card>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>&nbsp;</span>
                    <Button
                      variant="link"
                      onClick={e => {
                        saveSearch(
                          e,
                          {
                            isDefault: true,
                            geographyParams,
                            searchParams,
                            dataDict,
                            queryUrl: currentSearch,
                            count: this.state.leadsCount,
                            user: this.props.user
                          },
                          (error, result) => {
                            if (error) {
                              this.props.handleAlerts(
                                "",
                                "Uh oh, the default search wasn't saved.",
                                "warning"
                              );
                            }
                            if (result) {
                              this.props.handleAlerts(
                                "",
                                "Wahoo, the default search was saved!",
                                "success"
                              );
                              this.setState({
                                savedSearch: {
                                  geographyParams,
                                  searchParams,
                                  searchName: "",
                                  count: leadsCount ? leadsCount : 0,
                                  uid: userDoc.uid,
                                  queryUrl: this.state.currentSearch
                                }
                              });
                            }
                          }
                        );
                      }}
                      size="sm"
                      className="text-right"
                    >
                      Set as default
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {superAdmin && (
            <Col
              xs={12}
              className="text-left mt-5"
              style={{ minHeight: "50px" }}
            >
              <div className="d-flex justify-content-between align-items-center bg-light p-2 mb-3">
                <p className="lead mb-0">
                  <i
                    className="fa fa-eye-slash mr-3"
                    style={{ fontSize: "24px" }}
                  ></i>
                  Tests for this database
                </p>
                <div>
                  {!tests && !this.state.runningTests && (
                    <Button
                      onClick={e => {
                        e.preventDefault();
                        let tests = createTests(
                          this.props.dataDict,
                          this.fetchCount,
                          this.createRangeQuery
                        );
                        this.setState({ tests });
                      }}
                      variant="outline-danger"
                      size="sm"
                      disabled={tests}
                    >
                      {tests ? "Reset" : "Create tests"}
                    </Button>
                  )}
                  {(tests || this.state.runningTests) && (
                    <React.Fragment>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ tests: null });
                        }}
                        variant="outline-warning"
                        size="sm"
                        disabled={this.state.runningTests}
                        className="mr-1"
                      >
                        Reset
                      </Button>
                      <Button
                        onClick={async e => {
                          e.preventDefault();
                          e.persist();
                          this.setState({ runningTests: true, tests: null });
                          let testResults = { ...tests };

                          for (let field of Object.entries(tests)) {
                            console.log("field: ", field);
                            let newTests = [];
                            for (let test of field[1].tests) {
                              let newTest = { ...test };
                              let result = await test.test(e);
                              newTest.result = result;
                              newTests.push(newTest);
                            }
                            testResults[field[0]].tests = newTests;
                          }
                          //await Object.entries(tests).map( async field => {
                          //  console.log("Running tests for: ", field[0]);
                          //  let newTests = [];
                          //  await field[1].tests.map( async test => {
                          //    let newTest = {...test}
                          //    let result = await test.test(e);
                          //    newTest.result = result;
                          //    newTests.push(newTest);
                          //  })
                          //  testResults[field[0]].tests = newTests;
                          //})
                          console.log("testResults: ", testResults);
                          this.setState({
                            tests: testResults,
                            runningTests: false
                          });
                        }}
                        variant="outline-danger"
                        size="sm"
                        disabled={this.state.runningTests}
                      >
                        {!this.state.runningTests
                          ? "Run All Tests"
                          : "Running tests"}
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </div>

              <Table responsive hover striped>
                <thead>
                  <tr>
                    <th>Test</th>
                    <th>Passed</th>
                    <th>Details</th>
                  </tr>
                </thead>
                {tests && (
                  <tbody>
                    {Object.entries(tests).map(field => {
                      return field[1].tests.map(test => {
                        return (
                          <DisplayTest
                            fieldName={field[0]}
                            item={test.item}
                            test={test.test}
                            preTest={test.result}
                          />
                        );
                      });
                    })}
                  </tbody>
                )}
                {!tests && (
                  <tbody>
                    <tr>
                      <td colSpan="3">
                        {this.state.runningTests
                          ? "Running tests"
                          : "Click above to create tests"}
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Col>
          )}
        </Row>
        {this.state.showSaveModal && (
          <Modal
            size="lg"
            show={this.state.showSaveModal}
            onHide={() => this.setState({ showSaveModal: false })}
          >
            <Modal.Header>
              <h4>{canSave ? "Save new search" : "Manage saved search"}</h4>
            </Modal.Header>
            {savedSearch && (
              <Modal.Body>
                <SavedSearch
                  {...this.props}
                  savedSearch={savedSearch}
                  savedSearchId={savedSearchId}
                  canSave={canSave}
                  dataDict={dataDict}
                  geographyParams={this.state.geographyParams}
                  searchParams={this.state.searchParams}
                  hasGeo={hasGeo}
                  currentSearch={currentSearch}
                  onDelete={() =>
                    this.setState({
                      showSaveModal: false,
                      savedSearch: "",
                      savedSearchId: ""
                    })
                  }
                  onSave={(savedSearch, savedSearchId) => {
                    console.log("onSave: ", savedSearch, savedSearchId);
                    this.setState({
                      savedSearch,
                      savedSearchId
                    });
                  }}
                  fetchCount={this.fetchCount}
                  count={this.state.leadsCount}
                  queryString={this.queryString}
                  isModal={true}
                />
              </Modal.Body>
            )}
            {!savedSearch && <Modal.Body>Loading saved search ...</Modal.Body>}
            <Modal.Footer className="d-flex justify-content-between">
              <Button
                variant="light"
                onClick={() => this.setState({ showSaveModal: false })}
                className="pull-left"
              >
                Cancel
              </Button>
              <Link to="/searches" className="btn btn-light">
                View all
              </Link>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.showSavedSearches && (
          <Modal
            size="xl"
            show={this.state.showSavedSearches}
            onHide={() => this.setState({ showSavedSearches: true })}
          >
            <Modal.Body>
              <Searches
                {...this.props}
                dicts={[dataDict]}
                dict={dataDict}
                displaySearch={canSave ? null : savedSearch}
                reuseAction={(e, savedSearch) => {
                  this.setState({
                    savedSearch,
                    showSavedSearches: false,
                    currentSearch: savedSearch.queryUrl,
                    searchParams: savedSearch.searchParams,
                    geographyParams: savedSearch.geographyParams
                  });
                  this.fetchCount(e);
                }}
                isModal={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="light"
                onClick={() => this.setState({ showSavedSearches: false })}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Container>
    );
  }

  async componentDidMount() {
    let { handleAlerts, dataDict, user } = this.props;
    await this.resetQuery();
    if (this.checkParamCount()) {
      this.createUrl();
    }
    // Handle Shared Searches and Reusing searches from Downloads
    setSavedSearch(
      {
        handleState: obj => {
          this.setState(obj);
        },
        dataDict,
        user
      },
      (error, result) => {
        if (error) {
          handleAlerts(
            "",
            `Unable to set saved search: ${error.message}`,
            "warning"
          );
        }
        if (result) {
          handleAlerts(
            "",
            <React.Fragment>
              Yay, your viewing{" "}
              {result.type === "download"
                ? "the search used for download"
                : "the saved search"}{" "}
              <b>{result.savedSearch.searchName}</b>.
            </React.Fragment>
          );
        }
      }
    );
  }
}

Search.propTypes = {
  user: PropTypes.object,
  handleAlerts: PropTypes.func,
  userDoc: PropTypes.object
};

export default Search;

const DisplayTest = props => {
  let { test, fieldName, item, preTest } = props;
  let [result, setResult] = useState("");
  let [error, setError] = useState("");
  useEffect(() => {
    if (preTest) {
      if (preTest.data) {
        setResult(preTest.data);
      }
      if (preTest.error) {
        setError(preTest.error);
      }
    }
  }, []);
  return (
    <tr key={fieldName}>
      <td>
        <Button
          variant="outline-dark"
          size="sm"
          onClick={async e => {
            let response = await test(e);
            if (response.error) {
              return setError(response.error);
            }
            return setResult(response.data);
          }}
          className="mr-2"
        >
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </Button>
        <code>
          {fieldName}: {item.name}
        </code>
      </td>
      <td>
        {result && (
          <i className="text-success fa fa-check" aria-hidden="true"></i>
        )}
        {error && <i class="text-danger fa fa-times" aria-hidden="true"></i>}
      </td>
      <td>
        {result && (
          <span className="text-success">{result.toLocaleString()}</span>
        )}
        {error && (
          <JSONPretty
            className="text-left"
            id="json-pretty"
            data={error}
          ></JSONPretty>
        )}
      </td>
    </tr>
  );
};

const createTest = params => {
  // colshaped like
  // ["phonePref", {
  //   name: "Phone scrubbing",
  //   displayHeader: true,
  //   inputType: "select",
  //   filterType: "basic",
  //   filterCategory: "Contact preferences",
  //   items: [
  //     {index: 0, query: `(DNC_FLAG="False") and (PHONE <>"")`, value: "A", name: "DNC Scrubbed"},
  //     {index: 1, query: `(PHONE <>'')`, value: "B", name: "All records with phones (no scrubbing)"},
  //     {index: 2, value: "", name: "All records with or without phones"},
  //   ]
  // }]
  let { dataDict, field, item, fetchCount, createRangeQuery } = params;
  let type = field.inputType;
  let createTest = testParams => {
    return async e => {
      console.log(`Run test for ${field.fieldName}: ${item.name}`);
      try {
        let data = await fetchCount(e, true, testParams, true);
        return { data };
      } catch (error) {
        console.log("Test error: ", error);
        return { error };
      }
    };
  };

  let testParams, test, display;
  if (type === "select" || type === "checkbox") {
    testParams = { [field.fieldName]: [item] };
    test = createTest(testParams);
    display = (
      <DisplayTest fieldName={field.fieldName} test={test} item={item} />
    );
  }
  if (type === "range" || type === "stringRange") {
    //console.log("field: ", field);
    let min = JSON.stringify(field.items[0]);
    let max = JSON.stringify(field.items[field.items.length - 1]);
    //console.log("min max: ", min, max);
    let isString = type === "range" ? false : true;
    let rangeQuery = createRangeQuery(
      field.fieldName,
      min,
      max,
      isString,
      dataDict
    );
    testParams = { [field.fieldName]: [rangeQuery] };
    test = createTest(testParams);
    item = rangeQuery;
    display = (
      <DisplayTest fieldName={field.fieldName} test={test} item={rangeQuery} />
    );
  }
  if (test) {
    return { test, item };
  }
  return {
    test: () => console.log("No test for this."),
    display: () => <p>Test for {field.fieldName} is not Select Input</p>
  };
};

const createTests = (dataDict, fetchCount, createRangeQuery) => {
  let tests = {};
  Object.entries(dataDict.cols).map(col => {
    //console.log("col: ", col);
    let itemTests = [];
    let fieldName = col[0];
    let field = col[1];
    field.fieldName = fieldName;
    let type = col[1].inputType;
    if (type === "select" || type === "checkbox") {
      col[1].items.map(item => {
        itemTests.push(createTest({ field, item, fetchCount }));
      });
    }
    if (type === "range" || type === "stringRange") {
      let item = { name: "Max - Min" };
      itemTests.push(
        createTest({ dataDict, field, item, fetchCount, createRangeQuery })
      );
    }
    field.tests = itemTests;
    tests[fieldName] = field;
  });
  console.log("tests: ", tests);
  return tests;
};
