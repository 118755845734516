import React from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Jumbotron,
  CardGroup,
  Card,
  ListGroup,
  Image
} from "react-bootstrap";
import logo from "./media/icon.png";
import { formatPhoneNumber } from "./interfaceListShackPro.jsx";
import analyze from "./media/analyze.jpg";
import listshack from "./media/listshack.jpg";
import sonlet from "./media/sonlet.jpg";
import popitup from "./media/popitup.jpg";
import shout from "./media/shout.jpg";
import readyprez from "./media/readyprez.jpg";

// STATIC CONTENT

const SiteNav = props => {
  return (
    <Navbar
      expand="lg"
      variant="dark"
      className="bg-dark"
      style={{ height: "60px" }}
      id="nav2"
    >
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="listshack.io" width="50" height="50" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          <Nav.Item>
            <Link className="nav-link" to="/">
              Home
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/pricing">
              Pricing
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/contact">
              Contact Us
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/signup">
              Sign Up
            </Link>
          </Nav.Item>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const HomeLsio = props => {
  let { user } = props;

  if (user !== null) {
    return <Redirect to="/" />;
  } else {
    return (
      <Container fluid>
        <Row
          className="bg-dark text-center text-white d-flex justify-content-center align-items-center"
          style={{
            //backgroundImage: `url(${hero_img})`,
            //backgroundColor: "#f3506e",
            height: "60vh"
            //backgroundPosition: "top",
            //backgroundSize: "cover"
          }}
        >
          <Jumbotron
            style={{
              backgroundColor: "rgb(0,0,0,0.6)",
              height: "100%",
              width: "100%"
            }}
            className="d-flex justify-content-center align-items-middle flex-column"
            fluid
          >
            <h1>{process.env.REACT_APP_site_name}</h1>
            <h2>Software for small businesses</h2>
            <h4>Built with small business professionals in mind.</h4>
            <p className="lead">
              Simple presentation software, sales and marketing research, social
              selling, and more.
            </p>
          </Jumbotron>
        </Row>

        <Row className="d-flex justify-content-center align-items-center flex-column text-center bg-dark p-3">
          <Container>
            <Row>
              <Col className="d-flex justify-content-center align-items-center flex-column pt-5 pb-5">
                <h5 className="text-white">Products &amp; Affiliations</h5>
                {/*<Row className="mt-3 d-flex justify-content-between align-items-center">
                  { [analyze, listshack, sonlet, popitup, shout, readyprez].map( (src , i) => {
                    return(
                      <Col key={`prd_img_${i}`} xs={6} md={4} lg={2} className="p-1">
                        <Image src={src} fluid rounded />
                      </Col>
                    )
                  })}
                </Row>*/}
                <p className="mt-3 lead text-white text-left">
                  We build software products tailor made for small businesses.
                  Whether you need a simple solution for managing presentations
                  on the go, or your looking for better marketing insights for
                  your account management and sales team,{" "}
                  {process.env.REACT_APP_site_name} can help.
                  <br /> <br />
                  We save you time so you can focus on the things that matter
                  most for you business.
                </p>
              </Col>
            </Row>
          </Container>
        </Row>

        <Row className="d-flex justify-content-center align-items-center flex-row text-center bg-light">
          <Col className="d-flex justify-content-around align-items-middle flex-column text-center">
            <div className="p-5">
              <ul
                className="text-center"
                style={{ fontSize: "1.3rem", listStyle: "none" }}
              >
                <li>Present anywhere, anytime</li>
                <li>Market research</li>
                <li>Customer and account management</li>
                <li>Social selling</li>
                <li>Get started for as little as $10 per month</li>
              </ul>
              <Link className="btn btn-dark btn-lg mt-3" to="/signup">
                Signup Now
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
};

const PricingLsio = props => {
  return (
    <Container>
      <Row>
        <Col
          className="text-center d-flex flex-column justify-content-center align-items-center mt-5 mb-5"
          style={{ minHeight: "75vh" }}
        >
          <h2>Pricing</h2>
          <p className="lead">
            Small business software priced for small business owners.
          </p>
          <Row>
            <CardGroup>
              <Card>
                <Card.Header>
                  Social Selling &amp; Inventory Management
                </Card.Header>
                <Card.Body>
                  <h5>Features</h5>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Track inventory</ListGroup.Item>
                    <ListGroup.Item>Post to Facebook</ListGroup.Item>
                    <ListGroup.Item>Shopify Integration</ListGroup.Item>
                    <ListGroup.Item>eCommerce sites</ListGroup.Item>
                  </ListGroup>
                  <h6 className="text-muted mt-5">
                    Starting as low as $9.99 per month
                  </h6>
                </Card.Body>
              </Card>

              <Card>
                <Card.Header>Shipping &amp; Order Management</Card.Header>
                <Card.Body>
                  <h5>Features</h5>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Track orders</ListGroup.Item>
                    <ListGroup.Item>Order followups and alerts</ListGroup.Item>
                    <ListGroup.Item>Print USPS Shipping Labels</ListGroup.Item>
                    <ListGroup.Item>Schedule USPS pickups</ListGroup.Item>
                    <ListGroup.Item>Shipping status alerts</ListGroup.Item>
                  </ListGroup>
                  <h6 className="text-muted mt-5">
                    Starting as low as $9.99 per month
                  </h6>
                </Card.Body>
              </Card>

              <Card>
                <Card.Header>
                  Texting Tools &amp; Contact Management
                </Card.Header>
                <Card.Body>
                  <h5>Features</h5>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      Toll Free &amp; Local numbers
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Customer Relationship Management
                    </ListGroup.Item>
                    <ListGroup.Item>Group messages</ListGroup.Item>
                    <ListGroup.Item>Customer support bots</ListGroup.Item>
                    <ListGroup.Item>Team onboarding</ListGroup.Item>
                    <ListGroup.Item>Drip campaigns</ListGroup.Item>
                  </ListGroup>

                  <h6 className="text-muted mt-5">
                    Starting as low as $14.99 per month
                  </h6>
                </Card.Body>
              </Card>

              <Card>
                <Card.Header>Sales &amp; Marketing Presentations</Card.Header>
                <Card.Body>
                  <h5>Features</h5>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Present anywhere</ListGroup.Item>
                    <ListGroup.Item>
                      Prebuilt &amp; customer templates
                    </ListGroup.Item>
                    <ListGroup.Item>Instand PDF presentation</ListGroup.Item>
                    <ListGroup.Item>Present videos</ListGroup.Item>
                    <ListGroup.Item>Team support</ListGroup.Item>
                  </ListGroup>
                  <h6 className="text-muted mt-5">
                    Starting as low as $28 per month
                  </h6>
                </Card.Body>
              </Card>

              <Card>
                <Card.Header>Sales &amp; Marketing Research</Card.Header>
                <Card.Body>
                  <h5>Features</h5>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      Market and prospect research
                    </ListGroup.Item>
                    <ListGroup.Item>Database management</ListGroup.Item>
                    <ListGroup.Item>Consumer market research</ListGroup.Item>
                    <ListGroup.Item>
                      Franchise and Real estate site research
                    </ListGroup.Item>
                    <ListGroup.Item>Segment research</ListGroup.Item>
                    <ListGroup.Item>
                      Business segementation research
                    </ListGroup.Item>
                    <ListGroup.Item>Marketing data</ListGroup.Item>
                  </ListGroup>
                  <h6 className="text-muted mt-5">
                    Starting as low as $50 per month
                  </h6>
                </Card.Body>
              </Card>
            </CardGroup>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="m-5 text-center">
          <h5>
            Contact us today to discuss your tailored software solution for your
            small business.
          </h5>
          <Link to="/signup" className="btn btn-lg btn-dark mt-2">
            Signup Now
          </Link>
          <p className="lead mt-3">
            Signing up is as simple as texting or emailing. Receive a custom
            link for your tailored products.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

const ContactLsio = props => {
  return (
    <Container>
      <Row>
        <Col
          className="text-center d-flex flex-column justify-content-center align-items-center"
          style={{ minHeight: "75vh" }}
        >
          <h1>Contact us</h1>
          <p className="lead">
            We'd love to learn more about your business and how we can help.
          </p>
          <h4>Email sales &amp; support</h4>
          <p>Our sales and support team responds to email 24/7</p>
          <p>
            Email:{" "}
            <a href="mailto:help@listshack.support">help@listshack.support</a>
          </p>
          <h4>Text sales &amp; support</h4>
          <p>
            Our sales and support team responds to text Monday - Friday from
            10am to 3pm MST.
          </p>
          <p>
            Text:{" "}
            <a href={`sms:${process.env.REACT_APP_contact_sms}`}>
              {formatPhoneNumber(process.env.REACT_APP_contact_sms)}
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

const SignupLsio = props => {
  return (
    <Container>
      <Row>
        <Col
          className="text-center d-flex flex-column justify-content-center align-items-center"
          style={{ minHeight: "75vh" }}
        >
          <h1>Signup</h1>
          <Card>
            <Card.Body>
              Our sales and support team will help you get your account set up
              today.
              <br />
              Contact us to discuss your needs and receive a <br />
              tailored link to signup for your subscription today.
              <br />
              <Link className="mt-3 btn btn-primary btn-lg mt-5" to="/contact">
                Get Started
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { SiteNav, HomeLsio, PricingLsio, ContactLsio, SignupLsio };
