import React from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Accordion,
  Card,
  Button
} from "react-bootstrap";
import { useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import Spinner from "react-bootstrap/Spinner";

class Api extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      response: null
    };
    this.fetchApi = this.fetchApi.bind(this);
  }

  async setToken(user = this.props.user) {
    //console.log("user: ", user);
    if (typeof user !== "undefined") {
      const body = {
        user: {
          uid: user.uid,
          email: user.email
        },
        billing_cycle_anchor: this.props.customerDoc.billing_cycle_anchor
      };
      //console.log("body: ", body);
      const init = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      };

      let response = await fetch("/api/login", init);
      let data = await response.json();
      let token = data.token;
      this.setState({ api_token: token });
    }
  }

  async fetchApi(query, slug) {
    //Get data from firestore
    console.log("query: ", query);
    const init = {
      headers: {
        authorization: `Bearer ${this.state.api_token}`,
        method: "GET"
      },
      accept: "application/json"
    };

    let response = await fetch(query, init);
    let data = await response.json();
    await console.log("response: ", data);
    return await this.setState({
      response: JSON.stringify(data, null, 3),
      isLoaded: true,
      currentSlug: slug
    });
  }

  render() {
    let location = window.location;
    //console.log("location: ", location);

    const customerDoc = this.props.customerDoc;
    //let searches = `/api/searches/${customerDoc.uid}`;
    let downloads = `/api/downloads/${customerDoc.uid}`;
    if (this.state.isLoaded) {
      return (
        <React.Fragment>
          <Helmet>
            <title>Api query</title>
            <meta name="HandheldFriendly" content="true" />
            <meta name="apple-mobile-web-app-capable" content="YES" />
          </Helmet>
          <Container>
            <Row
              style={{ marginTop: "30px", minHeight: "70vh" }}
              className="api"
            >
              <Col>
                <h1>List Shack API</h1>
                <Accordion className="mb-4">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Quick reference
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <Table>
                          <thead>
                            <tr>
                              <th>Endpoint</th>
                              <th>Method</th>
                              <th>Description</th>
                              <th>Syntax</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Saved search</td>
                              <td>
                                <code>GET</code>
                              </td>
                              <td>Fetches all of the users saved searches.</td>
                              <td>
                                /api/searches/:user_id
                                <br />
                                <small className="text-muted">
                                  Sample url: <code>{downloads}</code>
                                </small>
                              </td>
                            </tr>
                            <tr>
                              <td>Downloads (all)</td>
                              <td>
                                <code>GET</code>
                              </td>
                              <td>Fetches all of the users downloads</td>
                              <td>
                                /api/downloads/:user_id
                                <br />
                                <small className="text-muted">
                                  Sample url: <code>{downloads}</code>
                                </small>
                              </td>
                            </tr>
                            <tr>
                              <td>Download</td>
                              <td>
                                <code>GET</code>
                              </td>
                              <td>Fetches a single download from the user</td>
                              <td>
                                /api/downloads/:user_id/:download_id
                                <br />
                                <small className="text-muted">
                                  Sample url:{" "}
                                  <code>{downloads}/download_id</code>
                                </small>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Accordion.Collapse>
                    <Card.Footer>
                      API Token: <code>{this.state.api_token}</code>
                    </Card.Footer>
                  </Card>
                </Accordion>
                <ApiResponse
                  location={location}
                  isLoaded={this.state.isLoaded}
                  response={this.state.response}
                  fetchApi={this.fetchApi}
                />
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <Container>
          <Row style={{ marginTop: "30px", minHeight: "70vh" }}>
            <Col style={{ minHeight: "80vh" }}>
              <h1>Api</h1>
              <hr />
              <br />
              <br />
              <Spinner animation="grow" variant="primary" size="lg" />
              <h2>Fetching your request. Hold tight!</h2>
              <ApiResponse
                location={location}
                isLoaded={this.state.isLoaded}
                response={this.state.response}
                fetchApi={this.fetchApi}
                slug={this.state.slug}
              />
            </Col>
          </Row>
        </Container>
      );
    }
  }

  async componentDidMount() {
    await this.setToken();
    const location = window.location;
    this.fetchApi(`${location.pathname}${location.search}`);
  }
}

Api.propTypes = {
  user: PropTypes.object
};

export default Api;

function ApiResponse(props) {
  //console.log("props: ", props);
  let match = useRouteMatch("/api/:slug");
  //console.log("match: ", match);
  //let location = useLocation();
  //console.log("location: ", location);
  //props.fetchApi(`${location.pathname}${location.search}`);
  let location = props.location;
  let endpoint =
    match !== null && match.params.slug !== null ? match.params.slug : "";

  return (
    <Table striped>
      <tbody>
        <tr>
          <td>Endpoint</td>
          <td>{endpoint}</td>
        </tr>
        <tr>
          <td>Pathname</td>
          <td>{location.pathname}</td>
        </tr>
        <tr>
          <td>Search</td>
          <td>{location.search}</td>
        </tr>
        {props.isLoaded && (
          <tr>
            <td>Response</td>
            <td>
              <pre>
                <code>{props.response}</code>
              </pre>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
