import React from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import queryString from "query-string";

// This class sets the plan for subscribing from the url "_subscribe"
// The the url should include a plain old url query such as "?plan=STRIPE_PLAN_ID"
// If it can't find the stripe plan ID in this.props.plans, then it just redirects back to the home page

class CustomPlan extends React.Component {
  constructor(props) {
    super(props);
    this.plan = queryString.parse(window.location.search).plan;
    this.planValues = Object.values(this.props.plans);
    this.stripePlan = this.planValues.find(obj => {
      return obj.planID === this.plan;
    });
    console.log("plan: ", this.plan, "planValues: ", this.planValues, "stripePlan: ", this.stripePlan);
  }

  render() {
    if (typeof this.stripePlan !== "undefined") {
      return (
        <Container>
          <Row style={{ paddingTop: "15%" }}>
            <Col
              xs={12}
              md={6}
              className="text-center"
              style={{ padding: "5px", margin: "0 auto" }}
            >
              <Card>
                <Card.Header>Start a custom subscription plan</Card.Header>
                <Card.Body>
                  <Card.Title className="">Plan</Card.Title>
                  <span>{this.stripePlan["shortname"]}</span>
                  <br />
                  <span className="text-muted">
                    {this.stripePlan["description"]}
                  </span>
                  <br />
                  <span
                    className="text-left"
                    style={{ textDecoration: "underline" }}
                  >
                    Price
                  </span>
                  <br />
                  <span style={{ fontSize: "1.6em" }}>
                    ${this.stripePlan["price"] / 100}
                  </span>
                  <br />
                  <span>per month</span>
                  <br />
                  <br />

                  <Link to={`/_${process.env.REACT_APP_signup_url}`} className="btn btn-primary">
                    Subscribe now
                  </Link>
                </Card.Body>
              </Card>
              <br /> <br />
              <Link to="/pricing">&lt; Pick a different plan</Link>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return <Redirect to="/" />;
    }
  }

  componentDidMount() {
    this.props.handleDistract("true");
    let planIndex = Object.values(this.props.plans).indexOf(this.stripePlan);
    this.props.selectPlan("", parseInt(planIndex));
  }

  componentWillUnmount() {
    this.props.handleDistract("false");
  }
}

CustomPlan.propTypes = {
  plans: PropTypes.object.isRequired,
  selectPlan: PropTypes.func.isRequired,
  handleDistract: PropTypes.func.isRequired
};

export default CustomPlan;
