import React from "react";
import { Link } from "react-router-dom";

export default {
  database: "bizEmails",
  type: "Email",
  path: "/search/business_emails",
  apiUrl: "https://listshack.apps.dreamfactory.com/api/v2/BizEmail/_table/bizEmail?",
  title: "Search business emails",
  subText: <small>Read how to use compiled emails <Link to="/support/use-compiled-emails" target="_blank" rel="noopener noreferrer">here</Link>.</small>,
  description: `${process.env.REACT_APP_site_name}'s business email database, consisting of compiled (not opt-in) email addresses by company name.`,
  themeColor: "info",
  geography: {
    county: {
      name: 'Counties',
      displayHeader: true,
      items: []
    }, 
    city: {
      name: 'Cities',
      displayHeader: true,
      items: []
    }, 
    zip: {
      name: 'Zip codes',
      displayHeader: true,
      items: []
    }, 
    state: {
      name: 'States',
      displayHeader: true,
      items: [
        {value: "AK", name: "Alaska"},
        {value: "AL", name: "Alabama"},
        {value: "AR", name: "Arkansas"},
        {value: "AZ", name: "Arizona"},
        {value: "CA", name: "California"},
        {value: "CO", name: "Colorado"},
        {value: "CT", name: "Connecticut"},
        {value: "DC", name: "District"},
        {value: "DE", name: "Delaware"},
        {value: "FL", name: "Florida"},
        {value: "GA", name: "Georgia"},
        {value: "HI", name: "Hawaii"},
        {value: "IA", name: "Iowa"},
        {value: "ID", name: "Idaho"},
        {value: "IL", name: "Illinois"},
        {value: "IN", name: "Indiana"},
        {value: "KS", name: "Kansas"},
        {value: "KY", name: "Kentucky"},
        {value: "LA", name: "Louisiana"},
        {value: "MA", name: "Massachusetts"},
        {value: "MD", name: "Maryland"},
        {value: "ME", name: "Maine"},
        {value: "MI", name: "Michigan"},
        {value: "MN", name: "Minnesota"},
        {value: "MO", name: "Missouri"},
        {value: "MS", name: "Mississippi"},
        {value: "MT", name: "Montana"},
        {value: "NC", name: "North Carolina"},
        {value: "ND", name: "North Dakota"},
        {value: "NE", name: "Nebraska"},
        {value: "NH", name: "New Hampshire"},
        {value: "NJ", name: "New Jersey"},
        {value: "NM", name: "New Mexico"},
        {value: "NV", name: "Nevada"},
        {value: "NY", name: "New York"},
        {value: "OH", name: "Ohio"},
        {value: "OK", name: "Oklahoma"},
        {value: "OR", name: "Oregon"},
        {value: "PA", name: "Pennsylvania"},
        {value: "RI", name: "Rhode Island"},
        {value: "SC", name: "South Carolina"},
        {value: "SD", name: "South Dakota"},
        {value: "TN", name: "Tennessee"},
        {value: "TX", name: "Texas"},
        {value: "UT", name: "Utah"},
        {value: "VA", name: "Virginia"},
        {value: "VT", name: "Vermont"},
        {value: "WA", name: "Washington"},
        {value: "WI", name: "Wisconsin"},
        {value: "WV", name: "West Virginia"},
        {value: "WY", name: "Wyoming"},
      ]
    },
    nationwide: {
      name: 'Nationwide',
      displayHeader: false,
      items: [],
    }
  },
  cols: {
    email: {
      name: "Has email",
      displayHeader: true,
      inputType: "select",
      filterCategory: "Email preferences",
      items: [
        {value: "", name: "Yes"},
      ]
    },
    sic: {
      name: 'SIC code',
      displayHeader: true,
      inputType: "search",
      items: [
        {value: "0111", name: "0111 - WHEAT (1455 records)"},
        {value: "0112", name: "0112 - RICE PRODUCTION (97 records)"},
        {value: "0115", name: "0115 - CORN (3543 records)"},
        {value: "0116", name: "0116 - SOYBEANS (527 records)"},
        {value: "0119", name: "0119 - CASH GRAINS NEC (817 records)"},
        {value: "0131", name: "0131 - COTTON (254 records)"},
        {value: "0132", name: "0132 - TOBACCO GROWERS (210 records)"},
        {value: "0133", name: "0133 - SUGARCANE & SUGAR BEETS (56 records)"},
        {value: "0134", name: "0134 - POTATO GROWERS (99 records)"},
        {value: "0139", name: "0139 - AGRICULTURAL PRODUCTS (3554 records)"},
        {value: "0161", name: "0161 - VEGETABLES & MELONS (394 records)"},
        {value: "0171", name: "0171 - CRANBERRY GROWERS (279 records)"},
        {value: "0172", name: "0172 - VINEYARDS (2090 records)"},
        {value: "0173", name: "0173 - FARMS-PECAN (167 records)"},
        {value: "0174", name: "0174 - CITRUS FRUIT PRODUCTS (228 records)"},
        {value: "0175", name: "0175 - ORCHARDS (2426 records)"},
        {value: "0179", name: "0179 - DATES-EDIBLE (75 records)"},
        {value: "0181", name: "0181 - GREENHOUSES (5486 records)"},
        {value: "0182", name: "0182 - MUSHROOMS (653 records)"},
        {value: "0191", name: "0191 - FARMS (61845 records)"},
        {value: "0211", name: "0211 - LIVESTOCK FEEDING (1668 records)"},
        {value: "0212", name: "0212 - BEEF CATTLE-EXCEPT FEEDLOTS (2427 records)"},
        {value: "0213", name: "0213 - HOGS (565 records)"},
        {value: "0214", name: "0214 - SHEEP & GOATS (183 records)"},
        {value: "0219", name: "0219 - GENERAL LIVESTOCK-EXCEPT DAIRY/POULTRY (96 records)"},
        {value: "0241", name: "0241 - DAIRIES (MILK) (7051 records)"},
        {value: "0251", name: "0251 - BROILER FRYER & ROASTER CHICKENS (95 records)"},
        {value: "0252", name: "0252 - CHICKEN EGGS (87 records)"},
        {value: "0253", name: "0253 - TURKEY FARMS (117 records)"},
        {value: "0254", name: "0254 - POULTRY FARMS (1085 records)"},
        {value: "0259", name: "0259 - OSTRICHES & EMUS (98 records)"},
        {value: "0271", name: "0271 - GAME FARMS (147 records)"},
        {value: "0272", name: "0272 - HORSES & OTHER EQUINES (669 records)"},
        {value: "0273", name: "0273 - FISH FARMS (921 records)"},
        {value: "0279", name: "0279 - BEEKEEPERS (1258 records)"},
        {value: "0291", name: "0291 - RANCHES (17231 records)"},
        {value: "0711", name: "0711 - DISCING-ROTARY CULTIVATING & PLOWING SVC (317 records)"},
        {value: "0721", name: "0721 - AGRICULTURAL CONTRACTORS (3105 records)"},
        {value: "0722", name: "0722 - CROP HARVESTING-PRIMARILY BY MACHINE (321 records)"},
        {value: "0723", name: "0723 - FARM PRODUCE (893 records)"},
        {value: "0724", name: "0724 - COTTON GINS (671 records)"},
        {value: "0741", name: "0741 - VETERINARY SERVICES FOR LIVESTOCK (6 records)"},
        {value: "0742", name: "0742 - VETERINARIANS (56393 records)"},
        {value: "0751", name: "0751 - LIVESTOCK BREEDERS (3801 records)"},
        {value: "0752", name: "0752 - PET WASHING & GROOMING (60509 records)"},
        {value: "0761", name: "0761 - PLANTERS (359 records)"},
        {value: "0762", name: "0762 - FARMING SERVICE (3262 records)"},
        {value: "0781", name: "0781 - LANDSCAPE DESIGNERS (10432 records)"},
        {value: "0782", name: "0782 - LANDSCAPE CONTRACTORS (131648 records)"},
        {value: "0783", name: "0783 - TREE SERVICE (37368 records)"},
        {value: "0811", name: "0811 - TIMBER TRACTS (198 records)"},
        {value: "0831", name: "0831 - GINSENG (204 records)"},
        {value: "0851", name: "0851 - GOVERNMENT-FORESTRY SERVICES (5239 records)"},
        {value: "0912", name: "0912 - FISHERMEN-COMMERCIAL (275 records)"},
        {value: "0913", name: "0913 - OYSTERS (114 records)"},
        {value: "0919", name: "0919 - MISCELLANEOUS MARINE PRODUCTS (23 records)"},
        {value: "0921", name: "0921 - FISHERY CONSULTANTS (262 records)"},
        {value: "0971", name: "0971 - HUNTING & FISHING PRESERVES (2444 records)"},
        {value: "1011", name: "1011 - IRON-ORE (8 records)"},
        {value: "1021", name: "1021 - COPPER ORES (10 records)"},
        {value: "1041", name: "1041 - GOLD PANNING (57 records)"},
        {value: "1044", name: "1044 - SILVER ORES (11 records)"},
        {value: "1061", name: "1061 - FERROALLOY ORES EXCEPT VANADIUM (4 records)"},
        {value: "1081", name: "1081 - METAL MINING SERVICES (54 records)"},
        {value: "1094", name: "1094 - URANIUM (5 records)"},
        {value: "1099", name: "1099 - ORE (35 records)"},
        {value: "1119", name: "1119 - Wheat Farm (6 records)"},
        {value: "1159", name: "1159 - Corn Farm (17 records)"},
        {value: "1169", name: "1169 - Soybean Farm (4 records)"},
        {value: "1199", name: "1199 - Cash Grains Farm (10 records)"},
        {value: "1221", name: "1221 - COAL MINING & SHIPPING (1480 records)"},
        {value: "1222", name: "1222 - BITUMINOUS COAL-UNDERGROUND MINING (3 records)"},
        {value: "1241", name: "1241 - COAL MINING SERVICES (89 records)"},
        {value: "1311", name: "1311 - OIL & GAS PRODUCERS (10929 records)"},
        {value: "1319", name: "1319 - Cotton Farm Tobacco Farm (3 records)"},
        {value: "1320", name: "1320 - Tobacco Farm (1 records)"},
        {value: "1321", name: "1321 - NATURAL GAS LIQUIDS (18 records)"},
        {value: "1340", name: "1340 - Irish Potato Farm (1 records)"},
        {value: "1381", name: "1381 - CONTRACTORS-OIL FIELD (1283 records)"},
        {value: "1382", name: "1382 - OIL & GAS EXPLORATION & DEVELOPMENT (8106 records)"},
        {value: "1389", name: "1389 - OIL FIELD SERVICE (15007 records)"},
        {value: "1390", name: "1390 - Field Crop Farm (161 records)"},
        {value: "1399", name: "1399 - Field Crop Farm (7 records)"},
        {value: "1411", name: "1411 - STONE-NATURAL (1832 records)"},
        {value: "1422", name: "1422 - LIMESTONE (302 records)"},
        {value: "1423", name: "1423 - CRUSHED & BROKEN GRANITE (10 records)"},
        {value: "1429", name: "1429 - STONE-CRUSHED (1166 records)"},
        {value: "1442", name: "1442 - QUARRIES (1458 records)"},
        {value: "1446", name: "1446 - SILICA (58 records)"},
        {value: "1455", name: "1455 - KAOLIN & BALL CLAY (7 records)"},
        {value: "1459", name: "1459 - CLAY (71 records)"},
        {value: "1474", name: "1474 - POTASH SODA & BORATE MINERALS (2 records)"},
        {value: "1479", name: "1479 - CHEMICAL & FERTILIZER MINING NEC (40 records)"},
        {value: "1481", name: "1481 - NONMETALLIC MINERALS SVC-EXCEPT FUELS (10 records)"},
        {value: "1499", name: "1499 - MISCELLANEOUS NONMETALLIC MINERALS (2655 records)"},
        {value: "1521", name: "1521 - GENERAL CONTRACTORS (400656 records)"},
        {value: "1522", name: "1522 - GENERAL CONTRACTORS-RESIDENTIAL BLDGS (2700 records)"},
        {value: "1531", name: "1531 - OPERATIVE BUILDERS (167 records)"},
        {value: "1541", name: "1541 - BUILDING RESTORATION & PRESERVATION (4879 records)"},
        {value: "1542", name: "1542 - BUILDING CONTRACTORS (77256 records)"},
        {value: "1610", name: "1610 - Vegetable/melon Farm (10 records)"},
        {value: "1611", name: "1611 - ROAD BUILDING CONTRACTORS (38076 records)"},
        {value: "1619", name: "1619 - Vegetable/melon Farm (3 records)"},
        {value: "1622", name: "1622 - CULVERTS (653 records)"},
        {value: "1623", name: "1623 - PIPE LINE CONTRACTORS (11872 records)"},
        {value: "1629", name: "1629 - CONSTRUCTION-HEAVY PROJECTS (9275 records)"},
        {value: "1710", name: "1710 - Berry Crop Farm (11 records)"},
        {value: "1711", name: "1711 - PLUMBING CONTRACTORS (252814 records)"},
        {value: "1720", name: "1720 - Grape Vineyard (71 records)"},
        {value: "1721", name: "1721 - PAINTERS (73608 records)"},
        {value: "1731", name: "1731 - ELECTRIC CONTRACTORS (113116 records)"},
        {value: "1740", name: "1740 - Citrus Fruit Grove Refrigeration Warehouse/storage (1 records)"},
        {value: "1741", name: "1741 - MASONRY CONTRACTORS (21188 records)"},
        {value: "1742", name: "1742 - DRY WALL CONTRACTORS (27734 records)"},
        {value: "1743", name: "1743 - TILE-CERAMIC-CONTRACTORS & DEALERS (15438 records)"},
        {value: "1750", name: "1750 - Orchard (71 records)"},
        {value: "1751", name: "1751 - CABINET MAKERS (38983 records)"},
        {value: "1752", name: "1752 - FLOOR LAYING REFINISHING & RESURFACING (29028 records)"},
        {value: "1761", name: "1761 - GUTTERS & DOWNSPOUTS (86492 records)"},
        {value: "1771", name: "1771 - CONCRETE CONTRACTORS (39974 records)"},
        {value: "1781", name: "1781 - WATER WELL DRILLING & SERVICE (9985 records)"},
        {value: "1790", name: "1790 - Dates-Edible (1 records)"},
        {value: "1791", name: "1791 - WELDING & STEEL FABRICATION (8870 records)"},
        {value: "1793", name: "1793 - GLAZIERS (1939 records)"},
        {value: "1794", name: "1794 - EXCAVATING CONTRACTORS (39203 records)"},
        {value: "1795", name: "1795 - DEMOLITION CONTRACTORS (5685 records)"},
        {value: "1796", name: "1796 - ELEVATORS-SALES & SERVICE (4373 records)"},
        {value: "1799", name: "1799 - ASBESTOS REMOVAL SERVICE (77969 records)"},
        {value: "1810", name: "1810 - Ornamental Nursery (210 records)"},
        {value: "1819", name: "1819 - Ornamental Nursery (2 records)"},
        {value: "1820", name: "1820 - Covered Food Crops Farm (18 records)"},
        {value: "1829", name: "1829 - Covered Food Crops Farm (1 records)"},
        {value: "1910", name: "1910 - General Crop Farms (3139 records)"},
        {value: "1919", name: "1919 - General Crop Farms (232 records)"},
        {value: "2011", name: "2011 - MEAT PRODUCTS (MFRS) (3638 records)"},
        {value: "2013", name: "2013 - GAME PROCESSING (MFRS) (464 records)"},
        {value: "2015", name: "2015 - POULTRY PROCESSING PLANTS (MFRS) (682 records)"},
        {value: "2021", name: "2021 - BUTTER MANUFACTURING (60 records)"},
        {value: "2022", name: "2022 - CHEESE PROCESSORS (MFRS) (352 records)"},
        {value: "2023", name: "2023 - DRY CONDENSED/EVPRTD DAIRY PROD (MFRS) (103 records)"},
        {value: "2024", name: "2024 - ICE CREAM & FROZEN DESSERTS (MFRS) (549 records)"},
        {value: "2026", name: "2026 - FLUID MILK (MFRS) (290 records)"},
        {value: "2032", name: "2032 - RAVIOLI-MANUFACTURERS (221 records)"},
        {value: "2033", name: "2033 - CANNING (MFRS) (670 records)"},
        {value: "2034", name: "2034 - DEHYDRATING SERVICE (MFRS) (156 records)"},
        {value: "2035", name: "2035 - CONDIMENTS & SAUCES-MANUFACTURERS (297 records)"},
        {value: "2037", name: "2037 - CITRUS FRUIT PRODUCTS-MANUFACTURERS (126 records)"},
        {value: "2038", name: "2038 - FROZEN FOOD PROCESSORS (MFRS) (418 records)"},
        {value: "2041", name: "2041 - MILLING (MFRS) (445 records)"},
        {value: "2043", name: "2043 - CEREALS (MFRS) (73 records)"},
        {value: "2044", name: "2044 - RICE MILLS (MFRS) (46 records)"},
        {value: "2045", name: "2045 - PREPARED FLOUR MIXES & DOUGHS (MFRS) (96 records)"},
        {value: "2046", name: "2046 - WET CORN MILLING (MFRS) (17 records)"},
        {value: "2047", name: "2047 - DOG & CAT FOOD (MFRS) (232 records)"},
        {value: "2048", name: "2048 - FEED-MANUFACTURERS (1558 records)"},
        {value: "2051", name: "2051 - BREAD/OTHER BAKERY PROD-EX COOKIES (2159 records)"},
        {value: "2052", name: "2052 - ICE CREAM CONE-MANUFACTURERS (270 records)"},
        {value: "2053", name: "2053 - FROZEN BAKERY PRODS-EXCEPT BREAD (MFRS) (24 records)"},
        {value: "2061", name: "2061 - SUGAR-RAW-MANUFACTURERS (71 records)"},
        {value: "2062", name: "2062 - SUGAR REFINERS (MFRS) (208 records)"},
        {value: "2063", name: "2063 - BEET SUGAR (MFRS) (5 records)"},
        {value: "2064", name: "2064 - CANDY & CONFECTIONERY-MANUFACTURERS (823 records)"},
        {value: "2066", name: "2066 - CHOCOLATE & COCOA-MANUFACTURERS (572 records)"},
        {value: "2067", name: "2067 - CHEWING GUM (MFRS) (10 records)"},
        {value: "2068", name: "2068 - SEEDS-COATING (MFRS) (87 records)"},
        {value: "2074", name: "2074 - COTTON OIL MILLS (MFRS) (86 records)"},
        {value: "2075", name: "2075 - SOYBEAN OIL MILLS (MFRS) (33 records)"},
        {value: "2076", name: "2076 - TALLOW (MFRS) (20 records)"},
        {value: "2077", name: "2077 - FISH BY-PRODUCTS (MFRS) (648 records)"},
        {value: "2079", name: "2079 - OLIVE OIL (MFRS) (389 records)"},
        {value: "2082", name: "2082 - BREWERS (MFRS) (5000 records)"},
        {value: "2083", name: "2083 - MALT (MFRS) (10 records)"},
        {value: "2084", name: "2084 - WINERIES (MFRS) (8300 records)"},
        {value: "2085", name: "2085 - DISTILLERS (MFRS) (666 records)"},
        {value: "2086", name: "2086 - BOTTLERS (MFRS) (2037 records)"},
        {value: "2087", name: "2087 - FLAVORING EXTRACTS & SYRUPS NEC (MFRS) (272 records)"},
        {value: "2091", name: "2091 - FISH PACKERS (MFRS) (286 records)"},
        {value: "2092", name: "2092 - PREPARED FISH & SEAFOOD PRODUCTS (MFRS) (596 records)"},
        {value: "2095", name: "2095 - COFFEE MILLS (MFRS) (607 records)"},
        {value: "2096", name: "2096 - POTATO CHIPS CORN CHIPS/SNACKS (MFRS) (744 records)"},
        {value: "2097", name: "2097 - ICE-MANUFACTURERS (524 records)"},
        {value: "2098", name: "2098 - MACARONI SPAGHETTI & NOODLES (MFRS) (275 records)"},
        {value: "2099", name: "2099 - FOOD PRODUCTS & MANUFACTURERS (4558 records)"},
        {value: "2110", name: "2110 - Beef Cattle Feedlot (41 records)"},
        {value: "2111", name: "2111 - CIGARETTES (MFRS) (172 records)"},
        {value: "2120", name: "2120 - Beef Cattle-except Feedlot (20 records)"},
        {value: "2121", name: "2121 - CIGAR CIGARETTE & TOBACCO-MANUFACTURERS (337 records)"},
        {value: "2131", name: "2131 - TOBACCO PRODUCTS-MANUFACTURERS (102 records)"},
        {value: "2139", name: "2139 - Hog Farm Or Feedlot (14 records)"},
        {value: "2140", name: "2140 - Sheep/goat Farm Horses/other Equines Farm (4 records)"},
        {value: "2141", name: "2141 - TOBACCO STEMMING & REDRYING (MFRS) (4 records)"},
        {value: "2149", name: "2149 - Sheep/goat Farm Horses/other Equines Farm (3 records)"},
        {value: "2211", name: "2211 - COTTON GOODS-MANUFACTURERS (651 records)"},
        {value: "2221", name: "2221 - SILKS-MANUFACTURERS (244 records)"},
        {value: "2231", name: "2231 - WOOLEN MILLS (MFRS) (50 records)"},
        {value: "2241", name: "2241 - BRAIDS (MFRS) (190 records)"},
        {value: "2251", name: "2251 - WOMENS FULL-LENGTH/KNEE-LNTH HSRY (MFRS) (13 records)"},
        {value: "2252", name: "2252 - HOSIERY-MANUFACTURERS (198 records)"},
        {value: "2253", name: "2253 - T-SHIRTS-MANUFACTURERS (135 records)"},
        {value: "2254", name: "2254 - KNIT UNDERWEAR & NIGHTWEAR MILLS (MFRS) (44 records)"},
        {value: "2257", name: "2257 - WEFT KNIT FABRIC MILLS (MFRS) (10 records)"},
        {value: "2258", name: "2258 - KNIT GOODS-PROCESSING & DYEING (MFRS) (62 records)"},
        {value: "2259", name: "2259 - CURTAINS-MANUFACTURERS (709 records)"},
        {value: "2261", name: "2261 - TEXTILE FINISHING (MFRS) (296 records)"},
        {value: "2262", name: "2262 - FLOCKING (MFRS) (36 records)"},
        {value: "2269", name: "2269 - LINENS-MANUFACTURERS (219 records)"},
        {value: "2273", name: "2273 - CARPET & RUG-MANUFACTURERS (576 records)"},
        {value: "2281", name: "2281 - COTTON PROCESSING (MFRS) (200 records)"},
        {value: "2282", name: "2282 - YARN-TEXTURIZING THROWING/WINDING MILLS (32 records)"},
        {value: "2284", name: "2284 - THREAD-MANUFACTURERS (98 records)"},
        {value: "2295", name: "2295 - WATERPROOFING MATERIALS-MANUFACTURERS (148 records)"},
        {value: "2296", name: "2296 - TIRE CORD & FABRICS (MFRS) (4 records)"},
        {value: "2297", name: "2297 - BLANKETS-MANUFACTURERS (138 records)"},
        {value: "2298", name: "2298 - ROPE-MANUFACTURERS (112 records)"},
        {value: "2299", name: "2299 - CAR TRIMMING & UPHOLSTERY MFRS (1496 records)"},
        {value: "2311", name: "2311 - COATS-MANUFACTURERS (66 records)"},
        {value: "2321", name: "2321 - SHIRTS-MANUFACTURERS (71 records)"},
        {value: "2322", name: "2322 - UNDERWEAR-MENS-MANUFACTURERS (3 records)"},
        {value: "2323", name: "2323 - NECKWEAR-MENS-MANUFACTURERS (52 records)"},
        {value: "2325", name: "2325 - PANTS-MANUFACTURERS (32 records)"},
        {value: "2326", name: "2326 - MENS & BOYS WORK CLOTHING (MFRS) (187 records)"},
        {value: "2329", name: "2329 - MEN'S CLOTHING-MANUFACTURERS (470 records)"},
        {value: "2331", name: "2331 - WOMEN'S APPAREL-MANUFACTURERS (708 records)"},
        {value: "2335", name: "2335 - DRESSES-MANUFACTURERS (135 records)"},
        {value: "2337", name: "2337 - WOMENS MISSES JR SUITS SKRTS/COATS (12 records)"},
        {value: "2339", name: "2339 - CLOTHES & ACCESSORIES-WOMENS-MFRS (1196 records)"},
        {value: "2341", name: "2341 - WOMENS MISSES/CHILDRENS UNDERWEAR (MFRS) (69 records)"},
        {value: "2342", name: "2342 - MATERNITY APPAREL-MANUFACTURERS (71 records)"},
        {value: "2353", name: "2353 - HATS-MANUFACTURERS (247 records)"},
        {value: "2361", name: "2361 - GIRLS CHLDRNS INFNTS DRESSES/SHRTS (20 records)"},
        {value: "2369", name: "2369 - CLOTHES & ACCESSORIES-CHILDRENS-MFRS (255 records)"},
        {value: "2371", name: "2371 - FUR GOODS (MFRS) (68 records)"},
        {value: "2381", name: "2381 - DRESS/WORK GLOVES-EX KNIT/LEATHER (MFRS) (72 records)"},
        {value: "2385", name: "2385 - WATERPROOF OUTERWEAR (MFRS) (38 records)"},
        {value: "2386", name: "2386 - LEATHER APPAREL-MANUFACTURERS (84 records)"},
        {value: "2387", name: "2387 - APPAREL-BELTS (MFRS) (37 records)"},
        {value: "2389", name: "2389 - APPAREL & ACCESSORIES NEC (MFRS) (345 records)"},
        {value: "2391", name: "2391 - CURTAIN & DRAPERY-MANUFACTURERS (282 records)"},
        {value: "2392", name: "2392 - QUILTS-MANUFACTURERS (637 records)"},
        {value: "2393", name: "2393 - TEXTILE BAGS (MFRS) (96 records)"},
        {value: "2394", name: "2394 - CANVAS & RELATED PRODUCTS (MFRS) (1325 records)"},
        {value: "2395", name: "2395 - PATCHES (MFRS) (541 records)"},
        {value: "2396", name: "2396 - AUTOMOTIVE TRIMMING/APPRL FINDINGS (524 records)"},
        {value: "2397", name: "2397 - SCHIFFLI MACHINE EMBROIDERIES (MFRS) (16 records)"},
        {value: "2399", name: "2399 - FLAGS & BANNERS-MANUFACTURERS (6726 records)"},
        {value: "2410", name: "2410 - Dairy Farm (345 records)"},
        {value: "2411", name: "2411 - LOGGING COMPANIES (MFRS) (4918 records)"},
        {value: "2421", name: "2421 - SAWMILLS (MFRS) (3512 records)"},
        {value: "2426", name: "2426 - HARDWOOD FLOORING (MFRS) (1326 records)"},
        {value: "2429", name: "2429 - STAVES & STAVE MILLS (MFRS) (27 records)"},
        {value: "2431", name: "2431 - MILLWORK (MFRS) (5641 records)"},
        {value: "2434", name: "2434 - CABINETS-MANUFACTURERS (2025 records)"},
        {value: "2435", name: "2435 - LAMINATED WOOD (MFRS) (228 records)"},
        {value: "2436", name: "2436 - SOFTWOOD VENEER & PLYWOOD (MFRS) (23 records)"},
        {value: "2439", name: "2439 - TRUSSES (MFRS) (396 records)"},
        {value: "2441", name: "2441 - PACKING CASE MFRS (142 records)"},
        {value: "2448", name: "2448 - PALLETS & SKIDS-MANUFACTURERS (1397 records)"},
        {value: "2449", name: "2449 - BARRELS & DRUMS-WOODEN (MFRS) (127 records)"},
        {value: "2451", name: "2451 - MOBILE HOMES-MANUFACTURERS (1145 records)"},
        {value: "2452", name: "2452 - LOG CABINS HOMES & BUILDINGS (MFRS) (4656 records)"},
        {value: "2491", name: "2491 - LUMBER-TREATING (MFRS) (341 records)"},
        {value: "2493", name: "2493 - RECONSTITUTED WOOD PRODUCTS (MFRS) (39 records)"},
        {value: "2499", name: "2499 - WOOD PRODUCTS-MANUFACTURERS (2363 records)"},
        {value: "2511", name: "2511 - WOOD-HOUSEHOLD FURN-EX UPHOLSTERED (406 records)"},
        {value: "2512", name: "2512 - WOOD-HOUSEHOLD FURN-UPHOLSTERED (MFRS) (147 records)"},
        {value: "2514", name: "2514 - METAL-HOUSEHOLD FURNITURE (MFRS) (107 records)"},
        {value: "2515", name: "2515 - MATTRESSES/FOUNDATIONS/CONV BEDS (MFRS) (567 records)"},
        {value: "2517", name: "2517 - WOOD-TV RADIO PHONO/SEWING CABNTS (MFRS) (2 records)"},
        {value: "2519", name: "2519 - HOUSEHOLD FURNITURE NEC (MFRS) (98 records)"},
        {value: "2521", name: "2521 - WOOD-OFFICE FURNITURE (MFRS) (111 records)"},
        {value: "2522", name: "2522 - OFFICE FURNITURE & EQUIP-MFRS (777 records)"},
        {value: "2530", name: "2530 - Turkey Farms (1 records)"},
        {value: "2531", name: "2531 - PUBLIC BUILDING/RELATED FURNITURE (MFRS) (159 records)"},
        {value: "2540", name: "2540 - Poultry Hatchery (32 records)"},
        {value: "2541", name: "2541 - COUNTER TOPS-MANUFACTURERS (428 records)"},
        {value: "2542", name: "2542 - OFFICE & STORE FIXTURES-EX WOOD (MFRS) (748 records)"},
        {value: "2590", name: "2590 - Poultry/poultry Egg Farm (2 records)"},
        {value: "2591", name: "2591 - DRAPERY HDWR-WINDOW BLINDS/SHADES (MFRS) (474 records)"},
        {value: "2599", name: "2599 - RESTAURANT EQUIPMENT & SUPPLIES-MFRS (2963 records)"},
        {value: "2611", name: "2611 - PULP & PULP PRODUCTS (MFRS) (560 records)"},
        {value: "2621", name: "2621 - PAPER-MANUFACTURERS (1747 records)"},
        {value: "2631", name: "2631 - PAPERBOARD MILLS (MFRS) (147 records)"},
        {value: "2652", name: "2652 - BOXES-PAPER (MFRS) (604 records)"},
        {value: "2653", name: "2653 - CORRUGATED & SOLID FIBER BOXES (MFRS) (1161 records)"},
        {value: "2655", name: "2655 - PAPER TUBES & CORES-MANUFACTURERS (563 records)"},
        {value: "2656", name: "2656 - PAPER & PLASTIC CUPS CONTAINERS ETC-MFRS (86 records)"},
        {value: "2657", name: "2657 - BOXES-FOLDING-MANUFACTURERS (228 records)"},
        {value: "2671", name: "2671 - PACKAGING MATERIALS-MANUFACTURERS (1165 records)"},
        {value: "2672", name: "2672 - LABELS-PAPER (MFRS) (831 records)"},
        {value: "2673", name: "2673 - BAGS-PLASTIC (MFRS) (851 records)"},
        {value: "2674", name: "2674 - BAGS-MANUFACTURERS (456 records)"},
        {value: "2675", name: "2675 - BOX PARTITIONS (MFRS) (89 records)"},
        {value: "2676", name: "2676 - SANITARY PAPER PRODUCTS (MFRS) (126 records)"},
        {value: "2677", name: "2677 - ENVELOPES-MANUFACTURERS (361 records)"},
        {value: "2678", name: "2678 - STATIONERY-MANUFACTURERS (181 records)"},
        {value: "2679", name: "2679 - TAGS-MANUFACTURERS (690 records)"},
        {value: "2710", name: "2710 - Fur Bearing Animal Farm General Crop Farm (6 records)"},
        {value: "2711", name: "2711 - NEWSPAPERS (PUBLISHERS/MFRS) (16388 records)"},
        {value: "2721", name: "2721 - PUBLISHERS-PERIODICAL (MFRS) (9935 records)"},
        {value: "2729", name: "2729 - Horses/other Equines Farm (7 records)"},
        {value: "2730", name: "2730 - Fish/shellfish Farm (27 records)"},
        {value: "2731", name: "2731 - PUBLISHERS-BOOK (MFRS) (4975 records)"},
        {value: "2732", name: "2732 - PRINTERS-BOOKS (MFRS) (105 records)"},
        {value: "2741", name: "2741 - MULTIMEDIA (MFRS) (21431 records)"},
        {value: "2752", name: "2752 - PRINTERS (MFRS) (36484 records)"},
        {value: "2754", name: "2754 - ENGRAVERS-STATIONERY (MFRS) (133 records)"},
        {value: "2759", name: "2759 - COMMERCIAL PRINTING NEC (MFRS) (19292 records)"},
        {value: "2761", name: "2761 - PRINTERS-BUSINESS FORMS (MFRS) (887 records)"},
        {value: "2771", name: "2771 - GREETING CARDS (MFRS) (176 records)"},
        {value: "2782", name: "2782 - CHECKS-PRINTING (MFRS) (281 records)"},
        {value: "2789", name: "2789 - BOOKBINDERS (MFRS) (716 records)"},
        {value: "2790", name: "2790 - Dog Grooming (31 records)"},
        {value: "2791", name: "2791 - TYPESETTING (MFRS) (434 records)"},
        {value: "2796", name: "2796 - ENGRAVERS-PLASTIC WOOD & ETC (MFRS) (983 records)"},
        {value: "2799", name: "2799 - Dog Grooming (9 records)"},
        {value: "2812", name: "2812 - ALKALIES (MFRS) (45 records)"},
        {value: "2813", name: "2813 - GAS-IND & MEDICAL-CYLINDER & BULK-MFRS (968 records)"},
        {value: "2816", name: "2816 - COLORS & PIGMENTS-MANUFACTURERS (201 records)"},
        {value: "2819", name: "2819 - INDUSTRIAL INORGANIC CHMCLS NEC (MFRS) (623 records)"},
        {value: "2821", name: "2821 - FIBER GLASS PRODUCTS-MANUFACTURERS (1605 records)"},
        {value: "2822", name: "2822 - RUBBER-SYNTHETIC (MFRS) (92 records)"},
        {value: "2823", name: "2823 - CELLULOSIC MANMADE FIBERS (MFRS) (19 records)"},
        {value: "2824", name: "2824 - SHRINK WRAPPING (MFRS) (82 records)"},
        {value: "2833", name: "2833 - MEDICINAL CHEM/BOTANICAL PRODS (MFRS) (162 records)"},
        {value: "2834", name: "2834 - DRUG MILLERS (MFRS) (1956 records)"},
        {value: "2835", name: "2835 - IN-VITRO/IN-VIVO DIAGNOSTIC SBSTNC (51 records)"},
        {value: "2836", name: "2836 - BIOLOGICAL PRODUCTS (MFRS) (751 records)"},
        {value: "2841", name: "2841 - SOAPS & DETERGENTS-MANUFACTURERS (1064 records)"},
        {value: "2842", name: "2842 - SPECIALTY CLNG PLSHNG/SANITATION (MFRS) (689 records)"},
        {value: "2843", name: "2843 - LEATHER FINISHERS (MFRS) (35 records)"},
        {value: "2844", name: "2844 - HEALTH & BEAUTY AIDS-MANUFACTURERS (1073 records)"},
        {value: "2851", name: "2851 - PAINT-MANUFACTURERS (917 records)"},
        {value: "2861", name: "2861 - GUM & WOOD CHEMICALS (MFRS) (51 records)"},
        {value: "2865", name: "2865 - CYCLIC ORGANIC CRUDES/DYES/PGMNTS (MFRS) (132 records)"},
        {value: "2869", name: "2869 - SOLVENTS-MANUFACTURERS (308 records)"},
        {value: "2873", name: "2873 - FERTILIZERS-MANUFACTURERS (1048 records)"},
        {value: "2874", name: "2874 - PHOSPHATIC FERTILIZERS (MFRS) (17 records)"},
        {value: "2875", name: "2875 - FERTILIZER MIXING ONLY (MFRS) (638 records)"},
        {value: "2879", name: "2879 - PESTICIDES & AG CHEMICALS NEC (MFRS) (312 records)"},
        {value: "2891", name: "2891 - ADHESIVES & SEALANTS (MFRS) (661 records)"},
        {value: "2892", name: "2892 - EXPLOSIVES-MANUFACTURERS (140 records)"},
        {value: "2893", name: "2893 - INKS-PRINTING & LITHOGRAPHING (MFRS) (687 records)"},
        {value: "2895", name: "2895 - CARBON BLACK-MANUFACTURERS (57 records)"},
        {value: "2899", name: "2899 - FIREPROOFING MATERIALS (MFRS) (3435 records)"},
        {value: "2910", name: "2910 - General Animal Farm (380 records)"},
        {value: "2911", name: "2911 - OILS-PETROLEUM-MANUFACTURERS (4236 records)"},
        {value: "2951", name: "2951 - ASPHALT & ASPHALT PRODUCTS-MANUFACTURERS (1232 records)"},
        {value: "2952", name: "2952 - ROOFING MATERIALS-MANUFACTURERS (227 records)"},
        {value: "2992", name: "2992 - LUBRICATING OILS & GREASES (MFRS) (523 records)"},
        {value: "2999", name: "2999 - PRODUCTS OF PETROLEUM & COAL NEC (MFRS) (96 records)"},
        {value: "3011", name: "3011 - TIRE-MANUFACTURERS (219 records)"},
        {value: "3021", name: "3021 - RUBBER & PLASTICS-FOOTWEAR (MFRS) (13 records)"},
        {value: "3052", name: "3052 - BELTING & BELTING SUPPLIES-MANUFACTURERS (340 records)"},
        {value: "3053", name: "3053 - SEALS-OIL, GREASE, ETC (MFRS) (810 records)"},
        {value: "3061", name: "3061 - MOLDED EXTRUDED/LATHE CUT RBBR GDS (65 records)"},
        {value: "3069", name: "3069 - RUBBER PRODUCTS-MANUFACTURERS (1723 records)"},
        {value: "3081", name: "3081 - PLASTICS-FABRICS,FILM-ETC PRODUCER (732 records)"},
        {value: "3082", name: "3082 - PLASTICS-ROD,TUBE,SHEETS,ETC-SUPL (MFRS) (44 records)"},
        {value: "3083", name: "3083 - LAMINATED PLSTCS-PLATE SHT/PROFILE (190 records)"},
        {value: "3084", name: "3084 - PIPE-PLASTIC (MFRS) (320 records)"},
        {value: "3085", name: "3085 - PLASTIC BOTTLES (MFRS) (142 records)"},
        {value: "3086", name: "3086 - INSULATION MATERIALS-COLD & HEAT-MFRS (625 records)"},
        {value: "3087", name: "3087 - CUSTOM COMPOUNDING-PURCHASED RESIN (21 records)"},
        {value: "3088", name: "3088 - PLASTICS-PLUMBING FIXTURES (MFRS) (142 records)"},
        {value: "3089", name: "3089 - PLASTICS-PRODUCTS-FINISHED-MANUFACTURERS (9587 records)"},
        {value: "3111", name: "3111 - TANNERS (MFRS) (332 records)"},
        {value: "3131", name: "3131 - SHOE FABRIC & LEATHER COMBINERS (MFRS) (86 records)"},
        {value: "3142", name: "3142 - HOUSE SLIPPERS (MFRS) (2 records)"},
        {value: "3143", name: "3143 - BOOTS-MANUFACTURERS (31 records)"},
        {value: "3144", name: "3144 - WOMENS FOOTWEAR-EXCEPT ATHLETIC (MFRS) (241 records)"},
        {value: "3149", name: "3149 - FOOTWEAR EXCEPT RUBBER NEC (MFRS) (38 records)"},
        {value: "3151", name: "3151 - LEATHER GLOVES & MITTENS (MFRS) (45 records)"},
        {value: "3161", name: "3161 - WARDROBES (MFRS) (193 records)"},
        {value: "3171", name: "3171 - WOMEN'S HANDBAGS & PURSES (MFRS) (260 records)"},
        {value: "3172", name: "3172 - LEATHER GOODS-MANUFACTURERS (306 records)"},
        {value: "3199", name: "3199 - LEATHER GOODS NEC (MFRS) (312 records)"},
        {value: "3211", name: "3211 - GLASS-MANUFACTURERS (373 records)"},
        {value: "3221", name: "3221 - GLASS CONTAINERS (MFRS) (223 records)"},
        {value: "3229", name: "3229 - GLASS-BLOWERS (MFRS) (870 records)"},
        {value: "3231", name: "3231 - GLASS SUBSTITUTES (MFRS) (1468 records)"},
        {value: "3241", name: "3241 - CEMENT-MANUFACTURERS (673 records)"},
        {value: "3251", name: "3251 - BRICK-CLAY COMMON & FACE-MANUFACTURERS (499 records)"},
        {value: "3253", name: "3253 - TILE-CERAMIC-MANUFACTURERS (240 records)"},
        {value: "3255", name: "3255 - CLAY REFRACTORIES (MFRS) (36 records)"},
        {value: "3259", name: "3259 - DRAIN PIPES-CLAY-MANUFACTURERS (198 records)"},
        {value: "3261", name: "3261 - BATHROOM ACCESSORIES-MANUFACTURERS (95 records)"},
        {value: "3262", name: "3262 - VITREOUS CHINA-TBL/KTCHN ARTICLES (MFRS) (17 records)"},
        {value: "3263", name: "3263 - FINE EARTHENWARE TBL/KTCHN ARTICLE (3 records)"},
        {value: "3264", name: "3264 - FERRITES (MFRS) (14 records)"},
        {value: "3269", name: "3269 - GIFTWARES-MANUFACTURERS (669 records)"},
        {value: "3271", name: "3271 - CONCRETE-BLOCK & BRICK (MFRS) (559 records)"},
        {value: "3272", name: "3272 - CONCRETE PRODS-EX BLOCK & BRICK (MFRS) (3292 records)"},
        {value: "3273", name: "3273 - READY-MIXED CONCRETE-MANUFACTURERS (1764 records)"},
        {value: "3274", name: "3274 - LIME-MANUFACTURERS (160 records)"},
        {value: "3275", name: "3275 - GYPSUM & GYPSUM PRODUCTS (MFRS) (285 records)"},
        {value: "3281", name: "3281 - CUT STONE & STONE PRODUCTS (MFRS) (2565 records)"},
        {value: "3291", name: "3291 - GRINDING WHEELS (MFRS) (428 records)"},
        {value: "3292", name: "3292 - ASBESTOS & ASBESTOS FREE PRODS (MFRS) (51 records)"},
        {value: "3295", name: "3295 - GRAPHITE (MFRS) (420 records)"},
        {value: "3296", name: "3296 - FIBER GLASS FABRICATORS (MFRS) (1172 records)"},
        {value: "3297", name: "3297 - REFRACTORIES (MFRS) (541 records)"},
        {value: "3299", name: "3299 - NONMETALLIC MINERAL PRODUCTS NEC (MFRS) (849 records)"},
        {value: "3312", name: "3312 - STEEL PROCESSING (MFRS) (2487 records)"},
        {value: "3313", name: "3313 - ELECTROMETALLURGICAL PROD-EX STEEL (MFR) (5 records)"},
        {value: "3315", name: "3315 - WIRE-MANUFACTURERS (292 records)"},
        {value: "3316", name: "3316 - STEEL-BAR, SHEET, STRIP, TUBE ETC (MFRS) (211 records)"},
        {value: "3317", name: "3317 - STEEL PIPE & TUBES (MFRS) (245 records)"},
        {value: "3321", name: "3321 - MANHOLE COVERS (MFRS) (211 records)"},
        {value: "3322", name: "3322 - FOUNDRIES-MALLEABLE IRON (MFRS) (12 records)"},
        {value: "3324", name: "3324 - INVESTMENT CASTINGS (MFRS) (145 records)"},
        {value: "3325", name: "3325 - FOUNDRIES-STEEL (MFRS) (1190 records)"},
        {value: "3331", name: "3331 - PRIMARY SMELTING-REFINING-COPPER (MFRS) (9 records)"},
        {value: "3334", name: "3334 - PRIMARY PRODUCTION OF ALUMINUM (MFRS) (25 records)"},
        {value: "3339", name: "3339 - SMELTERS-REFINERS-PRECIOUS METALS (MFRS) (275 records)"},
        {value: "3341", name: "3341 - REFINING (MFRS) (242 records)"},
        {value: "3351", name: "3351 - COPPER PRODUCTS-MANUFACTURERS (72 records)"},
        {value: "3353", name: "3353 - ALUMINUM SHEET PLATE & FOIL (MFRS) (24 records)"},
        {value: "3354", name: "3354 - ALUMINUM EXTRUDED PRODUCTS (MFRS) (183 records)"},
        {value: "3355", name: "3355 - ALUMINUM FABRICATORS (MFRS) (604 records)"},
        {value: "3356", name: "3356 - METAL-ROLLING & FORMING (MFRS) (299 records)"},
        {value: "3357", name: "3357 - CABLE (MFRS) (1206 records)"},
        {value: "3363", name: "3363 - ALUMINUM DIE CASTINGS (MFRS) (148 records)"},
        {value: "3364", name: "3364 - NONFERROUS DIE CASTINGS-EX ALUM (MFRS) (54 records)"},
        {value: "3365", name: "3365 - FOUNDRIES-ALUMINUM BRASS BRONZE (MFRS) (391 records)"},
        {value: "3366", name: "3366 - BRONZE (MFRS) (294 records)"},
        {value: "3369", name: "3369 - CASTINGS (MFRS) (709 records)"},
        {value: "3398", name: "3398 - LEAD BURNING (MFRS) (902 records)"},
        {value: "3399", name: "3399 - PRIMARY METAL PRODUCTS NEC (MFRS) (340 records)"},
        {value: "3411", name: "3411 - CAN-MANUFACTURERS (262 records)"},
        {value: "3412", name: "3412 - BARRELS & DRUMS (MFRS) (490 records)"},
        {value: "3421", name: "3421 - CUTLERY-MANUFACTURERS (65 records)"},
        {value: "3423", name: "3423 - KNIVES-MACHINE (MFRS) (1111 records)"},
        {value: "3425", name: "3425 - SHARPENING EQUIPMENT & STONES (MFRS) (215 records)"},
        {value: "3429", name: "3429 - HOSE COUPLINGS & FITTINGS-MANUFACTURERS (1161 records)"},
        {value: "3431", name: "3431 - HYGIENE & SANITARY APPLIANCE MFRS (23 records)"},
        {value: "3432", name: "3432 - PLUMBING FIXTURES & SUPPLIES-MFRS (687 records)"},
        {value: "3433", name: "3433 - SOLAR ENERGY EQUIPMENT-MANUFACTURERS (1068 records)"},
        {value: "3441", name: "3441 - STEEL-STRUCTURAL (MFRS) (8763 records)"},
        {value: "3442", name: "3442 - METAL DOORS SASH FRAMES & TRIM (MFRS) (947 records)"},
        {value: "3443", name: "3443 - TANKS-MANUFACTURERS (1285 records)"},
        {value: "3444", name: "3444 - SHEET METAL FABRICATORS (MFRS) (4010 records)"},
        {value: "3446", name: "3446 - ORNAMENTAL METAL WORK (MFRS) (3151 records)"},
        {value: "3448", name: "3448 - BUILDINGS-METAL-MANUFACTURERS (457 records)"},
        {value: "3449", name: "3449 - CONCRETE REINFORCEMENTS-MANUFACTURERS (199 records)"},
        {value: "3451", name: "3451 - SCREW MACHINE PRODUCTS (MFRS) (1030 records)"},
        {value: "3452", name: "3452 - BOLTS NUTS SCREWS RIVETS/WASHERS (MFRS) (779 records)"},
        {value: "3462", name: "3462 - FORGINGS (MFRS) (553 records)"},
        {value: "3463", name: "3463 - NONFERROUS FORGINGS (MFRS) (9 records)"},
        {value: "3465", name: "3465 - AUTOMOTIVE STAMPINGS (MFRS) (103 records)"},
        {value: "3466", name: "3466 - BOTTLE CAPS & SEALS (MFRS) (64 records)"},
        {value: "3469", name: "3469 - COINS & TOKENS-MANUFACTURERS (2718 records)"},
        {value: "3471", name: "3471 - METAL FINISHERS (MFRS) (3834 records)"},
        {value: "3479", name: "3479 - ENGRAVERS-METAL (MFRS) (7993 records)"},
        {value: "3482", name: "3482 - SMALL ARMS AMMUNITION (MFRS) (177 records)"},
        {value: "3483", name: "3483 - AMMUNITION-EXCEPT FOR SMALL ARMS (MFRS) (15 records)"},
        {value: "3484", name: "3484 - SMALL ARMS-MANUFACTURERS (318 records)"},
        {value: "3489", name: "3489 - ORDNANCE & ACCESSORIES NEC (MFRS) (26 records)"},
        {value: "3491", name: "3491 - INDUSTRIAL VALVES (MFRS) (260 records)"},
        {value: "3492", name: "3492 - HOSE ASSEMBLIES (MFRS) (124 records)"},
        {value: "3493", name: "3493 - SPRINGS-AUTO-SALES & SVC-MANUFACTURERS (45 records)"},
        {value: "3494", name: "3494 - VALVES & PIPE FITTINGS NEC (MFRS) (941 records)"},
        {value: "3495", name: "3495 - SPRINGS-MANUFACTURERS (328 records)"},
        {value: "3496", name: "3496 - FENCE-MANUFACTURERS (2407 records)"},
        {value: "3497", name: "3497 - FOIL & FOIL PRODUCTS (MFRS) (93 records)"},
        {value: "3498", name: "3498 - FABRICATED PIPE & PIPE FITTINGS (MFRS) (1148 records)"},
        {value: "3499", name: "3499 - METAL GOODS-MANUFACTURERS (5602 records)"},
        {value: "3511", name: "3511 - TURBINES-MANUFACTURERS (1216 records)"},
        {value: "3519", name: "3519 - INTERNAL COMBUSTION ENGINES NEC (MFRS) (475 records)"},
        {value: "3523", name: "3523 - FARM EQUIPMENT-MANUFACTURERS (2377 records)"},
        {value: "3524", name: "3524 - GARDEN & LAWN EQUIPMENT & SUPLS-MFRS (716 records)"},
        {value: "3531", name: "3531 - EXCAVATION EQUIPMENT MANUFACTURERS (2448 records)"},
        {value: "3532", name: "3532 - MANUFACTURING-AUGERS & TRENCHERS (236 records)"},
        {value: "3533", name: "3533 - OIL & GAS-FIELD MACHINERY/EQUIP (MFRS) (858 records)"},
        {value: "3534", name: "3534 - ELEVATORS & MOVING STAIRWAYS (MFRS) (906 records)"},
        {value: "3535", name: "3535 - CONVEYORS & CONVEYING EQUIPMENT-MFRS (725 records)"},
        {value: "3536", name: "3536 - BOAT LIFTS (MFRS) (603 records)"},
        {value: "3537", name: "3537 - MECHANICAL HANDLING EQUIPMENT MFRS (778 records)"},
        {value: "3541", name: "3541 - MILLING MACHINES (MFRS) (1642 records)"},
        {value: "3542", name: "3542 - PUNCH PRESSES (MFRS) (284 records)"},
        {value: "3543", name: "3543 - MODEL MAKERS (MFRS) (541 records)"},
        {value: "3544", name: "3544 - MOLDS (MFRS) (5356 records)"},
        {value: "3545", name: "3545 - BITS (MFRS) (816 records)"},
        {value: "3546", name: "3546 - POWER-DRIVEN HAND TOOLS (MFRS) (215 records)"},
        {value: "3547", name: "3547 - STEEL PROCESSING/FABRICATING EQUIP (MFR) (180 records)"},
        {value: "3548", name: "3548 - WELDING EQUIPMENT & SUPPLIES-MFRS (529 records)"},
        {value: "3549", name: "3549 - METALWORKING MACHINERY NEC (MFRS) (209 records)"},
        {value: "3552", name: "3552 - TEXTILE MACHINERY (MFRS) (811 records)"},
        {value: "3553", name: "3553 - PATTERN MAKERS (MFRS) (736 records)"},
        {value: "3554", name: "3554 - PAPER MILL MACHINERY-MANUFACTURERS (190 records)"},
        {value: "3555", name: "3555 - PRINTING EQUIPMENT-MANUFACTURERS (497 records)"},
        {value: "3556", name: "3556 - FISH PACKERS EQUIPMENT (MFRS) (788 records)"},
        {value: "3559", name: "3559 - AUTOMOBILE BODY SHOP EQUIPMENT-MFRS (2172 records)"},
        {value: "3561", name: "3561 - PUMPS-MANUFACTURERS (1366 records)"},
        {value: "3562", name: "3562 - BALL & ROLLER BEARING (MFRS) (330 records)"},
        {value: "3563", name: "3563 - COMPRESSORS-MANUFACTURERS (527 records)"},
        {value: "3564", name: "3564 - AIR CLEANING & PURIFYING EQUIPMENT-MFRS (767 records)"},
        {value: "3565", name: "3565 - PACKAGING MACHINERY-MANUFACTURERS (450 records)"},
        {value: "3566", name: "3566 - SPEED CHANGERS-IND-DRIVES & GEARS (MFRS) (500 records)"},
        {value: "3567", name: "3567 - FURNACES-INDUSTRIAL (MFRS) (515 records)"},
        {value: "3568", name: "3568 - ROTARY JOINTS (MFRS) (117 records)"},
        {value: "3569", name: "3569 - FILTERS-LIQUID (MFRS) (4097 records)"},
        {value: "3571", name: "3571 - COMPUTERS-ELECTRONIC-MANUFACTURERS (1432 records)"},
        {value: "3572", name: "3572 - COMPUTER STORAGE DEVICES (MFRS) (154 records)"},
        {value: "3575", name: "3575 - COMPUTER TERMINALS (MFRS) (40 records)"},
        {value: "3577", name: "3577 - COMPUTER PERIPHERALS (MFRS) (564 records)"},
        {value: "3578", name: "3578 - BANK EQUIPMENT & SUPPLIES-MANUFACTURERS (558 records)"},
        {value: "3579", name: "3579 - COPYING MACHINES & SUPPLIES-MFRS (1147 records)"},
        {value: "3581", name: "3581 - VENDING MACHINES-MANUFACTURERS (71 records)"},
        {value: "3582", name: "3582 - CARPET & RUG CLEANING EQUIP & SUPLS-MFRS (226 records)"},
        {value: "3585", name: "3585 - MARINE REFRIGERATION & AIR COND-MFRS (1551 records)"},
        {value: "3586", name: "3586 - SERVICE STATION EQUIPMENT-MANUFACTURERS (107 records)"},
        {value: "3589", name: "3589 - WATER PURIFICATION/FILTRATION EQPT-MFRS (2175 records)"},
        {value: "3592", name: "3592 - CARBURETORS PISTONS RINGS/VALVES (MFRS) (45 records)"},
        {value: "3593", name: "3593 - CYLINDERS-AIR & HYDRAULIC-MANUFACTURERS (171 records)"},
        {value: "3594", name: "3594 - FLUID POWER PUMPS & MOTORS (MFRS) (52 records)"},
        {value: "3596", name: "3596 - SCALES & BALANCES-EX LABORATORY (MFRS) (173 records)"},
        {value: "3599", name: "3599 - MACHINE SHOPS (MFRS) (37806 records)"},
        {value: "3612", name: "3612 - POWER PLANT EQUIPMENT-MANUFACTURERS (657 records)"},
        {value: "3613", name: "3613 - SWITCHGEAR/SWITCHBOARD APPARATUS (MFRS) (355 records)"},
        {value: "3621", name: "3621 - GENERATORS-ELECTRIC-MANUFACTURERS (1260 records)"},
        {value: "3624", name: "3624 - CARBON & GRAPHITE PRODUCTS (MFRS) (84 records)"},
        {value: "3625", name: "3625 - ELECTRONIC CONTROLS-MANUFACTURERS (711 records)"},
        {value: "3629", name: "3629 - ELECTRICAL INDSTRL APPARATUS NEC (MFRS) (133 records)"},
        {value: "3631", name: "3631 - BARBECUE EQUIPMENT & SUPPLIES-MFRS (133 records)"},
        {value: "3632", name: "3632 - REFRIGERATORS-MANUFACTURERS (36 records)"},
        {value: "3633", name: "3633 - HOUSEHOLD LAUNDRY EQUIPMENT (MFRS) (28 records)"},
        {value: "3634", name: "3634 - FOOD PROCESSORS-HOUSEHOLD (MFRS) (415 records)"},
        {value: "3635", name: "3635 - HOUSEHOLD VACUUM CLEANERS (MFRS) (569 records)"},
        {value: "3639", name: "3639 - SEWING MACHINES-MANUFACTURERS (380 records)"},
        {value: "3641", name: "3641 - LAMP BULBS-MANUFACTURERS (458 records)"},
        {value: "3643", name: "3643 - CONNECTORS-ELECTRIC-MANUFACTURERS (209 records)"},
        {value: "3644", name: "3644 - NONCURRENT-CARRYING WIRING DEVICES (MFR) (44 records)"},
        {value: "3645", name: "3645 - LIGHTING EQUIPMENT-MANUFACTURERS (766 records)"},
        {value: "3646", name: "3646 - COML IND INSTNL ELEC LIGHTING (MFRS) (77 records)"},
        {value: "3647", name: "3647 - VEHICULAR LIGHTING EQUIPMENT (MFRS) (46 records)"},
        {value: "3648", name: "3648 - LIGHTING EQUIPMENT NEC (MFRS) (390 records)"},
        {value: "3651", name: "3651 - SOUND SYSTEMS & EQUIPMENT-MANUFACTURERS (824 records)"},
        {value: "3652", name: "3652 - VIDEO TAPES & DISCS-MANUFACTURERS (297 records)"},
        {value: "3661", name: "3661 - TELEPHONE EQUIPMENT & SYSTEMS-MFRS (326 records)"},
        {value: "3663", name: "3663 - RADIO/TV BROADCASTING/COMM EQUIP (MFRS) (1146 records)"},
        {value: "3669", name: "3669 - TRAFFIC SIGNS SIGNALS & EQUIP-MFRS (2277 records)"},
        {value: "3671", name: "3671 - TELEVISION PICTURE TUBES-MANUFACTURERS (15 records)"},
        {value: "3672", name: "3672 - PRINTED & ETCHED CIRCUITS-MFRS (1147 records)"},
        {value: "3674", name: "3674 - SEMICONDUCTOR DEVICES (MFRS) (2648 records)"},
        {value: "3675", name: "3675 - ELECTRONIC CAPACITOR (MFRS) (61 records)"},
        {value: "3676", name: "3676 - RESISTORS (MFRS) (44 records)"},
        {value: "3677", name: "3677 - ELECTRONIC COIL & TRANSFORMERS (MFRS) (178 records)"},
        {value: "3678", name: "3678 - ELECTRONIC CONNECTORS (MFRS) (178 records)"},
        {value: "3679", name: "3679 - ELECTRONIC EQUIPMENT & SUPPLIES-MFRS (3200 records)"},
        {value: "3691", name: "3691 - STORAGE-BATTERIES (MFRS) (205 records)"},
        {value: "3692", name: "3692 - BATTERIES-DRY CELL-MANUFACTURERS (205 records)"},
        {value: "3694", name: "3694 - IGNITION INTERLOCK DEVICES (MFRS) (675 records)"},
        {value: "3695", name: "3695 - MAGNETIC/OPTICAL RECORDING MEDIA (MFRS) (109 records)"},
        {value: "3699", name: "3699 - ELECTRICAL PRODUCTS-MANUFACTURERS (1732 records)"},
        {value: "3711", name: "3711 - MOTOR VHCLS/PASSENGER CAR BODIES (MFRS) (1107 records)"},
        {value: "3713", name: "3713 - TRUCK BODIES-MANUFACTURERS (712 records)"},
        {value: "3714", name: "3714 - TRUCK EQUIPMENT & PARTS-MANUFACTURERS (5130 records)"},
        {value: "3715", name: "3715 - TRAILERS-TRUCK-MANUFACTURERS (330 records)"},
        {value: "3716", name: "3716 - MOTOR HOMES-MANUFACTURERS (31 records)"},
        {value: "3721", name: "3721 - AIRCRAFT-MANUFACTURERS (719 records)"},
        {value: "3724", name: "3724 - AIRCRAFT ENGINES & ENGINE PARTS-MFRS (303 records)"},
        {value: "3728", name: "3728 - AIRCRAFT COMPONENTS-MANUFACTURERS (1177 records)"},
        {value: "3731", name: "3731 - SHIP BUILDERS & REPAIRERS (MFRS) (751 records)"},
        {value: "3732", name: "3732 - BOATS-MANUFACTURERS (1633 records)"},
        {value: "3743", name: "3743 - RAILROAD CARS-MANUFACTURERS (441 records)"},
        {value: "3751", name: "3751 - MOTORCYCLES-SUPLS & PARTS-MANUFACTURERS (474 records)"},
        {value: "3761", name: "3761 - SPACE RESEARCH & DEVELOPMENT (MFRS) (36 records)"},
        {value: "3764", name: "3764 - GUIDED MSSLE/SPACE VHCL-PROP UNITS (MFR) (12 records)"},
        {value: "3769", name: "3769 - SPACE COMPONENTS & SYSTEMS (MFRS) (49 records)"},
        {value: "3792", name: "3792 - TRAILER-MANUFACTURERS & DESIGNERS (605 records)"},
        {value: "3795", name: "3795 - TANKS & TANK COMPONENTS (MFRS) (97 records)"},
        {value: "3799", name: "3799 - TRAILERS CARGO (609 records)"},
        {value: "3812", name: "3812 - AEROSPACE INDUSTRIES (MFRS) (3279 records)"},
        {value: "3821", name: "3821 - LABORATORY FURNITURE (MFRS) (447 records)"},
        {value: "3822", name: "3822 - ENERGY EQUIPMENT SYSTEMS-SUPPLIES (MFRS) (1696 records)"},
        {value: "3823", name: "3823 - INDUSTRIAL MEASURING/CONTROLLING INSTR (MFRS) (824 records)"},
        {value: "3824", name: "3824 - TOTALIZING FLUID MTRS/CNTNG DEVICE (MFR) (162 records)"},
        {value: "3825", name: "3825 - INSTRS-MEASURING/TESTING ELEC (MFRS) (432 records)"},
        {value: "3826", name: "3826 - LABORATORY ANALYTICAL INSTRUMENTS (MFRS) (214 records)"},
        {value: "3827", name: "3827 - MAGNIFYING GLASSES (MFRS) (259 records)"},
        {value: "3829", name: "3829 - THERMOMETERS-MANUFACTURERS (978 records)"},
        {value: "3841", name: "3841 - HOSPITAL EQUIPMENT & SUPPLIES-MFRS (7294 records)"},
        {value: "3842", name: "3842 - SAFETY EQUIPMENT & CLOTHING-MFRS (1139 records)"},
        {value: "3843", name: "3843 - DENTAL EQUIPMENT & SUPPLIES-MFRS (576 records)"},
        {value: "3844", name: "3844 - X-RAY APPARATUS & SUPPLIES-MANUFACTURERS (189 records)"},
        {value: "3845", name: "3845 - LASER MEDICAL (MFRS) (288 records)"},
        {value: "3851", name: "3851 - OPTICAL GOODS-MANUFACTURERS (899 records)"},
        {value: "3861", name: "3861 - MICROFILMING SERVICE EQUIP & SUPLS-MFRS (360 records)"},
        {value: "3873", name: "3873 - WATCHMAKERS (MFRS) (185 records)"},
        {value: "3911", name: "3911 - JEWELRY-MANUFACTURERS (3835 records)"},
        {value: "3914", name: "3914 - CUTLERY-MANUFACTURERS (TABLEWARE) (600 records)"},
        {value: "3915", name: "3915 - JEWELRY POLISHING (MFRS) (529 records)"},
        {value: "3931", name: "3931 - MUSICAL INSTRUMENTS-MANUFACTURERS (565 records)"},
        {value: "3942", name: "3942 - DOLLS & STUFFED TOYS (MFRS) (192 records)"},
        {value: "3944", name: "3944 - FUND RAISING GAMES MDSE & SUPLS-MFRS (986 records)"},
        {value: "3949", name: "3949 - HEALTH EQUIPMENT & SUPLS-MANUFACTURERS (2436 records)"},
        {value: "3951", name: "3951 - PENCILS (MFRS) (60 records)"},
        {value: "3952", name: "3952 - LEAD PENCILS CRAYONS/ARTISTS MTRL (MFRS) (150 records)"},
        {value: "3953", name: "3953 - RUBBER STAMPS-MANUFACTURERS (505 records)"},
        {value: "3955", name: "3955 - CARBON PAPER-MANUFACTURERS (19 records)"},
        {value: "3961", name: "3961 - COSTUME JWLRY/NOVELTIES-EX PRCS (MFRS) (226 records)"},
        {value: "3965", name: "3965 - FASTENERS-BUTTONS NEEDLES & PINS (MFRS) (191 records)"},
        {value: "3991", name: "3991 - BROOMS & BRUSHES (MFRS) (223 records)"},
        {value: "3993", name: "3993 - SIGNS & ADVERTISING SPECIALTIES (MFRS) (29246 records)"},
        {value: "3995", name: "3995 - BURIAL CASKETS (MFRS) (477 records)"},
        {value: "3996", name: "3996 - LINOLEUM-ASPHALT FELT BASE FLR CVR (MFR) (23 records)"},
        {value: "3999", name: "3999 - MANUFACTURERS (28463 records)"},
        {value: "4011", name: "4011 - RAILROADS (5352 records)"},
        {value: "4013", name: "4013 - RAILROAD SWITCHING & TERMINAL ESTBLSHMNT (28 records)"},
        {value: "4111", name: "4111 - SHUTTLE SERVICE (6730 records)"},
        {value: "4119", name: "4119 - LIMOUSINE SERVICE (44433 records)"},
        {value: "4121", name: "4121 - TAXICABS & TRANSPORTATION SERVICE (19281 records)"},
        {value: "4131", name: "4131 - BUS LINES (3595 records)"},
        {value: "4141", name: "4141 - LOCAL BUS CHARTER SERVICE (50 records)"},
        {value: "4142", name: "4142 - BUSES-CHARTER & RENTAL (4557 records)"},
        {value: "4151", name: "4151 - BUSES-SCHOOL TRANSPORTATION SERVICE (1897 records)"},
        {value: "4173", name: "4173 - TERMINAL & SERVICE FACILITIES (60 records)"},
        {value: "4212", name: "4212 - DELIVERY SERVICE (29078 records)"},
        {value: "4213", name: "4213 - TRUCKING (107637 records)"},
        {value: "4214", name: "4214 - MOVERS (30247 records)"},
        {value: "4215", name: "4215 - COURIER SERVICES (4280 records)"},
        {value: "4221", name: "4221 - COTTON COMPRESSES (6105 records)"},
        {value: "4222", name: "4222 - WAREHOUSES-COLD STORAGE (1509 records)"},
        {value: "4225", name: "4225 - STORAGE (78778 records)"},
        {value: "4226", name: "4226 - RECREATIONAL VEHICLES-STORAGE (2474 records)"},
        {value: "4231", name: "4231 - DISPATCH SERVICE-TRUCKING (196 records)"},
        {value: "4311", name: "4311 - POST OFFICES (39421 records)"},
        {value: "4412", name: "4412 - DEEP SEA-FOREIGN TRANSPORT OF FREIGHT (35 records)"},
        {value: "4424", name: "4424 - OCEAN-FREIGHT (62 records)"},
        {value: "4432", name: "4432 - FREIGHT-TRANSPORT ON THE GREAT LAKES (9 records)"},
        {value: "4449", name: "4449 - SHIPPING AGENTS (4447 records)"},
        {value: "4481", name: "4481 - DEEP SEA-PASSENGER TRANS-EXCEPT FERRY (64 records)"},
        {value: "4482", name: "4482 - FERRIES (250 records)"},
        {value: "4489", name: "4489 - BOATS-EXCURSIONS (1232 records)"},
        {value: "4491", name: "4491 - MARINE CARGO HANDLING (628 records)"},
        {value: "4492", name: "4492 - TOWING-MARINE (1417 records)"},
        {value: "4493", name: "4493 - BOAT REPAIRING (13208 records)"},
        {value: "4499", name: "4499 - SURVEYORS-MARINE (1779 records)"},
        {value: "4512", name: "4512 - AIRLINE COMPANIES (5465 records)"},
        {value: "4513", name: "4513 - EXPEDITERS (761 records)"},
        {value: "4522", name: "4522 - AIR AMBULANCE SERVICE (1268 records)"},
        {value: "4581", name: "4581 - AIRCRAFT SERVICING & MAINTENANCE (21714 records)"},
        {value: "4612", name: "4612 - PIPE LINE COMPANIES (5403 records)"},
        {value: "4613", name: "4613 - REFINED PETROLEUM PIPELINES (15 records)"},
        {value: "4619", name: "4619 - PIPELINES NEC (141 records)"},
        {value: "4724", name: "4724 - TRAVEL AGENCIES & BUREAUS (45044 records)"},
        {value: "4725", name: "4725 - TOURS-OPERATORS & PROMOTERS (8524 records)"},
        {value: "4729", name: "4729 - STEAMSHIP AGENCIES (1173 records)"},
        {value: "4731", name: "4731 - FREIGHT-FORWARDING (24160 records)"},
        {value: "4741", name: "4741 - RAILROAD CAR LEASING (112 records)"},
        {value: "4783", name: "4783 - PACKING & CRATING SERVICE (1507 records)"},
        {value: "4785", name: "4785 - WEIGHERS (287 records)"},
        {value: "4789", name: "4789 - TRANSPORTATION (17960 records)"},
        {value: "4812", name: "4812 - CELLULAR TELEPHONES (SERVICES) (71124 records)"},
        {value: "4813", name: "4813 - TELEPHONE COMPANIES (27762 records)"},
        {value: "4822", name: "4822 - TELEGRAM SERVICE (1061 records)"},
        {value: "4832", name: "4832 - RADIO STATIONS & BROADCASTING COMPANIES (22660 records)"},
        {value: "4833", name: "4833 - TELEVISION STATIONS & BROADCASTING CO (6282 records)"},
        {value: "4841", name: "4841 - TELEVISION-CABLE & CATV (11349 records)"},
        {value: "4899", name: "4899 - COMMUNICATIONS SERVICES-COMMON CARRIERS (23488 records)"},
        {value: "4911", name: "4911 - ELECTRIC COMPANIES (16412 records)"},
        {value: "4922", name: "4922 - GAS TRANSMISSION COMPANIES (422 records)"},
        {value: "4923", name: "4923 - NATURAL GAS TRANSMISSION & DISTRIBUTION (180 records)"},
        {value: "4924", name: "4924 - HEAT SUPPLYING COMPANIES (1081 records)"},
        {value: "4925", name: "4925 - GAS COMPANIES (5462 records)"},
        {value: "4931", name: "4931 - ELECTRIC & OTHER SERVICES-COMBINED (110 records)"},
        {value: "4932", name: "4932 - GAS SYSTEMS (46 records)"},
        {value: "4939", name: "4939 - UTILITIES-UNDERGROUND-CABLE LOCATING SVC (2135 records)"},
        {value: "4941", name: "4941 - WATER & SEWAGE COMPANIES-UTILITY (14992 records)"},
        {value: "4952", name: "4952 - SEWAGE TREATMENT EQUIPMENT (823 records)"},
        {value: "4953", name: "4953 - GARBAGE COLLECTION (24546 records)"},
        {value: "4959", name: "4959 - OIL SPILL CLEAN UP SERVICE (3745 records)"},
        {value: "4961", name: "4961 - GEOTHERMAL HEAT PUMPS (50 records)"},
        {value: "4971", name: "4971 - HYDROLOGISTS (707 records)"},
        {value: "5012", name: "5012 - AUTOMOBILE AUCTIONS (WHLS) (7410 records)"},
        {value: "5013", name: "5013 - TRUCK ACCESSORIES (WHLS) (15689 records)"},
        {value: "5014", name: "5014 - TIRE-DEALERS-USED (WHLS) (3193 records)"},
        {value: "5015", name: "5015 - AUTOMOBILE WRECKING (WHLS) (14157 records)"},
        {value: "5021", name: "5021 - OFFICE FURNITURE & EQUIP-DEALERS (WHLS) (8451 records)"},
        {value: "5023", name: "5023 - FLOOR MATERIALS-WHOLESALE (5607 records)"},
        {value: "5031", name: "5031 - TIMBER & TIMBERLAND COMPANIES (WHLS) (11411 records)"},
        {value: "5032", name: "5032 - SAND & GRAVEL (WHLS) (18815 records)"},
        {value: "5033", name: "5033 - ROOFING MATERIALS-WHOLESALE (1842 records)"},
        {value: "5039", name: "5039 - FENCE (WHLS) (11283 records)"},
        {value: "5043", name: "5043 - AUDIO-VISUAL EQUIPMENT & SUPLS (WHLS) (1747 records)"},
        {value: "5044", name: "5044 - COPYING & DUPLICATING MACHINES & SUPLS (13090 records)"},
        {value: "5045", name: "5045 - COMPUTERS-WHOLESALE (2630 records)"},
        {value: "5046", name: "5046 - RESTAURANT EQUIPMENT & SUPPLIES (WHLS) (9194 records)"},
        {value: "5047", name: "5047 - PHYSICIANS & SURGEONS EQUIP & SUPLS-WHLS (29368 records)"},
        {value: "5048", name: "5048 - OPTICAL EQUIPMENT MACHINERY/SUPLS (WHLS) (1189 records)"},
        {value: "5049", name: "5049 - LABORATORY EQUIPMENT & SUPPLIES (WHLS) (3440 records)"},
        {value: "5051", name: "5051 - ALUMINUM (WHLS) (11913 records)"},
        {value: "5052", name: "5052 - COPPER (WHLS) (441 records)"},
        {value: "5063", name: "5063 - TRAFFIC SIGNS SIGNALS & EQUIP-WHLS (60976 records)"},
        {value: "5064", name: "5064 - APPLIANCES-HOUSEHOLD-MAJOR-WHOLESALE (2220 records)"},
        {value: "5065", name: "5065 - SOUND SYSTEMS & EQUIPMENT (WHLS) (12460 records)"},
        {value: "5072", name: "5072 - FASTENERS-INDUSTRIAL (WHLS) (10156 records)"},
        {value: "5074", name: "5074 - BOILERS-SUPPLIES & PARTS (WHLS) (11948 records)"},
        {value: "5075", name: "5075 - HEATING EQUIPMENT & SYSTEMS (WHLS) (7759 records)"},
        {value: "5078", name: "5078 - REFRIGERATING EQUIP-COMMERCIAL (WHLS) (5842 records)"},
        {value: "5082", name: "5082 - CONTRACTORS-EQUIP/SUPLS-DLRS/SVC (WHLS) (12186 records)"},
        {value: "5083", name: "5083 - TRACTOR-DEALERS (WHLS) (16444 records)"},
        {value: "5084", name: "5084 - GAUGES & GAGES (WHLS) (42114 records)"},
        {value: "5085", name: "5085 - VALVES-WHOLESALE (25140 records)"},
        {value: "5087", name: "5087 - BEAUTY SALONS-EQUIPMENT & SUPLS (WHLS) (23476 records)"},
        {value: "5088", name: "5088 - AIRCRAFT AVIONICS-SALES & SERVICE (WHLS) (3210 records)"},
        {value: "5091", name: "5091 - SPORTING GOODS-WHOLESALE (6682 records)"},
        {value: "5092", name: "5092 - WHOLESALERS (12192 records)"},
        {value: "5093", name: "5093 - SCRAP METALS & IRON (WHLS) (21332 records)"},
        {value: "5094", name: "5094 - JEWELERS-WHOLESALE (9980 records)"},
        {value: "5099", name: "5099 - IMPORTERS (WHLS) (45381 records)"},
        {value: "5111", name: "5111 - PAPER PRODUCTS (WHLS) (2420 records)"},
        {value: "5112", name: "5112 - LEGAL FORMS (WHLS) (6232 records)"},
        {value: "5113", name: "5113 - PACKAGING MATERIALS-WHOLESALE (5993 records)"},
        {value: "5122", name: "5122 - PHARMACEUTICAL PRODUCTS-WHOLESALE (7782 records)"},
        {value: "5131", name: "5131 - WOOLEN GOODS-WHOLESALE (3114 records)"},
        {value: "5136", name: "5136 - T-SHIRTS-WHOLESALE (4429 records)"},
        {value: "5137", name: "5137 - FUR BUSINESS-WHOLESALE (3683 records)"},
        {value: "5139", name: "5139 - SHOES-WHOLESALE (706 records)"},
        {value: "5141", name: "5141 - FOOD BROKERS (WHLS) (13659 records)"},
        {value: "5142", name: "5142 - FROZEN FOODS-WHOLESALE (1094 records)"},
        {value: "5143", name: "5143 - ICE CREAM & FROZEN DESSERTS-DISTR (WHLS) (2001 records)"},
        {value: "5144", name: "5144 - POULTRY SERVICES (WHLS) (976 records)"},
        {value: "5145", name: "5145 - POTATO CHIPS (WHLS) (2681 records)"},
        {value: "5146", name: "5146 - SEAFOOD-WHOLESALE (2722 records)"},
        {value: "5147", name: "5147 - MEAT-WHOLESALE (3158 records)"},
        {value: "5148", name: "5148 - FRUITS & VEGETABLES-WHOLESALE (6362 records)"},
        {value: "5149", name: "5149 - CHOCOLATE & COCOA (WHLS) (15943 records)"},
        {value: "5153", name: "5153 - GRAIN-DEALERS (WHLS) (1824 records)"},
        {value: "5154", name: "5154 - LIVESTOCK-DEALERS (WHLS) (3588 records)"},
        {value: "5159", name: "5159 - PEANUT PRODUCTS (WHLS) (1109 records)"},
        {value: "5162", name: "5162 - PLASTICS-PRODUCTS-FINISHED-WHOLESALE (912 records)"},
        {value: "5169", name: "5169 - EXPLOSIVES (WHLS) (10838 records)"},
        {value: "5171", name: "5171 - GASOLINE & OIL BULK STATIONS (WHLS) (539 records)"},
        {value: "5172", name: "5172 - OILS-FUEL (WHLS) (35583 records)"},
        {value: "5181", name: "5181 - BEER & ALE-WHOLESALE (3003 records)"},
        {value: "5182", name: "5182 - WINES-WHOLESALE (2325 records)"},
        {value: "5191", name: "5191 - FEED-DEALERS (WHLS) (23534 records)"},
        {value: "5192", name: "5192 - BOOK DEALERS-WHOLESALE (1093 records)"},
        {value: "5193", name: "5193 - FLORISTS-WHOLESALE (5014 records)"},
        {value: "5194", name: "5194 - CIGAR CIGARETTE & TOBACCO DEALERS-WHLS (1387 records)"},
        {value: "5198", name: "5198 - WALLPAPERS & WALLCOVERINGS-WHOLESALE (1271 records)"},
        {value: "5199", name: "5199 - ADVERTISING-SPECIALTIES (WHLS) (15684 records)"},
        {value: "5211", name: "5211 - INSULATION MATERIALS-COLD & HEAT (107328 records)"},
        {value: "5231", name: "5231 - GLASS-AUTO PLATE & WINDOW & ETC (48424 records)"},
        {value: "5251", name: "5251 - TOOLS-HAND (26821 records)"},
        {value: "5261", name: "5261 - GARDEN CENTERS (42566 records)"},
        {value: "5271", name: "5271 - MOBILE OFFICES & COMMERCIAL UNITS (3265 records)"},
        {value: "5311", name: "5311 - RETAIL SHOPS (44079 records)"},
        {value: "5331", name: "5331 - VARIETY STORES (45974 records)"},
        {value: "5399", name: "5399 - GENERAL MERCHANDISE-RETAIL (37336 records)"},
        {value: "5411", name: "5411 - GROCERS-RETAIL (205997 records)"},
        {value: "5421", name: "5421 - SEAFOOD-RETAIL (18069 records)"},
        {value: "5431", name: "5431 - FRUITS & VEGETABLES & PRODUCE-RETAIL (14042 records)"},
        {value: "5441", name: "5441 - POPCORN & POPCORN SUPPLIES (11324 records)"},
        {value: "5451", name: "5451 - YOGURT (9386 records)"},
        {value: "5461", name: "5461 - BAKERS-RETAIL (67860 records)"},
        {value: "5499", name: "5499 - HEALTH & DIET FOODS-RETAIL (44410 records)"},
        {value: "5511", name: "5511 - AUTOMOBILE DEALERS-USED CARS (147455 records)"},
        {value: "5521", name: "5521 - TRUCK-DEALERS-USED (4902 records)"},
        {value: "5531", name: "5531 - TIRE-DEALERS-RETAIL (109761 records)"},
        {value: "5541", name: "5541 - SERVICE STATIONS-GASOLINE & OIL (108766 records)"},
        {value: "5551", name: "5551 - BOAT DEALERS SALES & SERVICE (17650 records)"},
        {value: "5561", name: "5561 - RECREATIONAL VEHICLES (7609 records)"},
        {value: "5571", name: "5571 - ALL TERRAIN VEHICLES (15127 records)"},
        {value: "5599", name: "5599 - AIRCRAFT EQUIPMENT PARTS & SUPPLIES (10317 records)"},
        {value: "5611", name: "5611 - LEATHER CLOTHING-RETAIL (14505 records)"},
        {value: "5621", name: "5621 - BRIDAL SHOPS (56285 records)"},
        {value: "5632", name: "5632 - HANDBAGS (11976 records)"},
        {value: "5641", name: "5641 - INFANTS WEAR (13534 records)"},
        {value: "5651", name: "5651 - CLOTHING-RETAIL (63207 records)"},
        {value: "5661", name: "5661 - SHOES-RETAIL (39322 records)"},
        {value: "5699", name: "5699 - DANCING SUPPLIES (52356 records)"},
        {value: "5712", name: "5712 - CABINETS (91140 records)"},
        {value: "5713", name: "5713 - FLOOR COVERINGS-RETAIL (29574 records)"},
        {value: "5714", name: "5714 - DRAPERY INSTALLATION SERVICE (7323 records)"},
        {value: "5719", name: "5719 - GLASS-CARVED ORNAMENTAL BEVELED ETC (33479 records)"},
        {value: "5722", name: "5722 - VACUUM CLEANERS-HOUSEHOLD-DEALERS (23540 records)"},
        {value: "5731", name: "5731 - VIDEO RECORDERS & PLAYERS (53865 records)"},
        {value: "5734", name: "5734 - COMPUTER & EQUIPMENT DEALERS (73032 records)"},
        {value: "5735", name: "5735 - VIDEO TAPES DISCS & CASSETTES (5169 records)"},
        {value: "5736", name: "5736 - PIANOS (14741 records)"},
        {value: "5812", name: "5812 - DELICATESSENS (885501 records)"},
        {value: "5813", name: "5813 - BARS (60578 records)"},
        {value: "5912", name: "5912 - PHARMACIES (85231 records)"},
        {value: "5921", name: "5921 - LIQUORS-RETAIL (48437 records)"},
        {value: "5932", name: "5932 - CARPET & RUG DEALERS-USED (96826 records)"},
        {value: "5941", name: "5941 - FISHING TACKLE-DEALERS (68995 records)"},
        {value: "5942", name: "5942 - BOOK DEALERS-RETAIL (20964 records)"},
        {value: "5943", name: "5943 - SCRAPBOOK ALBUMS & SUPPLIES (15986 records)"},
        {value: "5944", name: "5944 - JEWELERS-RETAIL (60933 records)"},
        {value: "5945", name: "5945 - CERAMIC EQUIPMENT & SUPPLIES (36152 records)"},
        {value: "5946", name: "5946 - PHOTOGRAPHIC EQUIP & SUPPLIES-RETAIL (1854 records)"},
        {value: "5947", name: "5947 - GIFT SHOPS (75986 records)"},
        {value: "5948", name: "5948 - LEATHER GOODS-DEALERS (3549 records)"},
        {value: "5949", name: "5949 - YARN-RETAIL (10875 records)"},
        {value: "5961", name: "5961 - E-COMMERCE (19417 records)"},
        {value: "5962", name: "5962 - VENDING MACHINES (9088 records)"},
        {value: "5963", name: "5963 - DIRECT SELLING ESTABLISHMENTS (13082 records)"},
        {value: "5983", name: "5983 - FUEL-RETAIL (582 records)"},
        {value: "5984", name: "5984 - PROPANE (LP) GAS (8490 records)"},
        {value: "5989", name: "5989 - FIREWOOD (1946 records)"},
        {value: "5992", name: "5992 - FLORISTS-RETAIL (48197 records)"},
        {value: "5993", name: "5993 - CIGAR CIGARETTE & TOBACCO DEALERS-RETAIL (26519 records)"},
        {value: "5994", name: "5994 - NEWS DEALERS (3548 records)"},
        {value: "5995", name: "5995 - OPTICAL GOODS-RETAIL (28463 records)"},
        {value: "5999", name: "5999 - ART GALLERIES & DEALERS (213402 records)"},
        {value: "6011", name: "6011 - FEDERAL RESERVE BANKS (31 records)"},
        {value: "6019", name: "6019 - CENTRAL RESERVE DEPOSITORY INSTN NEC (1 records)"},
        {value: "6021", name: "6021 - TRUST COMPANIES (448278 records)"},
        {value: "6022", name: "6022 - STATE COMMERCIAL BANKS (127 records)"},
        {value: "6029", name: "6029 - COMMERCIAL BANKS NEC (172 records)"},
        {value: "6035", name: "6035 - SAVINGS & LOAN ASSOCIATIONS (916 records)"},
        {value: "6036", name: "6036 - SAVINGS INSTITUTIONS-EXCEPT FEDERAL (28 records)"},
        {value: "6061", name: "6061 - CREDIT UNIONS (25280 records)"},
        {value: "6062", name: "6062 - CREDIT UNIONS NOT FEDERALLY CHARTERED (35 records)"},
        {value: "6081", name: "6081 - BRANCHES & AGENCIES OF FOREIGN BANKS (31 records)"},
        {value: "6082", name: "6082 - FOREIGN TRADE & INTERNATIONAL BANKS (38 records)"},
        {value: "6091", name: "6091 - FIDUCIARIES (47 records)"},
        {value: "6099", name: "6099 - ESCROW SERVICE (20743 records)"},
        {value: "6111", name: "6111 - COUNTY GOVT-ADM OF FED CREDIT AGENCIES (101 records)"},
        {value: "6141", name: "6141 - LOANS (57629 records)"},
        {value: "6153", name: "6153 - CREDIT CARD & OTHER CREDIT PLANS (3230 records)"},
        {value: "6159", name: "6159 - MISC BUSINESS CREDIT INSTITUTIONS (511 records)"},
        {value: "6162", name: "6162 - REAL ESTATE LOANS (62958 records)"},
        {value: "6163", name: "6163 - LOAN BROKERAGE (652 records)"},
        {value: "6211", name: "6211 - INVESTMENT SECURITIES (49043 records)"},
        {value: "6221", name: "6221 - COTTON BROKERS (1920 records)"},
        {value: "6231", name: "6231 - BARTER & TRADE EXCHANGE (688 records)"},
        {value: "6282", name: "6282 - FINANCING CONSULTANTS (147776 records)"},
        {value: "6289", name: "6289 - SERVICES ALLIED WITH EXCH OF SEC NEC (229 records)"},
        {value: "6311", name: "6311 - LIFE INSURANCE (UNDERWRITERS) (637 records)"},
        {value: "6321", name: "6321 - HEALTH SYSTEMS EVALUATION CONSULTANTS (99 records)"},
        {value: "6324", name: "6324 - HOSPITALIZATION PLANS-MEDICAL & SURGICAL (2405 records)"},
        {value: "6331", name: "6331 - INSURANCE-PROPERTY & CASUALTY (2485 records)"},
        {value: "6351", name: "6351 - BONDS-BAIL (26574 records)"},
        {value: "6361", name: "6361 - INSURANCE-TITLE (1287 records)"},
        {value: "6371", name: "6371 - PENSION & PROFIT SHARING PLANS (3084 records)"},
        {value: "6399", name: "6399 - REAL ESTATE MAINTENANCE PROTECTION PLANS (235 records)"},
        {value: "6411", name: "6411 - INSURANCE (379555 records)"},
        {value: "6512", name: "6512 - SHOPPING CENTERS & MALLS (89002 records)"},
        {value: "6513", name: "6513 - APARTMENTS (171992 records)"},
        {value: "6514", name: "6514 - TOWNHOUSES (785 records)"},
        {value: "6515", name: "6515 - MOBILE HOMES-PARKS & COMMUNITIES (12529 records)"},
        {value: "6517", name: "6517 - LESSORS OF RAILROAD PROPERTY (4 records)"},
        {value: "6519", name: "6519 - LESSORS OF REAL PROPERTY NEC (1618 records)"},
        {value: "6531", name: "6531 - COOPERATIVES (475516 records)"},
        {value: "6541", name: "6541 - TITLE COMPANIES (24688 records)"},
        {value: "6552", name: "6552 - REAL ESTATE DEVELOPERS (23281 records)"},
        {value: "6553", name: "6553 - CEMETERIES (13659 records)"},
        {value: "6712", name: "6712 - HOLDING COMPANIES (BANK) (3773 records)"},
        {value: "6719", name: "6719 - HOLDING COMPANIES (NON-BANK) (12961 records)"},
        {value: "6722", name: "6722 - MANAGEMENT INVESTMENT OPEN-END (96 records)"},
        {value: "6726", name: "6726 - INSURANCE ANNUITIES (1675 records)"},
        {value: "6732", name: "6732 - EDUCATIONAL SCHOLARSHIP PLANS (746 records)"},
        {value: "6733", name: "6733 - TRUSTEES (1284 records)"},
        {value: "6792", name: "6792 - OIL-CRUDE-PURCHASING (227 records)"},
        {value: "6794", name: "6794 - FRANCHISING (684 records)"},
        {value: "6798", name: "6798 - REAL ESTATE INVESTMENT TRUSTS (1135 records)"},
        {value: "6799", name: "6799 - INVESTORS NEC (6582 records)"},
        {value: "7011", name: "7011 - BED & BREAKFAST ACCOMMODATIONS (122204 records)"},
        {value: "7021", name: "7021 - HOSTELS (4133 records)"},
        {value: "7032", name: "7032 - CAMPS (9164 records)"},
        {value: "7033", name: "7033 - CAMPGROUNDS (14762 records)"},
        {value: "7041", name: "7041 - ORGANIZATION HOTELS ON MEMBERSHIP BASIS (58 records)"},
        {value: "7110", name: "7110 - Soil Preparation Sv (13 records)"},
        {value: "7210", name: "7210 - Crops-planting/protecting (71 records)"},
        {value: "7211", name: "7211 - LAUNDRIES (8536 records)"},
        {value: "7212", name: "7212 - CLEANERS (53091 records)"},
        {value: "7213", name: "7213 - LINEN SUPPLY SERVICE (2731 records)"},
        {value: "7215", name: "7215 - LAUNDRIES-SELF SERVICE (11239 records)"},
        {value: "7216", name: "7216 - DRAPERY & CURTAIN CLEANERS (681 records)"},
        {value: "7217", name: "7217 - CARPET & RUG REPAIRING (44846 records)"},
        {value: "7218", name: "7218 - UNIFORM RENTAL SERVICE (497 records)"},
        {value: "7219", name: "7219 - WEAVING & MENDING (1172 records)"},
        {value: "7220", name: "7220 - Crop Harvesting Services (11 records)"},
        {value: "7221", name: "7221 - PHOTOGRAPHERS-PORTRAIT (51104 records)"},
        {value: "7229", name: "7229 - Crop Harvesting Services (2 records)"},
        {value: "7230", name: "7230 - Crop Preparation For Market (8 records)"},
        {value: "7231", name: "7231 - BEAUTY SALONS (389833 records)"},
        {value: "7240", name: "7240 - Cotton Ginning Services (4 records)"},
        {value: "7241", name: "7241 - BARBERS (46692 records)"},
        {value: "7251", name: "7251 - SHOE & BOOT REPAIRING (5003 records)"},
        {value: "7261", name: "7261 - FUNERAL DIRECTORS (33408 records)"},
        {value: "7291", name: "7291 - TAX RETURN PREPARATION & FILING (78733 records)"},
        {value: "7299", name: "7299 - SINGLES ORGANIZATIONS (141174 records)"},
        {value: "7311", name: "7311 - ADVERTISING-AGENCIES & COUNSELORS (28259 records)"},
        {value: "7312", name: "7312 - ADVERTISING-OUTDOOR (2199 records)"},
        {value: "7313", name: "7313 - ADVERTISING-TELEVISION (2772 records)"},
        {value: "7319", name: "7319 - DISTRIBUTING SERVICE-CIRCULAR & SAMPLE (37725 records)"},
        {value: "7322", name: "7322 - COLLECTION AGENCIES (7181 records)"},
        {value: "7323", name: "7323 - CREDIT REPORTING AGENCIES (1106 records)"},
        {value: "7331", name: "7331 - ADVERTISING-DIRECT MAIL (15680 records)"},
        {value: "7334", name: "7334 - COPYING & DUPLICATING SERVICE (5790 records)"},
        {value: "7335", name: "7335 - PHOTOGRAPHERS-COMMERCIAL (16299 records)"},
        {value: "7336", name: "7336 - ARTISTS-COMMERCIAL (37187 records)"},
        {value: "7338", name: "7338 - RESUME SERVICE (11825 records)"},
        {value: "7342", name: "7342 - PEST CONTROL (55701 records)"},
        {value: "7349", name: "7349 - JANITOR SERVICE (117857 records)"},
        {value: "7352", name: "7352 - DISABLED PERSONS EQUIPMENT & SUPPLIES (760 records)"},
        {value: "7353", name: "7353 - CRANE SERVICE (4122 records)"},
        {value: "7359", name: "7359 - AIRCRAFT CHARTER RENTAL & LEASING SVC (45016 records)"},
        {value: "7361", name: "7361 - EMPLOYMENT AGENCIES & OPPORTUNITIES (46650 records)"},
        {value: "7363", name: "7363 - EMPLOYMENT CONTRACTORS-TEMPORARY HELP (12174 records)"},
        {value: "7371", name: "7371 - COMPUTER SERVICES (41803 records)"},
        {value: "7372", name: "7372 - INSTRUMENTATION SERVICES (5761 records)"},
        {value: "7373", name: "7373 - WEBSITE DESIGN SERVICE (23927 records)"},
        {value: "7374", name: "7374 - INTERNET SERVICE (42634 records)"},
        {value: "7375", name: "7375 - ONLINE SERVICES (1631 records)"},
        {value: "7376", name: "7376 - COMPUTER ROOMS MONITORING & MANAGEMENT (45 records)"},
        {value: "7377", name: "7377 - COMPUTERS-RENTING & LEASING (458 records)"},
        {value: "7378", name: "7378 - COMPUTERS-SERVICE & REPAIR (35711 records)"},
        {value: "7379", name: "7379 - COMPUTERS-NETWORKING (16417 records)"},
        {value: "7381", name: "7381 - SECURITY GUARD & PATROL SERVICE (31759 records)"},
        {value: "7382", name: "7382 - SECURITY SYSTEMS (10948 records)"},
        {value: "7383", name: "7383 - NEWS SERVICE (1264 records)"},
        {value: "7384", name: "7384 - PHOTO FINISHING-RETAIL (8755 records)"},
        {value: "7389", name: "7389 - DRAFTING SERVICES (353032 records)"},
        {value: "7420", name: "7420 - Veterinary Spc Svs (1146 records)"},
        {value: "7421", name: "7421 - Veterinary Spc Svs (1 records)"},
        {value: "7510", name: "7510 - Livestock Services (133 records)"},
        {value: "7511", name: "7511 - Livestock Services (1 records)"},
        {value: "7513", name: "7513 - MOVING-SELF-SERVICE (41725 records)"},
        {value: "7514", name: "7514 - AUTOMOBILE RENTING (25626 records)"},
        {value: "7515", name: "7515 - PASSENGER CAR LEASING (26 records)"},
        {value: "7519", name: "7519 - TRAILER RENTING & LEASING (2820 records)"},
        {value: "7520", name: "7520 - Grooming Svcs Pets (1018 records)"},
        {value: "7521", name: "7521 - PARKING STATIONS & GARAGES (18511 records)"},
        {value: "7522", name: "7522 - Grooming Svcs Pets (24 records)"},
        {value: "7523", name: "7523 - Grooming Svcs Pets (8 records)"},
        {value: "7524", name: "7524 - Grooming Svcs Pets (4 records)"},
        {value: "7532", name: "7532 - AUTOMOBILE BODY-REPAIRING & PAINTING (75477 records)"},
        {value: "7533", name: "7533 - MUFFLERS & EXHAUST SYSTEMS-ENGINE (4548 records)"},
        {value: "7534", name: "7534 - TIRE SERVICE (1983 records)"},
        {value: "7536", name: "7536 - AUTOMOBILE GLASS-SERVICE & INSTALLATION (15142 records)"},
        {value: "7537", name: "7537 - TRANSMISSIONS-AUTOMOBILE (11585 records)"},
        {value: "7538", name: "7538 - AUTOMOBILE REPAIRING & SERVICE (206388 records)"},
        {value: "7539", name: "7539 - AUTOMOBILE RADIATOR REPAIRING (13831 records)"},
        {value: "7542", name: "7542 - CAR WASHING & POLISHING (38126 records)"},
        {value: "7549", name: "7549 - WRECKER SERVICE (53171 records)"},
        {value: "7610", name: "7610 - Farm Labor Contractor (5 records)"},
        {value: "7619", name: "7619 - Farm Labor Contractor (7 records)"},
        {value: "7620", name: "7620 - Farm Management Services (151 records)"},
        {value: "7622", name: "7622 - TELEVISION & RADIO-SERVICE/REPAIR (6206 records)"},
        {value: "7623", name: "7623 - REFRIGERATING EQUIP-COMMERCIAL-SERVICE (10590 records)"},
        {value: "7629", name: "7629 - HEARING AIDS-PARTS & REPAIRING (48565 records)"},
        {value: "7631", name: "7631 - WATCHES-REPAIRING (5974 records)"},
        {value: "7641", name: "7641 - UPHOLSTERERS (20093 records)"},
        {value: "7692", name: "7692 - WELDING (20188 records)"},
        {value: "7694", name: "7694 - OUTBOARD MOTORS-REPAIRING (472 records)"},
        {value: "7699", name: "7699 - CONTRACTORS-EQUIPMENT & SUPLS-REPAIR (116771 records)"},
        {value: "7810", name: "7810 - Landscape Architects (402 records)"},
        {value: "7811", name: "7811 - Landscape Architects (15 records)"},
        {value: "7812", name: "7812 - VIDEO PRODUCTION & TAPING SERVICE (23944 records)"},
        {value: "7819", name: "7819 - VIDEO TAPE DUPLICATION SERVICE (5055 records)"},
        {value: "7820", name: "7820 - Lawn/garden Services (5223 records)"},
        {value: "7821", name: "7821 - Lawn/garden Services (45 records)"},
        {value: "7822", name: "7822 - MOTION PICTURE FILM-DISTRS & EXCHS (560 records)"},
        {value: "7829", name: "7829 - MOTION PICTURE FILM-LIBRARIES (76 records)"},
        {value: "7830", name: "7830 - Orna Shrub, Tree Sv (1496 records)"},
        {value: "7832", name: "7832 - THEATRES-MOVIE (11142 records)"},
        {value: "7833", name: "7833 - DRIVE-IN MOTION PICTURE THEATERS (366 records)"},
        {value: "7839", name: "7839 - Orna Shrub, Tree Sv (3 records)"},
        {value: "7841", name: "7841 - VIDEO TAPES & DISCS-RENTING & LEASING (10737 records)"},
        {value: "7911", name: "7911 - DANCING INSTRUCTION (21185 records)"},
        {value: "7922", name: "7922 - DANCE COMPANIES (19325 records)"},
        {value: "7929", name: "7929 - ENTERTAINMENT BUREAUS (41765 records)"},
        {value: "7933", name: "7933 - BOWLING CENTERS (5927 records)"},
        {value: "7941", name: "7941 - SOCCER CLUBS (7963 records)"},
        {value: "7948", name: "7948 - RACE TRACKS (4784 records)"},
        {value: "7991", name: "7991 - HEALTH CLUBS STUDIOS & GYMNASIUMS (58669 records)"},
        {value: "7992", name: "7992 - GOLF COURSES (20505 records)"},
        {value: "7993", name: "7993 - ARCADES (5927 records)"},
        {value: "7996", name: "7996 - AMUSEMENT PLACES (3503 records)"},
        {value: "7997", name: "7997 - HOCKEY CLUBS (14890 records)"},
        {value: "7999", name: "7999 - PSYCHIC MEDIUMS (136367 records)"},
        {value: "8011", name: "8011 - PHYSICIANS & SURGEONS (1208693 records)"},
        {value: "8021", name: "8021 - DENTISTS (272891 records)"},
        {value: "8031", name: "8031 - OFFICES & CLINICS OF DOCTORS-OSTEOPATHY (40 records)"},
        {value: "8041", name: "8041 - CHIROPRACTORS DC (77057 records)"},
        {value: "8042", name: "8042 - OPTOMETRISTS OD (57253 records)"},
        {value: "8043", name: "8043 - PODIATRISTS (20636 records)"},
        {value: "8049", name: "8049 - PHYSICAL THERAPISTS (668515 records)"},
        {value: "8051", name: "8051 - HEALTH CARE FACILITIES (14605 records)"},
        {value: "8052", name: "8052 - INTERMEDIATE CARE FACILITIES (137 records)"},
        {value: "8059", name: "8059 - HOMES & INSTITUTIONS (28297 records)"},
        {value: "8062", name: "8062 - HOSPITALS (23407 records)"},
        {value: "8063", name: "8063 - MENTAL HEALTH SERVICES (22354 records)"},
        {value: "8069", name: "8069 - BIRTH CENTERS (2213 records)"},
        {value: "8071", name: "8071 - DRUG DETECTION SERVICE & EQUIPMENT (30213 records)"},
        {value: "8072", name: "8072 - LABORATORIES-DENTAL (11701 records)"},
        {value: "8082", name: "8082 - HOME HEALTH SERVICE (66676 records)"},
        {value: "8092", name: "8092 - DIALYSIS (5510 records)"},
        {value: "8093", name: "8093 - SURGICAL CENTERS (7755 records)"},
        {value: "8099", name: "8099 - MEDICAL EMERGENCY TRAINING (77393 records)"},
        {value: "8111", name: "8111 - ATTORNEYS SERVICE BUREAUS (491415 records)"},
        {value: "8119", name: "8119 - Timber Tract Operation (3 records)"},
        {value: "8211", name: "8211 - EDUCATIONAL PROGRAMS (211249 records)"},
        {value: "8221", name: "8221 - SCHOOLS-UNIVERSITIES & COLLEGES ACADEMIC (34059 records)"},
        {value: "8222", name: "8222 - JUNIOR COLLEGES & TECHNICAL INSTITUTES (3543 records)"},
        {value: "8231", name: "8231 - LIBRARIES-PUBLIC (28743 records)"},
        {value: "8243", name: "8243 - COMPUTER TRAINING (2666 records)"},
        {value: "8244", name: "8244 - SCHOOLS-BUSINESS & SECRETARIAL (2149 records)"},
        {value: "8249", name: "8249 - TELEVISION & RADIO BROADCASTING SCHOOLS (4088 records)"},
        {value: "8299", name: "8299 - AIRCRAFT SCHOOLS (70316 records)"},
        {value: "8310", name: "8310 - Forest Product Gathering General Crop Farm (7 records)"},
        {value: "8319", name: "8319 - Forest Product Gathering General Crop Farm (1 records)"},
        {value: "8322", name: "8322 - SOCIAL SERVICE & WELFARE ORGANIZATIONS (323368 records)"},
        {value: "8331", name: "8331 - EMPLOYMENT TRAINING SERVICE (30130 records)"},
        {value: "8351", name: "8351 - CHILD CARE SERVICE (127826 records)"},
        {value: "8361", name: "8361 - RESIDENTIAL CARE HOMES (46730 records)"},
        {value: "8399", name: "8399 - ALCOHOLISM INFORMATION & TREATMENT CTRS (92855 records)"},
        {value: "8412", name: "8412 - NATIONAL MONUMENTS (27097 records)"},
        {value: "8422", name: "8422 - AQUARIUMS-PUBLIC (1775 records)"},
        {value: "8510", name: "8510 - Foresters-Consulting (100 records)"},
        {value: "8611", name: "8611 - ANCSA NATIVE CORPORATIONS (76037 records)"},
        {value: "8621", name: "8621 - PROFESSIONAL ORGANIZATIONS (3963 records)"},
        {value: "8631", name: "8631 - LABOR ORGANIZATIONS (15415 records)"},
        {value: "8641", name: "8641 - NATIVES SERVICE ORGANIZATIONS (69607 records)"},
        {value: "8651", name: "8651 - POLITICAL ORGANIZATIONS (4936 records)"},
        {value: "8661", name: "8661 - CHURCH ORGANIZATIONS (500852 records)"},
        {value: "8699", name: "8699 - ORGANIZATIONS (29175 records)"},
        {value: "8711", name: "8711 - ENGINEERS (82722 records)"},
        {value: "8712", name: "8712 - ARCHITECTS (53177 records)"},
        {value: "8713", name: "8713 - SURVEYORS-LAND (15858 records)"},
        {value: "8721", name: "8721 - ACCOUNTANTS (141890 records)"},
        {value: "8731", name: "8731 - ERGONOMICS (38389 records)"},
        {value: "8732", name: "8732 - ECONOMIC RESEARCH & ANALYSIS (16502 records)"},
        {value: "8733", name: "8733 - FOUNDATION-EDUC PHILANTHROPIC RESEARCH (16569 records)"},
        {value: "8734", name: "8734 - LABORATORIES-TESTING (11285 records)"},
        {value: "8741", name: "8741 - MANAGEMENT SERVICES (37836 records)"},
        {value: "8742", name: "8742 - BUSINESS MANAGEMENT CONSULTANTS (172978 records)"},
        {value: "8743", name: "8743 - LOBBYISTS (9723 records)"},
        {value: "8744", name: "8744 - FACILITIES MANAGEMENT (874 records)"},
        {value: "8748", name: "8748 - FIRE PROTECTION CONSULTANTS (102923 records)"},
        {value: "8999", name: "8999 - SERVICES NEC (105978 records)"},
        {value: "9111", name: "9111 - STATE GOVERNMENT-EXECUTIVE OFFICES (17569 records)"},
        {value: "9120", name: "9120 - Finfish Catching (4 records)"},
        {value: "9121", name: "9121 - GOVERNMENT OFFICES-CITY, VILLAGE & TWP (242562 records)"},
        {value: "9130", name: "9130 - Research Of Horseshoe Crabs (3 records)"},
        {value: "9199", name: "9199 - GOVERNMENT OFFICES-NATIVE AMERICAN (17020 records)"},
        {value: "9210", name: "9210 - Fish Htchy,Preserve (16 records)"},
        {value: "9211", name: "9211 - STATE GOVERNMENT-COURTS (16866 records)"},
        {value: "9221", name: "9221 - POLICE DEPARTMENTS (36751 records)"},
        {value: "9222", name: "9222 - STATE GOVERNMENT-LEGAL COUNSEL (5819 records)"},
        {value: "9223", name: "9223 - STATE GOVT-CORRECTIONAL INSTITUTIONS (5130 records)"},
        {value: "9224", name: "9224 - FIRE DEPARTMENTS (50512 records)"},
        {value: "9229", name: "9229 - CIVIL DEFENSE AGENCIES (3928 records)"},
        {value: "9311", name: "9311 - CITY GOVERNMENT-FINANCE & TAXATION (14745 records)"},
        {value: "9411", name: "9411 - STATE GOVERNMENT-EDUCATION PROGRAMS (1586 records)"},
        {value: "9431", name: "9431 - STATE GOVERNMENT-PUBLIC HEALTH PROGRAMS (7559 records)"},
        {value: "9441", name: "9441 - STATE GOVERNMENT-SOCIAL/HUMAN RESOURCES (13881 records)"},
        {value: "9451", name: "9451 - COUNTY GOVERNMENT-VETERANS AFFAIRS ADMIN (1112 records)"},
        {value: "9511", name: "9511 - STATE GOVERNMENT-ENVIRONMENTAL PROGRAMS (5142 records)"},
        {value: "9512", name: "9512 - STATE GOVERNMENT-CONSERVATION DEPTS (9820 records)"},
        {value: "9531", name: "9531 - FEDERAL GOVERNMENT-HOUSING PROGRAMS (493 records)"},
        {value: "9532", name: "9532 - CITY GOVERNMENT-URBAN PLANNING & DEV (4400 records)"},
        {value: "9611", name: "9611 - CONSUMER PROTECTION AGENCIES (3184 records)"},
        {value: "9621", name: "9621 - FEDERAL GOVERNMENT-TRANSPORTATION PRGRMS (16190 records)"},
        {value: "9631", name: "9631 - CITY GOVT-REGULATION/ADM-COMMS/UTILITIES (5984 records)"},
        {value: "9641", name: "9641 - FEDERAL GOVERNMENT-AGRICULTURAL PROGRAMS (2515 records)"},
        {value: "9651", name: "9651 - STATE GOVERNMENT-LICENSING & INSPECTION (3162 records)"},
        {value: "9661", name: "9661 - STATE GOVT-SPACE RESEARCH & TECHNOLOGY (42 records)"},
        {value: "9710", name: "9710 - Hunting & Fishing Preserves (121 records)"},
        {value: "9711", name: "9711 - FEDERAL GOVERNMENT-NATIONAL SECURITY (15372 records)"},
        {value: "9721", name: "9721 - FEDERAL GOVERNMENT-INTERNATIONAL AFFAIRS (2603 records)"},
        {value: "9999", name: "9999 - NONCLASSIFIED ESTABLISHMENTS (1077306 records)"},
      ],
    },
    employees: {
      name: "Number of employees",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, value: "1 to 4", name: "1 to 4", query: [`(employees >= 0) and (employees <= 4)`]},
        {index: 1, value: "5 to 9", name: "5 to 9", query: [`(employees >= 5) and (employees <= 9)`]},
        {index: 2, value: "10 to 19", name: "10 to 19", query: [`(employees >= 10) and (employees <= 19)`]},
        {index: 3, value: "20 to 49", name: "20 to 49", query: [`(employees >= 20) and (employees <= 49)`]},
        {index: 4, value: "50 to 99", name: "50 to 99", query: [`(employees >= 50) and (employees <= 99)`]},
        {index: 5, value: "100 to 249", name: "100 to 249", query: [`(employees >= 100) and (employees <= 249)`]},
        {index: 6, value: "250 to 499", name: "250 to 499", query: [`(employees >= 250) and (employees <= 499)`]},
        {index: 7, value: "500 to 999", name: "500 to 999", query: [`(employees >= 500) and (employees <= 999)`]},
        {index: 8, value: "1,000 to 4,999", name: "1,000 to 4,999", query: [`(employees >= 1000) and (employees <= 4999)`]},
        {index: 9, value: "5,000 to 9,999", name: "5,000 to 9,999", query: [`(employees >= 5000) and (employees <= 9999)`]},
      ]
    },
    revenue: {
      name: "Sales volume",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, query: [`(revenue<500000)`], value: "A", name: "LESS THAN $500,000"},
        {index: 1, query: [`(revenue>=500000) and (revenue<=1000000)`], value: "B", name: "$500,000 TO $1 MILLION"},
        {index: 2, query: [`(revenue>1000000) and (revenue<=2500000)`], value: "C", name: "$1 TO 2.5 MILLION"},
        {index: 3, query: [`(revenue>2500000) and (revenue<=5000000)`], value: "D", name: "$2.5 TO 5 MILLION"},
        {index: 4, query: [`(revenue>5000000) and (revenue<=10000000)`], value: "E", name: "$5 TO 10 MILLION"},
        {index: 5, query: [`(revenue>1000000) and (revenue<=20000000)`], value: "F", name: "$10 TO 20 MILLION"},
        {index: 6, query: [`(revenue>2000000) and (revenue<=50000000)`], value: "G", name: "$20 TO 50 MILLION"},
        {index: 7, query: [`(revenue>50000000) and (revenue<=100000000)`], value: "H", name: "$50 TO 100 MILLION"},
        {index: 8, query: [`(revenue>100000000) and (revenue<=500000000)`], value: "I", name: "$100 TO 500 MILLION"},
        {index: 9, query: [`(revenue>500000000) and (revenue<=1000000000)`], value: "J", name: "$500 MILLION TO $1 BILLION"},
        {index: 10, query: [`(revenue>1000000000)`], value: "K", name: "Over $1 BILLION"},
      ]
    },
    website: {
      name: "Web address",
      displayHeader: true,
      inputType: "select",
      formText: "The website address may be incomplete. Selecting businesses without a web address does not guarantee that the business will not have a web address.",
      items: [
        {index: 0, value: "", name: "No preference"},
        {index: 1, query: `website <>"")`, value: "WWA", name: "Businesses with web addresses"},
        {index: 2, query: ['(website="")'], value: "WOWA", name: "Businesses without web addresses"}
      ]
    },
    title : {
      name: "Title",
      displayHeader: true,
      inputType: "search",
      formText: "We do a cursory search on titles to match them up to specific types of positions.  Please double check the preview.",
      items: [
        {value: "owner", name: "Owner", query: [`(title like %owner%)`]},
        {value: "ceo", name: "CEO", query: [`(title like ceo)`]},
        {value: "founder", name: "Founder", query: [`(title like founder)`]},
        {value: "Partner", name: "Partner", query: [`(title like partner)`]},
        {value: "manager", name: "Manager", query: [`(title like manager)`]},
        {value: "director", name: "Director", query: [`(title like director)`]},
        {value: "sales", query: [`(title like sales)`], name: "Sales"},
        {value: "technical", query: [`(title like techn%)`, `(title like information)`, `(title like engineer%)`]},
        {value: "president", name: "President", query: [`(title like president)`]},
        {value: "officer", query: [`(title like officer)`, `(title like executive)`], name: "Executives and officers"},
        {value: "marketing", name: "Marketing", query: [`(title like marketing)`]},
        {value: "legal", query: [`(title like %counsel%)`, `(title like %legal%)`, `(title like attorney)`]},
        {value: "education", query: [`(title like educat%)`, `(title like teacher)`, `(title like learning)`, `(title like professor)`, `(title like counselor)`, `(title like alumni)`, `(title like dean)`, `(title like student%)`], name: "Learning and education", meta: "Includes teachers, professors, deans, and administrators"},
        {value: "accounting", query: [`(title like accounting)`,`(title like accountant)`,`(title like controller)`,`(title like financ%)`,`(title like cfo)`,`(title like treasur%)`], name: "Finance and accounting (all)", meta: "Includes acccounting, finance, controllers, and treasurers"},
        {value: "hr", query: [`(title like %human)`,`(title like recruit%)`,`(title like personnel)`], name: "Human resources", meta: "Includes human resources, recruiting, and personnel"},
        {value: "medical", query: [`(title like medical)`,`(title like doctor)`,`(title like nurs%)`,`(title like physician)`,`(title like surge%)`], name: "Medical professionals and staff", meta: "Includes doctors, surgeons, nurses, and physicians"},
        {value: "finance", query: [`(title like financ%)`], name: "Finance"}
      ]
    },
    county: {
      name: 'Counties',
      displayHeader: true,
      items: []
    }, 
    city: {
      name: 'Cities',
      displayHeader: true,
      items: []
    }, 
    zip: {
      name: 'Zip codes',
      displayHeader: true,
      items: []
    }, 
    state: {
      name: 'States',
      displayHeader: true,
      items: [
        {value: "AK", name: "Alaska"},
        {value: "AL", name: "Alabama"},
        {value: "AR", name: "Arkansas"},
        {value: "AZ", name: "Arizona"},
        {value: "CA", name: "California"},
        {value: "CO", name: "Colorado"},
        {value: "CT", name: "Connecticut"},
        {value: "DC", name: "District"},
        {value: "DE", name: "Delaware"},
        {value: "FL", name: "Florida"},
        {value: "GA", name: "Georgia"},
        {value: "HI", name: "Hawaii"},
        {value: "IA", name: "Iowa"},
        {value: "ID", name: "Idaho"},
        {value: "IL", name: "Illinois"},
        {value: "IN", name: "Indiana"},
        {value: "KS", name: "Kansas"},
        {value: "KY", name: "Kentucky"},
        {value: "LA", name: "Louisiana"},
        {value: "MA", name: "Massachusetts"},
        {value: "MD", name: "Maryland"},
        {value: "ME", name: "Maine"},
        {value: "MI", name: "Michigan"},
        {value: "MN", name: "Minnesota"},
        {value: "MO", name: "Missouri"},
        {value: "MS", name: "Mississippi"},
        {value: "MT", name: "Montana"},
        {value: "NC", name: "North Carolina"},
        {value: "ND", name: "North Dakota"},
        {value: "NE", name: "Nebraska"},
        {value: "NH", name: "New Hampshire"},
        {value: "NJ", name: "New Jersey"},
        {value: "NM", name: "New Mexico"},
        {value: "NV", name: "Nevada"},
        {value: "NY", name: "New York"},
        {value: "OH", name: "Ohio"},
        {value: "OK", name: "Oklahoma"},
        {value: "OR", name: "Oregon"},
        {value: "PA", name: "Pennsylvania"},
        {value: "RI", name: "Rhode Island"},
        {value: "SC", name: "South Carolina"},
        {value: "SD", name: "South Dakota"},
        {value: "TN", name: "Tennessee"},
        {value: "TX", name: "Texas"},
        {value: "UT", name: "Utah"},
        {value: "VA", name: "Virginia"},
        {value: "VT", name: "Vermont"},
        {value: "WA", name: "Washington"},
        {value: "WI", name: "Wisconsin"},
        {value: "WV", name: "West Virginia"},
        {value: "WY", name: "Wyoming"},
      ]
    },
    nationwide: {
      name: 'Nationwide',
      displayHeader: false,
      items: [],
    }
  },
  defaultSearchParams: {
    email: [{value: "", name: "Yes"}],
    sic: [],
    employees: [], 
    revenue: [],
    website: [],
    title: [],
  },
  previewCols: {
    display: [
      {label: "Company name", content: "company"},
      {label: "First name", content: "first_name"},
      {label: "Last name", content: "last_name"},
      {label: "Title", content: "title"},
      {label: "Email", content: "email"},
    ],
    popover: [
      {label: "Company name", content: "company"},
      {label: "Address", content: "address"},
      {label: "City", content: "city"},
      {label: "State", content: "state"},
      {label: "Zip", content: "zip"},
      {label: "Email", content: "email"},
      {label: "Website", content: "website"}
    ]
  },
  dlCols: [
    {value: "company", name: "Business name", default: true},
    {value: "email", name: "Email", default: true},
    {value: "first_name", name: "First name", default: true},
    {value: "last_name", name: "Last name", default: true},
    {value: "title", name: "Title", default: true},
    {value: "address", name: "Address", default: true},
    {value: "city", name: "City", default: true},
    {value: "state", name: "State", default: true},
    {value: "zip", name: "Zip", default: true},
    {value: "county", name: "County", default: true},
    {value: "revenue", name: "Sales volume", default: true},
    {value: "employees", name: "Number of employees", default: true},
    {value: "website", name: "Web address (where available)", default: true},
  ],
  selectFilterValidation: ["revenue", "employees"],
  leadsMultiplier: 1,
  access: "Any subscription"
}