import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "react-bootstrap";
import SearchNSuggest from "./searchNSuggest.jsx";

class Geographies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      suggestions: []
    };
  }
  render() {
    let searchBy = this.props.geoSearchBy;
    const { selectSearchBy, searchTypesDict } = this.props;
    const searchTypes = Object.keys(searchTypesDict);
    let showSearch = searchBy !== "nationwide" && searchBy !== null;
    return (
      <Row>
        <Col xs={12} className="text-left">
          <h3>Select your desired areas.</h3>
          <p className="mb-3" style={{ fontSize: ".8em" }}>
            Search up to one (1) state or five (5) zip codes, cities, counties,
            or area codes at once.
          </p>
        </Col>
        {showSearch && (
          <Col xs={12} className="text-left">
            <SearchNSuggest
              searchType={this.props.searchType}
              searchBy={this.props.geoSearchBy}
              selectSearchBy={this.props.selectSearchBy}
              addQueryParam={this.props.addQueryParam}
              searchTypesDict={this.props.searchTypesDict}
              dictKey={this.props.dictKey}
              handleAlerts={this.props.handleAlerts}
            />
          </Col>
        )}
        {searchBy === null && (
          <Col xs={12} id="geoSType">
            <p className="text-left">How would you like to search?</p>
            <Row>
              {searchTypes.map(type => (
                <Col xs={6} key={"col" + type}>
                  <Button
                    onClick={e => selectSearchBy(e, type)}
                    variant={this.props.themeColor}
                    size="lg"
                    block
                  >
                    {searchTypesDict[type]["name"]}
                  </Button>
                </Col>
              ))}
            </Row>
          </Col>
        )}
        {searchBy === "nationwide" && (
          <Col xs={12} className="text-left">
            <h4 className="text-info">You've selected a nationwide search</h4>
            <p className="text-muted">
              Nationwide searches can sometimes take a little while. Narrow your
              geography if you need to speed things up a bit!
            </p>
            <Button
              variant="link"
              size="sm"
              onClick={e => this.props.resetQuery(e)}
            >
              &lt; Narrow search
            </Button>
          </Col>
        )}
      </Row>
    );
  }
}

Geographies.propTypes = {
  user: PropTypes.object,
  searchType: PropTypes.string,
  geoSearchBy: PropTypes.string,
  selectSearchBy: PropTypes.func,
  addQueryParam: PropTypes.func,
  searchTypesDict: PropTypes.object,
  dictKey: PropTypes.string,
  resetQuery: PropTypes.func,
  themeColor: PropTypes.string
};

export default Geographies;
