import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HomeLs } from "./interfaceListShack.jsx";
import ResourcesLs from "./resourcesLs.jsx";

const RoutesLs = props => {
  let { user } = props;
  return (
    <div>
      <Route exact path="/">
        <Helmet>
          <title>Get Unlimited Sales Leads</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <HomeLs user={user} />
      </Route>
      <Route exact path="/home">
        <Helmet>
          <title>Get Unlimited Sales Leads</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <HomeLs user={null} />
      </Route>
      <Route path="/resources">
        <Helmet>
          <title>Resources</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ResourcesLs />
      </Route>
    </div>
  );
};

export default RoutesLs;
