import React from "react";
import PropTypes from "prop-types";
import * as jsPDF from "jspdf";
import { db, functions } from "./firebase.jsx";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  ButtonGroup
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      savedInvoices: [],
      showModal: false,
      docId: "",
      btCId: "",
      rAId: ""
    };
    this.fetchInvoices = this.fetchInvoices.bind(this);
    this.backfillInvoices = this.backfillInvoices.bind(this);
    this.listenToInvoices = this.listenToInvoices.bind(this);
  }

  // For converting epoch time stamps
  toDateTime(secs) {
    let t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

  // For returning the billing date
  getBillingDate(epoch_date) {
    return this.toDateTime(epoch_date).toLocaleDateString();
  }

  async backfillInvoices() {
    const getStripeInvoices = functions.httpsCallable("getStripeInvoices");

    let invoiceParams = {
      customer_id: this.props.customerDoc.customer_id,
      customerDocId: this.props.customerDocId,
      email: this.props.customerDoc.email
    };

    let invoices = await getStripeInvoices(invoiceParams);

    return await invoices.data;
  }

  async backFillBtInvoices(docId = null, cId = null) {
    console.log("Backfilling braintree invoices with params: ", docId, cId);
    let { customerDoc, customerDocId, apiKey } = this.props;
    const init = {
      method: "POST",
      headers: {
        authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json"
      },
      "Transfer-Encoding": "chunked",
      cache: "default",
      accept: "application/json",
      body: JSON.stringify({
        customerDocId: docId ? docId : customerDocId,
        customerId: cId ? cId : customerDoc.customer_id
      })
    };
    try {
      await fetch(
        `${process.env.REACT_APP_api_url}/api/v2/transactions/write/${
          docId ? docId : customerDocId
        }/${cId ? cId : customerDoc.customer_id}`,
        init
      );
      //let transaction = await response.json();
      //console.log("subscription: ", subscription);
      //await this.propogateTransaction(transaction, customeDocId);
      //return await console.log("transaction: ", transaction);
    } catch (err) {
      console.error(
        "Something went wrong backfilling braintree invoices: ",
        err
      );
      //this.props.handleAlerts("", `${customerDoc.stripe_errors} Please try again.`, "danger", "Payment failed")
      //this.setState({
      //  isSubscribing: false,
      //  retrySubscribe: true,
      //});
    }
  }

  async fetchInvoices() {
    try {
      await this.backFillRecurlyInvoices();
      await this.listenToInvoices();
    } catch (err) {
      console.error("Error fetching saved invoices: ", err.message);
      this.setState({
        isLoaded: true
        //Let the user know something went wrong
      });
    }
  }

  async listenToInvoices() {
    // Get data from firestore
    // Braintree Shaped liked this:
    // {
    //     "id": "in_1Fbcy0BnFLyVHyf9JOQr9WKv",
    //     "object": "invoice",
    //     "account_country": "US",
    //     "account_name": "List Store",
    //     "amount_due": 5000,
    //     "amount_paid": 5000,
    //     "amount_remaining": 0,
    //     "application_fee_amount": null,
    //     "attempt_count": 1,
    //     "attempted": true,
    //     "auto_advance": false,
    //     "billing_reason": "subscription_create",
    //     "charge": "ch_1Fbcy0BnFLyVHyf9B4cVKGq4",
    //     "collection_method": "charge_automatically",
    //     "created": 1573002348,
    //     "currency": "usd",
    //     "custom_fields": null,
    //     "customer": "cus_GJmA0HtaYa5XeJ",
    //     "customer_address": null,
    //     "customer_email": "trywithnewplanids@please.com",
    //     "customer_name": null,
    //     "customer_phone": null,
    //     "customer_shipping": null,
    //     "customer_tax_exempt": "none",
    //     "customer_tax_ids": [],
    //     "default_payment_method": null,
    //     "default_source": null,
    //     "default_tax_rates": [],
    //     "description": null,
    //     "discount": null,
    //     "due_date": null,
    //     "ending_balance": 0,
    //     "footer": null,
    //     "hosted_invoice_url": "https://pay.stripe.com/invoice/invst_NsjNvi2QNro2nVsXZi06yT8IPc",
    //     "invoice_pdf": "https://pay.stripe.com/invoice/invst_NsjNvi2QNro2nVsXZi06yT8IPc/pdf",
    //     "lines": {
    //       "data": [
    //         {
    //           "id": "il_tmp_f5b097f5d62b3a",
    //           "object": "line_item",
    //           "amount": 5000,
    //           "currency": "usd",
    //           "description": "1 × Individual Unlimited (at $50.00 / month)",
    //           "discountable": true,
    //           "livemode": false,
    //           "metadata": {},
    //           "period": {
    //             "end": 1578294953,
    //             "start": 1578294953
    //           },
    //           "plan": {
    //             "id": "plan_G7e8QGY8f5qJYl",
    //             "object": "plan",
    //             "active": true,
    //             "aggregate_usage": null,
    //             "amount": 5000,
    //             "amount_decimal": "5000",
    //             "billing_scheme": "per_unit",
    //             "created": 1572948023,
    //             "currency": "usd",
    //             "interval": "month",
    //             "interval_count": 1,
    //             "livemode": false,
    //             "metadata": {},
    //             "nickname": "Ind_unlimited",
    //             "product": "prod_G7e7Lqj6AFYvr7",
    //             "tiers": null,
    //             "tiers_mode": null,
    //             "transform_usage": null,
    //             "trial_period_days": null,
    //             "usage_type": "licensed"
    //           },
    //           "proration": false,
    //           "quantity": 1,
    //           "subscription": "sub_GJDTd47Uqzxp2A",
    //           "subscription_item": "si_GJDTRgeUcM1mYr",
    //           "tax_amounts": [],
    //           "tax_rates": [],
    //           "type": "subscription"
    //         }
    //       ],
    //       "has_more": false,
    //       "object": "list",
    //       "url": "/v1/invoices/in_1Fbcy0BnFLyVHyf9JOQr9WKv/lines"
    //     },
    //     "livemode": false,
    //     "metadata": {},
    //     "next_payment_attempt": null,
    //     "number": "7858E7E0-0001",
    //     "paid": true,
    //     "payment_intent": "pi_1Fbcy0BnFLyVHyf90jC3tkII",
    //     "period_end": 1573002348,
    //     "period_start": 1573002348,
    //     "post_payment_credit_notes_amount": 0,
    //     "pre_payment_credit_notes_amount": 0,
    //     "receipt_number": null,
    //     "starting_balance": 0,
    //     "statement_descriptor": null,
    //     "status": "paid",
    //     "status_transitions": {
    //       "finalized_at": 1573002348,
    //       "marked_uncollectible_at": null,
    //       "paid_at": 1573002349,
    //       "voided_at": null
    //     },
    //     "subscription": "sub_G7sjsrMeXxBH8S",
    //     "subtotal": 5000,
    //     "tax": null,
    //     "tax_percent": null,
    //     "total": 5000,
    //     "total_tax_amounts": [],
    //     "webhooks_delivered_at": 1573002350
    //  }
    // Recurly Invoices Shaped like This:
    //  {
    //  "id": "o9d00wklo3fd",
    //  "object": "invoice",
    //  "type": "charge",
    //  "origin": "renewal",
    //  "state": "paid",
    //  "account": {
    //    "id": "o37rfnpww9d5",
    //    "object": "account",
    //    "code": "OswX8PBODwgrfHqRN9DPIuI9nqU2",
    //    "email": "newuser9@test.com",
    //    "firstName": null,
    //    "lastName": null,
    //    "company": null,
    //    "parentAccountId": null,
    //    "billTo": "self"
    //  },
    //  "billingInfoId": null,
    //  "subscriptionIds": [
    //    "o37rra3vt0lb"
    //  ],
    //  "previousInvoiceId": null,
    //  "number": "1028",
    //  "collectionMethod": "automatic",
    //  "poNumber": "",
    //  "netTerms": 0,
    //  "address": {
    //    "nameOnAccount": null,
    //    "firstName": "Wyatt",
    //    "lastName": "Grantham",
    //    "company": "",
    //    "street1": "1391 E 400 N",
    //    "street2": null,
    //    "city": "Provo",
    //    "region": "UT",
    //    "postalCode": "84606",
    //    "country": null
    //  },
    //  "currency": "USD",
    //  "balance": 0,
    //  "paid": 50,
    //  "total": 50,
    //  "subtotal": 50,
    //  "refundableAmount": 50,
    //  "discount": 0,
    //  "tax": 0,
    //  "taxInfo": null,
    //  "vatNumber": null,
    //  "vatReverseChargeNotes": null,
    //  "termsAndConditions": null,
    //  "customerNotes": null,
    //  "lineItems": {
    //    "object": "list",
    //    "hasMore": false,
    //    "next": null,
    //    "data": [
    //      {
    //        "id": "o9d00wkobc8k",
    //        "object": "line_item",
    //        "uuid": "589ef47ffebdc6f7ce76ef44a6957a3a",
    //        "type": "charge",
    //        "state": "invoiced",
    //        "legacyCategory": null,
    //        "account": {
    //          "id": "o37rfnpww9d5",
    //          "object": "account",
    //          "code": "OswX8PBODwgrfHqRN9DPIuI9nqU2",
    //          "email": "newuser9@test.com",
    //          "firstName": null,
    //          "lastName": null,
    //          "company": null,
    //          "parentAccountId": null,
    //          "billTo": "self"
    //        },
    //        "subscriptionId": "o37rra3vt0lb",
    //        "planId": "nzltbn662f8b",
    //        "planCode": "ind_unlimited",
    //        "addOnId": null,
    //        "addOnCode": null,
    //        "invoiceId": "o9d00wklo3fd",
    //        "invoiceNumber": "1028",
    //        "previousLineItemId": null,
    //        "originalLineItemInvoiceId": null,
    //        "origin": "plan",
    //        "accountingCode": "ind_unlimited",
    //        "productCode": "ind_unlimited",
    //        "creditReasonCode": null,
    //        "currency": "USD",
    //        "amount": 50,
    //        "description": "ind_unlimited",
    //        "quantity": 1,
    //        "unitAmount": 50,
    //        "subtotal": 50,
    //        "discount": 0,
    //        "tax": 0,
    //        "taxable": true,
    //        "taxExempt": false,
    //        "taxCode": null,
    //        "taxInfo": null,
    //        "prorationRate": null,
    //        "refund": false,
    //        "refundedQuantity": null,
    //        "creditApplied": null,
    //        "shippingAddress": null,
    //        "itemCode": null,
    //        "itemId": null,
    //        "externalSku": null,
    //        "revenueScheduleType": "evenly",
    //        "startDate": "2021-01-23T16:52:22.000Z",
    //        "endDate": "2021-02-23T16:52:22.000Z",
    //        "createdAt": "2021-01-23T16:52:25.000Z",
    //        "updatedAt": "2021-01-23T16:52:25.000Z"
    //      }
    //    ]
    //  },
    //  "transactions": [
    //    {
    //      "id": "o9d07kc98d1f",
    //      "object": "transaction",
    //      "uuid": "589ef5734238545818de924db29f85b8",
    //      "account": {
    //        "id": "o37rfnpww9d5",
    //        "object": "account",
    //        "code": "OswX8PBODwgrfHqRN9DPIuI9nqU2",
    //        "email": "newuser9@test.com",
    //        "firstName": null,
    //        "lastName": null,
    //        "company": null,
    //        "parentAccountId": null,
    //        "billTo": "self"
    //      },
    //      "invoice": {
    //        "id": "o9d00wklo3fd",
    //        "object": "invoice",
    //        "number": "1028",
    //        "type": "charge",
    //        "state": "paid"
    //      },
    //      "voidedByInvoice": null,
    //      "subscriptionIds": [
    //        "o37rra3vt0lb"
    //      ],
    //      "originalTransactionId": null,
    //      "type": "purchase",
    //      "origin": "recurring",
    //      "currency": "USD",
    //      "amount": 50,
    //      "status": "success",
    //      "success": true,
    //      "refunded": false,
    //      "billingAddress": {
    //        "street1": "1391 E 400 N",
    //        "street2": null,
    //        "city": "Provo",
    //        "region": "UT",
    //        "postalCode": "84606",
    //        "country": null,
    //        "firstName": "Wyatt",
    //        "lastName": "Grantham",
    //        "phone": null
    //      },
    //      "collectionMethod": "automatic",
    //      "paymentMethod": {
    //        "object": "credit_card",
    //        "cardType": "Visa",
    //        "firstSix": "411111",
    //        "lastFour": "1111",
    //        "expMonth": 4,
    //        "expYear": 2024
    //      },
    //      "ipAddressV4": null,
    //      "ipAddressCountry": null,
    //      "statusCode": null,
    //      "statusMessage": null,
    //      "customerMessage": null,
    //      "customerMessageLocale": "en",
    //      "paymentGateway": {
    //        "id": null,
    //        "object": "payment_gateway",
    //        "type": "test",
    //        "name": "Test Gateway"
    //      },
    //      "gatewayMessage": "Successful test transaction",
    //      "gatewayReference": "9699069",
    //      "gatewayApprovalCode": null,
    //      "gatewayResponseCode": null,
    //      "gatewayResponseTime": 0.001,
    //      "gatewayResponseValues": {
    //        "transaction_type": null
    //      },
    //      "cvvCheck": null,
    //      "avsCheck": "D",
    //      "createdAt": "2021-01-23T16:53:28.000Z",
    //      "updatedAt": "2021-01-23T16:53:28.000Z",
    //      "voidedAt": null,
    //      "collectedAt": "2021-01-23T16:53:28.000Z"
    //    }
    //  ],
    //  "creditPayments": [],
    //  "shippingAddress": null,
    //  "createdAt": "2021-01-23T16:52:25.000Z",
    //  "updatedAt": "2021-01-23T16:53:28.000Z",
    //  "dueAt": "2021-01-23T16:52:25.000Z",
    //  "closedAt": "2021-01-23T16:53:28.000Z"
    //}
    const customerDocId = this.props.customerDocId;
    const docsRef = db
      .collection("customers")
      .doc(customerDocId)
      .collection("invoices");
    await docsRef.onSnapshot(async querySnapshot => {
      let fetchedDocs = [];
      querySnapshot.forEach(doc => {
        //console.log(doc.id, ": ", doc.data());
        fetchedDocs.push(doc.data());
      });
      let sortedDocs = fetchedDocs.sort((a, b) => {
        let aV = a.created ? a.created : Date.parse(a.createdAt);
        let bV = b.created ? b.created : Date.parse(b.createdAt);
        return aV - bV;
      });

      await this.setState({
        savedInvoices: sortedDocs,
        isLoaded: true
      });
    });
  }

  createInvoicePdf(invoice) {
    let doc = new jsPDF({
      format: "letter"
    });
    const blue = "2C2C52";
    const gray = "7D8DA0";
    const tMargin = 30;
    const lMargin = 20;
    const rMargin = 216 - lMargin;
    function lh(line) {
      return line * 5 + tMargin;
    }
    const rText = 150;

    doc.setFont("Helvetica");
    doc.setFontSize(26);
    doc.setTextColor(blue);
    doc.text("List Shack", lMargin, tMargin);
    doc.setFontSize(22);
    doc.setTextColor(gray);
    doc.text("Invoice", 170, tMargin);
    doc.setFontSize(10);
    doc.text("+1 833-222-7919", lMargin, lh(2));
    doc.text(
      `Invoice number:   ${invoice.number ? invoice.number : invoice.id}`,
      rText,
      lh(2)
    );
    doc.text("help@listshack.support", 20, lh(3));
    doc.text(
      `Date of issue:         ${new Date(
        Date.parse(invoice.createdAt)
      ).toLocaleDateString()}`,
      rText,
      lh(3)
    );
    doc.text(
      `Date due:               ${new Date(
        Date.parse(invoice.createdAt)
      ).toLocaleDateString()}`,
      rText,
      lh(4)
    );

    doc.setFontType("bold");
    doc.text(`Bill to`, 20, lh(7));
    doc.setFontType("");
    doc.text(`Account id: ${this.props.customerDoc.uid}`, 20, lh(8));
    doc.text(`Account email: ${this.props.customerDoc.email}`, 20, lh(9));
    doc.setFontSize(22);
    doc.setTextColor(blue);
    doc.text(
      `$${invoice.amount} due ${new Date(
        Date.parse(invoice.createdAt)
      ).toDateString()}`,
      20,
      lh(13)
    );

    doc.setFontSize(10);
    doc.setFontType("bold");
    doc.text("Description", lMargin + 2, lh(16));
    doc.text("Qty", lMargin + 60, lh(16));
    doc.text("Unit price", lMargin + 70, lh(16));
    doc.text("Amount", rMargin - 17, lh(16));

    doc.setDrawColor(gray);
    doc.line(lMargin - 3, lh(17), rMargin + 3, lh(17));

    doc.setFontType("");
    doc.text(
      `${formatBillingDate(
        invoice.subscription.billingPeriodStartDate
      )} - ${formatBillingDate(invoice.subscription.billingPeriodEndDate)}`,
      lMargin + 2,
      lh(18)
    );

    doc.text(
      `${
        Object.values(this.props.plans).find(
          plan => plan.planID.toLowerCase() === invoice.planId.toLowerCase()
        ).name
      }`,
      lMargin + 2,
      lh(20)
    );
    doc.text("1", lMargin + 60, lh(20));
    doc.text(`$${invoice.amount}`, lMargin + 70, lh(20));
    doc.text(`$${invoice.amount}`, rMargin - 17, lh(20));

    doc.line(lMargin - 3, lh(21), rMargin + 3, lh(21));

    doc.text(`Subtotal`, lMargin + 70, lh(22));
    doc.text(`$${invoice.amount}`, rMargin - 17, lh(22));

    doc.line(lMargin + 65, lh(23), rMargin + 3, lh(23));

    doc.setFontType("bold");
    doc.text(`Amount due`, lMargin + 70, lh(24));
    doc.text(`$${invoice.amount}`, rMargin - 17, lh(24));

    doc.setFontType("");
    doc.line(lMargin - 3, lh(46), rMargin + 3, lh(46));

    doc.text(
      `Questions? Contact List Shack at help@listshack.support.`,
      20,
      lh(48)
    );
    doc.text(`${invoice.id} - Page 1 of 1`, rMargin - 30, lh(48));

    doc.save(`Invoice-${invoice.id}.pdf`);
  }

  async backFillRecurlyInvoices(docId = null, accountId = null) {
    console.log("Backfilling recurly invoices with params: ", docId, accountId);
    let { customerDoc, customerDocId, apiKey } = this.props;
    const init = {
      method: "POST",
      headers: {
        authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json"
      },
      "Transfer-Encoding": "chunked",
      cache: "default",
      accept: "application/json",
      body: JSON.stringify({
        customerDocId: docId ? docId : customerDocId,
        customerId: accountId ? accountId : customerDoc.customer_id
      })
    };
    try {
      let response = await fetch(
        `${process.env.REACT_APP_api_url}/api/v2/recurly/invoices/write/${
          docId ? docId : customerDocId
        }/${accountId ? accountId : customerDoc.customer_id}`,
        init
      );
      let invoices = await response.json();
      console.log("invoices: ", invoices);
      //await this.propogateTransaction(transaction, customeDocId);
      return invoices;
    } catch (err) {
      console.error("Something went wrong backfilling recurly invoices: ", err);
      //this.props.handleAlerts("", `${customerDoc.stripe_errors} Please try again.`, "danger", "Payment failed")
      //this.setState({
      //  isSubscribing: false,
      //  retrySubscribe: true,
      //});
    }
  }

  render() {
    console.log(
      "invoices this.state: ",
      this.state,
      "customeDocId",
      this.props.customerDocId,
      this.props.customerDoc
    );
    let invoices = this.state.savedInvoices;
    let { userDoc } = this.props;
    let superAdmin = userDoc.superAdmin ? userDoc.superAdmin : false;
    let rows = [];
    for (let [i, invoice] of invoices.entries()) {
      //console.log(invoice)
      if (invoice.id.split("_")[1]) {
        rows.push(
          <tr key={i}>
            <td>{this.getBillingDate(invoice.created)}</td>
            <td>
              {this.getBillingDate(invoice.period_start)} -{" "}
              {this.getBillingDate(invoice.period_end)}
            </td>
            <td>${parseFloat(invoice.amount_due / 100)}</td>
            <td>${parseFloat(invoice.amount_paid / 100)}</td>
            <td>
              <a href={invoice.invoice_pdf} download>
                Download
              </a>
            </td>
          </tr>
        );
      } else {
        let sd = invoice.subscription.billingPeriodStartDate
          ? formatBillingDate(invoice.subscription.billingPeriodStartDate)
          : "";
        let ed = invoice.subscription.billingPeriodEndDate
          ? formatBillingDate(invoice.subscription.billingPeriodEndDate)
          : "";
        rows.push(
          <tr key={i}>
            <td>
              {new Date(Date.parse(invoice.createdAt)).toLocaleDateString()}
            </td>
            <td>{`${sd} - ${ed}`}</td>
            <td>${invoice.amount}</td>
            <td>${invoice.amount}</td>
            <td>
              {
                // eslint-disable-next-line
              }
              <a
                className="text-primary"
                type="button"
                onClick={() => {
                  this.createInvoicePdf(invoice);
                }}
              >
                Download
              </a>
            </td>
          </tr>
        );
      }
    }
    //console.log(rows);

    if (this.state.isLoaded) {
      return (
        <Container>
          <Row style={{ marginTop: "30px" }}>
            <Col xs={12} style={{ minHeight: "80vh" }}>
              <h1>Invoices</h1>

              <div className="pull-right">
                <Button
                  onClick={() =>
                    this.backFillRecurlyInvoices(
                      this.props.customerDocId,
                      this.props.customerDoc.customer_id
                    )
                  }
                  variant="outline-primary"
                  className="pull-right"
                >
                  Refresh invoices
                </Button>
                {superAdmin && (
                  <Button
                    variant="outline-danger"
                    onClick={() => this.setState({ showModal: true })}
                  >
                    SuperAdmin Invoice Refresh
                  </Button>
                )}
                <br />
                <br />
                <p className="text-muted">
                  <small>
                    Missing an invoice? Refresh invoices by clicking above.
                  </small>
                </p>
              </div>

              <Table>
                <tbody>
                  <tr>
                    <th>Date</th>
                    <th>Billing period</th>
                    <th>Amount due</th>
                    <th>Amound paid</th>
                    <th>PDF</th>
                  </tr>
                  {rows}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Modal
            size="md"
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
            centered
          >
            <Modal.Body>
              <Form.Group>
                <Form.Label>Customer Document ID (string)</Form.Label>
                <Form.Control
                  type="text"
                  value={this.state.docId}
                  onChange={e => this.setState({ docId: e.target.value })}
                  placeHolder="Customer Document Id"
                />
                <Form.Text>
                  Don't change this unless you are backfilling for another
                  account (not the current account you are impersonating)
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Braintree Customer Id</Form.Label>
                <Form.Control
                  type="text"
                  value={this.state.btCId}
                  onChange={e => this.setState({ btCId: e.target.value })}
                  placeHolder="Braintree Customer Id"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Recurly Account Id</Form.Label>
                <Form.Control
                  type="text"
                  value={this.state.rAId}
                  onChange={e => this.setState({ rAId: e.target.value })}
                  placeHolder="Recurly Account Id"
                />
              </Form.Group>
              <ButtonGroup>
                <Button
                  onClick={async () => {
                    if (!this.state.btCId) {
                      return window.alert(
                        "Please add a Braintree Customer Id."
                      );
                    }
                    try {
                      await this.backFillBtInvoices(
                        this.state.docId,
                        this.state.btCId
                      );
                      return window.alert("Backfilled Braintree Invoices.");
                    } catch (err) {
                      console.log("Error refreshing Braintree Invoices: ", err);
                      return window.alert(
                        "Error refreshing Braintree Invoices.  Check console."
                      );
                    }
                  }}
                  variant="success"
                >
                  Backfill Braintree Invoices
                </Button>
                <Button
                  onClick={async () => {
                    if (!this.state.btCId) {
                      return window.alert("Please add a Recurly Customer Id.");
                    }
                    try {
                      await this.backFillRecurlyInvoices(
                        this.state.docId,
                        this.state.rAId
                      );
                      return window.alert("Backfilled Recurly Invoices.");
                    } catch (err) {
                      console.log("Error refreshing Recurly Invoices: ", err);
                      return window.alert(
                        "Error refreshing Recurly Invoices.  Check console."
                      );
                    }
                  }}
                  variant="info"
                >
                  Backfill Recurly Invoices
                </Button>
              </ButtonGroup>
            </Modal.Body>
          </Modal>
        </Container>
      );
    } else {
      return (
        <Container>
          <Row style={{ marginTop: "30px" }}>
            <Col style={{ minHeight: "80vh" }} className="text-center">
              <Spinner animation="grow" variant="primary" size="lg" />
              <h2>Getting your Invoices. Hold tight!</h2>
            </Col>
          </Row>
        </Container>
      );
    }
  }

  componentDidMount() {
    this.fetchInvoices();
  }
}

Invoices.propTypes = {
  user: PropTypes.object,
  customerDoc: PropTypes.object,
  customerDocId: PropTypes.string,
  handleAlert: PropTypes.func
};

export default Invoices;

const formatBillingDate = dateString => {
  //console.log("dateString: ", dateString);
  let dateArray = dateString.split("-");
  //console.log("dateArray: ", dateArray);
  let formattedDate = `${dateArray[1]}/${dateArray[2].split("T")[0]}/${
    dateArray[0]
  }`;
  //console.log("formattedDate: ", formattedDate);
  return formattedDate;
};
