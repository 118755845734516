import { storage } from "./firebase.jsx";

//accepts an array of files and returns an array of urls where the documents are saved

const storeMedia = async (uid, newFilesArray, existingFilesArray = []) => {
  console.log("Storing media");
  let files = newFilesArray;
  var storageRef = storage.ref();
  let cUrls = existingFilesArray;
  let mediaUrls = [];
  let filei;
  let promises = [];

  async function someThing() {
    for (filei = 0; filei < files.length; filei++) {
      let file = files[filei];
      let mediaRef = storageRef.child(`downloads/${uid}/${file.name}`);
      await mediaRef
        .put(file)
        .then(snapshot => {
          console.info("Uploaded the file to: ", mediaRef);
          let y = mediaRef.getDownloadURL();
          promises.push(y);
        })
        .catch(error => {
          console.error("Error saving media: ", error);
        });
    }
  }

  await someThing();

  await Promise.all(promises).then(e => {
    mediaUrls = mediaUrls.concat(e);
    cUrls = cUrls.concat(mediaUrls);
  });

  return await cUrls;
};

export default storeMedia;
