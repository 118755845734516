import React from "react";
import {
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Card } from "react-bootstrap";
import AutoComplete from "./autoComplete.jsx";

class ResourcesLs extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/resources/:id">
          <Article />
        </Route>
        <Route exact path="/resources">
          <Redirect to="/resources/all" />
        </Route>
      </Switch>
    );
  }
}

export default ResourcesLs;

function Article() {
  let { url } = useRouteMatch();
  let { id } = useParams();
  let article = find(id);

  return (
    <React.Fragment>
      <Helmet>
        <title>{article.name}</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      <Container style={{ paddingBottom: "100px" }}>
        <Row>
          <Col xs={12} className="text-center">
            {article.id !== "all" && (
              <Link to="/resources/all" className="pull-left btn btn-link">
                &lt; Back to all articles
              </Link>
            )}
            <AutoComplete
              style={{
                margin: "0 auto",
                marginTop: "30px",
                marginBottom: "50px"
              }}
              className="col-xs-12 col-sm-8 col-md-6"
              suggestions={Articles}
              placeholder="Tips for telemarketers"
              helpText="Find helpful articles"
              path="/resources"
            />
            <h3>{article.name}</h3>
            <div
              className="help-content"
              style={{ margin: "40px 0px 150px 0px", textAlign: "left" }}
            >
              {article.content}
            </div>

            <Col xs={12}>
              {article.friends.map(id => (
                <Card
                  key={id}
                  style={{
                    margin: "0 auto",
                    marginBottom: "1rem",
                    border: "none",
                    borderBottom: "1px solid lightgray",
                    borderRadius: "0px"
                  }}
                  xs={12}
                >
                  <Card.Body className="text-left">
                    <Card.Title>{find(id).name}</Card.Title>
                    <Card.Text className="text-muted">
                      {find(id).excerpt}
                    </Card.Text>
                    <Link to={`/resources/${id}`} className="btn btn-info">
                      Read
                    </Link>
                  </Card.Body>
                </Card>
              ))}
            </Col>
          </Col>
        </Row>
        <Switch>
          <Route path={`${url}/:id`}>
            <Article />
          </Route>
        </Switch>
      </Container>
    </React.Fragment>
  );
}

// Articles is an array of objects.  While the number of articles is small
// it's intended to be static code.  If it grows, the articles can be easily
// moved to a firestore collection of documents.
const Articles = [
  {
    id: "all",
    name: `${process.env.REACT_APP_site_name} Resources`,
    friends: [
      "understanding-the-many-types-of-sales-leads",
      "10-tips-that-every-telemarketer-needs-to-know",
      "unlimited-business-leads-business-lists",
      "the-top-7-resources-for-marketing-leads",
      "tips-for-marketing-to-retirees-and-medicare-recipients",
      "how-to-get-turning-65-lists-with-list-shack",
      "listshack-com-customer-reviews",
      "insurance-lists-for-telemarketing-and-direct-mail",
      "how-to-find-the-right-list-broker",
      "how-to-get-affordable-roofing-leads",
      "how-accurate-are-marketing-lists",
      "how-to-find-targeted-direct-mail-lists",
      "finding-quality-homeowner-leads",
      "using-consumer-lists-for-telemarketing-or-direct-mail",
      "using-business-lists-for-telemarketing-and-direct-mail",
      "how-to-build-the-right-telemarketing-lists"
    ],
    content: (
      <React.Fragment>
        <p className="text-muted text-center">
          Stay up on the best ways to generate sales leads.
        </p>
      </React.Fragment>
    )
  },
  {
    id: "understanding-the-many-types-of-sales-leads",
    name: "Understanding the many types of sales leads",
    friends: [],
    excerpt:
      "If you’re in sales then you know leads are important, but what exactly is a lead?  For some folks it’s just a name and a number of someone to call, others expect a prospect that is ready to buy with checkbook in hand.  Whatever your definition is, it’s important to establish accurate terminology so if you’re shopping for leads you’re getting exactly what you’re expecting.",
    content: (
      <React.Fragment>
        <p>
          If you’re in sales then you know leads are important, but what exactly
          is a lead? For some folks it’s just a name and a number of someone to
          call, others expect a prospect that is ready to buy with checkbook in
          hand. Whatever your definition is, it’s important to establish
          accurate terminology so if you’re shopping for leads you’re getting
          exactly what you’re expecting.
        </p>
        <h5>Cold leads</h5>
        <p>
          These are sales leads that haven’t necessarily expressed interest in
          your product or service, but are a good fit. For some folks a
          directory of professionals can be a great source of cold leads, such
          as a mortgage broker that’s looking for real estate agents to network
          with. If that mortgage broker can reach through an association and get
          the contact info for those agents it’s definitely a lead, but they
          certainly haven’t already expressed an interest. Another awesome way
          to get cold leads is to just buy them from a site like{" "}
          {process.env.REACT_APP_site_name}. If you’re an insurance agent and
          looking for homeowners in your area to give quotes to, you can just go
          to {process.env.REACT_APP_site_name}, pay a small fee, and then pick
          your area and demographics then you’re off to the races.
        </p>
        <dl className="row">
          <dt className="col-sm-3">Advantages</dt>
          <dd className="col-sm-9">Generally very inexpensive and plentiful</dd>

          <dt className="col-sm-3">Disadvantages</dt>
          <dd className="col-sm-9">
            Do require more work and may be tougher to convert to sales
          </dd>
        </dl>
        <hr />
        <h5>Warm leads</h5>
        <p>
          These are leads that have expressed some level of interest in your
          good or services, but aren’t necessarily looking to make a purchase
          immediately. Examples of these might be survey cards taken at
          marketing events, prospects that have made inquiries in the past
          without making a buying decision, or people who have responded through
          more general marketing pieces/efforts. These also can be people that
          you’ve worked as a cold lead, but weren’t ready at the time.
        </p>
        <dl className="row">
          <dt className="col-sm-3">Advantages</dt>
          <dd className="col-sm-9">
            May be easier to convert than cold leads, still can be relatively
            easy to generate, and can convert well.
          </dd>

          <dt className="col-sm-3">Disadvantages</dt>
          <dd className="col-sm-9">
            Generally take effort and expense to generate. Availability may also
            be limited.
          </dd>
        </dl>
        <hr />
        <h5>Hot leads</h5>
        <p>
          These are people right in the decision making process that are more or
          less waiting with a checkbook. This is a recent inquiry at a website,
          someone at a booth wanting to talk about buying your product, or
          someone that has reached out to you from your marketing efforts.
        </p>
        <dl className="row">
          <dt className="col-sm-3">Advantages</dt>
          <dd className="col-sm-9">
            Convert at a high rate, short sales cycle, very motivated customers.
          </dd>

          <dt className="col-sm-3">Disadvantages</dt>
          <dd className="col-sm-9">
            Can be incredibly difficult and expensive to generate. Supply is
            generally also very limited.
          </dd>
        </dl>

        <p>
          Obviously best case scenario if you’re in sales is a stack of hot
          leads, but once you’ve worked through those you need ways of filling
          your funnel so building up a marketing plan that allows you to
          incorporate more plentiful cold leads or warm leads is the key to
          consistently hitting your sales goals.
        </p>
      </React.Fragment>
    )
  },
  {
    id: "10-tips-that-every-telemarketer-needs-to-know",
    name: "10 tips that every telemarketer needs to know",
    friends: [],
    excerpt:
      "So you’ve been looking for an inexpensive way to generate hot sales leads to help you hit your sales goals?  Great news!  Telemarketing is here for you!  So where do you start and how can you make this task easier?  Here are ten amazing telemarketing tips to help!",
    content: (
      <React.Fragment>
        <p>
          So you’ve been looking for an inexpensive way to generate hot sales
          leads to help you hit your sales goals? Great news! Telemarketing is
          here for you! So where do you start and how can you make this task
          easier? Here are ten amazing telemarketing tips to help!
        </p>
        <ol>
          <li>
            Be Specific. One of the most common mistakes people make when cold
            calling is being too generic with their offer. Let’s take a look at
            an insurance pitch for example. “Hey Ms. Jones, I’m calling you
            today to help with your insurance; would you like a free review of
            your insurance needs?” Maybe Ms. Jones is interested in that, but
            when you make it more specific you increase the odds of getting a
            positive reaction. What if instead the pitch was “Hey Ms. Jones.
            We’ve recently lowered rates in your area and I’d love to give you a
            quote to show you just how much you can save!” Now we’re onto
            something.
          </li>
          <li>
            Pitch the Gatekeeper: This is a lot more relevant to b2b
            telemarketing, but a lot of times people try to trick the gatekeeper
            vs using them as an ally. Especially if you’re calling a small
            business, it’s entirely possible that the person answering the phone
            is involved in the decision making process and/or knows exactly who
            you should be talking to. No reason to treat them with disrespect by
            trying to trick them, pitch them exactly what you’re looking for.
            For example, “Hi John, I’m calling today because we help businesses
            get fast approvals on loans. Is there someone in your office you
            think I should reach out to so we can find out if that would help
            your business?” Of course you are going to get a lot of rejection,
            but when you start with this approach now you have someone helping
            you get to the right person instead of leading with a trick.
          </li>
          <li>
            Accept Rejection: If your prospect already knows they won’t do
            business with you, why would you be upset at them saving you time?
            There are a lot of prospects out there and if you’re looking to grow
            your business you want to be focusing on the right type of customer,
            not just information gatherers that are listening to you “just to be
            nice”.{" "}
          </li>
          <li>
            Practice Practice Practice: When you’re making calls a lot of what
            you’re doing is listening to what the prospect is saying and how
            they’re saying it. If you can say your pitch cold and without
            thinking about it then it frees up your ability to really focus on
            the response you’re getting versus forcing yourself to focus on
            getting your pitch out right.
          </li>
          <li>
            Record Yourself: Some states require both parties on a call to
            consent to recording, but you can always record yourself. Simply
            putting a recording device on your desk while you make calls can
            give you unexpected insight into how you’re saying things and how
            it’s coming across. Simply listening to a few calls is well worth
            the effort.
          </li>
          <li>
            Choose the Right Audience: You might think you have an awesome
            product or service that can help everyone, but odds are you have a
            specific audience you’ll have a higher success rate with than just
            calling everyone you can get a phone number to, so give it some
            thoughtful consideration and look for prospects that you think you
            have the highest chance of being able to help so that way when you
            get a yes you’ll be able to convert it to a sale.
          </li>
          <li>
            Set Reasonable Goals: You don’t have any control over whether or not
            someone wants to hear your pitch, but you can control how many times
            you pick up the phone and dial as well as how much time you spend
            calling. Setting aside a specific amount of time each week with
            specific targets for goals within your control can help make an
            exhausting task feel less ambiguous. Don’t be afraid to start low
            and work yourself up to bigger goals.
          </li>
          <li>
            Refine Your Pitch: Obviously if something isn’t working then you
            don’t want to keep doing it, but give it at least 100 attempts
            before you make any drastic changes. If you can make 100 calls and
            not get anyone interested then you definitely have a problem. It
            might just be the audience, but odds are if your product or service
            is worth selling and you’re calling 100 people that are in your
            target audience without any traction, it’s time to change it up.
          </li>
          <li>
            Track Your Results: Something as simple as a spreadsheet can help
            you keep logs of specific people you contacted, what their needs
            are, when their renewals are coming up, or all sorts of helpful
            information. If you’re tracking your results you can identify how
            productive things are which can be really motivating; for example,
            if you know for every 100 calls you make you get five sales, that
            can give you a clear expectation of what your efforts are getting
            you.
          </li>
          <li>
            Relax: Telemarketing can be a lot of work and it involves taking a
            lot of rejection, but take it at your own pace and relax.
          </li>
        </ol>
        <p>Good luck and happy selling!</p>
      </React.Fragment>
    )
  },
  {
    id: "unlimited-business-leads-business-lists",
    name: "Unlimited Business Leads & Business Lists",
    friends: [],
    excerpt:
      "There are over 15 million businesses in the U.S.!  If you want to reach the ones that are most likely to become your customers then you should take advantage of our online portal that will let you instantly search for businesses in your area.  You can search by state, county, city, or zip code.  You can also search by type of industry so you can find customers with the type of needs that you can help them with.",
    content: (
      <React.Fragment>
        <h5>Business Leads and Business Lists</h5>
        <p>
          There are over 15 million businesses in the U.S.! If you want to reach
          the ones that are most likely to become your customers then you should
          take advantage of our online portal that will let you instantly search
          for businesses in your area. You can search by state, county, city, or
          zip code. You can also search by type of industry so you can find
          customers with the type of needs that you can help them with.
        </p>

        <h5>What information comes with this?</h5>
        <p>
          With our business lists you will receive the name of the business, the
          physical address of their location, phone number, a contact name,
          contact title, and the industry type all delivered in a CSV file. You
          can load the CSV file into anything you’d like including any popular
          dialer, CRM, or spreadsheet software.
        </p>

        <h5>What type of industries benefit from using our business lists?</h5>
        <p>
          Many kinds! We have credit card processors, insurance agents,
          commercial real estate agents, and many businesses you’ve never even
          heard of.
        </p>

        <h5>What’s the best way to use a business list like this?</h5>
        <p>
          Obviously these aren’t warm leads, but they’re a great way to build a
          pipeline full of interested prospects. The first step in working these
          business leads once you’ve downloaded them is good old fashioned
          dialing. Pick up the phone, call through the list, and make a quick
          pitch to whoever answers the phone to see if they can connect you with
          the right person. As you’re making calls you may find the right person
          at the right time to get a quick sale, but more importantly along the
          way you’ll be building relationships and collecting information. If
          you keep your information stored somewhere even as simple as a
          spreadsheet you can start enhancing the business list with contact
          info and details relevant to your sales process and that information
          will only become more valuable with time.
        </p>

        <h5>Won’t a lot of people say no?</h5>
        <p>
          Absolutely. That’s also probably a good thing. Realistically people
          have a filter and if they already know they’re never going to use your
          product, why wouldn’t you want them to tell you no? They’re saving you
          time and effort that you can use to find other prospects that are a
          better fit for what you’re offering. Just shake it off and keep moving
          through the list. With plans that start at $50/month and millions of
          leads to choose from, you’ll never have to give it a second thought.
        </p>
      </React.Fragment>
    )
  },
  {
    id: "the-top-7-resources-for-marketing-leads",
    name: "The Top 7 Sources for Marketing Leads",
    friends: [],
    excerpt:
      "Anyone who has done marketing at scale has heard these questions before: How did you get my name? Who gave you my number? How can I stop all the advertising? They are common questions from people who are frustrated with solicitations or, in some cases, are ornery by nature. Regardless of the attitude or tone used to ask the question, it deserves an answer. Despite the rumors, marketing data does not just appear from thin air.  Marketing data drives the economy. Businesses thrive or survive based on how effectively they are able to market their products and services to people or businesses that need them. Whether it's the timely introduction from a friend of a friend that can help, or the killer new device that all the friends and family are buying, without marketing there would be less cool stuff to make our lives better. By definition, you’ve heard of all the companies that effectively use marketing data that matter to you, and if any business is going to thrive, it must effectively source and use marketing data too. So where does the data come from? Here are seven common sources for marketing data.",
    content: (
      <React.Fragment>
        <div className="d-flex justify-content-center mb-5">
          <iframe
            title="Where does data come from"
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/ie0vVw3jlIw"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <h4>Introduction to marketing data</h4>
        <p>
          Anyone who has done marketing at scale has heard these questions
          before: How did you get my name? Who gave you my number? How can I
          stop all the advertising? They are common questions from people who
          are frustrated with solicitations or, in some cases, are ornery by
          nature. Regardless of the attitude or tone used to ask the question,
          it deserves an answer. Despite the rumors, marketing data does not
          just appear from thin air. Marketing data drives the economy.
          Businesses thrive or survive based on how effectively they are able to
          market their products and services to people or businesses that need
          them. Whether it's the timely introduction from a friend of a friend
          that can help, or the killer new device that all the friends and
          family are buying, without marketing there would be less cool stuff to
          make our lives better. By definition, you’ve heard of all the
          companies that effectively use marketing data that matter to you, and
          if any business is going to thrive, it must effectively source and use
          marketing data too. So where does the data come from? Here are seven
          common sources for marketing data.
        </p>

        <h4>Seven sources of marketing data</h4>
        <ul style={{ listStyle: "none" }}>
          <li className="h5">1. Businesses that you buy from</li>
          <p>
            Marketing data, whether it's the name and address of an individual
            consumer or a business primarily comes from you and the other people
            that buy things from a business. No matter what a business sells,
            word of mouth and referrals is usually the most effective source of
            marketing data. For this reason, businesses keep track of this data
            in a variety of ways, from the simple business cards and rolodexes
            to spreadsheets and complex customer relationship management
            software. The primary purpose of all tracking methods is to write
            down the names, contact information, and important information
            needed to effectively market products and services to people a
            business and its employees work with as customers or as prospective
            customers in the future for less expensive.
          </p>
          <li className="h5">2. Cooperative databases and social networks</li>
          <p>
            Second, successful companies will often share their data with other
            companies. Simplistically this started as friends exchanging
            rolodexes and has evolved from large cooperative databases or Coop
            data to online social networks. In exchange for letting someone else
            see your data you get access to their data and their relationships.
            Coop databases are very popular in industries that are largely
            relationship driven or niche industries where there maybe few
            influencers. Social networks like Facebook and LinkedIN have proven
            that sharing relationships is universally accepted as an effective
            way of getting access to marketing data.
          </p>
          <li className="h5">3. Licensed Data</li>
          <p>
            Third, some companies will license their data to other companies in
            exchange for licensing fees. Most credit bureaus and market research
            companies (think Experian, Acxiom, and Nielsen) will license data to
            third parties in exchange for fees, often selling data on a per name
            basis. Companies that have less comprehensive databases or companies
            in more targeted niche areas, like insurance, medicare, and real
            estate will often license their data to a data broker who will then
            resell the data for a profit after paying royalties to the data
            owners.
          </p>
          <li className="h5">4. Lead Generation</li>
          <p>
            Companies like BankRate or LendingTree for example, specialize in
            generating interest in certain types of products through online
            forms, telemarketing, direct mail, or social media and licensing
            data as their primary business model. These types of companies exist
            anywhere there is a known market for high demand products or
            services such as mortgages, higher education, and car dealerships.
            These types of companies will usually specialize in an industry
            where they can establish popular websites or repeatable
            telemarketing practices to consistently generate interest or offer
            value. LendingTree offers an easy way to “shop” mortgage rates just
            like Travelocity or Orbitz offers the ability shop hotel rooms or
            flights.
          </p>
        </ul>
        <h4>Important sources of compiled data</h4>
        <p>
          Several companies specialize in compiling or collecting data from
          public and proprietary sources and then license or sell the data for a
          profit. This type of data is often used for mass media advertising and
          marketing campaigns for products or services that have broad or
          universal appeal. This data is usually behind the marketing that you
          get in the mail for example or the telemarketing calls you try to
          ignore. There are three main types of compiled data including public
          records, online data, and telemarketing data.
        </p>
        <ul style={{ listStyle: "none" }}>
          <li className="h5">5. Compile Public data</li>
          <p>
            Public and government sources are some of the most obvious sources
            for compiling marketing data about businesses and consumers, and
            there are a variety of sources. The government will publish much of
            this data in order to encourage business and stimulate the economy.
            The U.S. Census for example is conducted once every ten years and
            summary level data is then published to allow businesses to
            effectively plan and market their products and services. The U.S.
            Department of Labor and several other bureaus will also publish
            summary data about businesses and consumers. Property records,
            liens, court filings, and citations are another public source for
            data that is used for marketing to consumers. UCC filings, SEC
            filings, and business licenses, SAM.gov and the DUNS systems are
            common public sources for business data.
          </p>
          <li className="h5">6. Compile online data</li>
          <p>
            Data disclosed on public facing websites is increasingly becoming a
            common source for compilers to collect data on consumers and
            businesses alike. Addresses and executive contact information is
            often scraped from business websites. Social networks, search
            engines, email service providers, and other online forums and
            businesses will collect personal and business data and metadata such
            as search terms and browsing history to more effectively market as
            well. The primary source of revenue for the largest search engines
            and social networks is selling access to marketing data or targeted
            marketing campaigns.
          </p>
          <li className="h5">7. Compile Telemarketing data</li>
          <p>
            Many traditional data compilers will call companies to ask questions
            about your business to collect information that they will use to
            market to you or sell to someone else. Some companies will use an
            autodialer to do phone number validation. Their method is simple--by
            dialing numbers as a survey method to determine which numbers ring,
            which numbers do not, and whether someone picks the line up.
          </p>
        </ul>
      </React.Fragment>
    )
  },
  {
    id: "tips-for-marketing-to-retirees-and-medicare-recipients",
    name: "Tips for marketing to retirees and medicare recipients",
    friends: [],
    excerpt: `With the age of 65 and retirement comes many lifestyle changes from insurance coverage, living arrangements, and hobbies. ${process.env.REACT_APP_site_name} offers turning 65 lists at a fraction of the cost of its competitors while providing similar if not the same data quality. You’ll be able to market to those approaching to 65 for less than ever before.`,
    content: (
      <React.Fragment>
        <p>
          With the age of 65 and retirement comes many lifestyle changes from
          insurance coverage, living arrangements, and hobbies.{" "}
          {process.env.REACT_APP_site_name} offers turning 65 lists at a
          fraction of the cost of its competitors while providing similar if not
          the same data quality. You’ll be able to market to those approaching
          to 65 for less than ever before.
        </p>
        <h5>Marketing lists for insurance agents</h5>
        <p>
          Insurance agents often attempt to market to individuals that are
          approaching the age of 65 so that they can get the first bite of the
          apple to offer them the plans and products that they will need.
          Especially as the baby boomers age, the number of individuals turning
          65 has steadily increased over the last decade, leaving a huge
          opportunity if you can find an effective way to market to these
          individuals.
        </p>

        <h5>Medicare supplement leads</h5>
        <p>
          Although you cannot buy a list of people that are on Medicare, the
          majority of individuals over the age of 65 do use Medicare as their
          primary insurance. Marketing Medicare supplement plans, insurance
          plans that offer features and fill gaps that original Medicare does
          not cover, is best accomplished by buying leads of individuals that
          are over the age of 65. Because the large majority of these
          individuals are married, and each individual, not each family, require
          separate Medicare supplement plans, speaking with one member of the
          household is a great way of getting access to someone’s spouse and
          their friends.
        </p>

        <h5>Marketing Lists for Health Service Providers</h5>
        <p>
          As a health care provider it's important to market to those people
          that need your services most and appreciate the value of a health
          service provider that knows them by name and helps him or her manage
          their health. Using age filters is a great way to market to people
          facing similar life changes and health challenges associated with
          retirement age. Get a targeted marketing list for retirement aged
          individuals and get the most from your marketing budget.
        </p>

        <h5>Marketing Lists for Retirement Homes</h5>
        <p>
          Most retirement homes need an effective way of identifying individuals
          that are planning for their futures and for their families with
          retirement homes. Finding a targeted marketing list of individuals
          that are in or approaching that planning stage is an effective way of
          improving the return on your marketing budget. You can easily target
          any age, or range of ages of elderly and approaching retirement age.
        </p>

        <h5>Marketing Lists for Financial Planners</h5>
        <p>
          Marketing to those individuals that are preparing for retirement is a
          great way of growing the client base for financial planners. As these
          individuals approach 65, they need services to help them plan for
          leaving their jobs and enjoying a comfortable lifestyle managing their
          retirement savings and investments. With{" "}
          {process.env.REACT_APP_site_name}, you can identify those approaching
          retirement age as well as hone your list in based on income levels to
          estimate who will need investment guidance versus reverse mortgage or
          401K and IRA planning.
        </p>

        <h5>Marketing Lists for Estate Attorneys and Law Practices</h5>
        <p>
          Estate planners and law practices specializing in wills and trusts
          find it especially useful to market to those who are preparing for
          retirement. As these individuals assess their assets and plan for
          spending time with family, travel, or picking up new hobbies, they
          will often holistically factor in estate planning and create their
          will. With {process.env.REACT_APP_site_name}, finding these
          individuals has never been easier.
        </p>

        <h5>Marketing Lists for Prescription Drug Companies</h5>
        <p>
          As individuals age, they seek remedies to common ailments like
          arthritis, high blood pressure, diabetes that will allow them to enjoy
          their travel and retirement. Using a targeted marketing list from{" "}
          {process.env.REACT_APP_site_name} is a great way to market to those
          individuals that are approaching and well into retirement and most
          likely to buy and seek the products of prescription drug companies.
          Look no further to hone your marketing list by age, income, or
          geographic area to ensure your messaging the right people at the right
          stage of their life.
        </p>

        <h5>Turning 65 Telemarketing Lists</h5>
        <p>
          Although baby boomers have rapidly adopted Facebook and most have an
          email, the most effective way of reaching these individuals is
          telemarketing. Baby boomers, having grown up before the era of
          computers or smart phones, usually pay for both cell phones and
          landlines, and prefer to communicate over the phone or in person.
          Finding a telemarketing list, the means of executing a targeted
          telemarketing campaign to speaking with individuals turning 65 is an
          effective way to grow your customer base.
        </p>

        <h5>Turning 65 Mailing Lists</h5>
        <p>
          In addition to religiously paying for a landline and cell phone, those
          turning 65 also check their mail. They expect to receive their bills
          and still appreciate handwritten and mailed birthday cards and thank
          you notes from their families. These means that sending targeted
          mailers is also an effective way get your message and your company top
          of mind for these individuals. Although the cost of sending an
          individual mail piece may be higher than a call or an email, you’ll
          find that this is still an effective way of marketing to this group.
        </p>

        <h5>Using {process.env.REACT_APP_site_name} to get Turning 65 Lists</h5>
        <p>
          It’s never been easy to download turning 65 lists to find individuals
          on Medicare, approaching retirement, and looking for age appropriate
          products and services. Simply use {process.env.REACT_APP_site_name}’s
          easy to use interface to select the ages of the individuals that you’d
          like to target. You can refine your search by our other consumer
          selects below as well! +
        </p>

        <h5>{process.env.REACT_APP_site_name} versus other Lead Sources</h5>
        <p>
          {process.env.REACT_APP_site_name} provides sales leads at a fraction
          of the price as its competitors. From our Individual Unlimited Plan
          for $50 per month all the way to our Enterprise plans providing leads
          at fractions of a penny per record, {process.env.REACT_APP_site_name}{" "}
          can’t be beat on price AND you’ll get comparable data quality that
          will serve the needs of your existing marketing efforts. We’ve been in
          business for more than 7 years, and through innovative technology
          advances, we’ve been able to make marketing data more affordable for
          every business in every industry.
        </p>

        <p>Contact the experts of marketing data at 1-888-905-3282</p>
      </React.Fragment>
    )
  },
  {
    id: "how-to-get-turning-65-lists-with-list-shack",
    name: `How to get turning 65 lists with ${process.env.REACT_APP_site_name}`,
    friends: [],
    excerpt: `${process.env.REACT_APP_site_name} provides turning 65 lists to thousands of insurance agents across the country.  See how easy it is to find consumers turning 65 for your business.`,
    content: (
      <React.Fragment>
        <div className="d-flex justify-content-center mb-5">
          <iframe
            title={`Video Demo of how to get turning 65 lists with ${process.env.REACT_APP_site_name}`}
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/jQq7IeDdN0U"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <p>
          {process.env.REACT_APP_site_name} provides turning 65 lists to
          thousands of insurance agents across the country. See how easy it is
          to find consumers turning 65 for your business.
        </p>
      </React.Fragment>
    )
  },
  {
    id: "listshack-com-customer-reviews",
    name: `${process.env.REACT_APP_site_name} customer reviews`,
    friends: [],
    excerpt: `${process.env.REACT_APP_site_name} provides sales leads at a fraction of the price as its competitors. From our Individual unlimited Plan for $50 per month all the way to our Enterprise plans providing leads at fractions of a penny per record, ${process.env.REACT_APP_site_name} can’t be beat on price AND you’ll get comparable data quality that will serve the needs of your existing marketing efforts. We’ve been in business for more than 7 years, and through innovative technology advances, we’ve been able to make marketing data more affordable for every business in every industry.`,
    content: (
      <React.Fragment>
        <p>
          {process.env.REACT_APP_site_name} provides sales leads at a fraction
          of the price as its competitors. From our Individual unlimited Plan
          for $50 per month all the way to our Enterprise plans providing leads
          at fractions of a penny per record, {process.env.REACT_APP_site_name}{" "}
          can’t be beat on price AND you’ll get comparable data quality that
          will serve the needs of your existing marketing efforts. We’ve been in
          business for more than 7 years, and through innovative technology
          advances, we’ve been able to make marketing data more affordable for
          every business in every industry.
        </p>
        <p className="lead">
          We’ve served thousands of professionals here’s what they have to say
          about us:
        </p>
        <blockquote class="blockquote text-left">
          <p class="mb-0">
            So I signed up for {process.env.REACT_APP_site_name} and I have
            tried infofree before. I have to say{" "}
            {process.env.REACT_APP_site_name} is top stuff. I have had my tm go
            through about 3k in records and I have to say that the names, ages,
            income, and addresses are all accurate. Now dealing with the senior
            market some tend to pass away, but this is some good stuff, $50 for
            all the records you can handle...
            <br />
            (this isn't a paid advertisement, I didn't gain anything from
            posting this, this is just a really convenient helpful service)
          </p>
          <footer class="blockquote-footer">
            Insurance Broker in Greater Cleveland Metro Area,{" "}
            <cite title="Source Title">
              <a
                href="https://www.insurance-forums.net/forum/insurance-leads/my-review-listshack-t63885.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                insurance-forums.net
              </a>
            </cite>
          </footer>
        </blockquote>

        <blockquote class="blockquote text-left">
          <p class="mb-0">
            I am the bug finder for {process.env.REACT_APP_site_name}. . It is
            by far the best value for list that I know of.
          </p>
          <footer class="blockquote-footer">Chicago Blackhawks Fan</footer>
        </blockquote>

        <blockquote class="blockquote text-left">
          <p class="mb-0">
            I personally have tried {process.env.REACT_APP_site_name} and I like
            their data. Good customer service, and I always recommend other
            professionals to here for their data needs. Appreciate the help!
          </p>
          <footer class="blockquote-footer">Justin A.</footer>
        </blockquote>

        <blockquote class="blockquote text-left">
          <p class="mb-0">
            Subscribed yesterday, downloaded a couple of consumer lists, &
            dialed about 4 hours total. Came away with 6 leads. Couldn't be more
            pleased!
          </p>
          <footer class="blockquote-footer">Micah B.</footer>
        </blockquote>

        <blockquote class="blockquote text-left">
          <p class="mb-0">
            Best thing that I've done when in need of leads. Easy website and
            supportive tech staff. Thanks, {process.env.REACT_APP_site_name}!
          </p>
          <footer class="blockquote-footer">Anna M.</footer>
        </blockquote>

        <blockquote class="blockquote text-left">
          <p class="mb-0">
            I have used it for my medical consulting business and it works
            really well. I was able to separate the data I was looking for
            easily.
          </p>
          <footer class="blockquote-footer">Jordan C.</footer>
        </blockquote>

        <blockquote class="blockquote text-left">
          <p class="mb-0">
            There's a newer source in town called{" "}
            {process.env.REACT_APP_site_name}. They're $50/month and let you
            download unlimited leads. I haven't tried them, yet, but other
            marketers I have talked to like them.
          </p>
          <footer class="blockquote-footer">
            David H., <cite>Retired RE Agent</cite>
          </footer>
        </blockquote>
      </React.Fragment>
    )
  },
  {
    id: "insurance-lists-for-telemarketing-and-direct-mail",
    name: "Insurance lists for telemarketing & direct mail",
    friends: [],
    excerpt: `${process.env.REACT_APP_site_name} makes finding insurance lists easier to get and less painful to pay for. With a database of 14M+ U.S. businesses and 200M+ U.S. Consumers at rock bottom prices, you’ll be able to get a targeted list of prospects for your insurance business campaign in no time. Insurance agents and Field Marketing Organizations (FMOs) can easily get access to all of the high quality, accurate lists such as turning 65 lists, final expense lists, homeowner lists, and long term care lists using an online, user friendly system that makes lists available 24/7 - 365.`,
    content: (
      <React.Fragment>
        <p>
          {process.env.REACT_APP_site_name} makes finding insurance lists easier
          to get and less painful to pay for. With a database of 14M+ U.S.
          businesses and 200M+ U.S. Consumers at rock bottom prices, you’ll be
          able to get a targeted list of prospects for your insurance business
          campaign in no time.
        </p>

        <p>
          Insurance agents and Field Marketing Organizations (FMOs) can easily
          get access to all of the high quality, accurate lists such as turning
          65 lists, final expense lists, homeowner lists, and long term care
          lists using an online, user friendly system that makes lists available
          24/7 - 365.
        </p>

        <h5>Insurance lists for telemarketing</h5>
        <p>
          All of our lists include telephone numbers scrubbed against the do not
          call registry. These lists can be targeted based both business and
          consumer filters that make the most sense for targeting prospects for
          your business. Telemarketing is a fantastic way to ensure that you can
          market to the right people in the way that they communicate best at a
          cost that makes sense for your budget.
        </p>

        <h5>Insurance lists for direct mail</h5>
        <p>
          All of our lists come with address and can be targeted based on zip
          code, area code, county, or state, so that you can easily hone your
          marketing list for your next direct mail campaign. Direct mail is a
          fantastic way to stay top of mind for your insurance prospects and
          ensure that every person that you want to market to will see your
          piece.
        </p>

        <h5>Trusted Provider of Insurance Lists</h5>
        <p>
          {process.env.REACT_APP_site_name} is the trusted solution for
          thousands of professionals in the United States and abroad for
          targeting insurance prospects to grow your business. Our lists can be
          used to target the following insurance products.
        </p>

        <h5>Life Insurance</h5>
        <p>
          Life insurance agents use {process.env.REACT_APP_site_name} to target
          consumers that are most likely to be interested in a life insurance
          policy that makes the most sense for their family and friends.{" "}
          {process.env.REACT_APP_site_name} makes it easy to target high income
          consumers for whole life policies and middle income and homeowners for
          term and value life insurance products. Marketing lists for Life
          Insurance products are an effective way of marketing your for agencies
          of any size.
        </p>

        <h5>Homeowners Insurance</h5>
        <p>
          {process.env.REACT_APP_site_name} makes it easy to target your
          marketing to homeowners in any geographic region based on age, income,
          credit range, marital status, and gender. Targeting Homeowners as part
          of a targeted campaign to grow the number of homeowners insurance
          products your company sells is win - win for both customers and
          company.
        </p>

        <h5>Disability Insurance</h5>
        <p>
          Disability insurance or DI policies are increasingly important for
          americans as more than 18% of United States citizens live with a
          disability, and more than 25% of americans will suffer a disabling
          injury before he or she retires. {process.env.REACT_APP_site_name}{" "}
          makes it easy download targeted lists for individual disability
          insurance and business-supplied disability insurance products.
          Targeting high risk industries is an effective way of identifying
          businesses that need to offer employer-supplied disability insurance
          to its employees.
        </p>

        <h5>Long-term Health Care</h5>
        <p>
          Long-term health care products are an important part of planning for
          retirement and keeping peace of mind during the golden years.
          Identifying individual consumers by age and income using{" "}
          {process.env.REACT_APP_site_name} is a simple way of growing your
          business by spending your marketing budget getting in front of those
          individuals that need these policies the most during. Use
          telemarketing or direct mail to ensure that consumers looking to
          purchase these policies know about your products.
        </p>

        <h5>Financial Planning</h5>
        <p>
          Given the economic climate of the last ten years, its never too early
          to begin financially planning and growing your client base has never
          been easier. Using age, income, and geographic region filters, it's
          easy to make sure that you can find the right clients for your 401K,
          IRA, and wealth management services. Marketing lists for financial
          planners is part of our expertise at {process.env.REACT_APP_site_name}
          .
        </p>

        <h5>Final Expense</h5>
        <p>
          Final expense agents won’t find a more cost effective and simple
          solution to target consumers that are most likely to need final
          expense policies. Using our targeted lists, you’ll be able to market
          to individuals in the stage of life that need your product the most
          and provide the biggest return on your marketing budgets. Final
          expense agents can use all of our consumer filters to create a
          targeted marketing list.
        </p>

        <h5>Medicare Open Enrollment</h5>
        <p>
          Every year in December and October medicare open enrollment presents a
          fantastic time to marketing to those who will be qualifying to enroll
          in medicare. Turning 65 lists are easier than ever before to download
          and market to in any geographic region. Using these lists is a great
          way to sell more medicare supplement plans and grow your business.
        </p>

        <h4>Using {process.env.REACT_APP_site_name} to get Insurance Lists</h4>
        <p>
          It’s never been easy to download insurance lists to find individuals
          on medicare, approaching retirement, and looking for age appropriate
          products and services. Simply use {process.env.REACT_APP_site_name}’s
          easy to use interface to select the ages of the individuals that you’d
          like to target. You can refine your search by our other consumer
          selects below as well!
        </p>

        <h5>Filters</h5>
        <ul>
          <li>Age</li>
          <li>Year and Month Born</li>
          <li>Estimated Income Range</li>
          <li>Homeowner Status</li>
          <li>Marital Status</li>
          <li>Gender</li>
          <li>Property Value</li>
          <li>Property Type</li>
          <li>Year Built</li>
          <li>Purchase Year</li>
          <li>Net Worth</li>
          <li>Credit Rating</li>
          <li>State</li>
          <li>County</li>
          <li>City</li>
          <li>Zip Code</li>
          <li>Area Code</li>
        </ul>
        <h5>{process.env.REACT_APP_site_name} versus other Lead Sources</h5>
        <p>
          {process.env.REACT_APP_site_name} provides sales leads at a fraction
          of the price as its competitors. From our Individual unlimited Plan
          for $50 per month all the way to our Enterprise plans providing leads
          at fractions of a penny per record, {process.env.REACT_APP_site_name}{" "}
          can’t be beat on price AND you’ll get comparable data quality that
          will serve the needs of your existing marketing efforts. We’ve been in
          business for more than 7 years, and through innovative technology
          advances, we’ve been able to make marketing data more affordable for
          every business in every industry.
        </p>

        <p>Contact the experts of marketing data at 1-888-905-3282</p>
      </React.Fragment>
    )
  },
  {
    id: "how-to-find-the-right-list-broker",
    name: "How to find the right list broker",
    friends: [],
    excerpt: (
      <React.Fragment>
        So you need to buy a marketing list? Or maybe just need some phone
        numbers to call or some addresses to mail to? Maybe you need to
        supplement your sales team’s outbound efforts with email addresses? Do
        you need cell phone numbers for voicemail drops? <br />
        <br /> You need a list broker.
      </React.Fragment>
    ),
    content: (
      <React.Fragment>
        <p>
          So you need to buy a marketing list? Or maybe just need some phone
          numbers to call or some addresses to mail to? Maybe you need to
          supplement your sales team’s outbound efforts with email addresses? Do
          you need cell phone numbers for voicemail drops? <br />
          <br /> You need a list broker.
        </p>
        Start with the most reasonable thing that any human being would do in
        your situation and google it! you’ll likely find something like this
        <p>What do you do?</p>
        <p>
          You can start clicking on all the links and searching for who’s got
          what you need, and thirty minutes later, you’ll likely come to the
          same conclusion most do: Why is this so complicated? I just need some
          data!
        </p>
        <p>
          Here’s a quick and easy guide that will allow you to sift through the
          many different list brokers and understand which broker offers the
          type of data that will be most useful for you.
        </p>
        <h3>Data Types</h3>
        <p>
          Let’s begin with different types of data that exist. It’s not uncommon
          for a list broker to offer all of these data types, but each broker
          will typically specialize or be best at one specific type of data.
          Here’s an overview:
        </p>
        <h5>Telemarketing data</h5>
        <p>
          Telemarketing data is typically divided into two buckets, (i) business
          to consumer telemarketing which uses automated dialers and (ii)
          business to business data that is often dialed by hand and read from a
          spreadsheet or customer relationship management software (CRM).
        </p>
        <h5>Voicemail drop data</h5>
        <p>
          This is typically a subset of telemarketing data that must only
          include cell phone numbers. The ability to determine whether a phone
          number is a cell phone number is typically determined by the Numbering
          Plan Area (NPA) or the first three digits of the seven digit phone
          number assigned under the North American Numbering Plan (NANP).
          However, determining with certainty which phone numbers are cell phone
          versus landlines is only feasible for companies that obtain a license
          to the Intermodal Port List from Neustar Inc., a federal government
          contractor paid by the United States government for maintaining the
          NANP.
        </p>
        <h5>Direct mail data</h5>
        <p>
          Direct mail data is often gathered from public property records. The
          United States Postal Service (USPS) sells licenses private companies
          to a database of known address changes called the National Change of
          Address (NCOA) database. Direct mail data is often purchased to mail
          individuals or households by name and household characteristic. For
          example, a pest control company would send a piece of mail to John
          Smith because he is a home owner in a particular geographic region.
          Direct mail is also used for saturation marketing where the USPS is
          paid for an Every Door Direct Mail campaign to deliver a mail piece to
          every individual in a particular geographic region.
        </p>
        <h5>Email marketing data</h5>
        <p>
          Email marketing data is often compiled from private companies that
          collect and resell email marketing data. CAN Spam regulations govern
          the use of emails for commercial purposes, and large Internet Service
          Providers (ISPs) and Email Service Providers (ESPs) monitor compliance
          to CAN Spam and private spamming policies to minimize the number of
          unsolicited emails received by its users.
        </p>
        <h5>Social media data</h5>
        <p>
          Social media data is often tightly controlled by social networks but
          has become increasingly available by these social networks for the
          purpose of advertising. While some social media data is available for
          purchase for analytical purposes, most access to this data is limited
          to the API provided by the social network.
        </p>
        <h5>Financial Data</h5>
        <p>
          The primary source of financial data are the credit bureaus Experian,
          Transunion, and Equifax. The use of credit scores is highly regulated
          but summary level financial information is typically available from
          most data providers. Using credit scores for marketing is often used
          by mortgage and lending institutions as they are required to make a
          firm offer of credit within a certain time period. Summary level
          financial information is often used to determine financial health of a
          prospect or income levels of a household.
        </p>
        <h3>Data Quality</h3>
        <p>
          Data quality varies among data brokers and data compilers. Data
          compilers, or companies that collect their own data, not surprisingly,
          have the highest quality data and most frequent updates or refresh
          rates on the dataset. Most compilers will not license their most
          accurate data to other data brokers, or companies that specialize in
          offering a variety of data types and sources, as they are able to
          charge a higher premium selling their data directly to companies that
          require accurate data that they would be licensing their most accurate
          data through data brokers. Each data type and each company will have
          unique data accuracy needs. Direct mail data, for example, is
          relatively inexpensive to find because any name and address can
          typically be updated through the NCOA database service through private
          companies. Telemarketing data is also relatively inexpensive because
          the data can be updated through a number of companies with licenses to
          the Intermodal Port List. Below is a list of well known data brokers
          and compilers that can help you quickly narrow your search for the
          right data broker.
        </p>
        <h5>Data Compilers and Data Brokers</h5>
        <p>
          United States Postal Service (USPS) - The USPS compiles change of
          address data and is a premium source for the National Change of
          Address database. They also offer Every Door Direct Mail campaigns.
        </p>
        <ul>
          <li>
            <b>Dunn & Bradstreet (D&B)</b> - D&B is required to issued DUNS
            numbers to businesses wishing to do business in the United State
            with federal, state, and local governments, and are a data compiler,
            as a result, for business information, including business name,
            address, telephone number, and owners, executives, and board members
            of each business. D&B owns several subsidiaries that complement
            their DUNS business, including Hoovers.
          </li>

          <li>
            <b>Experian</b> - Experian is one of three credit bureaus that
            offers consumer credit scoring in the United States. Experian is a
            primary data compiler of consumer information including name,
            address, telephone number, and consumer credit and spending
            attributes.
          </li>

          <li>
            <b>Acxiom</b> - Acxiom is a large marketing research firm that
            conducts and compiles consumer and business survey data.
          </li>

          <li>
            <b>Infogroup</b> - perhaps more commonly know as InfoUSA and
            SalesGenie, Infogroup is a conglomerate of data companies that does
            a variety of data compiling from a variety of sources.
          </li>

          <li>
            <b>Neustar</b> - as the contractor managing the NANP, Neustar is the
            premier compiler of telemarketing data.
          </li>

          <li>
            <b>{process.env.REACT_APP_site_name}</b> -{" "}
            {process.env.REACT_APP_site_name} the last 5 years has become the
            number one source for discount and wholesale telemarketing and
            direct mail data. The company also brokers data for VIN, Cell Phone,
            and email data upon request.
          </li>

          <li>
            <b>Accudata</b> - as a data broker for many different compilers,
            Accudata is known for offering targeted telemarketing and direct
            mail data and brokers other data sources as well.
          </li>

          <li>
            <b>List Giant</b> - as a data broker, List Giant brokers a variety
            of proprietary and brokered data.
          </li>

          <li>
            <b>Infofree</b> - Founded by Vin Gupta, former CEO of infogroup,
            Infofree is a data broker that offers a variety of data types.
          </li>

          <li>
            <b>ZoomInfo</b> - Zoominfo is a data broker that offers B2B data to
            sales and marketing teams through direct integration with CRM
            systems like SalesForce.
          </li>

          <li>
            <b>Data.com</b> - Owned by SalesForce, Data.com is a cooperative
            database compiled from companies that provide their B2B sales data
            in exchange for access to other companies information.
          </li>

          <li>
            <b>Melissa Data</b> - A well known data broker and compiler of a
            variety of data types.
          </li>

          <li>
            <b>Natimark</b> - A well known data compiler that does manual
            compiling work on B2B and B2C databases.
          </li>

          <li>
            <b>Dataman Group</b> - A data broker that offers a variety of data
            types.
          </li>
        </ul>
        <h3>Price of Data</h3>
        <p>
          As data collection techniques have increasingly become more automated
          with the decreasing cost of computer hardware and storage, the price
          of data has also continued to decrease. Data compilers typically sell
          their most accurate data at premium prices, both because they control
          the supply and because these companies have to cover the overhead and
          data collection expenses. Data brokers often sell slightly aged or
          less accurate data at less expensive price points, though they can be
          limited by their license agreement on the price at which they are able
          to sell the data.
        </p>
        <h4>{process.env.REACT_APP_site_name} is the best price for data</h4>
        <p>
          {process.env.REACT_APP_site_name} has pioneered the wholesale data
          model by making strategic purchases of B2B and B2C databases and
          offering the data at extremely discounted rates to thousands of
          businesses. The business maintains low overhead by providing data
          through a self service portal with comprehensive customer service
          model. Requests through {process.env.REACT_APP_site_name} for other
          types of data are referred to Affordable Marketing Lists, that offers
          traditional data broker services at discounted rates because of the
          success of its wholesale data business.
        </p>
        <p>Don’t hesitate to call Listshack data experts at 1-888-905-3282.</p>
      </React.Fragment>
    )
  },
  {
    id: "how-to-get-affordable-roofing-leads",
    name: "How to get affordable roofing leads",
    friends: [],
    excerpt:
      "If you’re one of the thousands of roofing contractors looking for new business you’re looking for great leads, and even better if they’re cheap! So how do you get in front of thousands of prospects in your area without having to take out a loan?",
    content: (
      <React.Fragment>
        <p>
          If you’re one of the thousands of roofing contractors looking for new
          business you’re looking for great leads, and even better if they’re
          cheap! So how do you get in front of thousands of prospects in your
          area without having to take out a loan?
        </p>

        <h4>Targeted Roofing Leads</h4>
        <p>
          Finding homeowners targeted in your area is as simple as creating an
          account with us, selecting your area, choosing homeowners, and then
          downloading the list. You can search homeowners by the value of the
          home, the age of the house, credit score, income, and age of the
          owner. These lists come with phone numbers so you can literally reach
          hundreds of leads a day without even leaving your office.
        </p>

        <h4>Why Use a Targeted List</h4>
        <p>
          Many lead companies will charge $25/lead or more AND you’ll be
          competing with several companies for the same business. By using a
          targeted list you’ll be able to create exclusive leads exactly where
          you want them. Just finished a job in a particular zip code? Pull a
          list in the area and reinforce your brand by calling people who are
          probably already driving by your work. Best of all, it’s super
          affordable. Getting started is just $50 and gets you access to an
          entire database of homeowners anywhere in the U.S.
        </p>

        <h4>Ask for Referrals</h4>
        <p>
          It’s surprising how many sales people won’t even ask for referrals.
          Any time you make a pitch is a great time to ask for a referral, even
          if they don’t buy from you. There are any number of reasons why people
          wouldn’t need a new roof or be prepared to buy from you, but that
          doesn’t mean they don’t like you or your company. Asking something as
          simple as “do you know anyone else that might be needing a new roof?”
          might not seem like a silver bullet because it’s not, but it can
          easily lead to more sales. It’s an especially good idea because if
          you’ve already made a pitch, it only takes an extra moment to open the
          door for refferals.
        </p>
      </React.Fragment>
    )
  },
  {
    id: "how-accurate-are-marketing-lists",
    name: "How accurate are marketing lists?",
    friends: [],
    excerpt:
      "There are two primary reasons why our customers call and ask us this question. First, they are just starting their marketing journey and they’ve not used marketing lists before. Second, they’ve just had what they think is a bad experience with marketing data, more disconnects than they would care for or a lot of returned mail pieces, maybe even a bunch of hard bounces on the email list that they used. No matter the reason why you are asking the question, the basic answer is the same:  Most marketing lists from reputable sources are of about the same accuracy.",
    content: (
      <React.Fragment>
        <p>
          There are two primary reasons why our customers call and ask us this
          question. First, they are just starting their marketing journey and
          they’ve not used marketing lists before. Second, they’ve just had what
          they think is a bad experience with marketing data, more disconnects
          than they would care for or a lot of returned mail pieces, maybe even
          a bunch of hard bounces on the email list that they used. No matter
          the reason why you are asking the question, the basic answer is the
          same: <br />
          <br />
          Most marketing lists from reputable sources are of about the same
          accuracy.
        </p>
        <p>
          The reason for this basic answer is very straightforward: If all data
          compilers did there job perfectly, the data and the accuracy would be
          exactly the same. When the data is not exactly the same there are only
          three reasons why it is not of exactly the same accuracy.
        </p>
        <ul>
          <li>The data was collected at a different time</li>
          <li>The data was collected in a different way</li>
          <li>The data collected was inaccurate or false to begin with</li>
        </ul>
        <h4>The data was collected at a different time</h4>
        <p>
          It’s not surprise that data changes over time. We grow older, our age
          changes. We move, get a new job, change our phone number, get married
          or divorced, purchase a new car, and have more children. Each of these
          changes in turn causes a change in the marketing data that is
          collected and compiled. To use a very simple example. On December 2,
          Compiler A records the county property records and sees that John
          Smith owns 123 Main Street. On December 15, the county updates their
          records to reflect that John Smith sold his home to Jane Doe on
          December 1. On December 16, Compiler B reviews the same property
          record and sees that Jane Doe owns 123 Main Street.
        </p>

        <h4>The data was collected in a different way</h4>
        <p>
          There are several ways to collec the exact same data. For mailing
          lists, for example, Compiler A may buy or collect data from public
          county property assessments. Compiler B, on the other hand, may
          collect mailing addresses from credit card or loan applications. In
          most cases, a person who owns a home also lives in that home and uses
          that home address on their credit card application. Nevertheless, it
          is possible that John Smith applied for his credit card before he
          purchased his home and moved, and if Compiler B does not use industry
          standard data validation and updates, John Smith’s address will be
          incorrect in its database.
        </p>

        <h4>The data collected was inaccurate or false to begin with</h4>
        <p>
          There are any number of reasons why consumers write down fake
          addresses that range from fraud to avoiding being marketed to.
          Reputable data compilers and brokers have no incentive for making up
          or falsifying the information; it’s bad for business. Nevertheless,
          especially when working with compilers that collect method online or
          from market research and surveys, the consumer may have an incentive
          to falsify their information to avoid solicitation.
        </p>

        <h4>Different Kinds of Data Change More or Less Frequently</h4>
        <p>
          On the list of things you probably already understand on a basic and
          logical level but have never deduced said out loud is that different
          types of data change at different rates. You change your phone number
          more frequently than your address and your email more frequently than
          your phone number. Here’s a short table that you can review to see how
          often certain types of data need to be updated and the reason it
          becomes inaccurate.
        </p>

        <table border="1" cellspacing="0" cellpadding="7">
          <tbody>
            <tr valign="TOP">
              <td width="132">
                <p>Data Type</p>
              </td>
              <td width="462">
                <p>Discussion</p>
              </td>
            </tr>
            <tr valign="TOP">
              <td width="132">
                <p>Mailing Lists</p>
              </td>
              <td width="462">
                <p>
                  Mailing lists need to be updated because people move. Who
                  moves and how frequently? Here are basic rules of thumb:
                </p>
                <ol>
                  <li>
                    <p>
                      10 - 13% of the US Population moves every year (about 36
                      million)
                    </p>
                  </li>
                  <li>
                    <p>Young people move more often than older people</p>
                  </li>
                  <li>
                    <p>Single people move more than married people</p>
                  </li>
                  <li>
                    <p>Older people move around the age that they retire</p>
                  </li>
                </ol>
                <p>
                  You can find an in-depth report from the U.S. Census Bureau on
                  who moves and why at{" "}
                  <a href="https://www.census.gov/prod/2014pubs/p20-574.pdf">
                    https://www.census.gov/prod/2014pubs/p20-574.pdf
                  </a>
                  .
                </p>
              </td>
            </tr>
            <tr valign="TOP">
              <td width="132">
                <p>Telemarketing Data</p>
              </td>
              <td width="462">
                <p>
                  Telephone numbers need to be updated because people cancel
                  their landlines or change their phone numbers. Because
                  cancellations and number changes are managed by privately
                  owned telecom companies, it is difficult to determine the
                  number of people that cancel or change their phone numbers.
                  The FCC published a report in 2010 that estimated that between
                  7 and 9% of landlines were cancelled in 2009 at{" "}
                  <a href="https://apps.fcc.gov/edocs_public/attachmatch/DOC-301823A1.pdf">
                    https://apps.fcc.gov/edocs_public/attachmatch/DOC-301823A1.pdf
                  </a>{" "}
                  and if trends have continued, that could be as high as 15% of
                  landlines that are cancelled each year.
                </p>
              </td>
            </tr>
            <tr valign="TOP">
              <td width="132">
                <p>Email data</p>
              </td>
              <td width="462">
                <p>
                  On average, between 25 and 31% of people either create a new
                  email address or change their existing email address. You read
                  more about those statistics here:
                </p>
                <p>
                  <a href="https://www.convinceandconvert.com/convince-convert/15-email-statistics-that-are-shaping-the-future/">
                    https://www.convinceandconvert.com/convince-convert/15-email-statistics-that-are-shaping-the-future/
                  </a>
                </p>
                <p>
                  <a href="https://www.quora.com/What-percentage-of-people-change-their-email-address-annually">
                    https://www.quora.com/What-percentage-of-people-change-their-email-address-annually
                  </a>
                </p>
                <p>
                  <a href="https://www.zdnet.com/article/how-often-do-you-change-your-email-address/">
                    https://www.zdnet.com/article/how-often-do-you-change-your-email-address/
                  </a>
                </p>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr valign="TOP">
              <td width="132">
                <p>Social Media Data</p>
              </td>
              <td width="462">
                <p>
                  More than 58% of adults in the United States have a Facebook
                  account and 70% of those use it every single day! Twitter,
                  Instagram, and LinkedIn are also very popular social media
                  sites. Advertising on social media has its nuances, and users
                  are usually responsible for populating their own information,
                  from age, income, and likes and interests. Here’s an in depth
                  study from the Pew Research Center that should provide you
                  with more details about social media users.
                </p>
                <p>
                  https://www.pewinternet.org/2015/01/09/frequency-of-social-media-use-2/
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <h4>Even the most recent data can still be wrong</h4>
        <p>
          For the most part, you can trust that most of the records will be
          correct most of the time, and for most marketers that’s good enough.
          Nevertheless, when you are buying data it's important to understand
          that even the most accurate purchased data or user provided opt-in
          data can still be wrong for many different reasons. For example, some
          users will provide false information to prevent from being marketing
          to. Other times, users will make typing errors. Some high wealth
          individuals own several homes and maintain different contact
          information. Just be prepared for some duds, regardless of how much
          you pay and from whom the data comes.
        </p>

        <h4>How much is accurate data worth to your business?</h4>
        <p>
          The importance of data accuracy varies by the business. If a business
          uses autodialers, the technology will usually be able to handle less
          accurate data, so telemarketers will put a premium on paying a small
          price for data that meets DNC and TCPA regulations. High volume direct
          mailers will also put a higher premium on price because they will
          often validate postal addresses directly with the USPS before they
          print and mail. Lenders and car dealerships using FICO or credit
          information will often put a premium on higher quality data, because
          of the types of loans and regulations for using credit scores for
          marketing purposes. Email marketers value opt-in and validate email
          data because it prevents them from dealing with spam traps and getting
          their email services shut down or their email servers blacklisted by
          the major emails service providers.
        </p>

        <h4>How can I improve data accuracy?</h4>
        <p>
          There are several great ways to improve the accuracy of your data. For
          telemarketing companies, you can use our free DNC scrubbing tool.
          These companies can also find autodialers that will automatically
          scrub for DNC and TCPA compliance, to offer even more assurance that
          your meeting your regulatory due diligence requirements. Companies
          doing direct mail can find third party National Change of Address
          (NCOA) Link service providers to get the most recent addresses from
          the postal services, and {process.env.REACT_APP_site_name} offers
          great email append and validation services for email service
          providers.
        </p>

        <h4>
          Why {process.env.REACT_APP_site_name} makes sense for your business
        </h4>
        <p>
          {process.env.REACT_APP_site_name} is the best choice for great prices
          on marketing data. {process.env.REACT_APP_site_name} is the most
          reputable company offering affordable marketing data solutions and
          with its great compliance tools, it will also ensure your business
          stays on the right side of the regulations.{" "}
          {process.env.REACT_APP_site_name} is rated an A on Better Business
          Bureau and gets great reviews on Insurance-Forum.net, Facebook, and
          many reputable sites. Check out our customer reviews{" "}
          <Link to="/resources/list-shack-com-customer-reviews">here</Link>.
        </p>
      </React.Fragment>
    )
  },
  {
    id: "how-to-find-targeted-direct-mail-lists",
    name: "How to find targeted direct mail lists",
    friends: [],
    excerpt: `${process.env.REACT_APP_site_name} makes finding direct mail lists easier to get and less painful to pay for. With a database of 14M+ U.S. businesses and 200M+ U.S. Consumers at rock bottom prices, you’ll be able to get a targeted list of prospects for your direct mail campaign in no time.`,
    content: (
      <React.Fragment>
        <p>
          {process.env.REACT_APP_site_name} makes finding direct mail lists
          easier to get and less painful to pay for. With a database of 14M+
          U.S. businesses and 200M+ U.S. Consumers at rock bottom prices, you’ll
          be able to get a targeted list of prospects for your direct mail
          campaign in no time.
        </p>

        <h4>Direct mail marketing lists</h4>
        <p>
          Direct mail is fantastic way to make sure that your customer will see
          your message. Although many businesses and consumers have
          affectionately termed direct mail as “junk mail,” almost every
          consumer and business will look at your piece before they throw it
          away. Direct mail has become a go-to marketing technique for many
          small, medium, and large businesses across the United States. These
          businesses leverage accurate direct mail marketing lists to target
          their audience and stay in front of new customers and existing
          clients.
        </p>

        <h4>Accurate Direct Mail Marketing Lists</h4>
        <p>
          Because so many banking and utility services require a verifiable
          physical address for sending statements and turning on services, the
          United States Postal Services maintains a comprehensive database of
          every mailable U.S. address. When a consumer moves, he or she is
          required to register that move with the USPS to ensure that their mail
          and bills are delivered to the correct address. As a result, the USPS
          operates the National Change of Address (NCOA) Link system, to provide
          businesses and marketers access to these address changes for their
          records for a fee. More information about NCOA Link from the USPS is
          available at https://ribbs.usps.gov/index.cfm?page=ncoalink.
          Individual businesses that lack the resources to get a NCOA Link
          services license directly from the USPS can pay for these services
          through direct mail companies or other third party data providers and
          brokers, including Affordable Marketing Lists. NCOA Link licensing and
          direct mail advertising is a significant revenue source for the USPS.
        </p>

        <h4>Methods of Direct Mail Marketing</h4>
        <p>
          There are many ways of sending direct mail. A common way of sending
          direct mail marketing that reduces postage expense is every door
          direct mail (EDDM) or saturation direct mail marketing. The USPS
          offers reduced postage rates for businesses that provide a piece of
          direct mail for every mailable address in a given area or mail route.
          More targeted direct mail methods include postcards and variable data
          printing. Post cards can sent to businesses and consumers of a
          particular firmographic nature. Variable data printing is a method
          whereby a direct mail company prints particular images and words based
          on the demographic or firmographic information of the person or
          business that it will be mailed to. There is a direct mail method for
          every type of marketing, from lenders that wish their mail pieces to
          look like bills or official notices to more creative handwritten
          printing or publishing methods, to mailing promotional items or even
          coconuts to consumers and businesses.
        </p>

        <h4>Save marketing budget using a direct mail list and direct mail</h4>
        <p>
          Using {process.env.REACT_APP_site_name} as your source for direct mail
          addresses and marketing list targeting is a great way to save money on
          an already cost effective approach to marketing.{" "}
          {process.env.REACT_APP_site_name} not only offers mailing lists at a
          fraction of the cost of other marketing data companies like
          Salesgenie, infofree, and experian, but direct mail itself is a very
          cost effective way of marketing to businesses and consumers. Because
          the price of large printing, cost effective data from{" "}
          {process.env.REACT_APP_site_name}, and more affordable postage rates
          from the USPS, direct mail is used by thousands of businesses as their
          primary source of finding new customers. The marketing approach can be
          further tested and refined using A/B Split testing as well as other
          approaches. Nonprofits often receive reduced shipping and postage
          rates from the USPS when using direct mail advertising.
        </p>

        <h4>
          Pinpoint Targeting with {process.env.REACT_APP_site_name} for Direct
          Mail Campaigns
        </h4>
        <p>
          Choose from the most important search selections to target your
          business and consumer lists to your businesses marketing plan.
        </p>

        <h5>Business Filters</h5>
        <ul>
          <li>Search Industry by SIC Code</li>
          <li>Number of Employees</li>
          <li>Sales Volume</li>
          <li>Years in business</li>
          <li>Square Footage</li>
          <li>State</li>
          <li>County</li>
          <li>Zip Code</li>
          <li>Area Code</li>
        </ul>
        <h5>Consumer Filters</h5>
        <ul>
          <li>Age</li>
          <li>Year and Month Born</li>
          <li>Estimated Income Range</li>
          <li>Homeowner Status</li>
          <li>Marital Status</li>
          <li>Gender</li>
          <li>Property Value</li>
          <li>Property Type</li>
          <li>Year Built</li>
          <li>Purchase Year</li>
          <li>Net Worth</li>
          <li>Credit Rating</li>
          <li>State</li>
          <li>County</li>
          <li>City</li>
          <li>Zip Code</li>
          <li>Area Code</li>
        </ul>
        <h5>Common uses for Direct Mail Lists</h5>
        <ul>
          <li>
            Identify businesses by SIC code for targeted direct mail offers.
          </li>
          <li>
            Identify consumers in California with $75K income or greater to
            offer solar panels.
          </li>
          <li>
            Identify technology companies with $5M+ annual revenue to offer
            telecommunications services or software.
          </li>
          <li>
            Identify individuals with $100K income or greater to offer financial
            planning services.
          </li>
          <li>Many, many, more</li>
        </ul>
      </React.Fragment>
    )
  },
  {
    id: "finding-quality-homeowner-leads",
    name: "Finding quality homeowner leads to grow your business",
    friends: [],
    excerpt: `${process.env.REACT_APP_site_name} makes finding home owner sales leads easier than checking your email with its user friendly interface and pinpoint targeting. With a database of 200M+ U.S. consumers and rock bottom pricing, you’ll be able closing more deals and growing your business at a fraction of the cost.`,
    content: (
      <React.Fragment>
        <p>
          {process.env.REACT_APP_site_name} makes finding home owner sales leads
          easier than checking your email with its user friendly interface and
          pinpoint targeting. With a database of 200M+ U.S. consumers and rock
          bottom pricing, you’ll be able closing more deals and growing your
          business at a fraction of the cost.
        </p>

        <h4>
          Marketing Lists for Homeowners are good for your Business’ Marketing
          Campaigns
        </h4>
        <p>
          Finding and marketing to homeowners is a great way to target consumers
          that have specific, predictable needs. Homeowners tend to spend more
          money on construction and handyman projects, hvac, roofing, have more
          needs for family and home goods, and spend money on furniture, and
          home services like cable, internet, and pest control. Home owners are
          also more likely to buy homeowners insurance, have multi vehicle
          policies for automobile insurance, and spend money on family outings
          and vacations.
        </p>

        <h4>Market to Homeowners with Money to Spend</h4>
        <p>
          Homeownership in the United States is an indicator of higher incomes
          and family status. In the United States, homeowners tend to be older
          and across the United States live in more suburban and rural areas. As
          you market and sell to homeowners these key facts and trends can help
          you develop your marketing materials and your messaging. You can read
          more about home owner macro trends here
          https://en.wikipedia.org/wiki/Home-ownership_in_the_United_States.
        </p>

        <h4>
          >Pinpoint homeowner targeting with {process.env.REACT_APP_site_name}
        </h4>
        <p>
          Choose from the most important search selections to target your
          homeowner leads to your businesses marketing plan.
        </p>

        <h5>Filters</h5>
        <ul>
          <li>Age</li>
          <li>Year and Month Born</li>
          <li>Estimated Income Range</li>
          <li>Homeowner Status</li>
          <li>Marital Status</li>
          <li>Gender</li>
          <li>Property Value</li>
          <li>Property Type</li>
          <li>Year Built</li>
          <li>Purchase Year</li>
          <li>Net Worth</li>
          <li>Credit Rating</li>
          <li>State</li>
          <li>County</li>
          <li>City</li>
          <li>Zip Code</li>
          <li>Area Code</li>
        </ul>
        <h4>>Ideas for using Homeowner Leads</h4>
        <ul>
          <li>
            Identify homeowners with incomes greater than $80K per year to sell
            custom remodelling projects
          </li>

          <li>
            Identify homeowners that have lived in their homes longer than five
            years to sell solar panels for their home
          </li>

          <li>
            Find homeowners that own homes that are older than 50 years for
            offering pest control and home rehabilitation services.
          </li>
        </ul>
      </React.Fragment>
    )
  },
  {
    id: "using-consumer-lists-for-telemarketing-or-direct-mail",
    name: "How to use consumer lists",
    friends: [],
    excerpt: `${process.env.REACT_APP_site_name} offers consumer lists at great prices. We offer a comprehensive database of 200M+ persons in the United States with the targeted filters you need to hone in on your market and get a great return on your marketing dollars. Our lists are great for direct mail or telemarketing. Have questions? Don’t hesitate to get in touch with us and speak to a consumer data broker who can give you in-depth information about how to use ${process.env.REACT_APP_site_name} as well as tell you more about our full service and specialty databases to supplement your consumer marketing efforts.  You don’t need to look any further than ${process.env.REACT_APP_site_name} if you are looking for great consumer marketing data. Read our customer reviews and see what thousands of professionals are saying about ${process.env.REACT_APP_site_name} and compare ${process.env.REACT_APP_site_name} to some of the other data brokers in the industry.`,
    content: (
      <React.Fragment>
        <p>
          {process.env.REACT_APP_site_name} offers consumer lists at great
          prices. We offer a comprehensive database of 200M+ persons in the
          United States with the targeted filters you need to hone in on your
          market and get a great return on your marketing dollars. Our lists are
          great for direct mail or telemarketing. Have questions? Don’t hesitate
          to get in touch with us and speak to a consumer data broker who can
          give you in-depth information about how to use{" "}
          {process.env.REACT_APP_site_name} as well as tell you more about our
          full service and specialty databases to supplement your consumer
          marketing efforts.
        </p>

        <p>
          You don’t need to look any further than{" "}
          {process.env.REACT_APP_site_name} if you are looking for great
          consumer marketing data. Read our customer reviews and see what
          thousands of professionals are saying about{" "}
          {process.env.REACT_APP_site_name} and compare{" "}
          {process.env.REACT_APP_site_name} to some of the other data brokers in
          the industry.
        </p>

        <h3>Consumer filters</h3>
        <ul>
          <li>
            Age - target adults in the United State based on their age, so you
            can send the right offers to the right age group, whether you are
            looking for young adults or seniors approaching retirement or
            anything above or between.
          </li>

          <li>
            Year and Month Born - Using year and month born filters you can get
            turning 65 lists or target consumers by birth month. However you
            want to identify age, it’s yours with these filters.
          </li>

          <li>
            Homeowner status - homeownership is an indicator of wealth, marital
            status, and spending needs. Whether you do home services like
            construction, remodel, roofing, or hvac, or you offer refinance or
            lending offers, homeowner status is a great filter for your
            targeting.
          </li>

          <li>
            Marital status - target single or married people with the right
            offers. Marital status is a great indicator for maturity and social
            interests as well as income family status.
          </li>

          <li>
            Gender - Send your marketing to men or women, so you can use the
            right language and market the right products to your audience.
          </li>

          <li>
            Property Value - property value is a great way to predict equity,
            network, and income and social status. Use this filter to make sure
            you are getting in front of the right type of homeowner.
          </li>

          <li>
            Property Type - property type is a great way to target by social and
            family status. Choose whether you are targeting single family or
            multifamily dwelling units.
          </li>

          <li>
            Year Built - target your leads by the year a home was built so that
            you can target based on the expected age of neighborhoods, whether
            its new construction or resale homes, and decide whether you should
            spend your time advertising remodel or new construction or
            landscaping services.
          </li>

          <li>
            Purchase Year - use purchase year to identify whether you are
            dealing with a long time or relatively new homeowner. This field is
            best used if you are targeting homeowners that purchased within the
            last 1 - 2 years, and not for new movers or new homeowner leads.
          </li>

          <li>
            Net Worth - use this filter to find the financial net worth that
            makes the most sense for the products or services that you offer,
            whether your insurance, lending, or home services products are best
            suited for low or high net worth individuals.
          </li>

          <li>
            Credit Rating - Credit rating is a great way to choose the credit
            range that you are targeting for your lending or financial offers
            without having to deal with secure FICO and FINRA regulation
            requirements.
          </li>

          <li>
            State - choose to filter base on State’s where you are licensed to
            do business so you don't waste time and money marketing to consumers
            that you aren’t licensed to serve.
          </li>

          <li>
            County - Use county as another great way to find the geographic
            region that is closest or best suited for your products or services.
          </li>

          <li>
            City - The city filter is great for small and medium sized
            retailers, franchises, and other small and medium sized businesses
            limited to a demographic that is within a reasonable travel
            distance.
          </li>

          <li>
            Zip Code - The zip code feature is one of the most effective ways to
            break up the total count or universe of your target demographic to
            avoid duplicate records or break down sales and marketing efforts
            into reasonable chunks.
          </li>

          <li>
            Area code - this filter is another great way to break down your
            lists into reasonable number of individuals for telemarketing and
            direct mail campaigns.
          </li>
        </ul>
        <h4>Ideas for using Consumer lists</h4>
        <ul>
          <li>
            Consumer lists are great for any business - to-consumer marketing
            campaign whether you need to do broad mass marketing or targeted
            offers. Here are few great ways our customers use our data.
          </li>

          <li>
            Telemarket consumers by geographic region to offer insurance and
            financial planning products
          </li>

          <li>
            Market to those approaching retirement with Medicare supplement plan
            offers
          </li>

          <li>
            Direct mail homeowners about your home services such as lawn care,
            roofing, HVAC, or remodeling services
          </li>

          <li>
            Telemarket to a scored list of homeowners with high credit scores to
            offer them solar panels
          </li>
        </ul>
      </React.Fragment>
    )
  },
  {
    id: "using-business-lists-for-telemarketing-and-direct-mail",
    name: "How to use business lists",
    friends: [],
    excerpt:
      "Listshack.com provides business lists at great prices. Our quality, targeted data is updated three to four times per year and includes every important filter needed to market to your best responding industries and business types. Our easy to use interface makes getting counts and downloading leads simpler than ever before. Our database of more than 15M business records include the following filters for your targeting and counts.",
    content: (
      <React.Fragment>
        <p>
          Listshack.com provides business lists at great prices. Our quality,
          targeted data is updated three to four times per year and includes
          every important filter needed to market to your best responding
          industries and business types. Our easy to use interface makes getting
          counts and downloading leads simpler than ever before. Our database of
          more than 15M business records include the following filters for your
          targeting and counts.
        </p>
        <h3>Business List Filters</h3>
        <ul>
          <li>
            Industry SIC Code - Standard Industry Classification (SIC) codes are
            the most common ways to search for businesses by industry.
            Listshackpro.com makes it easy to type in the industries you are
            looking for, like “Restaurants” or “Manufacturers” and the tool will
            recommend the SIC code closest to what you are looking for.
          </li>

          <li>
            Number of Employees - Identifying businesses by the number of
            employees, so marketing efforts can be targeted based on the company
            size, is a great way to use {process.env.REACT_APP_site_name} to
            download business lists.
          </li>

          <li>
            Sales Volume - Using the amount of sales is another great way to
            identify businesses that will best fit your sales and marketing
            efforts using {process.env.REACT_APP_site_name}. Each business has
            been segmented into easily identifiable groups to speed up the
            process of getting the best leads and the best price.
          </li>

          <li>
            Years in business - The Years in Business filter is a great way of
            indentifying mature businesses that have know-how and expertise to
            value products and services that will best solve its problems.
          </li>

          <li>
            Square Footage - The square footage feature is a great way to
            identify companies by the size of their footprint or real estate
            needs. Everything from business cleaning, maintenance, and human
            resources services can use the square footage filter to identify the
            best prospects for their services.
          </li>

          <li>
            State - choose to filter base on State’s where you are licensed to
            do business so you don't waste time and money marketing to consumers
            that you aren’t licensed to serve.
          </li>

          <li>
            County - Use county as another great way to find the geographic
            region that is closest or best suited for your products or services.
          </li>

          <li>
            City - The city filter is great for small and medium sized
            retailers, franchises, and other small and medium sized businesses
            limited to a demographic that is within a reasonable travel
            distance.
          </li>

          <li>
            Zip Code - The zip code feature is one of the most effective ways to
            break up the total count or universe of your target demographic to
            avoid duplicate records or break down sales and marketing efforts
            into reasonable chunks.
          </li>

          <li>
            Area code - this filter is another great way to break down your
            lists into reasonable number of individuals for telemarketing and
            direct mail campaigns.
          </li>
        </ul>
        <h4>
          Great Ideas for Using Business Lists to do Better Marketing and Sales
        </h4>
        <ul>
          <li>
            Create a list of businesses by number of employees to offer group
            and individual health insurance policies.
          </li>

          <li>
            Create a list of businesses in a geographic region such as state,
            zip code, area code, city or county to offer merchant processing
            services.
          </li>

          <li>
            Create a targeted list of businesses by an industry to provide
            landscaping services.{" "}
          </li>
        </ul>
      </React.Fragment>
    )
  },
  {
    id: "how-to-build-the-right-telemarketing-lists",
    name: "How To Build The Right Telemarketing Lists",
    friends: [],
    excerpt:
      "Every day millions of salespeople work hard to find new customers. One of the least expensive options for generating new business is cold calling. Why? Because building the right telemarketing list is easy. One approach to building the right type of marketing list is to spend a lot of time doing research on your ideal customer. LinkedIn has created a neat ecosystem for people to be able to search for folks in their area by industry type, position, and company. For some people this makes a lot of sense, especially if you’re working in a very specific niche and need to speak with very specific people. For most folks this easily ends up being a distracting process.",
    content: (
      <React.Fragment>
        <p>
          Every day millions of salespeople work hard to find new customers. One
          of the least expensive options for generating new business is cold
          calling. Why? Because building the right telemarketing list is easy.
          One approach to building the right type of marketing list is to spend
          a lot of time doing research on your ideal customer. LinkedIn has
          created a neat ecosystem for people to be able to search for folks in
          their area by industry type, position, and company. For some people
          this makes a lot of sense, especially if you’re working in a very
          specific niche and need to speak with very specific people. For most
          folks this easily ends up being a distracting process.
        </p>
        <p>
          So what makes a telemarketing list perfect? Obviously it’s ability to
          generate sales. The reality is that cold calling is a mass marketing
          approach vs being super specific. For example, if you selling roofs it
          would be nice if you had a list of people with roofs that were in poor
          condition, but you also know that if you call 100 people at random,
          you’re going to find a bunch of them with roofs they know need to be
          replaced. In just looking at the math, most people can make at least
          50 calls an hour. Does that sound high? Maybe at first blush, but if
          you consider that you won’t be able to reach most folks the number of
          times you’ll be pitching gets cut down quite a bit. If you hang up
          after 30 seconds of someone not answering it makes it easy to keep the
          average call at under a minute. If you can average one minute per call
          you’d even have time to take a 10 minute break while getting 50 calls
          an hour in! An average response rate on cold calling is around 2%
          which means you should be getting around one hot lead per hour. The
          right telemarketing list lets you focus on making the calls by getting
          in front of the right folks.
        </p>
        <p>
          So how specific should you be? Roughly, “close enough”. Most list
          brokers and marketers will tell you that you need a super specific and
          super accurate list. Yes those can be helpful, but for a lot of
          industries it’s overkill. Let’s go back to the roofer guy, the cost of
          getting a list of homeowners is relatively low (link to list pricing),
          but getting a list of folks specifically that have damage to the roof
          could be incredibly expensive (basically paying someone to drive
          around, look at the roofs, take down the address, and then look up the
          phone numbers). So if you called a list of homeowners you may very
          well end up talking to folks that already have a new roof, but the
          overall cost of calling those folks that might not need your service
          is a lot lower than the additional cost of getting a more targeted
          list.
        </p>
        <h4>Are Premium Filters Worth the Premium?</h4>
        <p>
          Not every industry has the luxury of being able to access premium
          filters, but some do. For example, mortgage brokers like working with
          “trigger data”. Trigger data is a marketing list that’s created based
          off a recent credit inquiry or “trigger”. For example, if someone
          fills out a credit application for a mortgage it indicates they may be
          a good prospect for a competitive mortgage company so mortgage brokers
          will pay a premium for it. While that is useful, it can also be more
          than 100 times the cost of traditional marketing lists! Because of
          that, most mortgage brokers and call centers will simply call on
          traditional marketing lists vs going through the time and expense of
          premium filters.
        </p>
        <h4>Using Alternative List Filters</h4>
        <p>
          Sometimes the filter you want just isn’t there, but you do have
          options. One of the best examples of this is getting a list of people
          on Medicare. There are thousands of agents looking to help seniors
          with their Medicare needs, but they can’t just buy a list of people on
          Medicare. Fun fact about Medicare, is nearly 97% of americans over the
          age of 65 are on it. Since the age filter is super reliable, getting a
          list of Medicare eligible seniors is super easy because it’s as simple
          as getting a list of consumers age 65 or older.
        </p>
        <h4>What Consumer Telemarketing List Filters are the Most Useful</h4>
        <p>
          If you’re trying to generate new customers you obviously know
          something about them. The most common filters would be age, income,
          homeowner status, property value, and credit score. Generally the
          easier it is to get a type of data the more reliable that data will
          be. For example, if you’re looking for a list of people that like
          green jello on Thursdays you’d usually be dealing with responder type
          data which heavily relies on reaching out to consumers and getting a
          response. This tends to skew results and limit the amount of available
          data. On the other hand if you’re in the residential construction
          business and want to be targeting people in homes that are at least
          ten years old it’s pretty easy to reliably get that information as
          it’s generally publicly available from county tax records.
        </p>
        <p>
          An example of a filter that’s a little bit more of mixed results is
          the income filter. The specifics on how a compiler will get this
          information can vary quite a bit, but it’s usually a combination of
          census and credit data. There are some skews so don’t be surprised if
          you end up finding folks that allegedly have high incomes living in
          low income areas or if you find millionaires listed with incomes under
          $20,000 per year.
        </p>
        <h4>What Business Telemarketing List Filters are the Most Useful</h4>
        <p>
          When it comes to business to business marketing a lot of marketing is
          done through networking, but cold calling still works. The most common
          business telemarketing list filters are industry type, number of
          employees, and sales revenue. Some list brokers will include specific
          contact information as well, but that can be very difficult to
          maintain as people are constantly changing jobs and there isn’t a
          great way for the compilers to keep that information updated.
        </p>
        <p>
          Of course any list broker should let you search by geography such as
          by state, by county, by area code, by zip code, or even a few others
          you may not heard of such as carrier route.
        </p>
      </React.Fragment>
    )
  }
];

function find(id) {
  return Articles.find(p => p.id === id);
}
