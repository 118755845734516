import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { db, getUserDocs, getCustomerDocs } from "./firebase.jsx";
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Form,
  ListGroup,
  Alert,
  Card,
  Table,
  Modal,
  ButtonGroup
} from "react-bootstrap";
import { Plans, Loading, toEpochISO } from "./interfaceListShackPro.jsx";
import { sendEmail } from "./email.jsx";
import { Collection, CollectionContext } from "./firebaseView.jsx";
import AutoComplete from "./autoComplete.jsx";
import { CopyToClipboard } from "react-copy-to-clipboard";

const SuperadminLanding = props => {
  return (
    <Container fluid>
      <Row>
        <Col className="m-5" style={{ minHeight: "75vh" }}>
          <h1>Super admin</h1>
          <p className="lead">
            This page is your home base for handling customer support and
            billing issues for {process.env.REACT_APP_site_name}.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

const SuperAdmin = props => {
  let { state, handleState } = props;
  return (
    <Alert
      variant="dark"
      className="mb-0 d-flex flex-row align-items-center justify-content-between"
    >
      <div className="d-flex justify-content-center align-items-center">
        <i className="fa fa-eye-slash" style={{ fontSize: "36px" }}></i>{" "}
        <Link to="/superadmin">
          <span className="ml-3">SuperAdmin Menu</span>
        </Link>
      </div>
      <div className="d-flex flex-row mb-0">
        <Link to="/superadmin_errors">
          <Button variant="link">Errors</Button>
        </Link>
        <Link to="/superadmin/manage">
          <Button variant="link">View All Users</Button>
        </Link>
        {!state.impersonating && (
          <SearchUsers
            selectCallback={props.selectCallback}
            state={state}
            handleState={handleState}
          />
        )}

        {state.impersonating && (
          <React.Fragment>
            <Button
              onClick={async () => {
                let superAdminDoc = JSON.parse(
                  window.localStorage.getItem("superAdminDoc")
                );
                //console.log( "localStorage.superAdminDoc: ", superAdminDoc );
                await props.selectCallback(superAdminDoc);
                handleState({
                  impersonating: false
                });
              }}
            >
              Stop Impersonating {state.userDoc.email}
            </Button>
          </React.Fragment>
        )}
      </div>
    </Alert>
  );
};

const SearchUsers = props => {
  let [suggestions, setSuggestions] = useState(false);
  let [searchText, setSearchText] = useState("");
  let [searchTimer, setSearchTimer] = useState(() => {});
  let showSuggestions = searchText.length > 0 && suggestions.length > 0;
  let searchWidth = "300px";
  const searchUsers = async searchText => {
    try {
      let users = [];
      await db
        .collection("users")
        .where("email", "==", searchText)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            users.push(doc.data());
          });
        });
      //console.log("users: ", users);
      return users;
    } catch (err) {
      console.log("Error searching users: ", err);
    }
  };

  const addSuggestions = userObjects => {
    let suggestions = [];
    userObjects.map((userObject, index) => {
      return suggestions.push(
        <ListGroup.Item
          key={index}
          variant="light"
          onClick={() => props.selectCallback(userObject)}
          action
          className="d-flex flex-row justify-content-between"
          style={{
            width: searchWidth
          }}
        >
          {userObject.email}{" "}
          {userObject.uid === userObject.id ? (
            <Badge variant="primary">Owner</Badge>
          ) : (
            <Badge variant="secondary">User</Badge>
          )}
        </ListGroup.Item>
      );
    });
    if (suggestions.length === 0) {
      suggestions.push(
        <ListGroup.Item
          style={{
            width: searchWidth
          }}
        >
          No user documents match
        </ListGroup.Item>
      );
    }
    return setSuggestions(suggestions);
  };

  return (
    <Form.Group className="mb-0">
      <Form.Control
        type="text"
        placeholder="Search Users By Email"
        onChange={e => {
          let value = e.target.value;
          setSearchText(value);
          clearTimeout(searchTimer);
          setSearchTimer(() =>
            setTimeout(async () => {
              let users = await searchUsers(value.toLowerCase());
              addSuggestions(users);
            }, 1000)
          );
        }}
        value={searchText}
        autoComplete="off"
        style={{
          width: searchWidth
        }}
      />
      {showSuggestions && (
        <ListGroup
          style={{
            width: "94.5%",
            zIndex: "1000",
            position: "absolute"
          }}
        >
          {suggestions}
        </ListGroup>
      )}
    </Form.Group>
  );
};

const AdminPage = props => {
  console.log("AdminPage props: ", props);
  let { uid } = useParams();
  const { apiKey, handleState, handleAlerts } = props;
  const [userDoc, setUserDoc] = useState(null);
  const [customerDoc, setCustomerDoc] = useState(null);
  const [leads, setLeads] = useState(props.userDoc.leads);
  const [leadsRemaining, setLeadsRemaining] = useState(
    props.userDoc.leadsRemaining
  );
  const [downloadLimit, setDownloadLimit] = useState(
    props.userDoc.downloadLimit
  );
  const [includesRollover, setIncludesRollover] = useState(
    props.userDoc.includesRollover
  );
  const [resetDay, setResetDay] = useState(props.userDoc.billing_cycle_anchor);
  const [monthUser, setMonthUser] = useState(new Date().getMonth());
  const [dayUser, setDayUser] = useState(
    new Date(props.userDoc.billing_cycle_anchor * 1000).getDate()
  );
  const [yearUser, setYearUser] = useState(new Date().getFullYear());

  const [sub_checked, setSubChecked] = useState(
    props.customerDoc.sub_checked || 0
  );
  const [customer_id, setCustomer_id] = useState(
    props.customerDoc.customer_id || 0
  );
  const [subscription_status, setSubscription_status] = useState(
    props.customerDoc.subscription_status || ""
  );
  const [plan_id, setStripe_plan_id] = useState(
    props.customerDoc.plan_id || ""
  );
  const [subscription_plan, setSubscription_plan] = useState(
    props.customerDoc.subscription_plan || ""
  );
  const [month, setMonth] = useState(new Date().getMonth());
  const [day, setDay] = useState(new Date().getDate());
  const [year, setYear] = useState(new Date().getFullYear());
  //console.log("includesRollover", includesRollover);
  const btRef =
    process.env.NODE_ENV === "development"
      ? "https://lsp.recurly.com/accounts/"
      : "https://listshack.recurly.com/accounts/";

  const [user, setUser] = useState(null);
  const [downloads, setDownloads] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const warmBoot = async (
    pUserDoc = props.userDoc,
    pCustomerDoc = props.customerDoc
  ) => {
    if (!user) {
      getUserData({ uid: pUserDoc.uid, apiKey }, user => setUser(user));
    }
    if (!downloads) {
      getDownloads({ aid: pUserDoc.uid, apiKey }, downloads =>
        setDownloads(downloads)
      );
    }
    if (!userDoc) {
      listenDoc({ _docRef: pUserDoc._docRef }, doc => setUserDoc(doc));
    }
    if (!customerDoc) {
      listenDoc({ _docRef: pCustomerDoc._docRef }, doc => setCustomerDoc(doc));
    }
  };

  const coldBoot = async () => {
    try {
      let userDoc = await getUserDocs(uid);
      let customerDoc = await getCustomerDocs(userDoc[0].email);
      warmBoot(userDoc[0], customerDoc[0]);
    } catch (err) {
      console.log("Error coldbooting: ", err);
    }
  };

  useEffect(() => {
    if (typeof props.userDoc === "undefined") {
      coldBoot();
    }
    if (typeof props.userDoc !== "undefined") {
      warmBoot();
    }

    handleState({ noDistract: true });

    return () => handleState({ noDistract: false });
  }, []);

  //console.log("user: ", user);
  console.log("userDoc: ", userDoc, "user: ", user);

  return (
    <Container>
      <Row style={{ minHeight: "75vh" }} className="align-items-start mb-5">
        <Col xs={12} className="m-3">
          <Link to="/superadmin/manage">
            <i className="fa fa-chevron-left mr-2" aria-hidden="true"></i>View
            all users
          </Link>
        </Col>
        <Col xs={12} className="text-center mt-3 mb-3">
          {props.title ? (
            props.title
          ) : (
            <h2 className="m-5">Super Admin Management</h2>
          )}
          {userDoc && customerDoc && (
            <Card>
              <Card.Header className="d-flex flex-row justify-content-between align-items-center">
                <h3>Summary</h3>
                <div>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      props.impersonateUser(props.userDoc);
                    }}
                    variant="outline-primary"
                    className="mr-1"
                  >
                    Impersonate
                  </Button>
                  <RecurlyAccount {...props} btRef={btRef} />
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col className="text-left">
                    <h5>Company information</h5>
                    {userDoc.companyProfile && (
                      <React.Fragment>
                        Company name: {userDoc.companyProfile.name}
                        <br />
                        Phone:{" "}
                        <a
                          href={`tel:${userDoc.companyProfile.phone.replace(
                            /\D/g,
                            ""
                          )}`}
                        >
                          {userDoc.companyProfile.phone}
                        </a>
                        <br />
                        Company email:{" "}
                        <a href={`mailto:${userDoc.companyProfile.email}`}>
                          {userDoc.companyProfile.email}
                        </a>
                        <br />
                      </React.Fragment>
                    )}
                    Email:{" "}
                    <CopyToClipboard
                      text={userDoc.email}
                      onCopy={() =>
                        handleAlerts(
                          "",
                          `Copied email ${userDoc.email}`,
                          "success"
                        )
                      }
                    >
                      <code>{userDoc.email}</code>
                    </CopyToClipboard>{" "}
                    <br />
                    Account verified:{" "}
                    {userDoc.verified ? (
                      <i className="fa fa-check" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-minus-circle" aria-hidden="true"></i>
                    )}
                    <Button
                      variant="light"
                      size="sm"
                      className="ml-2"
                      onClick={e => {
                        e.preventDefault();
                        db.collection("users")
                          .doc(userDoc._docId)
                          .update({
                            verified: userDoc.verified
                              ? !userDoc.verified
                              : true
                          })
                          .catch(err =>
                            console.log("Error marking verified: ", err)
                          );
                      }}
                    >
                      {userDoc.verified ? "unverify" : "verify"}
                    </Button>
                  </Col>
                  <Col className="text-left">
                    <h5>Account information</h5>
                    Account id: <code>{userDoc.id}</code>
                    <br />
                    Customer id:{" "}
                    <code>
                      {customerDoc.customer_id
                        ? customerDoc.customer_id
                        : " -- "}
                    </code>
                    <br />
                    Subscription id:{" "}
                    <code>
                      {customerDoc.subscription_id
                        ? customerDoc.subscription_id
                        : customerDoc.subscripton_uuid
                        ? customerDoc.subscription_uuid
                        : " -- "}
                    </code>
                    <br />
                    Subscription plan:{" "}
                    <code>
                      {customerDoc.subscription_id
                        ? Object.values(Plans).find(
                            plan => plan.planID === customerDoc.plan_id
                          )
                          ? Object.values(Plans).find(
                              plan => plan.planID === customerDoc.plan_id
                            )["shortname"]
                          : " -- "
                        : " -- "}
                    </code>
                    <br />
                    Plan id:{" "}
                    <code>
                      {customerDoc.plan_id ? customerDoc.plan_id : " -- "}
                    </code>
                  </Col>
                  <Col className="mt-3 text-left" xs={12}>
                    <h5>Team members</h5>
                    <Table size="sm" className="text-left" responsive striped>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!userDoc.companyUsers && (
                          <tr>
                            <td colSpan="3">No company users</td>
                          </tr>
                        )}
                        {userDoc.companyUsers &&
                          userDoc.companyUsers.map((user, i) => {
                            return (
                              <tr key={`user_${i}`}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col xs={12} className="mb-3">
          <Card>
            <Card.Header>
              <div className="d-flex flex-row justify-content-between">
                <span>Fraud Check</span>
                {userDoc && (
                  <Button
                    onClick={async () => {
                      console.log("Clicked mark fraudulent!");
                      userDoc._docRef
                        .update({
                          fraudulent: userDoc.fraudulent
                            ? !userDoc.fraudulent
                            : true
                        })
                        .catch(err => {
                          console.log(
                            "Error marking userdoc fraudulent: ",
                            err
                          );
                        });

                      customerDoc._docRef
                        .update({
                          subscription_status: userDoc.fraudulent
                            ? "active"
                            : "canceled"
                        })
                        .catch(err => {
                          console.log(
                            "Error marking customerDoc fraudulent: ",
                            err
                          );
                        });
                      updateUserData(
                        {
                          apiKey,
                          uid: user.uid,
                          data: {
                            disabled: userDoc.fraudulent ? false : true
                          }
                        },
                        user => setUser(user)
                      );
                    }}
                    variant={userDoc.fraudulent ? "danger" : "outline-danger"}
                  >
                    {!userDoc.fraudulent
                      ? "Mark Fraudulent"
                      : "Unmark Fraudulent"}
                  </Button>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <h5>Authentication</h5>
                  {user && (
                    <React.Fragment>
                      Last login:{" "}
                      <code>
                        {new Date(
                          user.metadata.lastSignInTime
                        ).toLocaleString()}
                      </code>
                      &nbsp;{" "}
                      <i className="text-muted">
                        {Math.floor(
                          (new Date().getTime() -
                            new Date(user.metadata.lastSignInTime).getTime()) /
                            (1000 * 60 * 60 * 24)
                        )}{" "}
                        days ago
                      </i>
                      <br />
                      Created:{" "}
                      <code>
                        {new Date(user.metadata.creationTime).toLocaleString()}
                      </code>
                      &nbsp;{" "}
                      <i className="text-muted">
                        {Math.floor(
                          (new Date().getTime() -
                            new Date(user.metadata.creationTime).getTime()) /
                            (1000 * 60 * 60 * 24)
                        )}{" "}
                        days ago
                      </i>
                    </React.Fragment>
                  )}
                </Col>
                <Col>
                  <h5>Download activity</h5>
                  {userDoc && downloads !== null && (
                    <React.Fragment>
                      Total downloads:{" "}
                      <code>{downloads.length.toLocaleString()}</code>
                      <Button
                        variant="light"
                        size="sm"
                        onClick={() => setShowModal(true)}
                        className="ml-2"
                      >
                        view
                      </Button>
                      <br />
                      Last download:{" "}
                      <code>
                        {downloads.length > 0
                          ? new Date(
                              newestObject({
                                array: downloads,
                                key: "timestamp"
                              }).timestamp.seconds * 1000
                            ).toLocaleString()
                          : "None"}
                      </code>
                      {downloads.length > 0 ? (
                        <React.Fragment>
                          &nbsp;{" "}
                          <i className="text-muted">
                            {Math.floor(
                              (new Date().getTime() -
                                new Date(
                                  newestObject({
                                    array: downloads,
                                    key: "timestamp"
                                  }).timestamp.seconds * 1000
                                ).getTime()) /
                                (1000 * 60 * 60 * 24)
                            )}{" "}
                            days ago
                          </i>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}
                </Col>
                {userDoc && userDoc.fraudulent && customerDoc && (
                  <Col xs={12} className="text-center">
                    <hr />
                    <p className="lead text-danger">
                      Remember to cancel the subscription &amp; refund all
                      payments in Recurly!
                    </p>
                    <RecurlyAccount btRef={btRef} customerDoc={customerDoc} />
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Header>Usage &amp; Plan Limits (UserDoc)</Card.Header>
            <Card.Body>
              <Row>
                {userDoc && (
                  <Col>
                    <Form>
                      <Form.Group>
                        <Form.Label>Day Leads Reset</Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              type="text"
                              value={new Date(
                                userDoc.billing_cycle_anchor * 1000
                              ).toLocaleString()}
                              readOnly={true}
                            />
                          </Col>
                          <Col>
                            <Form.Row>
                              <Form.Label column="sm" className="text-right">
                                Day of Month
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  size="sm"
                                  type="hidden"
                                  value={monthUser + 1}
                                  onChange={e =>
                                    setMonthUser(parseInt(e.target.value) - 1)
                                  }
                                  placeholder="12"
                                />
                                <Form.Control
                                  size="sm"
                                  type="text"
                                  value={dayUser}
                                  onChange={e => setDayUser(e.target.value)}
                                  placeholder="15"
                                />
                                <Form.Control
                                  type="hidden"
                                  value={yearUser}
                                  size="sm"
                                  onChange={e => setYear(e.target.value)}
                                  placholder="2020"
                                />
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => {
                                    let newEpoch = Math.round(
                                      new Date(
                                        yearUser,
                                        monthUser,
                                        dayUser
                                      ).getTime() / 1000
                                    );
                                    console.log("newEpoch: ", newEpoch);
                                    setResetDay(newEpoch);
                                  }}
                                  variant="outline-dark"
                                  size="sm"
                                >
                                  Update
                                </Button>
                              </Col>
                            </Form.Row>
                          </Col>
                        </Form.Row>
                        <Form.Text>
                          Use this to change the date that a user's leads are
                          reset each month. Usually, this should match the date
                          that a user's subscription is billed each month.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Set leads per cycle</Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              type="text"
                              value={userDoc.leads}
                              readOnly={true}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              value={leads}
                              onChange={e => {
                                //e.preventDefault();
                                setLeads(e.target.value);
                              }}
                            />
                          </Col>
                        </Form.Row>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="text-muted">
                          Leads rollover each month
                        </Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              type="text"
                              value={userDoc.includesRollover ? "Yes" : "No"}
                              readOnly={true}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Leads Rollover"
                              checked={includesRollover}
                              onChange={e => {
                                //e.preventDefault();
                                //console.log("includesRollover", includesRollover);
                                setIncludesRollover(!includesRollover);
                              }}
                            />
                          </Col>
                        </Form.Row>
                        <Form.Text>
                          This value is set based on the plan that a user picks
                          when they subscribe. However, it can be overriden on a
                          per account basis.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Set Leads remaining</Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              type="number"
                              value={userDoc.leadsRemaining}
                              readOnly={true}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              value={leadsRemaining}
                              onChange={e => {
                                //e.preventDefault();
                                setLeadsRemaining(e.target.value);
                              }}
                            />
                          </Col>
                        </Form.Row>
                        <Form.Text>
                          These are the leads the user has left in the current
                          monthly cycle. This number resets or gets added to
                          each month based on the Day Leads Reset above.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Set Download Limit</Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              type="number"
                              value={userDoc.downloadLimit}
                              readOnly={true}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              value={downloadLimit}
                              onChange={e => {
                                //e.preventDefault();
                                setDownloadLimit(e.target.value);
                              }}
                            />
                          </Col>
                        </Form.Row>
                        <Form.Text>
                          This determines how many leads users can download in
                          each file.
                        </Form.Text>
                      </Form.Group>
                    </Form>
                  </Col>
                )}
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                className="pull-right"
                onClick={e => {
                  e.preventDefault();
                  console.log("Submitted UserDoc Form");
                  try {
                    db.collection("users")
                      .doc(props.userDoc.id)
                      .update({
                        billing_cycle_anchor: resetDay,
                        leads: parseInt(leads),
                        leadsRemaining: parseInt(leadsRemaining),
                        includesRollover,
                        downloadLimit: parseInt(downloadLimit),
                        adminUpdate: new Date()
                      });
                  } catch (err) {
                    console.log("Error updating User Document: ", err);
                  }
                }}
                disabled={
                  resetDay === props.userDoc.billing_cycle_anchor &&
                  leads === props.userDoc.leads &&
                  leadsRemaining === props.userDoc.leadsRemaining &&
                  includesRollover === props.userDoc.includesRollover &&
                  downloadLimit === props.userDoc.downloadLimit
                }
              >
                Update User Document
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col xs="12" className="mt-5">
          <Card>
            <Card.Header>
              Subscription &amp; Plan Information (CustomerDoc)
              {customerDoc && (
                <RecurlyAccount
                  className="pull-right"
                  btRef={btRef}
                  customerDoc={customerDoc}
                />
              )}
            </Card.Header>
            <Card.Body>
              <Row>
                {customerDoc && (
                  <Col>
                    <Form>
                      <Form.Group>
                        <Form.Label>Require Subscription</Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              type="text"
                              value={new Date(
                                (sub_checked + 86400) * 1000
                              ).toLocaleString()}
                              disabled={true}
                            />
                          </Col>
                          <Col>
                            <Form.Row>
                              <Form.Label column="sm" className="text-right">
                                Month
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  size="sm"
                                  type="text"
                                  value={month + 1}
                                  onChange={e =>
                                    setMonth(parseInt(e.target.value) - 1)
                                  }
                                  placeholder="12"
                                />
                              </Col>
                              <Form.Label column="sm" className="text-right">
                                Day
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  size="sm"
                                  type="text"
                                  value={day}
                                  onChange={e => setDay(e.target.value)}
                                  placeholder="15"
                                />
                              </Col>
                              <Form.Label column="sm" className="text-right">
                                Year
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  type="text"
                                  value={year}
                                  size="sm"
                                  onChange={e => setYear(e.target.value)}
                                  placholder="2020"
                                />
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => {
                                    let newEpoch = Math.round(
                                      new Date(year, month, day).getTime() /
                                        1000 -
                                        86400
                                    );
                                    console.log("newEpoch: ", newEpoch);
                                    setSubChecked(newEpoch);
                                  }}
                                  variant="outline-dark"
                                  size="sm"
                                >
                                  Update
                                </Button>
                              </Col>
                            </Form.Row>
                          </Col>
                        </Form.Row>
                        <Form.Text>
                          Use this to allow users to access their account
                          without checking Braintree for their subscription
                          status. For example, for providing a free trial.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Subscription status</Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              type="text"
                              disabled={true}
                              value={customerDoc.subscription_status}
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              as="select"
                              onChange={e => {
                                setSubscription_status(e.target.value);
                              }}
                            >
                              <option className="text-muted">
                                Select new status
                              </option>
                              <option value="active">Active</option>
                              <option value="canceled">Canceled</option>
                              <option value="past due">Past Due</option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                        <Form.Text>
                          Manually set the users subscription status. Note that
                          the subscription status will automatically be updated
                          based on the users braintree subscription status
                          unless you change the Require Subscription date above.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Subscription Plan</Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              type="text"
                              disabled={true}
                              value={
                                Object.values(Plans).find(
                                  plan => customerDoc.plan_id === plan.planID
                                )
                                  ? Object.values(Plans).find(
                                      plan =>
                                        customerDoc.plan_id === plan.planID
                                    ).shortname
                                  : ""
                              }
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              as="select"
                              onChange={e => {
                                let plan = JSON.parse(e.target.value);
                                setStripe_plan_id(plan.planID);
                                setSubscription_plan(plan.shortname);
                              }}
                            >
                              <option>Select new plan</option>
                              {Object.values(Plans).map((plan, i) => {
                                return (
                                  <option
                                    key={`plan_option_${i}`}
                                    value={JSON.stringify(plan)}
                                  >
                                    {plan.shortname}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Col>
                        </Form.Row>
                        <Form.Text>
                          Change the subscription plan manually to enable the
                          features that cannot be set in the user document. For
                          example to increase the number of users or enable
                          search suppressions. To change the number of leads,
                          whether the leads rollover each month, or the download
                          limit change the user document for the user. Also note
                          that if you change the plan manually, the user's
                          billing settings will reflect the List price for the
                          plan, but their monthly billing amount will not
                          change.
                        </Form.Text>
                      </Form.Group>
                    </Form>
                  </Col>
                )}
              </Row>
            </Card.Body>
            <Card.Footer>
              {customerDoc && (
                <Button
                  className="pull-right"
                  onClick={e => {
                    e.preventDefault();
                    console.log("Submitted UserDoc Form");
                    try {
                      db.collection("customers")
                        .doc(props.customerDocId)
                        .update({
                          customer_id,
                          subscription_status,
                          plan_id,
                          subscription_plan,
                          sub_checked
                        });
                    } catch (err) {
                      console.log("Error updating Customer Document: ", err);
                    }
                  }}
                  disabled={
                    customer_id === customerDoc.customer_id &&
                    subscription_status === customerDoc.subscription_status &&
                    plan_id === customerDoc.plan_id &&
                    subscription_plan === customerDoc.subscription_plan &&
                    sub_checked === customerDoc.sub_checked
                  }
                >
                  Update Customer Document
                </Button>
              )}
            </Card.Footer>
          </Card>
        </Col>
        {showModal && (
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>Downloads</Modal.Header>
            <Modal.Body>
              {downloads && (
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Search name</th>
                      <th>Count</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {downloads.map(dl => {
                      return (
                        <tr key={dl.timestamp.seconds}>
                          <td>{dl.searchName ? dl.searchName : "DEFAULT"}</td>
                          <td>{parseInt(dl.downloadCount).toLocaleString()}</td>
                          <td>
                            {new Date(
                              dl.timestamp.seconds * 1000
                            ).toLocaleString()}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="light"
                onClick={() => setShowModal(false)}
                className="pull-right"
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Row>
    </Container>
  );
};

const AdminIndex = props => {
  console.log("AdminIndex props: ", props);
  let { url } = useRouteMatch();
  let { uid } = useParams();
  console.log("url: ", url, "uid: ", uid);
  const { apiKey, handleAlerts, handleIsApp } = props;
  const [index, setIndex] = useState(props.index || "customers");
  const customersIndex = index === "customers" ? true : false;
  const usersIndex = index === "users" ? true : false;
  const downloadsIndex = index === "downloads" ? true : false;
  const [userDocs, setUserDocs] = useState([]);
  const [customerDocs, setCustomerDocs] = useState([]);
  const [downloads, setDownloads] = useState([]);
  const [where, setWhere] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [uuids, setUuids] = useState([]);
  //const [emails,setEmails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showMigrate, setShowMigrate] = useState(false);
  const [plainText, setPlainText] = useState("");
  const [html, setHtml] = useState("");
  //const [from, setFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [showAdminPage, setShowAdminPage] = useState(false);
  const [userDoc, setUserDoc] = useState(null);
  const [customerDoc, setCustomerDoc] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [clientSort, setClientSort] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const getUsersCallback = useCallback(
    async (params, callback) => {
      let { ref, whereA, orderByA, index, clientSort } = params;
      console.log("getUsers() where: ", whereA, "orderBy: ", orderByA);
      if (whereA) {
        for (let param of whereA) {
          ref = ref.where(param[0], param[1], param[2]);
        }
      }
      if (orderByA) {
        for (let oParam of orderByA) {
          console.log("oParam: ", oParam);
          ref = ref.orderBy(oParam[0], oParam[1]);
        }
      }
      await ref.onSnapshot(snapShot => {
        let docs = [];
        snapShot.forEach(doc => {
          let docData = doc.data();
          docData._docId = doc.id;
          docData._docRef = doc.ref;
          docs.push(docData);
        });

        if (callback) {
          callback(docs);
        }
        if (whereA) {
          setWhere(whereA);
        }
        if (orderByA) {
          setOrderBy(orderByA);
        }
      });
    },
    [customersIndex]
  );

  const findSelectedUser = useCallback(
    async (params, callback) => {
      console.log("Ran this!");
      if (selectedUser.length === 0 && typeof uid !== "undefined") {
        let sUser = userDocs.find(doc => doc.uid === uid);
        let sCustomer = customerDocs.find(doc => doc.uid === uid);
        console.log("sUser: ", sUser, "sCustomer: ", sCustomer);
        if (sUser && sCustomer) {
          setSelectedUser([sUser, sCustomer]);
          if (callback) {
            callback();
          }
        }
      }
      if (typeof uid === "undefined") {
        if (callback) {
          callback();
        }
      }
    },
    [customerDocs, userDocs]
  );

  //console.log("userDocs: ", userDocs, "customerDocs: ", customerDocs);

  useEffect(() => {
    handleIsApp(false);
    //bootUp( null, () => setLoaded(true) );
    if (userDocs.length === 0) {
      getUsersCallback(
        {
          ref: db.collection("users")
        },
        docs => setUserDocs(docs)
      );
    }

    if (customerDocs.length === 0) {
      getUsersCallback(
        {
          ref: db.collection("customers")
        },
        docs => setCustomerDocs(docs)
      );
    }

    if (downloads.length === 0) {
      let start = new Date();
      start.setMonth(start.getMonth() - 1);
      start.setDate(start.getDate() - 1);
      start.setHours(0, 0, 0, 0);
      let end = new Date();
      end.setHours(23, 59, 59, 999);
      getUsersCallback(
        {
          ref: db
            .collectionGroup("files")
            .where("timestamp", "<=", end)
            .where("timestamp", ">=", start)
            .orderBy("timestamp", "desc")
        },
        docs => setDownloads(docs)
      );
    }

    if (selectedUser.length === 0) {
      findSelectedUser(null, () => setLoaded(true));
    }
  }, [getUsersCallback, handleIsApp, findSelectedUser]);

  const handleSelectUuid = (uid, uidS) => {
    console.log("fired handleSelectUuid: ", uuids.includes(uidS));
    let cuuids = [...uuids];
    if (uid && uuids.includes(uidS)) {
      for (var i = 0; i <= cuuids.length; i++) {
        if (cuuids[i] === uidS) {
          cuuids.splice(i, 1);
          console.log("cuuids: ", cuuids);
          break;
        }
      }
      setUuids(cuuids);
    } else {
      cuuids.push(uidS);
      setUuids(cuuids);
    }
    console.log(uidS, cuuids);
  };

  const emails = [];
  if (uuids.length) {
    console.log("There are uuids, should go to for loop");
    for (let doc of userDocs) {
      //console.log("doc: ", doc);
      if (doc.uid && uuids.includes(doc.uid)) {
        if (doc.companyProfile && doc.companyProfile.email) {
          emails.push(doc.email);
        } else {
          if (doc.email) {
            emails.push(doc.email);
          }
        }
      }
    }
  }
  const ResetQueryButton = props => {
    return (
      <Button
        variant="outline-dark"
        className="pull-right mr-2"
        size="sm"
        disabled={!where && !orderBy}
        onClick={() => {
          getUsersCallback([], []);
        }}
      >
        Reset
      </Button>
    );
  };
  const SendEmailButton = props => {
    return (
      <Button
        variant="dark"
        disabled={uuids.length ? false : true}
        onClick={() => setShowModal(true)}
        size="sm"
        className="pull-right mr-3 mb-2"
      >
        Send email
      </Button>
    );
  };

  const RefreshRecurlyButton = props => {
    return (
      <Button
        variant="outline-danger"
        className="pull-right mr-3 mb-2"
        size="sm"
        disabled={uuids.length ? (refreshing ? true : false) : false}
        onClick={async () => {
          let { apiKey } = props;
          setRefreshing(true);
          for (let uuid of uuids) {
            let customerDoc = customerDocs.find(doc => uuid === doc.uid);
            console.log("customerDoc: ", customerDoc);
            let userDoc = userDocs.find(doc => doc.uid === customerDoc.uid);
            console.log("userDoc: ", userDoc);
            if (
              typeof customerDoc !== "undefined" &&
              typeof userDoc !== "undefined"
            ) {
              await refreshRecurlyInfo({ customerDoc, userDoc, apiKey });
            }
          }
          setRefreshing(false);
        }}
      >
        {refreshing ? "Refreshing accounts" : "Refresh Subscription"}
      </Button>
    );
  };

  const openAdminPage = doc => {
    let uD = userDocs.find(uDoc => uDoc.uid === doc.uid);
    console.log("uD: ", uD, "doc: ", doc);
    setSelectedUser([uD, doc]);
    //setShowAdminPage(true);
  };

  if (clientSort && customerDocs) {
    console.log("index: ", index, "clientSort: ", clientSort);
    if (clientSort.index === "customers") {
      customerDocs.sort(clientSort.func);
    }
    if (clientSort.index === "users") {
      userDocs.sort(clientSort.func);
    }
  }

  console.log("selectedUser: ", selectedUser);
  if (!loaded) {
    return <Loading />;
  }

  if (url === "/superadmin/manage") {
    return (
      <Container fluid>
        <Row style={{ minHeight: "75vh" }}>
          <Col>
            <Row className="bg-light sticky-top pt-3">
              <Col xs={12} sm={8}>
                <SelectIndex
                  size="sm"
                  searchOptions={
                    index === "customers"
                      ? ["email", "uid"]
                      : ["email", "uid", "id"]
                  }
                  callback={openAdminPage}
                  indexCallback={ndx => setIndex(ndx)}
                  index={index}
                  docs={customersIndex ? customerDocs : userDocs}
                  searchBy={"email"}
                />
              </Col>
              <Col xs={12} sm={4}>
                <SendEmailButton />
                <RefreshRecurlyButton {...props} />
                <ResetQueryButton />
              </Col>
            </Row>
            <Table striped bordered hover size="sm" responsive>
              {usersIndex && (
                <React.Fragment>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <Form.Check
                          type="checkbox"
                          onChange={() => {
                            console.log("uuids.length: ", uuids.length);
                            if (uuids.length) {
                              return setUuids([]);
                            }
                            let allIds = [];
                            userDocs.map(doc => {
                              return doc.id
                                ? allIds.push(doc.id.toString())
                                : null;
                            });
                            console.log("allIds: ", allIds);
                            return setUuids(allIds);
                          }}
                        />
                      </th>
                      <th>UID</th>
                      <th>A ID</th>
                      <th>Email</th>
                      <th>Company</th>
                      <th>Company email</th>
                      <th>Company Phone</th>
                      <th>Users</th>
                      <th>Last Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userDocs.map((doc, i) => {
                      const {
                        uid,
                        id,
                        email,
                        companyProfile,
                        users,
                        lrUpdated
                      } = doc;
                      const uidS = uid ? uid.toString() : null;
                      const idS = id ? id.toString() : null;
                      return (
                        <tr key={`${uid}_${i}`}>
                          <td className="text-center">
                            <Form.Check
                              type="checkbox"
                              checked={
                                uid
                                  ? uuids.includes(uidS)
                                    ? true
                                    : false
                                  : false
                              }
                              onChange={() => {
                                handleSelectUuid(uid, uidS);
                              }}
                            />
                          </td>
                          <td>{uid ? uidS.slice(uidS.length - 5) : "-"}</td>
                          <td>{id ? idS.slice(idS.length - 5) : "-"}</td>
                          <td>
                            {email ? (
                              <Link
                                to={`/superadmin/manage/${id}`}
                                onClick={() =>
                                  openAdminPage(
                                    customerDocs.find(
                                      cDoc => doc.id === cDoc.uid
                                    )
                                  )
                                }
                              >
                                {email}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {companyProfile
                              ? companyProfile.name
                                ? companyProfile.name
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {companyProfile
                              ? companyProfile.email
                                ? companyProfile.email
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {companyProfile
                              ? companyProfile.phone
                                ? companyProfile.phone
                                : "-"
                              : "-"}
                          </td>
                          <td>{users ? users.length : "-"}</td>
                          <td>
                            {lrUpdated
                              ? new Date(
                                  lrUpdated.seconds * 1000
                                ).toDateString()
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </React.Fragment>
              )}
              {customersIndex && (
                <React.Fragment>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <Form.Check
                          type="checkbox"
                          onChange={() => {
                            console.log("uuids.length: ", uuids.length);
                            if (uuids.length) {
                              return setUuids([]);
                            }
                            let allIds = [];
                            customerDocs.map(doc => {
                              return doc.uid
                                ? allIds.push(doc.uid.toString())
                                : null;
                            });
                            console.log("allIds: ", allIds);
                            return setUuids(allIds);
                          }}
                          checked={uuids.length ? true : false}
                          label={
                            uuids.length ? (
                              <span
                                className="text-muted"
                                style={{ fontWeight: "400" }}
                              >
                                {uuids.length}
                              </span>
                            ) : null
                          }
                        />
                      </th>
                      <th>UID</th>
                      <th>
                        Account Email
                        <i
                          className="fa fa-sort ml-2"
                          onClick={e => {
                            e.preventDefault();
                            let nOrder = ["email", "asc"];
                            let nOrderBy = orderBy
                              ? orderBy.find(
                                  order =>
                                    JSON.stringify(order) ===
                                    JSON.stringify(nOrder)
                                )
                                ? [["email", "desc"]]
                                : [nOrder]
                              : [nOrder];
                            //setOrderBy(nOrderBy);
                            getUsersCallback(where, nOrderBy);
                          }}
                        ></i>
                      </th>
                      <th>
                        <Form.Control
                          as="select"
                          onChange={e => {
                            let whereA = [
                              ["subscription_status", "==", e.target.value]
                            ];
                            getUsersCallback(
                              {
                                ref: db.collection("customers"),
                                whereA,
                                orderBy
                              },
                              docs => setCustomerDocs(docs)
                            );
                          }}
                        >
                          <option>Status</option>
                          <option value="canceled">Canceled</option>
                          <option value="active">Active</option>
                          <option value="past due">Past Due</option>
                          <option value="past_due">Past_Due</option>
                          <option value="expired">Expired</option>
                        </Form.Control>
                      </th>
                      <th>
                        Renewal Date
                        <i
                          onClick={() => {
                            let clientSort = (docA, docB) => {
                              console.log("Ran clientsort!");
                              function cbd(bca) {
                                return new Date(bca * 1000).getDate();
                              }
                              let a = cbd(docA.billing_cycle_anchor);
                              let b = cbd(docB.billing_cycle_anchor);
                              let aa = Number.isNaN(a) ? 32 : a;
                              let bb = Number.isNaN(b) ? 32 : b;
                              console.log(a, b, aa - bb);
                              return aa - bb;
                            };
                            //let nDocs = [...customerDocs].sort(clientSort)
                            //setCustomerDocs(nDocs);
                            setClientSort({
                              func: clientSort,
                              index: "customers",
                              key: "bca"
                            });
                          }}
                          className="fa fa-sort ml-2"
                        ></i>
                      </th>
                      <th>
                        <Form.Control
                          as="select"
                          onChange={e => {
                            let whereA = [["plan_id", "==", e.target.value]];
                            getUsersCallback(
                              {
                                ref: db.collection("customers"),
                                whereA,
                                orderBy
                              },
                              docs => setCustomerDocs(docs)
                            );
                          }}
                        >
                          <option>Plan</option>
                          {Object.values(Plans).map(plan => {
                            return (
                              <option
                                key={`o_${plan.planID}`}
                                value={plan.planID}
                              >
                                {plan.shortname}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </th>
                      <th>Customer ID</th>
                      <th>Plan ID</th>
                      <th>Subscription ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerDocs.map((doc, i) => {
                      const {
                        uid,
                        email,
                        customer_id,
                        subscription_plan,
                        plan_id,
                        subscription_id,
                        subscription_status,
                        billing_cycle_anchor
                      } = doc;
                      const uidS = uid ? uid.toString() : null;
                      return (
                        <tr key={`${uid}_${i}`}>
                          <td className="text-center">
                            <Form.Check
                              type="checkbox"
                              checked={
                                uid
                                  ? uuids.includes(uidS)
                                    ? true
                                    : false
                                  : false
                              }
                              onChange={() => {
                                handleSelectUuid(uid, uidS);
                              }}
                            />
                          </td>
                          <td>{uid ? uidS.slice(uidS.length - 5) : "-"}</td>
                          <td>
                            {email ? (
                              <Link
                                to={`/superadmin/manage/${uid}`}
                                onClick={() => openAdminPage(doc)}
                              >
                                {email}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {subscription_status ? subscription_status : "-"}
                          </td>
                          <td>
                            {billing_cycle_anchor
                              ? new Date(billing_cycle_anchor * 1000).getDate()
                              : "-"}
                          </td>
                          <td>{subscription_plan ? subscription_plan : "-"}</td>
                          <td>{customer_id ? customer_id : "-"}</td>
                          <td>{plan_id ? plan_id : "-"}</td>
                          <td>{subscription_id ? subscription_id : "-"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </React.Fragment>
              )}
              {downloadsIndex && (
                <React.Fragment>
                  <thead>
                    <tr>
                      <th>UID</th>
                      <th>A ID</th>
                      <th>Email</th>
                      <th>Search Name</th>
                      <th>Download Count</th>
                      <th>
                        Date{" "}
                        <i
                          onClick={() => {
                            let clientSort = (docA, docB) => {
                              console.log("Ran clientsort!");
                              let a = docA.timestamp.seconds;
                              let b = docB.timestamp.seconds;
                              let aa = Number.isNaN(a) ? 1000000000000 : a;
                              let bb = Number.isNaN(b) ? 1000000000000 : b;
                              console.log(a, b, aa - bb);
                              return bb - aa;
                            };
                            //let nDocs = [...customerDocs].sort(clientSort)
                            //setCustomerDocs(nDocs);
                            setClientSort({
                              func: clientSort,
                              index: "downloads",
                              key: "timestamp"
                            });
                          }}
                          className="fa fa-sort ml-2"
                        ></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {downloads.map((doc, i) => {
                      const {
                        uid,
                        aid,
                        email,
                        searchName,
                        downloadCount,
                        timestamp,
                        csvFile
                      } = doc;

                      return (
                        <tr key={`dl_${i}`}>
                          <td>{uid ? uid.slice(uid.length - 5) : "-"}</td>
                          <td>{aid ? aid.slice(aid.length - 5) : "-"}</td>
                          <td>
                            {email ? (
                              <Link
                                to={`/superadmin/manage/${uid}`}
                                onClick={() =>
                                  openAdminPage(
                                    customerDocs.find(c => doc.aid === c.uid)
                                  )
                                }
                              >
                                {email}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {searchName ? (
                              <a href={csvFile ? csvFile : ""}>{searchName}</a>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>{downloadCount ? downloadCount : "-"}</td>
                          <td>
                            {timestamp
                              ? new Date(
                                  timestamp.seconds * 1000
                                ).toLocaleString()
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </React.Fragment>
              )}
            </Table>
          </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
          <Modal.Header closeButton={true}>Send Email</Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Recipients</Form.Label>
              <div
                style={{
                  padding: "10px",
                  border: "solid 1px darkgray",
                  borderRadius: "4px"
                }}
              >
                {emails.join(", ")}
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                value={subject}
                onChange={e => setSubject(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Plain Text</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={plainText}
                onChange={e => setPlainText(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>HTML EMAIL (As String)</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                value={html}
                onChange={e => setHtml(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={() => setShowModal(false)}
              className="pull-left"
            >
              Close
            </Button>
            <Button
              className="pull-right"
              onClick={async () => {
                //Send off the email
                console.log("Sending email!");
                for (let email of emails) {
                  await sendEmail({
                    msg: {
                      to: email,
                      subject,
                      from: "help@listshack.support",
                      text: plainText,
                      html: html
                    },
                    handleAlerts,
                    apiKey
                  });
                }
                handleAlerts(
                  "",
                  "Your email was successfully sent!",
                  "success"
                );
                setShowModal(false);
                setSubject("");
                setPlainText("");
                setHtml("");
              }}
            >
              Send Email
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showMigrate}
          onHide={() => setShowMigrate(false)}
          size="xl"
        >
          <Modal.Header>Document Migration</Modal.Header>
          <Modal.Body>
            <BulkMigration {...props} />
          </Modal.Body>
        </Modal>
        {showAdminPage && (
          <Modal
            show={showAdminPage}
            onHide={() => {
              setShowAdminPage(false);
              setSelectedUser([]);
            }}
            size="xl"
          >
            <Modal.Header>Manage User</Modal.Header>
            <Modal.Body>
              <AdminPage
                {...props}
                userDoc={selectedUser[0]}
                customerDoc={selectedUser[1]}
                customerDocId={selectedUser[1]["_docId"]}
                title={
                  <h2>
                    {selectedUser[0].companyProfile
                      ? selectedUser[0].companyProfile.name
                      : selectedUser[0].email}
                  </h2>
                }
              />
            </Modal.Body>
          </Modal>
        )}
      </Container>
    );
  }

  if (selectedUser.length > 0) {
    return (
      <AdminPage
        {...props}
        userDoc={selectedUser[0]}
        customerDoc={selectedUser[1]}
        customerDocId={selectedUser[1]["_docId"]}
        title={
          <h2>
            {selectedUser[0].companyProfile
              ? selectedUser[0].companyProfile.name
              : selectedUser[0].email}
          </h2>
        }
      />
    );
  }
  return null;
};

const SelectIndex = props => {
  // Search Users
  const [searchBy, setSearchBy] = useState(
    props.searchBy ? props.searchBy : ""
  );

  return (
    <Form.Row className={`${props.className ? props.className : ""}`}>
      <Col>
        {props.label ? <Form.Label>{props.label}</Form.Label> : null}
        <Form.Row>
          <Form.Group as={Col} style={{ maxWidth: "200px" }}>
            <Form.Control
              as="select"
              size={props.size ? props.size : ""}
              onChange={e => props.indexCallback(e.target.value)}
              defaultValue={props.index}
            >
              <option value="users">Users Collection</option>
              <option value="customers">Customers Collection</option>
              <option value="downloads">Downloads Collection</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} style={{ maxWidth: "200px" }}>
            <AutoComplete
              placeholder={`Search ${props.index} by ${searchBy}`}
              size={props.size ? props.size : ""}
              suggestions={[...props.docs]}
              onSelect={suggestion => props.callback(suggestion)}
              searchKey={searchBy}
              suggestion={suggestion => {
                return (
                  <Link to={`/superadmin/manage/${suggestion.uid}`}>
                    <small>
                      {suggestion.email}{" "}
                      <span className="text-muted">{searchBy}</span>
                    </small>
                  </Link>
                );
              }}
            />
          </Form.Group>
          <Form.Group as={Col} style={{ maxWidth: "100px" }}>
            <Form.Control
              as="select"
              size={props.size ? props.size : ""}
              value={searchBy}
              onChange={e => {
                e.preventDefault();
                setSearchBy(e.target.options[e.target.selectedIndex].value);
              }}
            >
              <option>Search by</option>
              {props.searchOptions
                ? props.searchOptions.map((so, i) => {
                    return (
                      <option key={`so_${i}`} value={so}>
                        {so}
                      </option>
                    );
                  })
                : null}
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </Col>
    </Form.Row>
  );
};

const ErrorsPage = props => {
  const { handleIsApp, isApp } = props;
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(<p>Nothing added to Modal Yet</p>);
  useEffect(() => {
    handleIsApp(isApp);
  }, [handleIsApp, isApp]);
  return (
    <Collection
      colRef={db}
      collection="errors"
      permissions={["read", "write", "delete"]}
    >
      <CollectionContext.Consumer>
        {context => {
          console.log("context: ", context);
          return (
            <Container fluid>
              <Row>
                <Col>
                  <h2>Application Errors</h2>
                  <Table>
                    <thead>
                      <tr>
                        <th>Occurred on</th>
                        <th>User Info</th>
                        <th>Error</th>
                        <th>Additonal Error Information</th>
                      </tr>
                    </thead>
                    <tbody>
                      {context.docs.map(doc => {
                        return (
                          <tr key={doc._docId}>
                            <td>{doc.timeStamp ? doc.timeStamp : "-"}</td>
                            <td>
                              {doc.uid ? doc.uid : "-"}
                              <br />
                              {doc.email ? doc.email : "-"}
                            </td>
                            <td>{doc.error ? doc.error : "-"}</td>
                            <td>
                              <pre>
                                <code>
                                  {doc.errorInfo ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: doc.errorInfo.replaceAll(
                                          "\\n",
                                          "<br />"
                                        )
                                      }}
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </code>
                              </pre>
                              <Button
                                variant="link"
                                onClick={() => {
                                  setModalBody(
                                    <React.Fragment>
                                      <h4>App State</h4>
                                      <hr />
                                      <p>
                                        {doc.state ? (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: doc.state
                                                .replaceAll("\\n", "<br />")
                                                .replaceAll(',"', ',<br />"')
                                            }}
                                          />
                                        ) : (
                                          "-"
                                        )}
                                      </p>
                                    </React.Fragment>
                                  );
                                  setShowModal(true);
                                }}
                              >
                                View More
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="xl"
              >
                <Modal.Body>{modalBody}</Modal.Body>
              </Modal>
            </Container>
          );
        }}
      </CollectionContext.Consumer>
    </Collection>
  );
};

const BulkMigration = props => {
  const templateAccountDoc = {
    billing_cycle_anchor: {
      sample: 1606806000,
      required: true,
      type: "number"
    },
    leads: {
      sample: 100,
      required: true,
      type: "number"
    },
    leadsRemaining: {
      sample: 100,
      required: true,
      type: "number"
    },
    includesRollover: {
      sample: false,
      required: true,
      type: "boolean"
    },
    downloadLimit: {
      sample: 100,
      required: true,
      type: "number"
    },
    email: {
      sample: "devtest@listshack.support",
      required: true,
      type: "string"
    },
    id: {
      sample: "someAccountId",
      required: true,
      type: "string",
      description:
        'The "uid" will always match the "id" on an account document.'
    },
    uid: {
      sample: "someAccountId",
      required: true,
      type: "string",
      description:
        'The "uid" will always match the "id" on an account document.'
    },
    companyProfile: {
      sample: {
        email: "someemail@example.com",
        name: "My company name",
        phone: "5551234567"
      },
      required: false,
      type: "object",
      description:
        "The user will set this the first time they set up their account. If it is not set, the user either created their account before this field was present or they created their subscription and never used the account (such as a fraudster). All of the subtypes for the company object are strings"
    },
    companyUsers: {
      sample: {
        email: "someuseremail@example.com",
        name: "John Doe",
        role: "admin"
      },
      type: "object",
      required: false,
      description:
        'The user may set this value when they create their account or from the settings page. All subtypes of the companyUsers object are strings.  The possible values for the role sub key is "admin" or "download".'
    },
    verified: {
      sample: false,
      required: false,
      type: "boolean",
      description:
        "All new users are required to verify their accounts via us cellphone number by receiving a text message and entering the code. With some minor code work the same could be added for email verification, but because of overseas fraudsters, this has not been added at the moment. There is also a bug where a new user can refresh their browser and avoid the account verification."
    },
    lrUpdated: {
      sample: {
        seconds: 124566,
        nanoseconds: 123456879
      },
      type: "datetime",
      required: false,
      description:
        "This field is used and set when the daily script updates or resets the document.  The daily script checks whether the custodDocument associated with the uid field has an active subscription_status.  If so, it resets or updates the document based on the customerDoc plan. When it updates it adds a timestamp."
    }
  };

  const templateUserDoc = {
    email: {
      sample: "someemail@example.com",
      type: "string",
      required: true
    },
    uid: {
      sample: "someuid",
      type: "string",
      require: true
    },
    id: {
      sample: "someid",
      type: "string",
      require: true
    },
    meta: {
      description:
        "This type of document lives in the firebase collection named users.  It is only used for users that are added to someone elses account.  Once the user log's in the main application component app.js will fetch the user document associated with the id key and set that as the user document, and it will set the role of the user based on the companyUsers key of the account document and the users email address."
    }
  };

  const [customerDoc, setCustomerDoc] = useState({});
  return null;
};

export {
  SuperadminLanding,
  SearchUsers,
  SuperAdmin,
  AdminPage,
  AdminIndex,
  ErrorsPage
};

const getUserData = async (params, callback) => {
  const { apiKey, uid } = params;
  //console.log("apiKey: ", apiKey);
  const init = {
    method: "GET",
    headers: {
      authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json"
    },
    "Transfer-Encoding": "chunked",
    cache: "default",
    accept: "application/json"
    //"body": JSON.stringify({uid})
  };
  try {
    let response = await fetch(
      `${process.env.REACT_APP_api_url}/api/admin/auth/find/${uid}`,
      init
    );
    console.log("response: ", response);
    let user = await response.json();
    console.log("user: ", user);
    if (callback) {
      callback(user);
    }
    return user;
  } catch (err) {
    console.log("Error getting user data: ", err);
  }
};

const updateUserData = async (params, callback) => {
  const { apiKey, uid, data } = params;
  //console.log("apiKey: ", apiKey);
  const init = {
    method: "POST",
    headers: {
      authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json"
    },
    "Transfer-Encoding": "chunked",
    cache: "default",
    accept: "application/json",
    body: JSON.stringify({ uid, data })
  };
  try {
    let response = await fetch(
      `${process.env.REACT_APP_api_url}/api/admin/auth/update/${uid}`,
      init
    );
    console.log("response: ", response);
    let user = await response.json();
    console.log("user: ", user);
    if (callback) {
      callback(user);
    }
    return user;
  } catch (err) {
    console.log("Error updating user data: ", err);
  }
};

const getDownloads = async (params, callback) => {
  const { aid } = params;
  try {
    let downloads = [];
    await db
      .collection("downloads")
      .doc(aid)
      .collection("files")
      .orderBy("timestamp", "desc")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          let docData = doc.data();
          docData._docId = doc.id;
          downloads.push(docData);
        });
      });
    console.log("downloads: ", downloads);
    if (callback) {
      callback(downloads);
    }
    return downloads;
  } catch (err) {
    console.log("Error getting downloads: ", err);
  }
};

const listenDoc = (params, callback) => {
  const { _docRef } = params;
  try {
    _docRef.onSnapshot(doc => {
      console.log("Doc changed!");
      let docData = doc.data();
      docData._docId = doc.id;
      docData._docRef = doc.ref;
      if (callback) {
        callback(docData);
      }
    });
    return () => {
      let unsubscribe = () => {
        _docRef.onSnapshot(snap => {
          // do nothing
          console.log("Unsubscribing from document!");
        });
      };
      unsubscribe();
    };
  } catch (err) {
    console.log("Error getting userDoc: ", err);
  }
};

const refreshRecurlyInfo = async (params, callback) => {
  let { customerDoc, userDoc, apiKey } = params;
  let { email } = customerDoc;
  const init = {
    method: "POST",
    headers: {
      authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json"
    },
    "Transfer-Encoding": "chunked",
    cache: "default",
    accept: "application/json",
    body: JSON.stringify({ email })
  };
  try {
    let response = await fetch(
      `${process.env.REACT_APP_api_url}/api/recurly/accounts/find/${email}`,
      init
    );
    let accounts = await response.json();
    let account = accounts[0];
    init.body = JSON.stringify({ state: "active" });
    let response2 = await fetch(
      `${process.env.REACT_APP_api_url}/api/recurly/subscriptions/find/${account.id}`,
      init
    );
    let subscriptions = await response2.json();
    let subscription = subscriptions[0];
    let billing_cycle_anchor = subscription
      ? toEpochISO(subscription.createdAt)
      : null;
    let billing_day_of_month = subscription
      ? new Date(subscription.createdAt).getDate()
      : null;
    customerDoc._docRef.update({
      billing_cycle_anchor,
      billing_day_of_month,
      current_period_ends_at: subscription
        ? subscription.currentPeriodEndsAt
        : null,
      current_period_starts_at: subscription
        ? subscription.currentPeriodStartedAt
        : null,
      customer_id: account ? account.id : null,
      plan_id: subscription
        ? subscription.plan.code
        : process.env.REACT_APP_plan_free_trial,
      subscription_id: subscription ? subscription.id : null,
      subscription_plan: subscription ? subscription.plan.name : "Free Trial",
      subscription_status: subscription
        ? subscription.state.toLowerCase()
        : "canceled",
      updated_at: subscription ? toEpochISO(subscription.updatedAt) : null
    });
    userDoc._docRef.update({
      billing_cycle_anchor
    });
    console.log("Refreshed the recurly account and subscription information.");
  } catch (err) {
    console.log("Error refreshing recurly account: ", err);
  }
};

const RecurlyAccount = props => {
  const { btRef, customerDoc, userDoc, apiKey } = props;
  return (
    <ButtonGroup className={`${props.className ? props.className : ""}`}>
      <a
        href={`${btRef}${customerDoc.uid}`}
        target="_blank"
        rel="noopener noreferrer"
        className={`btn btn-outline-dark`}
      >
        View Customer on Recurly
      </a>
      <Button
        variant="outline-dark"
        size="sm"
        onClick={() => refreshRecurlyInfo({ customerDoc, userDoc, apiKey })}
      >
        <i className="fa fa-refresh"></i>
      </Button>
    </ButtonGroup>
  );
};

const oldestObject = (params, callback) => {
  let { array, key } = params;
  let oldest = array[0];
  array.map(obj => {
    if (obj[key].seconds < oldest[key].seconds) {
      return (oldest = obj);
    }
    return obj;
  });
  if (callback) {
    callback(oldest);
  }
  //console.log("oldest: ", oldest);
  return oldest;
};

const newestObject = (params, callback) => {
  let { array, key } = params;
  let newest = array[0];
  array.map(obj => {
    if (obj[key].seconds > newest[key].seconds) {
      return (newest = obj);
    }
    return obj;
  });
  if (callback) {
    callback(newest);
  }
  //console.log("newest: ", oldest);
  return newest;
};
