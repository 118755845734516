import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { PieChart, Pie, Sector } from "recharts";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
  }

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index
    });
  };

  toDateTime(secs) {
    let t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  render() {
    // Format data for the pie chart
    const leadsUsed =
      this.props.userDoc.leads - this.props.userDoc.leadsRemaining;
    const data = [
      {
        name: "Leads left",
        value: this.props.userDoc.leadsRemaining,
        color: "#4ca2ff",
        textColor: "#4ca2ff"
      },
      {
        name: "Leads used",
        value: leadsUsed,
        color: "#4ca2ff",
        textColor: "#4ca2ff"
      }
    ];

    return (
      <Row>
        <Col xs={12} className="text-left">
          <PieChart width={150} height={150} style={{ margin: "0 auto" }}>
            <Pie
              activeIndex={this.state.activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx={75}
              cy={60}
              innerRadius={20}
              outerRadius={40}
              fill="#d8d8d8"
              dataKey="value"
              onMouseEnter={this.onPieEnter}
              textColor="#4ca2ff"
            />
          </PieChart>
          {this.props.userDoc.includesRollover && (
            <p
              className="text-muted text-left"
              style={{ padding: "8px", fontSize: ".8em" }}
            >
              Your account receives {this.props.userDoc.leads.toLocaleString()}{" "}
              more leads on the{" "}
              {this.ordinal_suffix_of(
                this.toDateTime(
                  this.props.userDoc.billing_cycle_anchor
                ).getDate()
              )}{" "}
              of each month.
            </p>
          )}
          {!this.props.userDoc.includesRollover && (
            <p
              className="text-muted text-left"
              style={{ padding: "8px", fontSize: ".8em" }}
            >
              Your leads reset on the{" "}
              {this.ordinal_suffix_of(
                this.toDateTime(
                  this.props.userDoc.billing_cycle_anchor
                ).getDate()
              )}{" "}
              of each month.
            </p>
          )}
        </Col>
      </Row>
    );
  }
}

Dashboard.propTypes = {
  userDoc: PropTypes.object
};

export default Dashboard;

//const data = [
//  { name: 'Group A', value: 400 },
//  { name: 'Group B', value: 300 },
//  { name: 'Group C', value: 300 },
//  { name: 'Group D', value: 200 },
//];

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload
  } = props;
  //const {percent, value, textColor} = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  //const sx = cx + (outerRadius + 10) * cos;
  //const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  //const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <text x={cx} y={135} dy={8} textAnchor="middle" fill={payload.textColor}>
        {payload.value.toLocaleString()} {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.color}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={payload.color}
      />

      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

      {/* reserved for when we introduce the component on the Settings page
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value.toLocaleString()}`}</text>
      
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(0)}%)`}
      </text>
      */}
    </g>
  );
};
