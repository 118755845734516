import React from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";
import { firebaseApp } from "./firebase.jsx";
import { Form, Button } from "react-bootstrap";

class ResetPass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountEmail: "",
      wasSent: false
    };
    this.resetPass = this.resetPass.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async resetPass(e) {
    e.preventDefault();
    let auth = firebaseApp.auth();
    let email = this.state.accountEmail;
    try {
      await auth.sendPasswordResetEmail(email);
      console.log(
        "An email will be sent to the email address if there is a user associated with the email"
      );
      await this.props.handleAlerts(
        e,
        `A password reset email has been sent to ${email}.`,
        "success",
        "Success! Email sent!"
      );
      await this.setState({
        wasSent: true
      });
    } catch (error) {
      console.log("Uh oh, something went wrong", error);
      await this.props.handleAlerts(
        e,
        `Uh oh something went wrong :( ${error.message}`,
        "warning"
      );
    }
  }

  render() {
    if (!this.state.wasSent) {
      return (
        <Form
          style={{
            maxWidth: "410px",
            margin: "0 auto",
            padding: "10px",
            paddingTop: "15%"
          }}
        >
          <h3>Reset password</h3>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="accountEmail"
              placeholder="Enter your email address"
              onChange={this.handleInputChange}
            />
            <Form.Text>
              Enter the same email that you used to sign up and we'll email you
              a link to reset your password.
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Button variant="secondary" onClick={this.resetPass}>
              Send reset link
            </Button>
          </Form.Group>
          <Form.Text className="text-muted">
            Remember your password?{" "}
            <Link to="/login" className="btn btn-sm btn-link">
              Login here.
            </Link>
          </Form.Text>
        </Form>
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login"
          }}
        />
      );
    }
  }

  componentDidMount() {
    if (typeof this.props.noDistract !== "undefined") {
      this.props.handleState({ noDistract: this.props.noDistract });
    }
  }

  componentWillUnmount() {
    if (this.props.noDistract) {
      this.props.handleState({ noDistract: false });
    }
  }
}

ResetPass.propTypes = {
  user: PropTypes.object,
  noDistract: PropTypes.bool,
  handleState: PropTypes.func,
  handleAlerts: PropTypes.func
};

export default ResetPass;
