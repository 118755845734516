import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import { firebaseApp, db, getUserDocs } from "./firebase.jsx";
import { Row, Col, Button, Alert, Modal } from "react-bootstrap";
import Routes from "./routes.jsx";
import RoutesLsp from "./routes_lsp.jsx";
import RoutesLs from "./routes_ls.jsx";
import RoutesLsio from "./routes_lsio.jsx";

import {
  Loading,
  Topnav,
  Secondnav,
  SideBar,
  Footer,
  FooterSmall,
  Plans,
  ComparePlans,
  SomethingWentWrong,
  toDatetime,
  CheckActiveStatus
} from "./interfaceListShackPro.jsx";
import { TopnavLs, SecondnavLs } from "./interfaceListShack.jsx";
import { SiteNav } from "./interfaceListShackio.jsx";
import { SuperAdmin } from "./superadmin.jsx";
import ScrollToTop from "./scrollToTop.jsx";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      user: null,
      userDoc: null,
      customerDoc: null,
      apiKey: null,
      loaded: false,
      noDistract: false,
      isApp: false,
      selectedPlan: 0,
      alertVariant: "info",
      alertHeading: "Unlimited Downloads on ListShack Desktop!",
      alertShow: false,
      displayModal: false,
      showComparePlans: true,
      sideBarIsOpen: true,
      alertText: (
        <React.Fragment>
          Downloads on the new <i>Beta</i> Desktop app are unlimited until New
          Year! Merry Christmas!{" "}
          <Link className="alert-link" to="/listshack_desktop">
            Download ListShack Desktop here
          </Link>
          .
        </React.Fragment>
      )
    };
    this.interface = process.env.REACT_APP_interface;
    this.plans = Plans;
    this.handleDistract = this.handleDistract.bind(this);
    this.selectPlan = this.selectPlan.bind(this);
    this.handleIsApp = this.handleIsApp.bind(this);
    this.handleAlerts = this.handleAlerts.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleState = this.handleState.bind(this);
  }

  // Some legacy users need their account configured, do it here
  portCustomerAccount = async (
    customerDoc = this.state.customerDoc,
    customerDocId = this.state.customerDocId,
    userDoc = this.state.userDoc
  ) => {
    let plan;
    console.log("customerDoc: ", customerDoc);
    console.log("userDoc: ", userDoc);

    let shouldConfCust =
      (userDoc !== null &&
        userDoc !== undefined &&
        customerDoc !== null &&
        customerDoc !== undefined &&
        !customerDoc.hasOwnProperty("uid")) ||
      (userDoc !== null &&
        userDoc !== undefined &&
        customerDoc !== null &&
        typeof customerDoc !== "undefined" &&
        !userDoc.hasOwnProperty("leadsRemaining") &&
        customerDoc.hasOwnProperty("subscription_status"));

    if (shouldConfCust) {
      console.log("Porting the account to the new interface.");
      try {
        // write the uid to the customers doc so we can correctly count leads every month
        db.collection("customers")
          .doc(customerDocId)
          .update({
            uid: this.state.user.uid
          });
        console.log(
          `A customerDoc with the email ${userDoc.email} was found, added the uid to the customerDoc. `
        );
        // Lookup the correct plan for the user
        plan = await Object.values(this.plans).find(plan => {
          return plan.planID === customerDoc.plan_id;
        });
        // Write the plan details to the userDoc for correct accounting
        await db
          .collection("users")
          .doc(this.state.user.uid)
          .update({
            downloadLimit: plan.downloadLimit,
            leads: plan.leads,
            leadsRemaining: plan.leads,
            includesRollover: plan.includesRollover,
            billing_cycle_anchor: customerDoc.billing_cycle_anchor
          });
        console.log(`Added the plan ${plan.shortname} to the userDoc. `);

        //await this.handleAlerts("", <React.Fragment>Welcome to our new interface!  We've ported your subscription over from the previous interface, but if something seems off, please email <Alert.Link href={`mailto:${process.env.REACT_APP_contact_email}`}>{process.env.REACT_APP_contact_email}</Alert.Link> and we'll get things squared away for you.</React.Fragment>,"info", "You made it!");
      } catch (err) {
        console.log(
          "There was an error porting your account to the new interface: ",
          err.message
        );
      }
    } else {
      console.log("Account does not need to be ported to the new interface.");
      if (customerDoc === null || typeof customerDoc === "undefined") {
        console.log(
          "Cannot find a customerDoc with a subscription linked to the email address."
        );
      }
      if (
        customerDoc !== null &&
        typeof customerDoc !== "undefined" &&
        customerDoc.hasOwnProperty("uid")
      ) {
        console.log(
          "The account already has a unique user id (uid), meaning the user is not a legacy user or has purchased a subscription after the new interface was released, or the account was already ported to the new interface."
        );
      }
      if (
        userDoc !== null &&
        typeof userDoc !== "undefined" &&
        userDoc.hasOwnProperty("leadsRemaining")
      ) {
        console.log(
          "The accounts leadsRemaining property of the userDoc is correctly configured"
        );
      }
    }
  };

  getApiKey = async user => {
    console.log("user: ", user);
    const init = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      "Transfer-Encoding": "chunked",
      cache: "default",
      accept: "application/json",
      body: JSON.stringify(user)
    };
    //console.log("init: ", init);
    let response = await fetch(
      `${process.env.REACT_APP_api_url}/api/login`,
      init
    );
    let { token } = await response.json();
    //console.log("token: ", token);
    return await token;
  };

  customerDocChange = async (user = this.state.user, userDoc) => {
    console.log(
      "Listening for changes to the customer doc associated with email ",
      userDoc.email
    );
    if (typeof userDoc === "undefined") {
      return;
    }
    const email = userDoc.email;
    let customerDoc, customerDocId;
    await db
      .collection("customers")
      .where("email", "==", email)
      .onSnapshot(async snapshot => {
        // Get the document data
        console.log("Firebase returned a snapshot of the customer document.");
        snapshot.forEach(doc => {
          customerDoc = doc.data();
          customerDoc._docId = doc.id;
          customerDoc.ref = doc.ref;
          customerDocId = doc.id;
        });

        // We might need to port the customers account over
        if (!this.state.loaded) {
          await this.portCustomerAccount(customerDoc, customerDocId, userDoc);
        }

        // Notify user of changes from cloud functions
        //snapshot.docChanges().forEach( (change) => {
        //  //console.log("change.doc.data(): ", change.doc.data());
        //  if (change.type === "added" || change.type === "modified") {
        //    let changedDoc = change.doc.data();
        //  }
        //});
        //this.cio_identify(customerDoc);
        console.log("customerDoc: ", customerDoc);
        // Display stuff to the user
        this.setState({
          customerDoc: customerDoc,
          customerDocId: customerDocId,
          loaded: true
        });
      });

    return [customerDoc, customerDocId];
  };

  userDocChange = async (user = this.state.user) => {
    console.log("Listening for changes to the user doc for uid", user.uid);
    return new Promise(async (resolve, reject) => {
      try {
        const { uid, email } = user;
        let accountDoc, role;
        let userDocInfo = await getUserDocs(uid);
        let apiKey = await this.getApiKey({ uid, email });
        console.log("userDocInfo: ", userDocInfo);
        let userDocId = userDocInfo[1];
        let userDoc = userDocInfo[0];
        let accountId = userDoc.id;

        //console.log("userDoc: ", userDoc);
        console.log("accountId: ", accountId);
        // Listen to the account document
        await db
          .collection("users")
          .doc(userDocId)
          .onSnapshot(doc => {
            accountDoc = doc.data();
            accountDoc._docId = doc.id;
            accountDoc._docPath = doc.ref.path;
            console.log("accountDoc: ", accountDoc);
            let coUser =
              accountDoc !== undefined && accountDoc.companyUsers !== undefined
                ? accountDoc.companyUsers.find(coUser => coUser.email === email)
                : undefined;
            //console.log("uid: ", uid, "userDoc.id: ", userDoc.id);
            role =
              uid === userDoc.id
                ? "admin"
                : coUser === undefined
                ? "removed"
                : coUser["role"];
            console.log("role: ", role);
            //console.log("accountDoc: ", accountDoc);
            if (this.state.customerDoc === null) {
              this.customerDocChange(user, accountDoc);
            }
            this.setState({
              superAdmin: accountDoc.superAdmin,
              role,
              user: user,
              userDoc: accountDoc,
              apiKey: apiKey
            });
            resolve(accountDoc);
          });
      } catch (err) {
        this.setState({ hasError: true });
        console.log("Error handling userDocChange: ", err);
        reject(`Error handling userDocChange: ${err}`);
      }
    });
  };

  loadHeadway = () => {
    let user = this.state.user;
    let loaded = this.state.loaded;
    let notSignup = window.location.pathname !== "/signup";
    //console.log("notSignup: ", notSignup, "windoow.location.pathname: ", window.location.pathname);
    if (user !== null && user !== "" && loaded && notSignup) {
      var HWconfig = {
        selector: "#hw",
        account: "J0PbKJ"
      };
      window.Headway.init(HWconfig);
    }
  };

  cio_identify = customerDoc => {
    //console.log("this.state.user: ", this.state.user);
    //console.log("customerDoc: ", customerDoc);
    let user = this.state.user !== null ? this.state.user : null;
    if (user !== null) {
      let uid = user.uid;
      let email = user.email;
      let created_at = parseInt(user.metadata.a / 1000);
      let last_login = parseInt(user.metadata.b / 1000);
      //console.log(uid, email, created_at);
      window._cio.identify({
        // Required attributes
        id: uid, // Unique id for the currently signed in user

        // Strongly recommended attributes
        email: email, // Email of the currently signed in user.
        created_at: created_at, // Timestamp since epoch
        last_login: last_login // Timestamp since epoch
      });
    }
    if (customerDoc !== undefined) {
      let last4 =
        customerDoc.sources && customerDoc.sources.data[0].last4
          ? customerDoc.sources.data[0].last4
          : "****";
      window._cio.identify({
        // Required attributes
        id: customerDoc.uid, // Unique id for the currently
        subscription_status: customerDoc.subscription_status,
        subscription_plan: customerDoc.subscription_plan,
        last4: last4,
        canceled_at: customerDoc.canceled_at,
        cancel_at_period_end: customerDoc.cancel_at_period_end,
        billing_cycle_anchor: customerDoc.billing_cycle_anchor
      });
    }
  };

  authStateChange = () => {
    //console.log("authStateChange called!");
    firebaseApp.auth().onAuthStateChanged(async user => {
      //console.log("AuthStateChanged fired!");
      if (user) {
        // User is signed in.
        //this.setState({
        //  loaded: false
        //});
        // listen for the userDoc
        // if it exists
        // Get the users doc from Firestore
        // Set the account document listener
        // cancel the userDoc listener
        // if it does not exist
        // do nothing and wait for it to be created
        this.userDocChange(user);
      } else {
        // No user is signed in.
        console.log("No user is signed in!");
        this.setState({
          user: null,
          loaded: true,
          superAdmin: false,
          isApp: false,
          userDoc: null,
          customerDoc: null,
          apiKey: null
        });
        return;
      }
    });
  };

  handleState = obj => {
    this.setState(obj);
  };

  handleAlerts = (e, alertText, alertVariant = "info", alertHeading = null) => {
    //e.preventDefault();

    this.setState({
      alertText: alertText,
      alertVariant: alertVariant,
      alertHeading: alertHeading,
      alertShow: true
    });
  };

  signOut = (e, user) => {
    e.preventDefault();
    firebaseApp
      .auth()
      .signOut()
      .then(() => {
        console.log("Successfully signed out!");
        this.setState({
          user: null
        });
      })
      .catch(error => {
        console.log("Unable to logout :(", error);
      });
  };

  handleDistract = bool => {
    //console.log("handleDistract: ", bool);
    let a = false;
    if (typeof bool === "string") {
      a = bool === "true" ? true : false;
    }

    if (typeof bool === "boolean") {
      a = bool;
    }
    this.setState({
      noDistract: a
    });
  };

  handleIsApp = bool => {
    //console.log("bool: ", bool, typeof bool, typeof true, typeof false);
    let a = false;
    if (typeof bool === "string") {
      a = bool === "true" ? true : false;
    }

    if (typeof bool === "boolean") {
      a = bool;
    }

    this.setState({
      isApp: a
    });
  };

  selectPlan = (e, planKey) => {
    //e.preventDefault();
    this.setState({
      selectedPlan: planKey
    });
  };

  handleModalClose = () => {
    this.setState({ displayModal: false });
  };

  handleModalShow = () => this.setState({ displayModal: true });

  handleComparePlans = bool => {
    this.setState({ showComparePlans: bool });
  };

  handleSideBarIsOpen = (e, bool) => {
    e.preventDefault();
    this.setState({ sideBarIsOpen: bool });
  };

  impersonateUser = async userObject => {
    console.log("impersonateUser userObject: ", userObject);
    this.setState({
      loaded: false
    });
    if (!this.state.impersonating) {
      window.localStorage.setItem(
        "superAdminDoc",
        JSON.stringify(this.state.userDoc)
      );
    }
    let accountDoc = await this.userDocChange(userObject);
    await this.customerDocChange(userObject, accountDoc);
    this.setState({
      superAdmin: true,
      impersonating: true
    });
  };

  render() {
    //console.log("this.state: ", this.state);
    let { user, customerDoc, userDoc, noDistract, loaded, isApp } = this.state;

    const plans = this.plans;
    const appInterface = process.env.REACT_APP_interface;
    const isListShack = appInterface === "list_shack";
    const isListShackPro = appInterface === "list_shack_pro";
    const isListShackio = appInterface === "listshack_io";

    let isCustomer = customerDoc !== null && customerDoc !== undefined;
    let isFreeTrial = false;

    let superAdmin = userDoc
      ? userDoc.superAdmin
        ? userDoc.superAdmin
        : false
      : false;

    if (isCustomer) {
      isFreeTrial =
        customerDoc.plan_id === process.env.REACT_APP_plan_free_trial;
    }

    if (this.state.hasError) {
      return (
        <Router>
          <ScrollToTop>
            {this.state.loaded && this.state.superAdmin && (
              <SuperAdmin
                state={this.state}
                handleState={this.handleState}
                selectCallback={this.impersonateUser}
              />
            )}
            {isListShackPro && (
              <React.Fragment>
                <Topnav
                  user={user}
                  loaded={loaded}
                  signOut={this.signOut}
                  isApp={isApp}
                  loadHeadway={this.loadHeadway}
                />
                {isApp && <Secondnav />}
              </React.Fragment>
            )}
            {isListShack && (
              <React.Fragment>
                <TopnavLs
                  user={user}
                  loaded={loaded}
                  signOut={this.signOut}
                  isApp={isApp}
                />
                {isApp && <SecondnavLs />}
              </React.Fragment>
            )}
            <SomethingWentWrong error={this.state.error} />
          </ScrollToTop>
        </Router>
      );
    }

    if (!loaded) {
      return <Loading />;
    }

    const activeStatus = ["active", "pending"];
    const haveActiveSub = CheckActiveStatus(customerDoc);
    const pastDueStatus = ["past due", "past_due", "failed"];
    const isCancelling = customerDoc
      ? customerDoc.cancel_at_period_end
        ? customerDoc.cancel_at_period_end
        : false
      : false;
    const isCancelled = customerDoc
      ? !activeStatus.includes(customerDoc.subscription_status)
      : true;
    const isPastdue = customerDoc
      ? pastDueStatus.includes(customerDoc.subscription_status)
      : false;
    isFreeTrial = customerDoc
      ? customerDoc.subscription_plan
        ? customerDoc.plan_id === process.env.REACT_APP_plan_free_trial
        : false
      : false;

    let userPlan = customerDoc
      ? Object.values(plans).find(
          plan =>
            customerDoc.plan_id.toLowerCase() === plan.planID.toLowerCase()
        )
      : Object.values(plans).find(
          plan =>
            plan.planID.toLowerCase() === process.env.REACT_APP_plan_free_trial
        );

    let bca = customerDoc ? customerDoc.billing_cycle_anchor : null;
    let bd = bca ? toDatetime(bca).getDate() : null;

    return (
      <Router>
        <ScrollToTop>
          {!noDistract && (
            <React.Fragment>
              {this.state.loaded && this.state.superAdmin && (
                <SuperAdmin
                  state={this.state}
                  handleState={this.handleState}
                  selectCallback={this.impersonateUser}
                />
              )}
              {isListShackPro && (
                <React.Fragment>
                  <Topnav
                    user={user}
                    loaded={loaded}
                    signOut={this.signOut}
                    isApp={isApp}
                    loadHeadway={this.loadHeadway}
                  />
                  {!isApp && <Secondnav />}
                </React.Fragment>
              )}
              {isListShack && (
                <React.Fragment>
                  <TopnavLs
                    user={user}
                    loaded={loaded}
                    signOut={this.signOut}
                    isApp={isApp}
                  />
                  {!isApp && <SecondnavLs />}
                </React.Fragment>
              )}
              {isListShackio && <SiteNav />}
            </React.Fragment>
          )}

          <Row noGutters={true}>
            {isApp && (
              <SideBar
                sideBarIsOpen={this.state.sideBarIsOpen}
                handleSideBarIsOpen={this.handleSideBarIsOpen}
                userDoc={this.state.userDoc}
              />
            )}
            <Col>
              <AppAlerts {...this.state} handleState={this.handleState} />
              {/*
            <Alert variant="warning" className="text-center mb-0">
              <span role="img" aria-label="turkey">🦃</span> Our support team will have reduced hours during the Thanksgiving weekend.  If you need support please send us an email at <a href="mailto:help@listshack.support">help@listshack.support</a>. <span role="img" aria-label="turkey">🦃</span>
            </Alert>
            */}
              {this.state.loaded && isFreeTrial && (
                <Alert variant="primary" className="text-center mb-0">
                  Your using the free trial! Subscribe if you'd like to download
                  more leads.{" "}
                  <Button
                    to="/settings/billing"
                    variant="outline-primary"
                    style={{ marginLeft: "20px" }}
                    onClick={() => this.setState({ displayModal: true })}
                  >
                    Subscribe now
                  </Button>
                </Alert>
              )}

              {!isListShackio && (
                <Routes
                  {...this.state}
                  user={user}
                  plans={plans}
                  selectPlan={this.selectPlan}
                  handleDistract={this.handleDistract}
                  handleIsApp={this.handleIsApp}
                  handleAlerts={this.handleAlerts}
                  handleModalShow={this.handleModalShow}
                  getCustomerDocs={this.getCustomerDocs}
                  handleState={this.handleState}
                  impersonateUser={this.impersonateUser}
                  superAdmin={superAdmin}
                  haveActiveSub={haveActiveSub}
                  isCancelling={isCancelling}
                  isCancelled={isCancelled}
                  isFreeTrial={isFreeTrial}
                  isPastdue={isPastdue}
                  userPlan={userPlan}
                  bca={bca}
                  bd={bd}
                />
              )}
              {isListShackPro && <RoutesLsp user={user} />}
              {isListShack && <RoutesLs user={user} />}
              {isListShackio && <RoutesLsio user={null} />}
            </Col>
          </Row>

          {!noDistract && !isApp && <Footer appInterface={appInterface} />}
          {!noDistract && isApp && <FooterSmall />}
          {this.state.displayModal && (
            <Modal
              show={this.state.displayModal}
              onHide={this.handleModalClose}
              size="lg"
              centered
            >
              <AppAlerts {...this.state} handleState={this.handleState} />
              <ComparePlans
                {...this.state}
                selectedPlan={this.state.selectedPlan}
                title={
                  <h4 className="text-center">
                    {this.state.showComparePlans && <span>Select a plan</span>}
                    {!this.state.showComparePlans && <span>Subscribe</span>}
                  </h4>
                }
                plans={this.plans}
                selectPlan={this.selectPlan}
                handleClose={this.handleModalClose}
                currentPlan={
                  customerDoc
                    ? customerDoc.plan_id
                      ? customerDoc.plan_id
                      : userPlan
                    : userPlan
                }
                changePlan={true}
                handleAlerts={this.handleAlerts}
                closeModal={this.handleModalClose}
                handleComparePlans={this.handleComparePlans}
                handleState={this.handleState}
                superAdmin={superAdmin}
                haveActiveSub={haveActiveSub}
                isCancelling={isCancelling}
                isCancelled={isCancelled}
                isFreeTrial={isFreeTrial}
                isPastdue={isPastdue}
                userPlan={userPlan}
                bca={bca}
                bd={bd}
              />
              <Modal.Footer>
                <Button
                  variant="light"
                  className="pull-right"
                  onClick={this.handleModalClose}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </ScrollToTop>
      </Router>
    );
  }

  componentDidMount() {
    if (process.env.REACT_APP_interface !== "listshack_io") {
      this.authStateChange();
    } else {
      this.setState({ loaded: true });
    }
  }

  componentDidUpdate() {
    this.loadHeadway();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log("getDerivedStateFromError error: ", error);
    return { hasError: true, error: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
    try {
      db.collection("errors").add({
        uid: this.state.userDoc.uid || "",
        id: this.state.userDoc.id || "",
        email: this.state.userDoc.email || "",
        state: JSON.stringify(this.state),
        errorInfo: JSON.stringify(errorInfo),
        error: error.toString(),
        location: {
          host: window.location.host,
          href: window.location.href,
          origin: window.locaiton.origin,
          pathname: window.location.pathname,
          port: window.location.port,
          protocol: window.location.protocol,
          search: window.location.search
        },
        timeStamp: new Date()
      });
    } catch (err) {
      console.log("Error logging errors to firestore: ", err);
    }
  }
}

export default App;

const AppAlerts = props => {
  const {
    alertShow,
    alertVariant,
    alertHeading,
    alertText,
    handleState
  } = props;
  return (
    <React.Fragment>
      {alertShow && (
        <Alert
          variant={alertVariant || "info"}
          style={{
            marginBottom: "0px",
            zIndex: "1030"
          }}
          className="sticky-top"
        >
          <Button
            onClick={() => handleState({ alertShow: false })}
            variant="link"
            style={{
              position: "absolute",
              top: "0",
              right: "0"
            }}
          >
            &times;
          </Button>
          {alertHeading && <Alert.Heading>{alertHeading}</Alert.Heading>}
          <p style={{ marginBottom: "0px" }}>{alertText}</p>
        </Alert>
      )}
    </React.Fragment>
  );
};
