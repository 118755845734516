import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import {
  NoPermissions,
  NoSubscription,
  Loading,
  PleaseLogin
} from "./interfaceListShackPro.jsx";

class Paywall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async checkSubscription() {
    let { customerDoc, apiKey } = this.props;
    //console.log("customerDoc.sub_checked: ", Date.now()/1000 - customerDoc.sub_checked);
    console.log("customerDoc: ", customerDoc);
    if (customerDoc === null || customerDoc === undefined) {
      return console.log(
        "This is not a customer. Don't look for a subscription."
      );
    }

    let lastCheck = 0;
    if (
      typeof customerDoc.sub_checked !== "undefined" &&
      customerDoc.sub_checked !== null
    ) {
      lastCheck = customerDoc.sub_checked;
    }

    if (Date.now() / 1000 - lastCheck < 86400) {
      return console.log("Checked subscription within the last 24 hours.");
    }
    const init = {
      method: "POST",
      headers: {
        authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json"
      },
      "Transfer-Encoding": "chunked",
      cache: "default",
      accept: "application/json",
      body: JSON.stringify({ email: customerDoc.email })
    };

    try {
      let response = await fetch(
        `${process.env.REACT_APP_api_url}/api/recurly/accounts/find/${customerDoc.email}`,
        init
      );
      let accounts = await response.json();
      let account = accounts[0];
      console.log("accounts: ", accounts);
      init.body = JSON.stringify({ state: "active" });
      let responseS = await fetch(
        `${process.env.REACT_APP_api_url}/api/recurly/subscriptions/find/${accounts[0].id}`,
        init
      );
      let subscriptions = await responseS.json();
      console.log("Checked subscriptions: ", subscriptions);
      let subscription = subscriptions[0];
      if (subscriptions.length === 0) {
        console.log("No subscription marking canceled.");
        return customerDoc.ref.update({
          subscription_status: "canceled"
        });
      }

      if (subscription) {
        if (typeof customerDoc.subscription_id === "undefined") {
          customerDoc.ref.update({
            subscription_id: subscription.id,
            subscription_plan: subscription.plan.code
          });
        }
        if (
          ["past_due", "past due"].includes(customerDoc.subscription_status)
        ) {
          console.log("Subscription is past due.");
          return this.props.handleState({ account, subscription });
        }
        console.log("Subscription found, updating.");
        customerDoc.ref.update({
          subscription_status: subscriptions[0].state.toLowerCase(),
          sub_checked: Math.round(new Date().getTime() / 1000)
        });
        return this.props.handleState({ account, subscription });
      }

      return;
    } catch (err) {
      console.error(
        "Something went wrong checking the subscription status: ",
        err.message
      );
      customerDoc.ref.update({
        subscription_status: "canceled"
      });
    }
  }

  render() {
    let { loaded, customerDoc, user, role, userDoc } = this.props;
    let isUser = user !== null && user !== "" && userDoc !== null;
    let isAdmin = role === "admin";
    let noPermissions = role === "removed";

    let isCustomer = customerDoc !== null && typeof customerDoc !== "undefined";
    //let next = window.location.pathname || "/search";
    const activeStatus = ["active", "pending"];
    let isExpiredCustomer =
      isCustomer &&
      customerDoc.subscription_status &&
      !activeStatus.includes(customerDoc.subscription_status)
        ? true
        : false;
    if (loaded) {
      if (!isUser) {
        return (
          //<Redirect to={`/login?next=${next}`} />
          <PleaseLogin />
        );
      }

      if (isUser && noPermissions) {
        return <NoPermissions user={user} />;
      }

      if (isUser && !isCustomer) {
        return <NoSubscription userDoc={userDoc} />;
      }

      if (isUser && isExpiredCustomer) {
        return <Redirect to="/settings/billing" />;
      }

      if (
        isUser &&
        isAdmin &&
        !isExpiredCustomer &&
        typeof userDoc.companyProfile === "undefined"
      ) {
        return <Redirect to="/quick_start" />;
      }

      if (isUser && !isExpiredCustomer) {
        return this.props.children;
      }
    } else {
      return <Loading />;
    }
  }

  componentDidMount() {
    //console.log("this.props: ", this.props);
    if (!this.props.isApp) {
      this.props.handleState({ isApp: true });
    }
    if (this.props.loaded && this.props.user !== "") {
      this.checkSubscription();
    }
  }
}

Paywall.propTypes = {
  user: PropTypes.object,
  customerDoc: PropTypes.object,
  isApp: PropTypes.bool,
  loaded: PropTypes.bool
};

export default Paywall;
