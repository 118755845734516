import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { firebase, db } from "./firebase.jsx";
import {
  Container,
  Row,
  Col,
  Card,
  Nav,
  ButtonGroup,
  Button,
  Form,
  Alert,
  Tab,
  Modal
} from "react-bootstrap";
import { ShareButton, shareLink } from "./share.jsx";
import SelectedParams from "./selectedParams.jsx";
import queryString from "query-string";
import AutoComplete from "./autoComplete.jsx";
import Spinner from "react-bootstrap/Spinner";
import { ComposeEmail, searchCardHtml } from "./email.jsx";
import { removeItemAll } from "./interfaceListShackPro.jsx";

async function saveSearch(e, params, cb) {
  // params shaped like:
  // {
  //   geographyParams: object,
  //   searchParams: object,
  //   dataDict: object,
  //   query: object,
  //   count: number,
  //   searchName: string,
  //   user: object,
  //   isDefault: boolean
  // }
  const {
    geographyParams,
    searchParams,
    queryUrl,
    count,
    searchName,
    user,
    dataDict,
    isDefault
  } = params;
  e.preventDefault();
  //const dataDict = this.props.dataDict;
  //const savedParams = "searchParams";
  //let {geographyParams, searchParams, searchName} = this.state;
  const newSavedSearch = {
    geographyParams,
    searchParams,
    queryUrl,
    count: count ? count : null,
    searchName: searchName ? searchName : null,
    uid: user.uid,
    timestamp: new Date()
  };
  console.log("newSavedSearch", newSavedSearch);
  // send it to the database
  if (!isDefault) {
    try {
      let newSS = await db
        .collection("searches")
        .doc(user.uid)
        .collection("params")
        .add(newSavedSearch);
      let savedSearchId = newSS.id;
      let result = { savedSearch: newSavedSearch, savedSearchId };
      if (cb) {
        cb(null, result);
      }
      return console.log("search saved: ", result);
    } catch (error) {
      if (cb) {
        cb(error);
      }
      return console.error("Something went wrong saving the search. ", error);
    }
  } else {
    try {
      await db
        .collection("searches")
        .doc(user.uid)
        .collection("params")
        .doc(dataDict.dictId)
        .set(newSavedSearch);
      let newSearchId = dataDict.index;
      if (cb) {
        cb(null, { savedSearch: newSavedSearch, savedSearchId: newSearchId });
      }
      return console.log("Default search has been updated!");
    } catch (error) {
      if (cb) {
        cb(error);
      }
      return console.error("Something went wrong saving the search. ", error);
    }
  }
}

async function deleteSavedSearch(e, params, cb) {
  // params shaped like:
  // {
  //   isDefault: boolean,
  //   savedSearchId: string,
  //   user: object,
  //   dataDict: object
  // }
  e.preventDefault();
  //let {dataDict} = this.props;
  //let {geographyParams, searchParams, searchName} = this.state;
  console.log("Deleting search: ", params.savedSearchId);
  // send it to the database
  if (!params.isDefault) {
    try {
      await db
        .collection("searches")
        .doc(params.user.uid)
        .collection("params")
        .doc(params.savedSearchId)
        .delete();
      if (cb) {
        cb(null, params.savedSearchId);
      }
      console.log("Document successfully deleted!");
    } catch (error) {
      if (cb) {
        cb(error);
      }
      console.error(
        "Something went wrong deleting the search. ",
        error.message
      );
    }
  } else {
    try {
      await db
        .collection("searches")
        .doc(params.user.uid)
        .collection("params")
        .doc(params.dataDict.index)
        .delete();

      if (cb) {
        cb(null, params.dataDict.index);
      }
      console.log("Document successfully deleted!");
    } catch (error) {
      cb(error);
      console.log(
        `Something went wrong deleting the default search. ${error.message}`
      );
    }
  }
}

async function updateSavedSearch(e, params, cb) {
  // params shaped like
  // {
  //   savedSearch,
  //   newName,
  //   user,
  //   savedSearchId
  // }

  e.preventDefault();
  let newSavedSearch = { ...params.savedSearch };
  newSavedSearch.name = params.newName;
  await db
    .collection("searches")
    .doc(params.user.uid)
    .collection("params")
    .doc(params.savedSearchId)
    .set(newSavedSearch)
    .then(id => {
      if (cb) {
        cb(null, id);
      }
    })
    .catch(err => {
      if (cb) {
        cb(err, null);
      }
    });
}

async function setSavedSearch(params, cb) {
  // Handle Shared Searches and Reusing searches from Downloads
  // params shaped like
  // {
  //   handleState: function,
  //   dataDict: object
  //   dicts: array,
  //   user: object
  // }
  //console.log("setSavedSearch params: ", params);
  let { handleState, dataDict, dicts, user } = params;
  let thisQueryString = queryString.parse(window.location.search);
  let { downloadSearch, aid, savedSearch, uid } = thisQueryString;

  async function getSearch(ref) {
    let search, searchId;
    await ref
      .get()
      .then(doc => {
        if (doc.exists) {
          search = doc.data();
          searchId = doc.id;
        } else {
          console.log("doc doesn't exist");
        }
      })
      .catch(console.log);
    //console.log("search: ", search);
    if (typeof search === "undefined") {
      return search;
    }

    return [search, searchId];
  }
  //console.log("thisQueryString: ", thisQueryString, "dataDict: ", dataDict);
  let search, type;
  if (downloadSearch && aid) {
    // get the download
    let dRef = db
      .collection("downloads")
      .doc(aid)
      .collection("files")
      .doc(downloadSearch);
    search = await getSearch(dRef);
    type = "download";
  }
  if (savedSearch && uid) {
    //console.log("savedSearch: ", savedSearch);
    let sRef = db
      .collection("searches")
      .doc(uid)
      .collection("params")
      .doc(savedSearch);
    search = await getSearch(sRef);
    type = "search";
  }

  if ((savedSearch && uid) || (downloadSearch && aid)) {
    //console.log("search: ", search);
    if (typeof search === "undefined") {
      return cb({ message: "Saved search not found :(" });
    }
    let ss = search[0];
    let ssId = search[1];
    //console.log("dataDict: ", dataDict);
    if (!dataDict) {
      dataDict = dicts.find(dict => {
        //console.log("search: ", ss.queryUrl.dictId, "dict: ", dict.dictId);
        return dict.dictId === ss.queryUrl.dictId;
      });
    }
    if (search && ss.queryUrl.index && ss.queryUrl.index === dataDict.index) {
      let newSavedSearch = {
        type,
        dataDict,
        geographyParams: ss.geographyParams,
        searchParams: ss.searchParams,
        savedSearchId: ssId,
        savedSearch: ss,
        currentSearch: ss.queryUrl
      };
      // Only do this if it is the current user's Search user.uid === uid so it shows as already saved.
      //console.log("user: ", typeof user);
      if (typeof user !== "undefined" ? user.uid === uid : false) {
        newSavedSearch.currentSearch = ss.queryUrl;
      }
      handleState(newSavedSearch);
      if (cb) {
        return cb(null, newSavedSearch);
      }
    }
  }
  return;
}

const SavedSearch = props => {
  const {
    canSave,
    dataDict,
    geographyParams,
    searchParams,
    hasGeo,
    currentSearch,
    savedSearch,
    savedSearchId,
    count,
    onDelete,
    onSave,
    fetchCount,
    handleAlerts,
    user,
    isModal,
    userDoc
  } = props;
  //console.log("SavedSearch props: ", props);
  const [searchName, setSearchName] = useState("");
  const [feedback, setFeedback] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  //console.log( typeof savedSearch.count )
  return (
    <div className={props.className} style={props.style}>
      {feedback && feedback}
      <Card className="shadow" border={dataDict.themeColor}>
        {canSave && (
          <Card.Header className="p-2">
            <Form.Group>
              <Form.Label>Search name</Form.Label>
              <Form.Control
                value={
                  canSave
                    ? searchName
                    : savedSearch
                    ? savedSearch.searchName
                    : "DEFAULT SEARCH"
                }
                onChange={e => setSearchName(e.target.value)}
                type="text"
                placeholder={"Name this search"}
                className="searchSelectedName"
                readOnly={!canSave}
              />
            </Form.Group>
          </Card.Header>
        )}
        {!canSave && (
          <Card.Header
            className={`bg-${dataDict.themeColor} text-white d-flex justify-content-between align-items-center`}
          >
            {savedSearch.searchName
              ? savedSearch.searchName
              : `${dataDict.type.toUpperCase()} DEFAULT`}
          </Card.Header>
        )}
        <Card.Body>
          {!hasGeo && <div>Select a geography to save this search</div>}
          {hasGeo && (
            <Row noGutters className="text-left">
              <Col xs={12} sm={6}>
                <SelectedParams
                  selectedParams={geographyParams}
                  dict={dataDict["geography"]}
                  dictKey="geography"
                  paramHeader="Geography"
                />
              </Col>
              <Col xs={12} sm={6}>
                <SelectedParams
                  selectedParams={searchParams || {}}
                  dict={dataDict["cols"]}
                  dictKey="cols"
                  paramHeader={`${dataDict.type} filters`}
                />
              </Col>
              {!["object", "undefined"].includes(typeof savedSearch.count) && (
                <Col
                  xs={12}
                  sm={12}
                  className="d-flex justify-content-between pt-3 px-3 pb-0 border-top mt-2 mb-0"
                >
                  <span style={{ fontSize: "1.1em" }}>Leads count:</span>{" "}
                  <span style={{ fontSize: "1.3em" }}>
                    {savedSearch.count.toLocaleString()}
                  </span>
                </Col>
              )}
            </Row>
          )}
        </Card.Body>
        <Card.Footer>
          <div className="d-flex justify-content-between">
            {!canSave && savedSearch && (
              <React.Fragment>
                <Button
                  variant="danger"
                  onClick={e => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this search?"
                      )
                    ) {
                      deleteSavedSearch(
                        e,
                        { isDefault: false, savedSearchId, dataDict, user },
                        (error, result) => {
                          if (error) {
                            setFeedback(
                              <Alert variant="warning">{error.message}</Alert>
                            );
                          }
                          if (result) {
                            onDelete();
                          }
                        }
                      );
                    }
                  }}
                  disabled={savedSearch.uid !== user.uid}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete
                </Button>
                <ButtonGroup>
                  <ShareButton
                    type="search"
                    searchId={savedSearchId}
                    uid={savedSearch.uid}
                    cb={() => {
                      console.log(
                        "Copied search share link to your clipboard."
                      );
                      if (isModal) {
                        setFeedback(
                          <Alert
                            variant="success"
                            dismissible
                            onClose={() => setFeedback("")}
                          >
                            Copied the link to your search to your clipboard.
                          </Alert>
                        );
                      }
                      if (!isModal) {
                        handleAlerts(
                          "",
                          "Copied the link to your search to your clipboard.",
                          "success"
                        );
                      }
                    }}
                  >
                    <Button className="ml-2" variant="outline-primary">
                      Copy Link&nbsp;
                      <i className="fa fa-clone" aria-hidden="true"></i>
                    </Button>
                  </ShareButton>
                  {props.reuseAction && (
                    <Button
                      onClick={e => {
                        e.preventDefault();
                        props.reuseAction(e, savedSearch);
                      }}
                      variant="outline-primary"
                    >
                      Reuse search
                    </Button>
                  )}
                  {!props.reuseAction && (
                    <Link
                      to={`/_search/${savedSearch.queryUrl.dictId}?savedSearch=${savedSearchId}&uid=${savedSearch.uid}`}
                      className="btn btn-outline-primary"
                    >
                      Reuse search
                    </Link>
                  )}
                  <Button onClick={() => setShowShareModal(true)}>
                    Share <i className="fa fa-share" aria-hidden="true"></i>
                  </Button>
                </ButtonGroup>
              </React.Fragment>
            )}
            {canSave && (
              <React.Fragment>
                <span>&nbsp;</span>
                <Button
                  variant={canSave ? dataDict.themeColor : "danger"}
                  onClick={async e => {
                    if (!searchName) {
                      return setFeedback(
                        <Alert
                          variant="warning"
                          dismissible
                          onClose={() => setFeedback("")}
                        >
                          Please name your search before saving.
                        </Alert>
                      );
                    }
                    await fetchCount(e);
                    saveSearch(
                      e,
                      {
                        geographyParams,
                        searchParams,
                        queryUrl: currentSearch,
                        count,
                        searchName,
                        user,
                        dataDict
                      },
                      (error, result) => {
                        if (error) {
                          setFeedback(
                            <Alert
                              variant="warning"
                              dismissible
                              onClose={() => setFeedback("")}
                            >
                              {error.message}
                            </Alert>
                          );
                        }
                        if (result) {
                          setFeedback(
                            <Alert
                              variant="success"
                              dismissible
                              onClose={() => setFeedback("")}
                            >
                              Wahoo, your search was saved!
                            </Alert>
                          );
                          onSave(result.savedSearch, result.savedSearchId);
                        }
                      }
                    );
                  }}
                  disabled={!hasGeo}
                  className="pull-right"
                >
                  Save search
                </Button>
              </React.Fragment>
            )}
          </div>
        </Card.Footer>
      </Card>
      <Modal
        size="xl"
        show={showShareModal}
        onHide={() => setShowShareModal(false)}
      >
        <Modal.Body>
          <ComposeEmail
            {...props}
            title="Share search"
            from={{
              email: "wyatt@listshack.com",
              name: "List Shack"
            }}
            plainText={`Check out this search from ${process.env.REACT_APP_site_name}.`}
            appendPlainText={plaintext =>
              `${plaintext} ${shareLink({
                type: "search",
                searchId: savedSearchId,
                uid: savedSearch.uid
              })}`
            }
            subject={`${userDoc.companyProfile.name} is sharing a search from ${process.env.REACT_APP_site_name}`}
            wrapHtml={text => `<div style="display: block; margin-bottom: 30px">${text}<br />&mdash;${
              userDoc.companyProfile.name
            }</div>
              <div style="display: block">${searchCardHtml({
                savedSearch,
                savedSearchId
              })}</div>`}
            consolidated={true}
            hide={["subject", "from"]}
            onSent={({ sent }) => {
              const userRef = db.collection("users").doc(userDoc._docId);
              // Handle folks trying to cheat
              let cEmails = [];
              sent.map(email => {
                let tEmail = email.replace(/(?=\+)(.*?)(?=\@)/, "");
                //console.log("tEmail: ", tEmail);
                if (!cEmails.includes(tEmail)) {
                  return cEmails.push(tEmail);
                }
                return;
              });

              let cleanedEmails = removeItemAll(cEmails, props.userDoc.email);
              cleanedEmails = removeItemAll(
                cEmails,
                props.userDoc.companyProfile.email
              );
              let leadsEarned =
                cleanedEmails.length *
                parseInt(process.env.REACT_APP_share_incentive);

              try {
                userRef.update({
                  leadsRemaining: firebase.firestore.FieldValue.increment(
                    leadsEarned
                  )
                });
                userRef.collection("promos").add({
                  timestamp: new Date(),
                  type: "free_leads",
                  code: "jan21_share_list_shack",
                  details: {
                    value: leadsEarned,
                    type: "leads",
                    emails: sent
                  },
                  uid: userDoc.uid,
                  email: userDoc.email
                });
                setShowShareModal(false);
                handleAlerts(
                  "",
                  `We've successfully shared your search with ${sent.join(
                    ", "
                  )}.  We've added ${leadsEarned.toLocaleString()} free leads to your account!`,
                  "success",
                  "You earned free leads"
                );
              } catch (err) {
                console.log(
                  "Something went wrong sharing search via email: ",
                  err
                );
              }
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

SavedSearch.propTypes = {
  dataDict: PropTypes.object.isRequired,
  geographyParams: PropTypes.object.isRequired,
  searchParams: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isModal: PropTypes.bool.isRequired,
  savedSearch: PropTypes.object.isRequired,
  savedSearchId: PropTypes.string
};

class Searches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      savedDownloads: [],
      downloadsIds: {},
      selectedDownload: null,
      dlProblem: "",
      displayModal: false
    };
    this.fetchSavedDownloads = this.fetchSavedDownloads.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalClose() {
    this.setState({ displayModal: false });
  }

  async fetchSavedDownloads() {
    //Get data from firestore
    const uid = this.props.userDoc.uid;
    let sdlRef = db
      .collection("searches")
      .doc(uid)
      .collection("params"); //.where("deleted", "!=", true );
    if (this.props.dict) {
      sdlRef = sdlRef.where("queryUrl.index", "==", this.props.dict.index);
    }
    try {
      await sdlRef.onSnapshot(async querySnapshot => {
        let fetchedDownloads = [];

        querySnapshot.forEach(doc => {
          //console.log(doc.id, ": ", doc.data());
          let docData = doc.data();
          docData.docId = doc.id;
          fetchedDownloads.push(docData);
        });

        let sortedDownloads = [];
        if (fetchedDownloads.length) {
          sortedDownloads = fetchedDownloads.sort((a, b) => {
            let aV = a.timestamp.seconds;
            let bV = b.timestamp.seconds;
            return aV - bV;
          });
        }

        await this.setState({
          savedDownloads: sortedDownloads,
          isLoaded: true
        });
      });
    } catch (error) {
      await console.error("Error fetching saved downloads: ", error);

      await this.setState({
        isLoaded: true
        //Let the user know something went wrong
      });
    }
  }

  render() {
    let { dicts, handleAlerts } = this.props;
    let downloads = this.state.savedDownloads;
    //let downloadsIds = this.state.downloadsIds;
    //console.log("Downloads state: ", this.state, "Downloads props: ", this.props);
    //console.log("downloads: ", downloads);

    let navItems = [];
    let tabPanes = [];
    navItems.push(
      <AutoComplete
        key="niSearch"
        suggestions={downloads}
        placeholder="Find saved searches"
        onSelect={suggestion => console.log("Selected: ", suggestion)}
        size="md"
        searchKey="searchName"
        suggestion={suggestion => {
          console.log("suggestion: ", suggestion);
          return (
            <Nav.Link
              eventKey={suggestion.timestamp.seconds}
              style={{
                fontSize: ".8em",
                margin: "0 auto",
                padding: "0px"
              }}
              className="d-flex justify-content-between"
            >
              <span>{suggestion.searchName}</span>
              <span className="text-muted">{suggestion.count} leads</span>
            </Nav.Link>
          );
        }}
      />
    );
    if (downloads.length > 0) {
      for (let [i, download] of downloads.entries()) {
        //console.log(new Date(download.timestamp.seconds * 1000).toString());
        let dict = dicts.find(dict => dict.index === download.queryUrl.index);
        //console.log("dict: ", dict);
        navItems.push(
          <Nav.Item key={`ni${i}`}>
            <Nav.Link eventKey={download.timestamp.seconds}>
              {download.searchName
                ? download.searchName
                : `${dict.type.toUpperCase()} DEFAULT`}
            </Nav.Link>
          </Nav.Item>
        );
        tabPanes.push(
          <Tab.Pane key={`pane${i}`} eventKey={download.timestamp.seconds}>
            <SavedSearch
              {...this.props}
              canSave={false}
              dataDict={dict}
              geographyParams={download.geographyParams}
              searchParams={download.searchParams}
              hasGeo={true}
              savedSearch={download}
              savedSearchId={download.docId}
              user={this.props.user}
              onDelete={() => {
                handleAlerts(
                  "",
                  <React.Fragment>
                    Search <b>{download.searchName}</b> deleted!
                  </React.Fragment>,
                  "success"
                );
              }}
              isModal={this.props.isModal ? this.props.isModal : false}
              reuseAction={
                this.props.reuseAction ? this.props.reuseAction : undefined
              }
            />
          </Tab.Pane>
        );
      }
    }

    if (this.state.isLoaded) {
      //console.log("tabPanes: ", tabPanes, "navItems: ", navItems);
      return (
        <Container>
          <Row style={{ marginTop: "30px" }}>
            <Col style={{ minHeight: "80vh" }}>
              <h1>Saved Searches</h1>
              <hr />
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={
                  this.props.displaySearch
                    ? this.props.displaySearch.timestamp.seconds
                    : downloads.length
                    ? downloads[downloads.length - 1].timestamp.seconds
                    : -1
                }
              >
                <Row>
                  <Col sm={3} md={4}>
                    <Nav
                      variant="pills"
                      className="flex-column"
                      style={{
                        maxHeight: "80vh",
                        overflow: "hidden",
                        flexWrap: "nowrap",
                        overflowY: "auto",
                        backgroundColor: "#f5f5f5"
                      }}
                    >
                      {navItems.length === 1 ? (
                        <Nav.Item>
                          <Nav.Link eventKey={-1}>Saved Searches</Nav.Link>
                        </Nav.Item>
                      ) : (
                        navItems
                      )}
                    </Nav>
                  </Col>
                  <Col sm={9} md={8}>
                    <Tab.Content>
                      {tabPanes.length === 0 ? (
                        <Tab.Pane eventKey={-1}>
                          <p className="lead">
                            Searches you save from the{" "}
                            <Link to="/search">Search</Link> page will be saved
                            here for your convenience.
                          </p>
                        </Tab.Pane>
                      ) : (
                        tabPanes
                      )}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container>
          <Row style={{ marginTop: "30px" }}>
            <Col style={{ minHeight: "80vh" }}>
              <h1>Downloads</h1>
              <hr />
              <br />
              <br />
              <Spinner animation="grow" variant="primary" size="lg" />
              <h2>Getting your saved searches. Hold tight!</h2>
            </Col>
          </Row>
        </Container>
      );
    }
  }

  componentDidMount() {
    this.fetchSavedDownloads();
  }
}

Searches.propTypes = {
  user: PropTypes.object
};

export {
  SavedSearch,
  saveSearch,
  deleteSavedSearch,
  updateSavedSearch,
  setSavedSearch,
  Searches
};
