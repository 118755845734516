import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Terms, Privacy, CcpaOptout, Ccpa} from "./interfaceListShackPro.jsx";

import {
  HomeLsio,
  PricingLsio,
  ContactLsio,
  SignupLsio
} from "./interfaceListShackio.jsx";

const RoutesLs = props => {
  let { user } = props;
  return (
    <div>
      <Route exact path="/">
        <Helmet>
          <title>ListShack.io</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <HomeLsio user={user} />
      </Route>
      
      <Route exact path="/home">
        <Helmet>
          <title>ListShack.io</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <HomeLsio user={null} />
      </Route>
      
      <Route path="/pricing">
        <Helmet>
          <title>Pricing</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <PricingLsio />
      </Route>
          
      <Route path="/contact">
        <Helmet>
          <title>Contact Us</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ContactLsio />
      </Route>

      <Route path="/signup">
        <Helmet>
          <title>Signup</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <SignupLsio />
      </Route>
      
      <Route path="/terms">
        <Helmet>
          <title>Terms of service</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Terms />
      </Route>
      
      <Route path="/privacy">
        <Helmet>
          <title>Privacy policy</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Privacy />
      </Route>
      
      <Route exact path="/ccpa_optout">
        <Helmet>
          <title>California Consumer Optout</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <CcpaOptout {...props} />
      </Route>
      
      <Route exact path="/ccpa">
        <Helmet>
          <title>CCPA  Disclosures</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Ccpa />
      </Route>
    </div>
  );
};

export default RoutesLs;
